import React from 'react';
import cx from 'classnames';
import { WebViewerInstance } from '@pdftron/webviewer';
import {
    Root as AccordionRoot,
    Item as AccordionItem,
    Trigger as AccordionTrigger,
    Content as AccordionContent
} from '@radix-ui/react-accordion';

import Client from './Client';
import Sender from './Sender';
import UploadedDocumentInfoCard from './UploadedDocumentInfoCard';
import Icon, { Icons } from '../../../common/components/Icon';
import { AccountType } from '../../../constants/constants';
import { removeFileExtension } from '../../../utils/documentUtils.js';
import { DeliveryType, DOCUMENT_DELIVERY_TYPE_EMAIL } from '../../../config';
import {
    Client as ClientType,
    CustomPlaceholder as CustomPlaceholderType,
    isUploadADocOrTemplate
} from '../../../types/UploadADoc';
import { Point } from '../types';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { DOCUMENTS_DROPDOWN } from '../../../constants/featureFlags';
import Button from '../../../common/components/Button.js';
import { UploadedDocumentState } from './useUploadedDocumentState';
import CustomPlaceholderAccordionContent from './CustomPlaceholderAccordionContent';
import CustomPlaceholderModal from './CustomPlaceholderModal';
import { deleteAnnotationsForCustomPlaceholder } from '../utils';
import { DocumentWithUploadedDocuments } from '../PdfViewer';
import { ClientType as EocClientType, EocClient, isExchangeOfContracts } from '@app/types/ExchangeOfContracts';

import styles from './Sidebar.module.scss';

export enum SidebarAccordionItems {
    CLIENTS = 'clients',
    DOCUMENTS = 'documents',
    CUSTOM_PLACEHOLDERS = 'customPlaceholders'
}
type SidebarProps<T extends DocumentWithUploadedDocuments> = {
    className?: string;
    clients: (Pick<ClientType, 'name' | 'email' | 'phone'> & { id?: ClientType['id'] })[];
    document: T;
    uploadedDocumentStateItems: UploadedDocumentState[];
    selectedUploadedDocId?: string;
    onClickDocument: (documentId: string) => void;
    agent: {
        fullName: string;
        accountType: AccountType;
    };
    deliveryType?: DeliveryType;
    selectedWebViewerInstance?: WebViewerInstance;
    dropPointRef: React.MutableRefObject<Point | undefined>;
    isOnboarding: boolean;
    customPlaceholders?: CustomPlaceholderType[];
    defaultOpenItems?: SidebarAccordionItems[];
    hideCustomPlaceholderTitles: boolean;
    toggleHideCustomPlaceholderTitles: () => void;
    setHoveredCustomPlaceholderId: (customPlaceholderId?: string) => void;
};

function Sidebar<T extends DocumentWithUploadedDocuments>({
    className,
    clients,
    document,
    uploadedDocumentStateItems,
    selectedUploadedDocId,
    onClickDocument,
    selectedWebViewerInstance,
    dropPointRef,
    isOnboarding,
    customPlaceholders,
    defaultOpenItems,
    hideCustomPlaceholderTitles,
    toggleHideCustomPlaceholderTitles,
    setHoveredCustomPlaceholderId,
    agent,
    deliveryType
}: SidebarProps<T>): React.ReactElement {
    const isEoc = isExchangeOfContracts(document);
    const isUad = isUploadADocOrTemplate(document);

    const isDocumentsDropdownActive = useFeatureFlag(DOCUMENTS_DROPDOWN);
    const [isCustomPlaceholdersModalOpen, setIsCustomPlaceholdersModalOpen] = React.useState(false);

    const [openItems, setOpenItems] = React.useState<SidebarAccordionItems[]>([
        SidebarAccordionItems.CLIENTS,
        SidebarAccordionItems.DOCUMENTS
    ]);

    React.useEffect(() => {
        if (defaultOpenItems) {
            setOpenItems(defaultOpenItems);
        }
    }, [defaultOpenItems]);

    const purchasers = React.useMemo(
        () => (isEoc ? (clients as EocClient[]).filter(client => client.clientType === EocClientType.PURCHASER) : []),
        [isEoc, clients]
    );

    const vendors = React.useMemo(
        () => (isEoc ? (clients as EocClient[]).filter(client => client.clientType === EocClientType.VENDOR) : []),
        [isEoc, clients]
    );

    return (
        <>
            <AccordionRoot
                asChild
                type="multiple"
                value={openItems}
                onValueChange={items => setOpenItems(items as SidebarAccordionItems[])}
            >
                <div className={cx(styles.sidebar, className)}>
                    <Sender
                        name={agent.fullName || 'Your name here'}
                        accountType={agent.accountType || AccountType.GENERAL_ACCOUNT_TYPE}
                        webViewerInstance={selectedWebViewerInstance}
                        dropPointRef={dropPointRef}
                        agentName={agent.fullName}
                        isOnboarding={isOnboarding}
                        isEoc={isEoc}
                    />
                    <AccordionItem
                        value={SidebarAccordionItems.CLIENTS}
                        className={cx(styles.accordionSection, styles.clientSection)}
                    >
                        <AccordionTrigger className={styles.sectionHeader}>
                            <hr className={styles.divider} />
                            <div className={styles.sectionHeaderContent}>
                                <span className={styles.sectionHeading}>Signers</span>
                                <Icon className={styles.sectionHeaderIcon} icon={Icons.CHEVRON_DOWN} />
                            </div>
                            <hr className={styles.divider} />
                        </AccordionTrigger>
                        <AccordionContent className={styles.clients}>
                            {isUad && (
                                <div className={styles.clientGroup}>
                                    {clients?.map((client, index) => (
                                        <Client
                                            key={index}
                                            details={{
                                                name: client.name,
                                                contact:
                                                    deliveryType === DOCUMENT_DELIVERY_TYPE_EMAIL
                                                        ? client.email
                                                        : client.phone,
                                                id: client.id
                                            }}
                                            clientIndex={index}
                                            webViewerInstance={selectedWebViewerInstance}
                                            dropPointRef={dropPointRef}
                                            canAddWitness={isUad}
                                            className={cx(styles.client, {
                                                [styles.firstClient]: index === 0,
                                                [styles.lastClient]: index === clients.length - 1
                                            })}
                                        />
                                    ))}
                                </div>
                            )}
                            {isEoc && (
                                <>
                                    <h4 className={styles.clientsHeader}>Purchasers</h4>
                                    <div className={styles.clientGroup}>
                                        {purchasers.map((client, index) => (
                                            <Client
                                                key={index}
                                                details={{
                                                    name: client.name,
                                                    contact: client.email,
                                                    id: client.id
                                                }}
                                                clientIndex={index}
                                                webViewerInstance={selectedWebViewerInstance}
                                                dropPointRef={dropPointRef}
                                                canAddWitness={isUad}
                                                className={cx(styles.client, {
                                                    [styles.firstClient]: index === 0,
                                                    [styles.lastClient]: index === purchasers.length - 1
                                                })}
                                            />
                                        ))}
                                    </div>
                                    <h4 className={styles.clientsHeader}>Vendors</h4>
                                    <div className={styles.clientGroup}>
                                        {vendors.map((client, index) => (
                                            <Client
                                                key={index}
                                                details={{
                                                    name: client.name,
                                                    contact: client.email,
                                                    id: client.id
                                                }}
                                                clientIndex={index + purchasers.length}
                                                webViewerInstance={selectedWebViewerInstance}
                                                dropPointRef={dropPointRef}
                                                canAddWitness={isUad}
                                                className={cx(styles.client, {
                                                    [styles.firstClient]: index === 0,
                                                    [styles.lastClient]: index === vendors.length - 1
                                                })}
                                            />
                                        ))}
                                    </div>
                                </>
                            )}
                        </AccordionContent>
                    </AccordionItem>
                    {!isDocumentsDropdownActive && (
                        <>
                            <AccordionItem
                                value={SidebarAccordionItems.DOCUMENTS}
                                className={cx(styles.accordionSection, styles.documentSection)}
                            >
                                <AccordionTrigger className={styles.sectionHeader}>
                                    <hr className={styles.divider} />
                                    <div className={styles.sectionHeaderContent}>
                                        <div className={styles.sectionCount}>{document.uploadedDocuments.length}</div>
                                        <span className={styles.sectionHeading}>Documents</span>
                                        <Icon className={styles.sectionHeaderIcon} icon={Icons.CHEVRON_DOWN} />
                                    </div>
                                    <hr className={styles.divider} />
                                </AccordionTrigger>
                                <AccordionContent className={styles.uploadedDocuments}>
                                    {document.uploadedDocuments?.map(uploadedDocument => {
                                        const uploadedDocumentState = uploadedDocumentStateItems.find(
                                            item => item.uploadedDocumentId === uploadedDocument.id
                                        );

                                        return (
                                            <Button
                                                key={uploadedDocument.id}
                                                onClick={() => onClickDocument(uploadedDocument.id)}
                                                type="button"
                                            >
                                                <UploadedDocumentInfoCard
                                                    displayName={removeFileExtension(
                                                        uploadedDocument.document.documentName
                                                    )}
                                                    isSelected={uploadedDocument.id === selectedUploadedDocId}
                                                    clientSignatureCounts={
                                                        uploadedDocumentState?.clientAnnotationsCounts
                                                            .signatureAnnotationCounts
                                                    }
                                                    agentSignatureCount={
                                                        uploadedDocumentState?.agentSignatureAnnotationCount
                                                    }
                                                />
                                            </Button>
                                        );
                                    })}
                                </AccordionContent>
                            </AccordionItem>
                        </>
                    )}
                    <>
                        <AccordionItem
                            value={SidebarAccordionItems.CUSTOM_PLACEHOLDERS}
                            className={cx(styles.accordionSection, styles.customPlaceholderSection)}
                        >
                            <AccordionTrigger className={styles.sectionHeader}>
                                <hr className={styles.divider} />
                                <div className={styles.sectionHeaderContent}>
                                    <span className={styles.sectionHeading}>Custom info placeholders</span>
                                    <Button
                                        type="button"
                                        onClick={e => {
                                            e.stopPropagation();
                                            setIsCustomPlaceholdersModalOpen(true);
                                        }}
                                        className={styles.editCustomPlaceholdersButton}
                                    >
                                        Add/Edit
                                    </Button>
                                    <Icon className={styles.sectionHeaderIcon} icon={Icons.CHEVRON_DOWN} />
                                </div>
                                <hr className={styles.divider} />
                            </AccordionTrigger>
                            <AccordionContent className={styles.customPlaceholders}>
                                <CustomPlaceholderAccordionContent
                                    customPlaceholderData={customPlaceholders}
                                    webViewerInstance={selectedWebViewerInstance}
                                    dropPointRef={dropPointRef}
                                    hideCustomPlaceholderTitles={hideCustomPlaceholderTitles}
                                    toggleHideCustomPlaceholderTitles={toggleHideCustomPlaceholderTitles}
                                    setHoveredCustomPlaceholderId={setHoveredCustomPlaceholderId}
                                />
                            </AccordionContent>
                        </AccordionItem>
                    </>
                </div>
            </AccordionRoot>
            <CustomPlaceholderModal
                isOpen={isCustomPlaceholdersModalOpen}
                close={() => setIsCustomPlaceholdersModalOpen(false)}
                deleteAnnotationsForCustomPlaceholder={customPlaceholderId =>
                    deleteAnnotationsForCustomPlaceholder(customPlaceholderId, uploadedDocumentStateItems)
                }
            />
        </>
    );
}

export default Sidebar;
