import { LEASE_STATUS_AWAITING_COMPLETION, LEASE_STATUS_SENT_SIGNING } from '@app/actions/dashboard';
import { ClientType, EocClient, ExchangeOfContracts } from '@app/types/ExchangeOfContracts';
import { CompletionStatus } from '@app/config';

export const COMPLETE_FORM_ID = 'complete-eoc-form';
type UiModel = {
    showEmailForm: boolean;
    showCustomPlaceholders: boolean;
    showAuditTrail: boolean;
    showSentForSigningFooter: boolean;
    showAwaitingCompletionFooter: boolean;
    purchasers: EocClient[];
    vendors: EocClient[];
    isCompletionInProgress: boolean;
    showSignatures: boolean;
};

export const projectUiModel = (eocDocument: ExchangeOfContracts): UiModel => {
    return {
        showEmailForm: eocDocument.status === LEASE_STATUS_AWAITING_COMPLETION,
        showCustomPlaceholders: eocDocument.customPlaceholders.length > 0,
        showAuditTrail: eocDocument.auditTrail.length > 0,
        showSentForSigningFooter: eocDocument.status === LEASE_STATUS_SENT_SIGNING,
        showAwaitingCompletionFooter: eocDocument.status === LEASE_STATUS_AWAITING_COMPLETION,
        purchasers: eocDocument.client?.clients.filter(client => client.clientType === ClientType.PURCHASER) || [],
        vendors: eocDocument.client?.clients.filter(client => client.clientType === ClientType.VENDOR) || [],
        isCompletionInProgress:
            !!eocDocument.completion?.status &&
            (eocDocument.completion.status === CompletionStatus.Started ||
                eocDocument.completion.status === CompletionStatus.Processing),
        showSignatures: eocDocument.status === LEASE_STATUS_SENT_SIGNING
    };
};

export const shouldShowClientLink = (eocDocument: ExchangeOfContracts, client: EocClient) => {
    const signingClientOrder = eocDocument.client?.currentSigningClientOrder;
    const clientOrder = client.signingOrder;

    if (!signingClientOrder || !clientOrder) {
        return false;
    }

    const isBlockedFromSigning = clientOrder > signingClientOrder;

    return eocDocument.status === LEASE_STATUS_SENT_SIGNING && !eocDocument.isLinkExpired && !isBlockedFromSigning;
};
