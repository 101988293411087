import React, { memo, useEffect, useState } from 'react';
import cx from 'classnames';
import { SelectField } from '../../../../components/form/FormSelect';
import Button from '../../../../common/components/Button';
import { cloneDeep, isEmpty } from 'lodash';
import axios from 'axios';
import { cancelSigningDocumentAction, refreshDocumentList } from '../../../../actions/document';
import {
    COMPLETION_STATUS_ERROR,
    COMPLETION_STATUS_PROCESSING,
    FORM_SUBMIT_TYPE_SAVE,
    FORM_SUBMIT_TYPE_SEND,
    HIDE_MESSAGE_TIME,
    DOCUMENT_DELIVERY_TYPE_EMAIL,
    DOCUMENT_DELIVERY_TYPE_SMS,
    DOCUMENT_DELIVERY_TYPE_QR_CODE
} from '../../../../config';
import { openNoSignatureModal } from '../../../../actions/user';
import ButtonGroup from '../../../../common/components/ButtonGroup';
import { isDocumentCreateMode, isDocumentEditMode } from '../../../../utils/generalUtils';
import {
    LEASE_STATUS_AWAITING_COMPLETION,
    LEASE_STATUS_COMPLETE,
    LEASE_STATUS_SENT_SIGNING
} from '../../../../actions/dashboard';
import ResendDocForSigningModal from '../../../../components/modals/ResendForSigningModal/ResendDocForSigningModal';
import { DEADLINE_OPTIONS } from '../../../../utils/formUtils';
import { useDispatch, useSelector } from 'react-redux';
import { getAgentSignature, getCompletionForm } from '../../../../selectors/completion';
import ReactTooltip from 'react-tooltip';
import CompletionProgress from '../../../../components/dashboard/InfoAgreementComponents/footerComponents/CompletionProgress';
import {
    ACKNOWLEDGE_CONFIRMATION_TYPE,
    SIGN_CONFIRMATION_TYPE,
    EXECUTION_TYPE_EMBEDDED_SIGNATURE,
    PDF_GEN_PENDING,
    PDF_GEN_PROCESSING
} from '../../../../constants/constants';
import { confirmAlert } from 'react-confirm-alert';
import AgreementMainAuditTrail from '../../../../components/dashboard/InfoAgreementComponents/AgreementMainComponents/shared/AgreementMainAuditTrail';
import { getGeneralAppLabel } from '../../../../utils/labelUtils';
import { useAccountProvider } from '../../../../components/providers/AccountProvider';
import { getCurrentDocumentAgentName } from '../../../../selectors/document';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { DOC_COMPLETION_EMAIL_SEND_LIST } from '../../../../constants/featureFlags';
import { CheckboxCheck } from '../../../../components/form/FormCheckboxCheck';
import EditSenderConfirmation from '../../../../components/modals/document/EditSenderConfirmation';
import { DOCUMENT_CUSTOM } from '../../../../config';
import { getUserInfo } from '../../../../selectors/user';
import { getAllPdfSenderDetails } from '../../../../utils/documentUtils';
import { clearDoubleSendGuard } from '../../../../hooks/useDoubleSendGuard';
import { MobileCompletionProgress } from './MobileCompletionProgress';
import styles from './FlkAPdfFooter.module.scss';

export const viewPdf = (currentDocId, uploadedDocId) => {
    window.open(`/reader/document/custom-document/${currentDocId}/${uploadedDocId}`, '_blank');
};

const viewExecutionPdf = (currentDocId, uploadedDocId) => {
    window.open(`/reader/document/custom-document-execution/${currentDocId}/${uploadedDocId}`, '_blank');
};

export function getViewPDFToolTipText(showToolTip, pdfGenerated) {
    if (pdfGenerated && showToolTip && pdfGenerated === PDF_GEN_PROCESSING) {
        return 'Your PDF is currently getting processed, please wait.';
    } else if (pdfGenerated && showToolTip && pdfGenerated === PDF_GEN_PENDING) {
        return 'You have modified the data of this PDF, please save before viewing.';
    }
    return '';
}

export function ViewDocumentButtonGroup({ documentName, tooltipText, onViewPdf, disabled }) {
    return (
        <div className="section space-between">
            <ButtonGroup
                title={documentName}
                toolTip={tooltipText}
                buttonList={[
                    {
                        onClick: onViewPdf,
                        className: 'logo-button',
                        label: 'View PDF',
                        icon: 'view',
                        disabled: disabled
                    }
                ]}
            />
        </div>
    );
}
export const ViewDocumentWithAcknowledgementButtonGroup = ({
    uploadedDocument,
    showToolTip,
    currentDocument,
    pdfGenerated
}) => {
    return (
        <div className="section space-between">
            <ButtonGroup
                title={uploadedDocument.document.documentName}
                toolTip={getViewPDFToolTipText(showToolTip, pdfGenerated)}
                buttonList={[
                    {
                        onClick: () => viewPdf(currentDocument.id, uploadedDocument.id),
                        className: 'logo-button',
                        label: 'View PDF',
                        icon: 'view',
                        disabled:
                            showToolTip &&
                            pdfGenerated &&
                            (pdfGenerated === PDF_GEN_PROCESSING || pdfGenerated === PDF_GEN_PENDING)
                    },
                    {
                        onClick: () => viewExecutionPdf(currentDocument.id, uploadedDocument.id),
                        className: 'logo-button',
                        label: 'Acknowledgement',
                        icon: 'view'
                    }
                ]}
            />
        </div>
    );
};

function FlkAPdfFooter({
    form,
    values,
    currentDocument,
    documentsList,
    isSubmitting,
    isSubmitFail,
    isSubmitSuccessful,
    documentEditMode,
    customDocumentId,
    setIsSending,
    setIsSendFail,
    setIsSendSuccessful,
    closeModal,
    isSending,
    isSendFail,
    isSendSuccessful,
    isTemplate,
    pdfGenerated,
    setBackendErrors,
    saveDocumentAndRedirectToAnnotationViewer,
    resendWaitSeconds,
    areAllDocumentsInitialised,
    isMobileView,
    isOpenResendModal,
    setIsOpenResendModal
}) {
    const dispatch = useDispatch();

    const optionsNumber = DEADLINE_OPTIONS;
    const agentSignature = useSelector(getAgentSignature);
    const completion = useSelector(getCompletionForm);
    const accountType = useAccountProvider();
    const loggedUserInfo = useSelector(getUserInfo);

    const currentDocumentAgentName = useSelector(getCurrentDocumentAgentName);

    const [isChangeSenderConfirmationModel, setIsChangeSenderConfirmationModel] = useState(false);

    const [signedByAgent, setSignedByAgent] = useState(currentDocument?.confirmationType === SIGN_CONFIRMATION_TYPE);

    const [isCompleteSuccessful, setIsCompleteSuccessful] = useState(false);
    const [isCompleteFail, setIsCompleteFail] = useState(false);

    const [completionProgress, setCompletionProgress] = useState(
        currentDocument && currentDocument.completion && currentDocument.completion.progress
            ? currentDocument && currentDocument.completion && currentDocument.completion.progress
            : []
    );

    const [isCompleting, setIsCompleting] = useState(false);

    const [senderDetailsOnPdf, setSenderDetailsOnPdf] = useState({
        name: '',
        totalAnnotationCount: 0
    });

    const isCompletionSendListActive = useFeatureFlag(DOC_COMPLETION_EMAIL_SEND_LIST);

    useEffect(() => {
        if (currentDocument && currentDocument.completion && currentDocument.completion.progress) {
            setCompletionProgress(currentDocument.completion.progress);
            if (currentDocument.completion.status === COMPLETION_STATUS_ERROR) {
                setIsCompleting(false);
            }
        }

        if (currentDocument && currentDocument.uploadedDocuments) {
            const senderDetails = getAllPdfSenderDetails(currentDocument.uploadedDocuments);
            setSenderDetailsOnPdf(senderDetails);
        }
    }, [currentDocument]);

    const renderSendButton = (isResend = false) => {
        let sendButtonText = 'Send';
        if (resendWaitSeconds > 0) {
            sendButtonText = `Resend in ${resendWaitSeconds} seconds`;
        } else if (values.deliveryType === DOCUMENT_DELIVERY_TYPE_EMAIL) {
            sendButtonText = 'Send via Email';
        } else if (values.deliveryType === DOCUMENT_DELIVERY_TYPE_SMS) {
            sendButtonText = 'Send via SMS';
        }
        return (
            <React.Fragment>
                <div className={cx('send-for-signing-container', styles.footer)}>
                    <div className="deadline-container for-lg-modal">
                        <SelectField
                            label="Deadline"
                            name="deadline"
                            options={optionsNumber}
                            containerClassName="deadline-select"
                        />
                    </div>
                    <br />
                    <div className="footer-help-text for-lg-modal">
                        <p>
                            {`* It is the ${getGeneralAppLabel(
                                'agents',
                                accountType
                            )} responsibility to ensure the uploaded PDF is allowed to be signed in this
                            format.`}
                        </p>
                    </div>
                    {isResend && (
                        <Button
                            primary
                            type="button"
                            className="btn-close"
                            height={48}
                            width={140}
                            onClick={() => setIsOpenResendModal(true)}
                            disabled={resendWaitSeconds > 0}
                            loading={isSending || isSubmitting || !areAllDocumentsInitialised}
                        >
                            Resend{resendWaitSeconds > 0 && ` in ${resendWaitSeconds} seconds`}
                        </Button>
                    )}
                    {!isResend && (
                        <Button
                            primary
                            className="btn-terminate"
                            height={48}
                            width={140}
                            type="submit"
                            // set submitType in here, then use that is handleSubmit to decide send or save
                            onClick={() => {
                                setBackendErrors(null);
                                form.change('submitType', FORM_SUBMIT_TYPE_SEND);
                            }}
                            disabled={
                                isSending ||
                                isSubmitting ||
                                documentsList.length === 0 ||
                                values.clients.length === 0 ||
                                resendWaitSeconds > 0
                            }
                            loading={isSending || isSubmitting || !areAllDocumentsInitialised}
                        >
                            {sendButtonText}
                        </Button>
                    )}
                </div>
            </React.Fragment>
        );
    };

    const renderSaveButton = (isPrimary = false) => {
        const isDisabled = !isTemplate
            ? isSubmitting || isSending || documentsList.length === 0
            : values.deliveryType === DOCUMENT_DELIVERY_TYPE_QR_CODE &&
              values.enableQrCode &&
              documentsList.length === 0;
        return (
            <React.Fragment>
                <div className="save-button">
                    <Button
                        secondary={!isPrimary}
                        primary={isPrimary}
                        className="btn-terminate left-button"
                        height={48}
                        width={140}
                        // set submitType in here, then use that in handleSubmit to decide send or save
                        onClick={() => {
                            setBackendErrors(null);
                            form.change('submitType', FORM_SUBMIT_TYPE_SAVE);
                        }}
                        type="submit"
                        disabled={isDisabled}
                        loading={isSubmitting || isSending || !areAllDocumentsInitialised}
                    >
                        Save
                    </Button>
                </div>
            </React.Fragment>
        );
    };

    const resendCustomDocument = (id, values, selectedClients) => {
        setIsSending(true);
        setIsSendFail(false);
        setIsOpenResendModal(false);

        const data = cloneDeep(values);
        data.selectedClients = selectedClients;

        return axios
            .post(`api/document/${id}/resend-custom-document`, data)
            .then(() => {
                setIsSending(false);
                setIsSendSuccessful(true);
                dispatch(refreshDocumentList());
                setTimeout(() => {
                    setIsSendSuccessful(false);
                    closeModal();
                }, HIDE_MESSAGE_TIME);
            })
            .catch(error => {
                clearDoubleSendGuard();
                setIsSending(false);
                setIsSendSuccessful(false);
                setIsSendFail(true);
                const response = error.response;
                if (response && response.data && response.data.errors) {
                    return response.data.errors;
                }
                return false;
            });
    };

    const completeCustomDocument = id => {
        let data;
        if ((currentDocument.hasAgentSignatureAnnotations || signedByAgent) && isEmpty(agentSignature)) {
            dispatch(openNoSignatureModal());
        } else {
            if (isCompletionSendListActive) {
                data = {
                    signedByAgent,
                    toNotificationList: completion?.toNotificationList,
                    ccNotificationList: completion?.ccNotificationList,
                    bccNotificationList: completion?.bccNotificationList
                };
            } else {
                data = {
                    signedByAgent
                };
            }

            setIsCompleting(true);
            return axios
                .post(`api/document/${id}/custom-document-complete`, data)
                .then(() => {
                    setIsCompleteSuccessful(true);
                    setTimeout(() => {
                        setIsCompleteFail(false);
                    }, HIDE_MESSAGE_TIME);
                })
                .catch(error => {
                    setIsCompleting(false);
                    setIsCompleteSuccessful(false);
                    setIsCompleteFail(true);
                    const response = error.response;
                    if (response && response.data && response.data.errors) {
                        return response.data.errors;
                    }
                    return false;
                });
        }
    };

    const renderCompleteButton = () => {
        return (
            <Button
                primary
                type="button"
                disabled={
                    isCompleting ||
                    (currentDocument.completion && currentDocument.completion.status === COMPLETION_STATUS_PROCESSING)
                }
                className="complete"
                onClick={() => handleConfirmationMessageOrComplete(currentDocument.id)}
                loading={isCompleting}
            >
                {currentDocument.hasAgentSignatureAnnotations && <span>Sign and Complete</span>}
                {!currentDocument.hasAgentSignatureAnnotations && <span>Complete</span>}
                {isCompleteFail && (
                    <span className="savestatus-failed">
                        <span className="icon savestatus-icon" />
                    </span>
                )}
            </Button>
        );
    };

    const renderViewSignedAcknowledgedButton = () => {
        return (
            <div className="section space-between">
                <h6 className="button-group-header">Documents</h6>
                {currentDocument.uploadedDocuments.map((uploadedDocument, index) => {
                    return (
                        <div
                            key={index}
                            className="button-footer-docs"
                            data-test={`${uploadedDocument.id}-footer-buttons`}
                        >
                            {currentDocument && currentDocument.executionType === EXECUTION_TYPE_EMBEDDED_SIGNATURE ? (
                                <ViewDocumentWithAcknowledgementButtonGroup
                                    uploadedDocument={uploadedDocument}
                                    showToolTip={false}
                                    currentDocument={currentDocument}
                                    pdfGenerated={pdfGenerated}
                                />
                            ) : (
                                <ViewDocumentButtonGroup
                                    documentName={uploadedDocument.document.documentName}
                                    tooltipText={getViewPDFToolTipText(true, pdfGenerated)}
                                    disabled={pdfGenerated === PDF_GEN_PROCESSING || pdfGenerated === PDF_GEN_PENDING}
                                    onViewPdf={() => viewPdf(currentDocument.id, uploadedDocument.id)}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };

    /**
     * Cancel signing of document
     * After canceling lease agreement will be moved to the DRAFT status.
     * All signatures will also be removed
     */
    function cancelSigningWithConfirmation() {
        confirmAlert({
            title: '',
            message: 'Are you sure want to cancel? All progress and signatures will be lost.',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        await cancelSigning();
                    }
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        });
    }

    async function cancelSigning() {
        await dispatch(cancelSigningDocumentAction(customDocumentId));
        closeModal();
    }

    const getErrorAndSuccessMessages = () => {
        return (
            (isSubmitSuccessful ||
                isSubmitFail ||
                isCompleteSuccessful ||
                isCompleteFail ||
                isSendSuccessful ||
                isSendFail) && (
                <div className="feedback-message">
                    <div className="button-row">
                        {isSendSuccessful && (
                            <span className="savestatus-saved">
                                <span className="icon savestatus-icon" />
                                Document sent successfully
                            </span>
                        )}
                        {isSendFail && (
                            <span className="savestatus-failed">
                                <span className="icon savestatus-icon" />
                                Document send failed
                            </span>
                        )}
                        {isSubmitSuccessful && (
                            <span className="savestatus-saved">
                                <span className="icon savestatus-icon" />
                                Document saved successfully
                            </span>
                        )}
                        {isSubmitFail && (
                            <span className="savestatus-failed">
                                <span className="icon savestatus-icon" />
                                Document save failed
                            </span>
                        )}
                        {isCompleteFail && (
                            <span className="savestatus-failed">
                                <span className="icon savestatus-icon" />
                                Document completion failed
                            </span>
                        )}
                    </div>
                </div>
            )
        );
    };

    const getButtons = (currentDocument, values) => {
        if (isDocumentCreateMode(documentEditMode) || isDocumentEditMode(documentEditMode)) {
            return (
                <React.Fragment>
                    <div className="footer-draft">
                        {getErrorAndSuccessMessages()}
                        {!isTemplate && (
                            <div className="button-row">
                                <div className="button-footer-docs">{renderSaveButton()}</div>
                                <div className="button-footer-docs">{renderSendButton(false, values)}</div>
                            </div>
                        )}
                    </div>
                    {isTemplate && (
                        <div className="button-row single">
                            <div className="button-footer-docs">{renderSaveButton(true)}</div>
                        </div>
                    )}
                </React.Fragment>
            );
        } else {
            if (currentDocument.status === LEASE_STATUS_SENT_SIGNING) {
                return (
                    <React.Fragment>
                        <div className="footer-draft">
                            {getErrorAndSuccessMessages()}
                            <div className="button-row">
                                <div className="button-footer-docs">
                                    <ReactTooltip
                                        id="cancel-signing"
                                        class="tooltip"
                                        place="bottom"
                                        effect="solid"
                                        globalEventOff="wheel"
                                    >
                                        <p>This will cancel signing and move the document to drafts</p>
                                    </ReactTooltip>
                                    <Button
                                        type="button"
                                        className="cancel-button left-button"
                                        data-tip
                                        data-for="cancel-signing"
                                        secondary
                                        onClick={cancelSigningWithConfirmation}
                                    >
                                        Move to drafts
                                    </Button>
                                </div>
                                <div className="button-footer-docs">{renderSendButton(true, values)}</div>
                            </div>
                        </div>
                    </React.Fragment>
                );
            } else if (currentDocument.status === LEASE_STATUS_AWAITING_COMPLETION) {
                const shouldShowCompleteButton =
                    !currentDocument.completion ||
                    (currentDocument.completion && currentDocument.completion.status === COMPLETION_STATUS_ERROR);
                return (
                    <React.Fragment>
                        {shouldShowCompleteButton && (
                            <div className="footer-draft">
                                <div className="section space-between submit-row">
                                    <CheckboxCheck
                                        className="footer-checkbox"
                                        name="signedByAgent"
                                        label={`Add my signature to the acknowledgement page`}
                                        onClick={value => setSignedByAgent(value)}
                                    />
                                </div>
                                {getErrorAndSuccessMessages()}
                                <div>{renderCompleteButton()}</div>
                            </div>
                        )}
                        {isMobileView ? (
                            <div className={styles.mobileProgress}>
                                <MobileCompletionProgress progress={completionProgress} />
                            </div>
                        ) : (
                            <CompletionProgress progress={completionProgress} />
                        )}
                    </React.Fragment>
                );
            }
        }
    };

    const closeSenderChangeModel = () => {
        setIsChangeSenderConfirmationModel(false);
    };

    const senderChangeConfirmationHandler = () => {
        completeCustomDocument(currentDocument.id);
        setIsChangeSenderConfirmationModel(false);
    };

    /**
     * When completing the upload doc, if logged user not the assigned agent & pdf contains sender name, popup confirmation message before the completion. otherwise user can complete the doc.
     */
    const handleConfirmationMessageOrComplete = id => {
        if (
            currentDocument.docType === DOCUMENT_CUSTOM &&
            currentDocument?.agent.id !== loggedUserInfo.id &&
            senderDetailsOnPdf.totalAnnotationCount > 0
        ) {
            setIsChangeSenderConfirmationModel(true);
            return;
        }
        completeCustomDocument(id);
    };

    return (
        <React.Fragment>
            {!isDocumentCreateMode(documentEditMode) &&
                !isMobileView &&
                currentDocument &&
                currentDocument.auditTrail &&
                currentDocument.auditTrail.length > 0 && (
                    <AgreementMainAuditTrail leaseId={currentDocument.id} isDocument={true} />
                )}
            {getButtons(currentDocument, values)}
            <EditSenderConfirmation
                isOpen={isChangeSenderConfirmationModel}
                close={closeSenderChangeModel}
                primaryHandler={senderChangeConfirmationHandler}
                secondaryHandler={closeSenderChangeModel}
                currentAgentName={currentDocumentAgentName}
                assignedAgentName={senderDetailsOnPdf.name}
                accountType={accountType}
                docStatus={currentDocument?.status}
                loggedUserInfo={loggedUserInfo}
            />
            <ResendDocForSigningModal
                isOpen={isOpenResendModal}
                enforceSequentialSigning={currentDocument?.enforceSequentialSigning}
                clients={
                    values.enforceSequentialSigning
                        ? values.clients.filter(
                              client => client.signingOrder === currentDocument?.client?.currentSigningClientOrder
                          )
                        : values.clients
                }
                closeModal={() => setIsOpenResendModal(false)}
                shouldResendForAll={true}
                resendForSigning={selectedClients => resendCustomDocument(currentDocument.id, values, selectedClients)}
            />
        </React.Fragment>
    );
}

export default memo(FlkAPdfFooter);
