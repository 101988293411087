import React from 'react';
import { useDispatch } from 'react-redux';
import '../../../../../sass/dashboardContentTable.scss';
import { Table, Td, Th, Thead, Tr } from 'reactable';
import AgentLogo from '../../../../AgentLogo.js';
import { openDocumentForm } from '../../../../../actions/document';
import { EXCHANGE_OF_CONTRACTS, DOCUMENT_EDIT_MODE } from '../../../../../config';
import FlowDocumentsMoreOptionBlock from '../../cells/FlowDocumentsMoreOptionBlock';
import { useAccountProvider } from '../../../../providers/AccountProvider';

const Draft = ({ documentList }) => {
    const dispatch = useDispatch();
    const accountType = useAccountProvider();

    function openDocumentFormView(event, doc) {
        event.stopPropagation();
        dispatch(openDocumentForm(EXCHANGE_OF_CONTRACTS, doc, DOCUMENT_EDIT_MODE));
    }

    return (
        <Table>
            <Thead>
                <Th column="agent">AGENT</Th>
                <Th column="address">ADDRESS</Th>
                <Th column="vendors">VENDOR(S)</Th>
                <Th column="purchasers">PURCHASER(S)</Th>
                <Th column="action">&nbsp;</Th>
            </Thead>
            {documentList &&
                documentList.map((doc, key) => {
                    return (
                        <Tr
                            key={key}
                            className="showmodal"
                            onClick={event => {
                                openDocumentFormView(event, doc);
                            }}
                        >
                            <Td column="agent">
                                <AgentLogo agent={doc.agent} />
                            </Td>
                            <Td column="address">
                                <b>{doc?.address || '-'}</b>
                            </Td>
                            <Td column="vendors">
                                <b>{doc?.client?.vendorsListText || '-'}</b>
                            </Td>
                            <Td column="purchasers">
                                <b>{doc?.client?.purchasersListText || '-'}</b>
                            </Td>
                            <Td className="desktop-only" column="action">
                                <div className="action">
                                    <FlowDocumentsMoreOptionBlock
                                        agreementStatus={doc.status}
                                        leaseType={doc.docType}
                                        doc={doc}
                                    />
                                    <span className="arrow-right" />
                                </div>
                            </Td>
                        </Tr>
                    );
                })}
        </Table>
    );
};

export default Draft;
