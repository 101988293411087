import React, { memo } from 'react';
import RentIncreaseListings from './RentIncreaseListings';
import TerminationListings from './TerminationListings';
import RentReductionListings from './RentReductionListings';
import CustomDocumentListings from './CustomDocumentListings';
import ExchangeOfContractsListings from './ExchangeOfContractsListings';
import DisclosureListings from './DisclosureListings';
import CreateFlkListings from './CreateFlkListings';
import BreachListings from './BreachListings';
import EntryNoticeListings from './EntryNoticeListings';
import IntentionToSellListings from './IntentionToSellListings.tsx';
import EstimatedSellingPriceNoticeListings from './EstimatedSellingPriceNoticeListings.tsx';

import {
    DOCUMENT_RENT_REDUCTION,
    DOCUMENT_RENT_INCREASE,
    DOCUMENT_TERMINATION_NOTICE,
    DOCUMENT_CUSTOM,
    DISCLOSURE_DOCUMENT,
    DOCUMENT_CREATE_A_FLK,
    BREACH_NOTICE,
    ALL,
    ENTRY_NOTICE,
    DOCUMENT_INTENTION_TO_SELL,
    DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE,
    EXCHANGE_OF_CONTRACTS
} from '../../../config';
import AllDocumentListings from './AllDocumentListings';

const DocumentTable = ({ docType, docStatus, documentList, agentSignature }) => {
    let table;
    switch (docType) {
        case DOCUMENT_TERMINATION_NOTICE: {
            table = (
                <TerminationListings
                    documentList={documentList[docType][docStatus]}
                    docType={docType}
                    agentSignature={agentSignature}
                    docStatus={docStatus}
                />
            );
            break;
        }
        case DOCUMENT_RENT_REDUCTION: {
            table = (
                <RentReductionListings
                    documentList={documentList[docType][docStatus]}
                    docType={docType}
                    agentSignature={agentSignature}
                    docStatus={docStatus}
                />
            );
            break;
        }
        case DOCUMENT_RENT_INCREASE: {
            table = (
                <RentIncreaseListings
                    documentList={documentList[docType][docStatus]}
                    docType={docType}
                    agentSignature={agentSignature}
                    docStatus={docStatus}
                />
            );
            break;
        }

        case DOCUMENT_CUSTOM: {
            table = (
                <CustomDocumentListings
                    documentList={documentList[docType][docStatus]}
                    docType={docType}
                    docStatus={docStatus}
                />
            );
            break;
        }
        case EXCHANGE_OF_CONTRACTS: {
            table = (
                <ExchangeOfContractsListings
                    documentList={documentList[docType][docStatus]}
                    docType={docType}
                    docStatus={docStatus}
                />
            );
            break;
        }
        case DISCLOSURE_DOCUMENT: {
            table = (
                <DisclosureListings
                    documentList={documentList[docType][docStatus]}
                    docType={docType}
                    docStatus={docStatus}
                />
            );
            break;
        }
        case DOCUMENT_CREATE_A_FLK: {
            table = (
                <CreateFlkListings
                    documentList={documentList[docType][docStatus]}
                    docType={docType}
                    docStatus={docStatus}
                />
            );
            break;
        }
        case BREACH_NOTICE: {
            table = (
                <BreachListings
                    documentList={documentList[docType][docStatus]}
                    docType={docType}
                    agentSignature={agentSignature}
                    docStatus={docStatus}
                />
            );
            break;
        }
        case ENTRY_NOTICE: {
            table = (
                <EntryNoticeListings
                    documentList={documentList[docType][docStatus]}
                    docType={docType}
                    agentSignature={agentSignature}
                    docStatus={docStatus}
                />
            );
            break;
        }
        case DOCUMENT_INTENTION_TO_SELL: {
            table = (
                <IntentionToSellListings
                    documentList={documentList[docType][docStatus]}
                    docType={docType}
                    agentSignature={agentSignature}
                    docStatus={docStatus}
                />
            );
            break;
        }
        case DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE: {
            table = (
                <EstimatedSellingPriceNoticeListings
                    documentList={documentList[docType][docStatus]}
                    docType={docType}
                    agentSignature={agentSignature}
                    docStatus={docStatus}
                />
            );
            break;
        }
        case ALL: {
            table = (
                <AllDocumentListings
                    documentList={documentList[docType][docStatus]}
                    docType={docType}
                    docStatus={docStatus}
                />
            );
            break;
        }
    }

    return <div className="tb-wrapper">{table}</div>;
};

export default memo(DocumentTable);
