import React from 'react';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import { QLD_STATE, NSW_STATE, VIC_STATE, ACT_STATE } from '../../../../../config';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { formatDateDay, getAgencyTimezoneFromUser } from '../../../../../utils/dateUtils';
import { getUserInfo } from '../../../../../selectors/user';
import { getResTenStepNo } from '../../../../../utils/agreementUtils';
import { getResTenLabel } from '../../../../../utils/labelUtils';

const AgreementResidentialAdditional = ({ additionalTerms, leaseAllInfo }) => {
    let petInformation;
    const loggedInUser = useSelector(getUserInfo);
    if (additionalTerms.animals) {
        let pets = additionalTerms.animals.pets.map(function (animals) {
            if (animals.amount) {
                return animals.information + ' x ' + animals.amount;
            } else {
                return animals.information;
            }
        });

        petInformation = pets.join(', ');
    } else {
        petInformation = '';
    }

    return (
        <AgreementSection
            title="Additional Terms"
            step={getResTenStepNo(leaseAllInfo.location, 'additionalTerms', leaseAllInfo.subLeaseType)}
            leaseAllInfo={leaseAllInfo}
        >
            {additionalTerms.separatedWaterUsage && (
                <p className="text text-margin">The tenant is required to pay separately for water usage.</p>
            )}
            {additionalTerms.communityScheme && (
                <p className="text text-margin">
                    There are strata or community scheme by-laws applicable to the residential premises. And the owners
                    corporation of the strata scheme
                    {additionalTerms.isOwnerResponsibleForRepairSmokeAlarms ? ' ' : ' not '}
                    responsible for the repair and replacement of smoke alarms in the residential premises
                </p>
            )}
            {additionalTerms.allowAnimals && (
                <p className="text text-margin" key="allowAnimals">
                    {getResTenLabel('tenant', leaseAllInfo.location)} may keep the following animals on the residential
                    premises: {petInformation}
                </p>
            )}
            {additionalTerms.animals && additionalTerms.animals.profCleaning && additionalTerms.allowAnimals && (
                <p className="text text-margin" key="profCleaning">
                    The tenant must to have the carpet professionally cleaned or to have the residential premises
                    fumigated if the cleaning of fumigation is required because animals have been kept on the
                    residential premises during the tenancy.
                </p>
            )}
            {leaseAllInfo.location === VIC_STATE && (
                <React.Fragment>
                    {additionalTerms.ownersCorporation && (
                        <p className="text text-margin">Owners corporation rules apply to this premises.</p>
                    )}
                    {additionalTerms.swimmingPool && (
                        <p className="text text-margin">
                            The premises has a swimming pool.
                            {additionalTerms.swimingPoolMaintenance && (
                                <React.Fragment>
                                    <br />
                                    <span className="text text-margin">
                                        The{' '}
                                        {additionalTerms.swimingPoolMaintenance === 'tenant'
                                            ? 'renter'
                                            : 'rental provider'}{' '}
                                        is responsible for maintenance of the pool.
                                        {additionalTerms.swimingPoolMaintenance !== 'tenant' && (
                                            <b> ({additionalTerms.swimmingPoolMaintenanceDuration})</b>
                                        )}
                                    </span>
                                </React.Fragment>
                            )}
                        </p>
                    )}

                    {additionalTerms.isPropertyOnTheMarket && (
                        <p className="text text-margin">
                            The property is on the market for sale, or in the process of being sold..
                        </p>
                    )}
                    {additionalTerms.isPropertyRepossessedUnderMortgage && (
                        <p className="text text-margin">The property is to be repossessed under a mortgage.</p>
                    )}
                    {additionalTerms.isNotTheOwner && (
                        <p className="text text-margin">
                            The rental provider is not the owner of the property. <br />(
                            <b> {additionalTerms.notTheOwnerRights}</b>)
                        </p>
                    )}
                </React.Fragment>
            )}

            {(leaseAllInfo.location === NSW_STATE || leaseAllInfo.location === VIC_STATE) &&
                additionalTerms.isElectricitySupplied && (
                    <p className="text text-margin">
                        Electricity is supplied to the premises from an embedded network.
                        {additionalTerms.detailsOfNetwork && (
                            <React.Fragment>
                                {' '}
                                <br />(<b> {additionalTerms.detailsOfNetwork}</b>)
                            </React.Fragment>
                        )}
                    </p>
                )}
            {leaseAllInfo.location === NSW_STATE && additionalTerms.isGasSupplied && (
                <p className="text text-margin">Gas is supplied to the premises from an embedded network</p>
            )}
            {leaseAllInfo.location === NSW_STATE &&
                additionalTerms.smokeAlarm &&
                additionalTerms.smokeAlarm.isHardwired && (
                    <p className="text text-margin">
                        The smoke alarms installed in the residential premises are Hardwired and back-up batteries in
                        the smoke alarms are of a kind the tenant can
                        {additionalTerms.smokeAlarm.hardwired.canReplaceBatteries ? ' ' : ' not '}
                        replace.
                        {additionalTerms.smokeAlarm.hardwired.canReplaceBatteries
                            ? `(Type of battery: ${
                                  additionalTerms.smokeAlarm.hardwired.batteryType === 'other'
                                      ? additionalTerms.smokeAlarm.hardwired.customBatteryType
                                      : additionalTerms.smokeAlarm.hardwired.batteryType
                              })`
                            : ''}
                    </p>
                )}
            {leaseAllInfo.location === NSW_STATE &&
                additionalTerms.smokeAlarm &&
                additionalTerms.smokeAlarm.isBatteryOperated && (
                    <p className="text text-margin">
                        The smoke alarms installed in the residential premises are Battery Operated and batteries in the
                        smoke alarms are of a kind the tenant can
                        {additionalTerms.smokeAlarm.batteryOperated.canReplaceBatteries ? ' ' : ' not '}
                        replace.
                        {additionalTerms.smokeAlarm.batteryOperated.canReplaceBatteries
                            ? `(Type of battery: ${
                                  additionalTerms.smokeAlarm.batteryOperated.batteryType === 'other'
                                      ? additionalTerms.smokeAlarm.batteryOperated.customBatteryType
                                      : additionalTerms.smokeAlarm.batteryOperated.batteryType
                              })`
                            : ''}
                    </p>
                )}
            {additionalTerms.isLeaseRenewalEnabled && (
                <p className="text text-margin">
                    The original condition report from{' '}
                    <span className="bold">
                        {formatDateDay(additionalTerms.leaseRenewalDate, getAgencyTimezoneFromUser(loggedInUser))}
                    </span>{' '}
                    will be used.
                </p>
            )}
            {leaseAllInfo.location === ACT_STATE && (
                <React.Fragment>
                    {additionalTerms.breakLeaseClause && (
                        <p className="text text-margin">Break Lease clause enabled.</p>
                    )}
                    {additionalTerms.fairClause && <p className="text text-margin">Fair clause enabled.</p>}
                    {additionalTerms.maintenanceClause && (
                        <p className="text text-margin">90 Day Maintenance clause enabled.</p>
                    )}
                    {additionalTerms.embeddedNetwork && (
                        <p className="text text-margin">The premises is part of an Embedded Network.</p>
                    )}
                    {additionalTerms.swimmingPool && (
                        <p className="text text-margin">The premises has a swimming pool.</p>
                    )}
                    {additionalTerms.unitOrTownhouse && (
                        <p className="text text-margin">The property is a unit or a townhouse.</p>
                    )}
                </React.Fragment>
            )}
            {leaseAllInfo.location === QLD_STATE && additionalTerms.premiseServices && (
                <div>
                    <h2 className="services">Services</h2>
                    {map(additionalTerms.premiseServices.premiseServices, (value, key) => {
                        let enabled = value.mustPay ? 'Yes' : 'No';
                        let billingType = value.individuallyMetred ? 'Individually metered' : value.costApportionment;
                        let paymentMethod = value.directToSupplier
                            ? 'As account holder direct to the supplier'
                            : value.howToPay;

                        return (
                            <div key={key}>
                                <h3>{value.name}</h3>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>Tenant has to pay:</td>
                                            <td>
                                                <span className="bold">{enabled}</span>
                                            </td>
                                        </tr>
                                        {value.mustPay && (
                                            <tr>
                                                <td>Billing Type:</td>
                                                <td>
                                                    <span className="bold">{billingType}</span>
                                                </td>
                                            </tr>
                                        )}
                                        {value.mustPay && (
                                            <tr>
                                                <td>Payment Method:</td>
                                                <td>
                                                    <span className="bold">{paymentMethod}</span>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        );
                    })}
                </div>
            )}
        </AgreementSection>
    );
};

export default AgreementResidentialAdditional;
