import React from 'react';
import { Core, WebViewerInstance } from '@pdftron/webviewer';

import {
    AgentNameAnnotation,
    AgentSignatureAnnotation,
    AnnotationAction,
    ClientAnnotationCounts,
    ClientDateAnnotation,
    ClientSignatureAnnotation,
    WitnessAnnotation,
    WitnessAnnotationCounts,
    WitnessNameAnnotation,
    isAgentNameAnnotation,
    isAgentSignatureAnnotation,
    isClientDateAnnotation,
    isClientSignatureAnnotation,
    isWitnessAnnotation,
    isWitnessNameAnnotation
} from '../types';
import {
    getClientAnnotationClientIndex,
    getWitnessAnnotationSignatureIndex,
    getWitnessAnnotationNameIndex
} from '../utils';
import { SidebarAccordionItems } from './Sidebar';

export type UploadedDocumentState = {
    uploadedDocumentId: string;
    instance: WebViewerInstance;
    /** This will be true until the webviewer has loaded the document and annotations */
    isWebViewerInitialised: boolean;
    clientAnnotationsCounts: {
        signatureAnnotationCounts: ClientAnnotationCounts;
        dateAnnotationCounts: ClientAnnotationCounts;
        witness: {
            signatureAnnotationCounts: WitnessAnnotationCounts;
            nameAnnotationCounts: WitnessAnnotationCounts;
        };
    };
    agentSignatureAnnotationCount: number;
    agentNameAnnotationCount: number;
    agentName: string;
    isVisited?: boolean;
};

// This hook consolidates state and state manipulation for uploaded documents
export const useUploadedDocumentStateItems = (options?: { defaultOpenSidebarItems?: SidebarAccordionItems[] }) => {
    const [uploadedDocumentStateItems, setUploadedDocumentStateItems] = React.useState<UploadedDocumentState[]>([]);
    const [selectedUploadedDocId, setSelectedUploadedDocId] = React.useState<string>();
    const [defaultOpenSidebarItems, setDefaultOpenSidebarItems] = React.useState(options?.defaultOpenSidebarItems);

    const areAllDocumentsInitialised = React.useMemo(() => {
        return uploadedDocumentStateItems.every(item => item.isWebViewerInitialised);
    }, [uploadedDocumentStateItems]);

    // Mark a document as visited when it is selected
    React.useEffect(() => {
        if (selectedUploadedDocId) {
            setUploadedDocumentStateItems(prevState => {
                const newState = [...prevState];

                const selectedUploadedDocumentStateItem = newState.find(
                    item => item.uploadedDocumentId === selectedUploadedDocId
                );

                if (selectedUploadedDocumentStateItem && !selectedUploadedDocumentStateItem.isVisited) {
                    selectedUploadedDocumentStateItem.isVisited = true;
                }
                return newState;
            });
        }
    }, [selectedUploadedDocId]);

    const addUploadedDocumentStateItem = React.useCallback(
        (uploadedDocumentId: string, webviewerInstance: WebViewerInstance, clientCount: number) => {
            const emptyAnnotionCount = Array.from(Array(clientCount)).reduce(
                (acc: ClientAnnotationCounts, _, index) => {
                    acc[index] = 0;
                    return acc;
                },
                {}
            );

            setUploadedDocumentStateItems(prevState => {
                // Remove item with uploadedDocumentId if it already exists
                return [
                    ...prevState.filter(item => item.uploadedDocumentId !== uploadedDocumentId),
                    {
                        uploadedDocumentId: uploadedDocumentId,
                        instance: webviewerInstance,
                        clientAnnotationsCounts: {
                            signatureAnnotationCounts: { ...emptyAnnotionCount },
                            dateAnnotationCounts: { ...emptyAnnotionCount },
                            witness: {
                                signatureAnnotationCounts: { ...emptyAnnotionCount },
                                nameAnnotationCounts: { ...emptyAnnotionCount }
                            }
                        },
                        agentSignatureAnnotationCount: 0,
                        agentNameAnnotationCount: 0,
                        agentName: '',
                        isWebViewerInitialised: false
                    }
                ];
            });
        },
        []
    );

    const removeUploadedDocumentStateItem = React.useCallback((uploadedDocumentId: string) => {
        setUploadedDocumentStateItems(prevState => {
            return prevState.filter(item => item.uploadedDocumentId !== uploadedDocumentId);
        });
    }, []);

    const setIsWebViewerInitialised = React.useCallback(
        (uploadedDocumentId: string, isWebViewerInitialised: boolean) => {
            setUploadedDocumentStateItems(prevState => {
                const newState = [...prevState];

                const selectedUploadedDocumentStateItem = newState.find(
                    item => item.uploadedDocumentId === uploadedDocumentId
                );

                if (selectedUploadedDocumentStateItem) {
                    selectedUploadedDocumentStateItem.isWebViewerInitialised = isWebViewerInitialised;
                }
                return newState;
            });
        },
        []
    );

    const resetVisitedState = React.useCallback(() => {
        setUploadedDocumentStateItems(prevState => {
            return prevState.map(item => ({
                ...item,
                isVisited: false
            }));
        });
    }, []);

    const handleUpdateAnnotationCount = React.useCallback(
        (uploadedDocumentId: string, updatedAnnotations: Core.Annotations.Annotation[], action: string) => {
            const updatedSignatureAnnotations = updatedAnnotations.filter(
                isClientSignatureAnnotation
            ) as ClientSignatureAnnotation[];

            const updatedDateAnnotations = updatedAnnotations.filter(isClientDateAnnotation) as ClientDateAnnotation[];

            const updatedAgentSignatureAnnotations = updatedAnnotations.filter(
                isAgentSignatureAnnotation
            ) as AgentSignatureAnnotation[];

            const updatedAgentNameAnnotations = updatedAnnotations.filter(
                isAgentNameAnnotation
            ) as AgentNameAnnotation[];

            const updatedWitnessNameAnnotations = updatedAnnotations.filter(
                isWitnessNameAnnotation
            ) as WitnessNameAnnotation[];

            const updatedWitnessSignatureAnnotations = updatedAnnotations.filter(
                isWitnessAnnotation
            ) as WitnessAnnotation[];

            setUploadedDocumentStateItems(prevState => {
                const updatedWebViewerInstances = [...prevState];
                const updatedDocumentState = updatedWebViewerInstances.find(
                    item => item.uploadedDocumentId === uploadedDocumentId
                );

                if (!updatedDocumentState) {
                    return prevState;
                }

                const signatureAnnotationCounts =
                    updatedDocumentState.clientAnnotationsCounts.signatureAnnotationCounts;
                const dateAnnotationCounts = updatedDocumentState.clientAnnotationsCounts.dateAnnotationCounts;

                const witnessSignatureAnnotationCounts =
                    updatedDocumentState.clientAnnotationsCounts.witness.signatureAnnotationCounts;
                const witnessNameAnnotationCounts =
                    updatedDocumentState.clientAnnotationsCounts.witness.nameAnnotationCounts;

                updatedSignatureAnnotations.forEach(annotation => {
                    const clientIndex = getClientAnnotationClientIndex(annotation);
                    if (signatureAnnotationCounts[clientIndex] === undefined) {
                        signatureAnnotationCounts[clientIndex] = 0;
                    }
                    if (action === AnnotationAction.ADD) {
                        signatureAnnotationCounts[clientIndex] += 1;
                    } else if (action === AnnotationAction.DELETE) {
                        signatureAnnotationCounts[clientIndex] -= 1;
                    }
                });

                updatedDateAnnotations.forEach(annotation => {
                    const clientIndex = getClientAnnotationClientIndex(annotation);
                    if (dateAnnotationCounts[clientIndex] === undefined) {
                        dateAnnotationCounts[clientIndex] = 0;
                    }
                    if (action === AnnotationAction.ADD) {
                        dateAnnotationCounts[clientIndex] += 1;
                    } else if (action === AnnotationAction.DELETE) {
                        dateAnnotationCounts[clientIndex] -= 1;
                    }
                });

                updatedAgentSignatureAnnotations.forEach(() => {
                    if (!updatedDocumentState.agentSignatureAnnotationCount) {
                        updatedDocumentState.agentSignatureAnnotationCount = 0;
                    }
                    if (action === AnnotationAction.ADD) {
                        updatedDocumentState.agentSignatureAnnotationCount += 1;
                    } else if (action === AnnotationAction.DELETE) {
                        updatedDocumentState.agentSignatureAnnotationCount -= 1;
                    }
                });

                updatedAgentNameAnnotations.forEach(annotation => {
                    if (!updatedDocumentState.agentNameAnnotationCount) {
                        updatedDocumentState.agentNameAnnotationCount = 0;
                    }
                    if (action === AnnotationAction.ADD) {
                        updatedDocumentState.agentNameAnnotationCount += 1;
                        updatedDocumentState.agentName = annotation.getContents();
                    } else if (action === AnnotationAction.DELETE) {
                        updatedDocumentState.agentNameAnnotationCount -= 1;
                        if (updatedDocumentState.agentNameAnnotationCount === 0) {
                            updatedDocumentState.agentName = '';
                        }
                    }
                });

                updatedWitnessSignatureAnnotations.forEach(annotation => {
                    const witnessIndex = getWitnessAnnotationSignatureIndex(annotation);
                    if (witnessSignatureAnnotationCounts[witnessIndex] === undefined) {
                        witnessSignatureAnnotationCounts[witnessIndex] = 0;
                    }
                    if (action === AnnotationAction.ADD) {
                        witnessSignatureAnnotationCounts[witnessIndex] += 1;
                    } else if (action === AnnotationAction.DELETE) {
                        witnessSignatureAnnotationCounts[witnessIndex] -= 1;
                    }
                });

                updatedWitnessNameAnnotations.forEach(annotation => {
                    const witnessIndex = getWitnessAnnotationNameIndex(annotation);
                    if (witnessNameAnnotationCounts[witnessIndex] === undefined) {
                        witnessNameAnnotationCounts[witnessIndex] = 0;
                    }
                    if (action === AnnotationAction.ADD) {
                        witnessNameAnnotationCounts[witnessIndex] += 1;
                    } else if (action === AnnotationAction.DELETE) {
                        witnessNameAnnotationCounts[witnessIndex] -= 1;
                    }
                });
                return updatedWebViewerInstances;
            });
        },
        []
    );

    return {
        uploadedDocumentStateItems,
        selectedUploadedDocId,
        setSelectedUploadedDocId,
        addUploadedDocumentStateItem,
        removeUploadedDocumentStateItem,
        resetVisitedState,
        handleUpdateAnnotationCount,
        defaultOpenSidebarItems,
        setDefaultOpenSidebarItems,
        areAllDocumentsInitialised,
        setIsWebViewerInitialised
    };
};
