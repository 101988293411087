import { AxiosError } from 'axios';

export const DEFAULT_CACHE_DURATION = 1000 * 60 * 5; // 5 minutes
export const DEFAULT_STALE_DURATION = 1000 * 60 * 5; // 5 minutes

export const DEFAULT_RETRY_COUNT = 2;

// 502: Bad Gateway - invalid response from an upstream server
// 503: Service Unavailable - server overload or maintenance
// 504: Gateway Timeout - network delay or upstream server timeout
// 408: Request Timeout - client request took too long to complete
export const RETRY_STATUS_CODES = [502, 503, 504, 408];

export const PERMANENTLY_GONE_STATUS_CODE = 410;

export const shouldRetryRequest = (error: AxiosError | null): boolean => {
    // we don't retry requests that have not errored
    if (!error) {
        return false;
    }

    const errorStatus = error.response?.status;

    // Only retry errors if status is 502, 503, 504, 408, definition check
    // ensures that if errorStatus is falsey and RETRY_STATUS_CODES accidentally
    // contains a falsey value like null i.e. [502, null] we don't retry it
    if (errorStatus && !RETRY_STATUS_CODES.includes(errorStatus)) {
        return false;
    }
    // we've handled every condition that prevents a retry, in all other cases we retry
    // the request, by structuring the code like this the simple return true
    // equates to `return !!error && RETRY_STATUS_CODES.includes(status)`
    // but with the benefit of a definition check on the errorStatus code too
    return true;
};

// Don't use this function if you are using axios as the http client for react query
// Axios has its own retry mechanism. Default retry count is 3
export const shouldRetryTanstackRequest = (failureCount: number, error: AxiosError | null): boolean => {
    const maxRetriesReached = failureCount >= DEFAULT_RETRY_COUNT;

    // we don't retry requests when out of retry attempts
    if (maxRetriesReached) {
        return false;
    }

    return shouldRetryRequest(error);
};

export const shouldRetryAxiosRequests = (error: AxiosError | null) => {
    return shouldRetryRequest(error);
};
