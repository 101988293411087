import axios from 'axios';
import * as Lease from '../reducers/lease';
import * as Account from '../reducers/account';
import * as Dashboard from './../reducers/dashboard';

import { DOCUMENT_CREATE_MODE } from '../config';
import {
    ADD_OR_REPLACE_DOCUMENT_IN_LIST,
    REMOVE_DOCUMENT_IN_LIST,
    UPDATE_DOCUMENT_PROGRESS,
    UPDATE_RELOAD_DOCUMENT_LIST
} from '../reducers/dashboard';
export const OPEN_CREATE_DOC_FORM = 'OPEN_CREATE_DOC_FORM';
export const CLOSE_CREATE_DOC_FORM = 'CLOSE_CREATE_DOC_FORM';

export const SET_CURRENT_DOCUMENT = 'SET_CURRENT_DOCUMENT';

export const SET_PDF_GEN_STATE = 'SET_PDF_GEN_STATE';

export const CLOSE_RENT_REDUCTION_MODAL = 'CLOSE_RENT_REDUCTION_MODAL';
export const CLOSE_RENT_INCREASE_MODAL = 'CLOSE_RENT_INCREASE_MODAL';
export const CLOSE_CUSTOM_DOCUMENT_MODAL = 'CLOSE_CUSTOM_DOCUMENT_MODAL';
export const CLOSE_DISCLOSURE_DOCUMENT_MODAL = 'CLOSE_DISCLOSURE_DOCUMENT_MODAL';
export const CLOSE_CREATE_FLK_MODAL = 'CLOSE_CREATE_FLK_MODAL';
export const CLOSE_BREACH_MODAL = 'CLOSE_BREACH_MODAL';
export const CLOSE_INTENTION_TO_SELL_MODAL = 'CLOSE_INTENTION_TO_SELL_MODAL';
export const CLOSE_ESTIMATED_SELLING_PRICE_MODAL = 'CLOSE_ESTIMATED_SELLING_PRICE_MODAL';
export const CLOSE_ENTRY_NOTICE_MODAL = 'CLOSE_ENTRY_NOTICE_MODAL';
export const CLOSE_ESTIMATED_SELLING_NOTICE_MODAL = 'CLOSE_ESTIMATED_SELLING_NOTICE_MODAL';
export const CLOSE_EXCHANGE_OF_CONTRACTS_MODAL = 'CLOSE_EXCHANGE_OF_CONTRACTS_MODAL';

export const UPDATE_TERMINATE_WITHOUT_LEASE_DATA = 'UPDATE_TERMINATE_WITHOUT_LEASE_DATA';

export const RENT_REDUCTION_CREATE_SUCCESS = 'RENT_REDUCTION_CREATE_SUCCESS';
export const RENT_REDUCTION_CREATE_REQUEST = 'RENT_REDUCTION_CREATE_REQUEST';
export const RESET_REDUCTION_CREATE_SUCCESS = 'RESET_REDUCTION_CREATE_SUCCESS';

export const RENT_INCREASE_CREATE_REQUEST = 'RENT_INCREASE_CREATE_REQUEST';
export const RENT_INCREASE_CREATE_SUCCESS = 'RENT_INCREASE_CREATE_SUCCESS';

export const DISCLOSURE_DOCUMENT_CREATE_SUCCESS = 'DISCLOSURE_DOCUMENT_CREATE_SUCCESS';

export const CLEAR_DOCUMENT_TEMPLATES_TO_UPDATE = 'CLEAR_DOCUMENT_TEMPLATES_TO_UPDATE';
export const CLEAR_LINK_TEMPLATES_TO_UPDATE = 'CLEAR_LINK_TEMPLATES_TO_UPDATE';

export const RESEND_RENT_REDUCTION_REQUEST = 'RESEND_RENT_REDUCTION_REQUEST';
export const RESEND_RENT_REDUCTION_SUCCESS = 'RESEND_RENT_REDUCTION_SUCCESS';
export const RESEND_RENT_REDUCTION_FAIL = 'RESEND_RENT_REDUCTION_FAIL';

export const SEND_COMPLETE_RENT_REDUCTION_REQUEST = 'SEND_COMPLETE_RENT_REDUCTION_REQUEST';
export const SEND_COMPLETE_RENT_REDUCTION_SUCCESS = 'SEND_COMPLETE_RENT_REDUCTION_SUCCESS';
export const SEND_COMPLETE_RENT_REDUCTION_FAIL = 'SEND_COMPLETE_RENT_REDUCTION_FAIL';

export const UPDATE_BULK_SEND_STATUS_REQUEST = 'UPDATE_BULK_SEND_STATUS_REQUEST';
export const UPDATE_BULK_SEND_STATUS_SUCCESS = 'UPDATE_BULK_SEND_STATUS_SUCCESS';
export const UPDATE_BULK_SEND_STATUS_FAIL = 'UPDATE_BULK_SEND_STATUS_FAIL';
export const SHOW_APPROVAL_BUTTONS = 'SHOW_APPROVAL_BUTTONS';

export const CANCEL_SIGNING_DOCUMENT_REQUEST = 'CANCEL_SIGNING_DOCUMENT_REQUEST';
export const CANCEL_SIGNING_DOCUMENT_SUCCESS = 'CANCEL_SIGNING_DOCUMENT_SUCCESS';
export const CANCEL_SIGNING_DOCUMENT_FAIL = 'CANCEL_SIGNING_DOCUMENT_FAIL';

export const EMAIL_TERMINATE_WITHOUT_LEASE_REQUEST = 'EMAIL_TERMINATE_WITHOUT_LEASE_REQUEST';
export const EMAIL_TERMINATE_WITHOUT_LEASE_SUCCESS = 'EMAIL_TERMINATE_WITHOUT_LEASE_SUCCESS';
export const EMAIL_TERMINATE_WITHOUT_LEASE_FAIL = 'EMAIL_TERMINATE_WITHOUT_LEASE_FAIL';
export const SET_DOCUMENT_TYPE = 'SET_DOCUMENT_TYPE';

export const TERMINATE_WITHOUT_LEASE_CLEAR_SUCCESS = 'TERMINATE_WITHOUT_LEASE_CLEAR_SUCCESS';
export const REFRESH_DOCUMENT_LIST = 'REFRESH_DOCUMENT_LIST';

export const CLOSE_ALL_DOCUMENT_MODALS = 'CLOSE_ALL_DOCUMENT_MODALS';

export const OPEN_CANCEL_REASON_MODAL = 'OPEN_CANCEL_REASON_MODAL';
export const CLOSE_CANCEL_REASON_MODAL = 'CLOSE_CANCEL_REASON_MODAL';
export const CANCEL_DOCUMENT_SUCCESS = 'CANCEL_DOCUMENT_SUCCESS';
export const CLONE_DOCUMENT_SUCCESS = 'CLONE_DOCUMENT_SUCCESS';
export const DOCUMENT_LIST_RESET = 'DOCUMENT_LIST_RESET';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';

export const GET_CLIENT_FIELD_DETAILS_REQUEST = 'GET_CLIENT_FIELD_DETAILS_REQUEST';
export const GET_CLIENT_FIELD_DETAILS_SUCCESS = 'GET_CLIENT_FIELD_DETAILS_SUCCESS';
export const GET_CLIENT_FIELD_DETAILS_FAIL = 'GET_CLIENT_FIELD_DETAILS_FAIL';

export const GET_QUESTIONS_REQUEST = 'GET_QUESTIONS_REQUEST';
export const GET_QUESTIONS_SUCCESS = 'GET_QUESTIONS_SUCCESS';
export const GET_QUESTIONS_FAIL = 'GET_QUESTIONS_FAIL';

export const SET_DISABLED_FOR_APPROVAL = 'DISABLED_FOR_APPROVAL';

export const SET_EDIT_MODE = 'SET_EDIT_MODE';

/**
 * Open document create form.
 * @param {string} docType - type of doc
 * @param doc
 * @param editMode
 * @param isGlobalTemplate
 * @param files
 */
export function openDocumentForm(docType, doc, editMode = DOCUMENT_CREATE_MODE, isGlobalTemplate = false, files = []) {
    return {
        type: OPEN_CREATE_DOC_FORM,
        docType,
        doc,
        editMode,
        isGlobalTemplate,
        files
    };
}

export function getInfoDocument(documentId) {
    return () => {
        return axios
            .get(`/api/document/${documentId}`)
            .then(result => {
                return result;
            })
            .catch(error => {
                return { error: error };
            });
    };
}

/**
 * Close document create form.
 * @returns {{type: *}}
 */
export function closeDocumentForm() {
    return {
        type: CLOSE_CREATE_DOC_FORM
    };
}

export function closeRentReductionForm() {
    return {
        type: CLOSE_RENT_REDUCTION_MODAL
    };
}

export function sendRentReductionAgreement(data) {
    if (data.documentId) {
        return axios.post(`api/document/rent-relief-draft`, data);
    } else {
        return axios.post(`api/document/rent-relief`, data);
    }
}

export function resendReductionAgreement(docId) {
    return {
        types: [RESEND_RENT_REDUCTION_REQUEST, RESEND_RENT_REDUCTION_SUCCESS, RESEND_RENT_REDUCTION_FAIL],
        payload: {
            docId
        },
        promise: axios.post(`api/document/${docId}/resend-rent-relief`)
    };
}
export function refreshDocumentList() {
    return {
        type: REFRESH_DOCUMENT_LIST
    };
}

export function addOrReplaceDocumentInList(document, currentStatus) {
    return {
        type: ADD_OR_REPLACE_DOCUMENT_IN_LIST,
        payload: { document, currentStatus: currentStatus || document.status }
    };
}

export function removeDocumentInList(document, status, docType = null) {
    return {
        type: REMOVE_DOCUMENT_IN_LIST,
        payload: { document, status: status || document.status, docType }
    };
}

export function updateDocumentProgress(data, docStatus, docType = null) {
    return {
        type: UPDATE_DOCUMENT_PROGRESS,
        payload: { ...data, docStatus, docType }
    };
}

export function updateReloadDocumentList(reloadDocumentList) {
    return {
        type: UPDATE_RELOAD_DOCUMENT_LIST,
        payload: { reloadDocumentList }
    };
}

export function completeReductionAgreementAction(docId) {
    return {
        types: [
            SEND_COMPLETE_RENT_REDUCTION_REQUEST,
            SEND_COMPLETE_RENT_REDUCTION_SUCCESS,
            SEND_COMPLETE_RENT_REDUCTION_FAIL
        ],
        payload: {
            docId
        },
        promise: axios.post(`api/document/${docId}/finish-completion`)
    };
}

export function cancelSigningDocumentAction(docId) {
    return {
        types: [CANCEL_SIGNING_DOCUMENT_REQUEST, CANCEL_SIGNING_DOCUMENT_SUCCESS, CANCEL_SIGNING_DOCUMENT_FAIL],
        payload: {
            docId
        },
        promise: axios.post(`api/document/${docId}/cancel-signing`)
    };
}

export function rentReductionCreateRequest() {
    return {
        type: RENT_REDUCTION_CREATE_REQUEST
    };
}

export function rentReductionCreateSuccess() {
    return {
        type: RENT_REDUCTION_CREATE_SUCCESS
    };
}

export function rentIncreaseCreateRequest() {
    return {
        type: RENT_INCREASE_CREATE_REQUEST
    };
}

export function rentIncreaseCreateSuccess() {
    return {
        type: RENT_INCREASE_CREATE_SUCCESS
    };
}

export function disclosureDocumentCreateSuccess() {
    return {
        type: DISCLOSURE_DOCUMENT_CREATE_SUCCESS
    };
}

export function resetCreateSuccess() {
    return {
        type: RESET_REDUCTION_CREATE_SUCCESS
    };
}

export function clearDocumentTemplatesToUpdate() {
    return {
        type: CLEAR_DOCUMENT_TEMPLATES_TO_UPDATE
    };
}

export function clearLinkTemplatesToUpdate() {
    return {
        type: CLEAR_LINK_TEMPLATES_TO_UPDATE
    };
}

export function setCurrentDocType(docType) {
    return {
        type: SET_DOCUMENT_TYPE,
        payload: {
            docType
        }
    };
}

export function updateTenantLinks(links, lease) {
    return {
        type: Lease.UPDATE_TENANT_LINKS_SUCCESS,
        payload: {
            links,
            lease
        }
    };
}

export function updateDefaultTenantLinks(data, templatesToUpdate) {
    return {
        type: Account.UPDATE_DEFAULT_TENANT_LINKS_SUCCESS,
        payload: {
            data,
            templatesToUpdate
        }
    };
}

export function emailStandAloneTerminationPDF(docId) {
    return axios.post(`api/document/${docId}/pdf/email-stand-alone-termination-notice`);
}
export function terminateWithoutLeaseClearSuccess() {
    return {
        type: TERMINATE_WITHOUT_LEASE_CLEAR_SUCCESS
    };
}
export function closeRentIncreaseForm() {
    return {
        type: CLOSE_RENT_INCREASE_MODAL
    };
}
export function closeAllDocumentModals() {
    return {
        type: CLOSE_ALL_DOCUMENT_MODALS
    };
}

export function openCancelReasonModal(doc, clone = false) {
    return {
        type: OPEN_CANCEL_REASON_MODAL,
        payload: {
            doc,
            clone
        }
    };
}

export function closeCancelReasonModal() {
    return {
        type: CLOSE_CANCEL_REASON_MODAL
    };
}

export function cancelDocumentSuccess() {
    return {
        type: CANCEL_DOCUMENT_SUCCESS
    };
}

export function cloneDocumentSuccess() {
    return {
        type: CLONE_DOCUMENT_SUCCESS
    };
}

export function documentListReset() {
    return {
        type: DOCUMENT_LIST_RESET
    };
}

export function deleteDocumentSuccess() {
    return {
        type: DELETE_DOCUMENT_SUCCESS
    };
}

export function closeCustomDocumentModal() {
    return {
        type: CLOSE_CUSTOM_DOCUMENT_MODAL
    };
}
export function setCurrentDocument(currentDocument) {
    return {
        type: SET_CURRENT_DOCUMENT,
        currentDocument
    };
}

export function setPdfGeneratedState(pdfGeneratedState) {
    return {
        type: SET_PDF_GEN_STATE,
        pdfGeneratedState
    };
}

export function closeDisclosureDocumentModal() {
    return {
        type: CLOSE_DISCLOSURE_DOCUMENT_MODAL
    };
}

export function closeCreateFlkModal() {
    return {
        type: CLOSE_CREATE_FLK_MODAL
    };
}

export function setDisabledSendForApproval(value) {
    return {
        type: SET_DISABLED_FOR_APPROVAL,
        payload: {
            value
        }
    };
}

export function getClientFieldDetails(data) {
    return {
        types: [GET_CLIENT_FIELD_DETAILS_REQUEST, GET_CLIENT_FIELD_DETAILS_SUCCESS, GET_CLIENT_FIELD_DETAILS_FAIL],
        payload: {
            ...data
        },
        promise: axios.post(`api/client/get-field-details`, data)
    };
}

export function getQuestions(data) {
    return {
        types: [GET_QUESTIONS_REQUEST, GET_QUESTIONS_SUCCESS, GET_QUESTIONS_FAIL],
        payload: {
            ...data
        },
        promise: axios.post(`api/question/get-questions`, data)
    };
}

export function updateStatusForBulkSendApproval(docId, data) {
    return {
        types: [UPDATE_BULK_SEND_STATUS_REQUEST, UPDATE_BULK_SEND_STATUS_SUCCESS, UPDATE_BULK_SEND_STATUS_FAIL],
        payload: {
            docId,
            data
        },
        promise: axios.put(`api/document/${docId}/bulk-send-approval-status`, data)
    };
}

export function showApprovalButtons() {
    return {
        type: SHOW_APPROVAL_BUTTONS
    };
}

export function closeBreachAgreementForm() {
    return {
        type: CLOSE_BREACH_MODAL
    };
}

export function closeIntentionToSellForm() {
    return {
        type: CLOSE_INTENTION_TO_SELL_MODAL
    };
}

export function closeEstimatedSellingPriceForm() {
    return {
        type: CLOSE_ESTIMATED_SELLING_PRICE_MODAL
    };
}

export function closeEntryNoticeForm() {
    return {
        type: CLOSE_ENTRY_NOTICE_MODAL
    };
}

export function updateDocumentIntegrationForTerminationNotice(docId) {
    return {
        type: Dashboard.UPLOAD_INTEGRATION_DOCUMENT_FOR_TERMINATION_NOTICE,
        payload: { docId }
    };
}

export function updateDocumentIntegrationForIntentionToSellNotice(docId) {
    return {
        type: Dashboard.UPLOAD_INTEGRATION_DOCUMENT_FOR_INTENTION_TO_SELL_NOTICE,
        payload: { docId }
    };
}

export function updateDocumentIntegrationForEntryNotice(docId) {
    return {
        type: Dashboard.UPLOAD_INTEGRATION_DOCUMENT_FOR_ENTRY_NOTICE,
        payload: { docId }
    };
}

export function updateDocumentIntegrationForRentIncrease(docId) {
    return {
        type: Dashboard.UPLOAD_INTEGRATION_DOCUMENT_FOR_RENT_INCREASE,
        payload: { docId }
    };
}

export function closeExchangeOfContractsForm() {
    return {
        type: CLOSE_EXCHANGE_OF_CONTRACTS_MODAL
    };
}

export function setEditMode(editMode) {
    return {
        type: SET_EDIT_MODE,
        payload: { editMode }
    };
}

export function updateDocumentIntegrationForBreachNotice(docId) {
    return {
        type: Dashboard.UPLOAD_INTEGRATION_DOCUMENT_FOR_BREACH_NOTICE,
        payload: { docId }
    };
}
