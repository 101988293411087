import React from 'react';

import Icon, { Icons, IconSize } from '@app/common/components/Icon';
import Tooltip from '@app/common/components/tooltips/Tooltip';
import useComponentId from '@app/hooks/useComponentId';

import styles from './ConnectIdTooltip.module.scss';

type ConnectIdTooltipProps = {
    place?: 'top' | 'bottom' | 'left' | 'right';
};

export const ConnectIdTooltip: React.FC<ConnectIdTooltipProps> = ({ place = 'bottom' }) => {
    const componentId = useComponentId();

    return (
        <>
            <Icon
                className={styles.helpIcon}
                icon={Icons.HELP}
                size={IconSize.SMALL}
                data-for={`connect-id-tooltip-${componentId}`}
                data-tip
            />
            <Tooltip
                effect="solid"
                place={place}
                id={`connect-id-tooltip-${componentId}`}
                clickable
                className={styles.connectIdTooltip}
            >
                <p>
                    ConnectID checks the Recipient’s identity using their full legal name, so make sure to enter their
                    full name on the document to avoid any issues with verification.
                </p>
                <p>
                    ConnectID is an Australian-owned digital identity solution which allows you to securely prove who
                    you are. ConnectID does not see or store your personal data. Visit{' '}
                    <a href="https://www.connectid.com.au">connectid.com.au</a> to find out more.
                </p>
            </Tooltip>
        </>
    );
};
