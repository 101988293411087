import React, { useState } from 'react';
import '../../sass/documentTitleHeader.scss';
import { ArrowBackIosSharp, CloseSharp, HelpSharp, LockSharp, LockOpenSharp } from '@flk-mui-icons';
import { DocumentHeaderLeft } from '../../components/dashboard/InfoAgreementComponents/DocumentHeaderLeft';
import FlowDocumentsMoreOptionBlock from '../../components/dashboard/documentList/cells/FlowDocumentsMoreOptionBlock';
import AuditTrailOpenButton from '../../common/components/AuditTrailOpenButton';
import { isDocumentStatusDraft } from '../../utils/generalUtils';
import VisibilityListModal from '../../components/modals/VisibilityListModal';
import classNames from 'classnames';
import { isAdmin } from '../../utils/userUtils';
import { formatCurrency, formatCurrencyWithSeparator } from '../../utils/formUtils';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../../selectors/user';
import { showApprovalButtons, updateStatusForBulkSendApproval } from '../../actions/document';
import Button from '../../common/components/Button';
import BulkApprovalStatus from './BulkApprovalStatus';
import { getBulkStatusEditMode, getSendingApproval, getSendingDecline } from '../../selectors/document';
import { DOCUMENT_CUSTOM } from '../../config';

const DocumentTitleHeader = ({
    doc,
    closeModal,
    showHelpIcon,
    helpComponent,
    isTemplate,
    docType,
    showLockedIcon,
    saveDoc,
    isReadOnlyTemplate,
    hideCloseButton,
    customRightButton,
    linkIntegrationProperty,
    integrationAddress,
    unLinkIntegrationProperty,
    hideActions,
    signersLabel,
    editSigners
}) => {
    const dispatch = useDispatch();
    const loggedInUser = useSelector(getUserInfo);
    const bulkStatusEditMode = useSelector(getBulkStatusEditMode);
    const sendingDecline = useSelector(getSendingDecline);
    const sendingApproval = useSelector(getSendingApproval);
    const [showHelpMessage, setShowHelpMessage] = useState(false);
    const [isOpenLockedModal, setIsOpenLockedModal] = useState(false);

    let [sending, setSending] = useState(false);

    /**
     * Approve the sending of this bulk document
     */
    function approveSend() {
        setSending(true);
        dispatch(updateStatusForBulkSendApproval(doc.id, { approval: true }));
    }

    /**
     * Decline the sending of this bulk document
     */
    function declineSend() {
        setSending(true);
        dispatch(updateStatusForBulkSendApproval(doc.id, { approval: false }));
    }

    function getApprovalButtons() {
        return (
            <div className="approval-buttons">
                <Button primary type="button" onClick={approveSend} disabled={sending}>
                    {sendingApproval && <span className="savestatus-spinner-approval" />}
                    Approve
                </Button>
                <Button secondary onClick={declineSend} disabled={sending}>
                    {sendingDecline && <span className="savestatus-spinner-approval" />}
                    Decline
                </Button>
            </div>
        );
    }

    function setApprovalButtonsVisible() {
        dispatch(showApprovalButtons());
        setSending(false);
    }

    function getApprovalCosts() {
        if (isAdmin(loggedInUser.role) && doc.requiresApproval) {
            if (doc.deliveryType === 'sms') {
                return (
                    <div className="approval-block">
                        <p>The following costs will be incurred for sending this document via SMS</p>
                        <p>Number of documents over your monthly plan limit: {doc.payableBulkItems}</p>
                        <p>
                            Total cost for sending{' '}
                            {formatCurrencyWithSeparator(formatCurrency(doc.additionalSMSChargeCalculated))}
                        </p>
                        {bulkStatusEditMode && getApprovalButtons()}
                        {!bulkStatusEditMode && (
                            <div className="header-title">
                                <BulkApprovalStatus doc={doc} />
                            </div>
                        )}
                        {!bulkStatusEditMode && (
                            <div>
                                <span className="special-link" onClick={() => setApprovalButtonsVisible()}>
                                    <span>Update approval status</span>
                                </span>
                            </div>
                        )}
                    </div>
                );
            } else {
                return (
                    <div className="approval-block">
                        <p>The following costs will be incurred for sending this document via email</p>
                        <p>Number of documents over your monthly plan limit: {doc.payableBulkItems}</p>
                        <p>
                            Total cost for sending{' '}
                            {formatCurrencyWithSeparator(formatCurrency(doc.additionalEmailChargeCalculated))}
                        </p>
                        {bulkStatusEditMode && getApprovalButtons()}
                        {!bulkStatusEditMode && (
                            <div className="status-block">
                                <BulkApprovalStatus doc={doc} class={'no-margin'} />
                                <span className="special-link" onClick={() => setApprovalButtonsVisible()}>
                                    <span>Update approval status</span>
                                </span>
                            </div>
                        )}
                    </div>
                );
            }
        }
    }

    return (
        <div className="document-title-header">
            <div className="document-title-header-content">
                <div
                    className={classNames('document-title-header-content-info-agreement', {
                        approvalStatus: isDocumentStatusDraft(doc.status) && doc.requiresApproval,
                        large: isTemplate
                    })}
                >
                    {doc && doc.agent && (
                        <DocumentHeaderLeft
                            doc={doc}
                            isTemplate={isTemplate}
                            docType={docType}
                            isReadOnlyTemplate={isReadOnlyTemplate}
                            saveDoc={saveDoc}
                            integrationAddress={integrationAddress}
                            integration={doc.integration}
                            linkIntegrationProperty={linkIntegrationProperty}
                            unLinkIntegrationProperty={unLinkIntegrationProperty}
                        />
                    )}

                    <div className="header-right">
                        <div className="header-right-wrapper">
                            {customRightButton}
                            {showLockedIcon && (
                                <div className="header-button for-lg-modal">
                                    {doc.locked && (
                                        <LockSharp
                                            className={`help-icon ${isReadOnlyTemplate ? 'disabled' : ''}`}
                                            onClick={() => {
                                                setIsOpenLockedModal(true);
                                            }}
                                        />
                                    )}
                                    {!doc.locked && (
                                        <LockOpenSharp
                                            className={`help-icon ${isReadOnlyTemplate ? 'disabled' : ''}`}
                                            onClick={() => {
                                                setIsOpenLockedModal(true);
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                            {showHelpIcon && (
                                <div className="header-button for-lg-modal">
                                    <HelpSharp
                                        className="help-icon"
                                        onClick={() => {
                                            // keep local state so we can toggle
                                            setShowHelpMessage(!showHelpMessage);
                                        }}
                                    />
                                </div>
                            )}
                            {doc &&
                                !isDocumentStatusDraft(doc.status) &&
                                doc.auditTrail &&
                                doc.auditTrail.length > 0 && <AuditTrailOpenButton leaseId={doc.id} showText={false} />}
                            {!hideActions && (
                                <div className="action">
                                    <FlowDocumentsMoreOptionBlock
                                        agreementStatus={doc.status}
                                        leaseType={doc.docType}
                                        doc={doc}
                                        isReadOnlyTemplate={isReadOnlyTemplate}
                                        editSigners={editSigners}
                                        signersLabel={signersLabel}
                                    />
                                </div>
                            )}
                            <div onClick={() => closeModal()} className="close header-button for-sm-modal">
                                {docType === DOCUMENT_CUSTOM ? <CloseSharp /> : <ArrowBackIosSharp />}
                            </div>
                            {!hideCloseButton && (
                                <div
                                    onClick={() => closeModal()}
                                    className="close header-button for-lg-modal"
                                    data-test="template-model-close-button"
                                >
                                    <CloseSharp>Close</CloseSharp>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {getApprovalCosts()}
                {showHelpMessage && helpComponent}
                {showLockedIcon && (
                    <VisibilityListModal
                        doc={doc}
                        isOpenLockedModal={isOpenLockedModal}
                        closeModal={() => setIsOpenLockedModal(false)}
                        saveDoc={saveDoc}
                    />
                )}
            </div>
        </div>
    );
};

export default DocumentTitleHeader;
