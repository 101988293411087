import React from 'react';
import cx from 'classnames';

import Button from '@app/common/components/Button';
import Icon, { Icons } from '@app/common/components/Icon';
import { SectionHeader } from '../SectionHeader';
import Card, { CardShadow, CardStyles } from '@app/common/components/cards/Card';

import styles from './DocumentCheckSection.module.scss';

interface InnerStep {
    text: string;
    isDone: boolean;
}
type Step =
    | InnerStep
    | {
          text: string;
          subItems: InnerStep[];
      };

interface DocumentCheckSectionProps {
    hasDocuments: boolean;
    doAllRecipientsHaveNames: boolean;
    isBusy: boolean;
    sectionError?: string;
    openPdfViewer: () => void;
    steps: Step[];
}

export const DocumentCheckSection: React.FC<DocumentCheckSectionProps> = ({
    hasDocuments,
    doAllRecipientsHaveNames,
    isBusy,
    sectionError,
    openPdfViewer,
    steps
}) => (
    <div className={styles.container}>
        <SectionHeader
            title="Final document review"
            action={
                <Button
                    type="button"
                    onClick={openPdfViewer}
                    secondary
                    disabled={isBusy || !hasDocuments || !doAllRecipientsHaveNames}
                    tooltip={
                        !hasDocuments
                            ? 'Please upload a document'
                            : !doAllRecipientsHaveNames
                              ? 'Please fill out recipient names'
                              : undefined
                    }
                    className={styles.reviewButton}
                    startIcon={<Icon icon={Icons.SIGNATURE} />}
                >
                    Review and position signatures
                </Button>
            }
        />
        <Card shadow={CardShadow.SMALL} style={CardStyles.SQUARE} className={styles.checklistContainer}>
            <h3 className={styles.checklistTitle}>Checklist:</h3>
            <ul className={styles.checklist}>
                {steps.map((step, index) => {
                    const { text } = step;
                    const isDone = 'isDone' in step ? step.isDone : step.subItems.every(({ isDone }) => isDone);
                    const subItems = 'subItems' in step ? step.subItems : undefined;
                    return (
                        <>
                            <li key={index} className={styles.checklistItem}>
                                {isDone ? (
                                    <Icon icon={Icons.TICK} className={styles.tick} />
                                ) : (
                                    <Icon icon={Icons.CLOSE} className={styles.cross} />
                                )}
                                {text}
                            </li>
                            {subItems && (
                                <ul className={styles.subList}>
                                    {subItems.map(({ text, isDone }, index) => (
                                        <li key={index} className={cx(styles.checklistItem, styles.subItem)}>
                                            {isDone ? (
                                                <Icon icon={Icons.TICK} className={styles.tick} />
                                            ) : (
                                                <Icon icon={Icons.CLOSE} className={styles.cross} />
                                            )}
                                            {text}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </>
                    );
                })}
            </ul>
        </Card>
        {sectionError && (
            <div className={styles.error} data-has-error={true}>
                {sectionError}
            </div>
        )}
    </div>
);
