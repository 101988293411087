import React from 'react';
import { SectionHeader } from '../../eoc-form/SectionHeader';
import styles from './Section.module.scss';

interface SectionProps {
    title: string;
    divider?: boolean;
    children: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ title, divider, children }) => {
    return (
        <>
            {divider && <hr className={styles.divider} />}
            <SectionHeader title={title} />
            <div className={styles.content}>{children}</div>
        </>
    );
};

export default Section;
