import '../../../sass/rent.scss';
import React, { forwardRef, memo, useState, useEffect, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import { cloneDeep, has, isEmpty } from 'lodash';
import {
    rentPeriods as period,
    PERIOD_MONTHLY,
    PERIOD_FORTNIGHTLY,
    PERIOD_WEEKLY,
    VIC_STATE,
    NSW_STATE,
    ACT_STATE,
    SA_STATE,
    formatPeriod,
    QLD_STATE
} from '../../../config';
import * as agreementUtils from '../../../utils/agreementUtils';
import { getResTenLabel } from '../../../utils/labelUtils';
import { CheckboxCheck } from '../../../components/form/FormCheckboxCheck';
import DatePickerInAgencyTimeZone from '../../../components/form/DatePickerInAgencyTimeZone';
import RentIncreaseGroup from '../../../components/lease/mainScreen/common/RentIncreaseGroup';
import { FormTextRegular, FormTextHidden, FormNumber } from '../../../components/form/FormText';
import { SelectField } from '../../../components/form/FormSelect';
import { FormRadioGroup } from '../../../components/form/FormRadioGroup';
import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../actions/lease';
import {
    getDirtyStep,
    getTenant,
    getStep,
    getRent,
    getTerm,
    getLocation,
    getLeaseType,
    getSubLeaseType,
    getLeaseInfo
} from '../../../selectors/lease';
import axios from 'axios';
import * as Lease from '../../../reducers/lease';
const RENT_FORM = 'rentForm';
import { confirmAlert } from 'react-confirm-alert';
import { ToWords } from 'to-words';
import { addDays, addMonths, format } from 'date-fns';
import { FormYesNoCheckbox } from '../../../components/form/responsive/FormSingleCheckbox';
import RentAmount from '../../../components/form/RentAmount.tsx';
import { YES_VALUE, NO_VALUE } from '../../../config';
import SuggestionDatePicker from '../../../components/form/SuggestionDatePicker';

const Rent = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const toWords = new ToWords({
        localeCode: 'en-US',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: true
        }
    });

    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const tenant = useSelector(getTenant);
    const reduxTerm = useSelector(getTerm);
    const reduxRent = useSelector(getRent);
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const subLeaseType = useSelector(getSubLeaseType);
    const lease = useSelector(getLeaseInfo);

    const [rent, setRent] = useState(reduxRent);
    const [term, setTerm] = useState(reduxTerm);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(RENT_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    const _countDecimals = value => {
        if (value % 1) {
            const s = String(value);
            return s.length - s.indexOf('.') - 1;
        }
        return 0;
    };

    useEffect(() => {
        let initialRentState = reduxRent || {};
        let initialTermState = reduxTerm || {};
        if (reduxRent) {
            let newRentIncreaseList;
            if (reduxRent.isRentIncrease) {
                newRentIncreaseList = cloneDeep(reduxRent.rentIncreaseList);
                newRentIncreaseList.forEach(rentIncreaseListItem => {
                    rentIncreaseListItem.rentIncrease.newRentDateStart = rentIncreaseListItem.rentIncrease
                        .newRentDateStart
                        ? new Date(rentIncreaseListItem.rentIncrease.newRentDateStart)
                        : null;

                    rentIncreaseListItem.rentIncrease.showNewRentAmount =
                        rentIncreaseListItem.rentIncrease.newRentAmount &&
                        _countDecimals(parseFloat(rentIncreaseListItem.rentIncrease.newRentAmount)) > 2
                            ? parseFloat(rentIncreaseListItem.rentIncrease.newRentAmount).toFixed(2)
                            : rentIncreaseListItem.rentIncrease.newRentAmount;

                    rentIncreaseListItem.rentIncrease.unRoundedNewAmount =
                        rentIncreaseListItem.rentIncrease.newRentAmount;
                });
                newRentIncreaseList.forEach(rentIncreaseListItem => {
                    const newRentAmount =
                        rentIncreaseListItem.rentIncrease.unRoundedNewAmount ||
                        rentIncreaseListItem.rentIncrease.newRentAmount;
                    rentIncreaseListItem.rentIncrease.newRentAmount = newRentAmount;
                    rentIncreaseListItem.rentIncrease.showNewRentAmount =
                        newRentAmount && _countDecimals(parseFloat(newRentAmount)) > 2
                            ? parseFloat(newRentAmount).toFixed(2)
                            : newRentAmount;
                    rentIncreaseListItem.rentIncrease.unRoundedNewAmount = null;
                    rentIncreaseListItem.rentIncrease.newRentMonthly = parseFloat(
                        calcRentMonthly(newRentAmount, rentIncreaseListItem.rentIncrease.newRentPayablePeriod)
                    ).toFixed(2);
                });
            } else {
                newRentIncreaseList = [];
            }

            const amount = reduxRent.amount;
            let bondAmountInWords = '';
            let amountInWords = '';
            if (location === SA_STATE && reduxRent.bondAmount) {
                bondAmountInWords = toWords.convert(reduxRent.bondAmount);
            }
            if (location === SA_STATE && amount) {
                amountInWords = `${toWords.convert(
                    agreementUtils.getRentForPeriod(parseFloat(amount), reduxRent.payablePeriod)
                )}`;
            }

            initialRentState = {
                ...reduxRent,
                amount,
                showAmount: parseFloat(reduxRent.amount).toFixed(2),
                amountInWords,
                rentMonthly:
                    reduxRent.amount && reduxRent.payablePeriod
                        ? parseFloat(calcRentMonthly(reduxRent.amount, reduxRent.payablePeriod)).toFixed(2)
                        : 0,
                dateStart: reduxRent.dateStart
                    ? new Date(reduxRent.dateStart)
                    : reduxTerm.startDate
                      ? new Date(reduxTerm.startDate)
                      : null,
                nominatedDateLastIncreased: reduxRent.nominatedDateLastIncreased
                    ? new Date(reduxRent.nominatedDateLastIncreased)
                    : null,
                bondDate: reduxRent.bondDate ? new Date(reduxRent.bondDate) : null,
                bondAmountInWords,
                rentIncreaseList: newRentIncreaseList,
                firstPaymentDueOn: reduxRent.firstPaymentDueOn
                    ? new Date(reduxRent.firstPaymentDueOn)
                    : getSaDefaultFirstPaymentDue(),
                secondPaymentDueOn: reduxRent.secondPaymentDueOn
                    ? new Date(reduxRent.secondPaymentDueOn)
                    : getSaDefaultSecondPaymentDue(),
                dueOnDayOfMonthPeriod:
                    location === SA_STATE ? reduxRent.dueOnDayOfMonthPeriod || reduxRent.payablePeriod : undefined
            };
        }

        if (reduxTerm) {
            initialTermState = {
                ...reduxTerm,
                startDate: reduxTerm.startDate ? new Date(reduxTerm.startDate) : null,
                endDate: reduxTerm.endDate ? new Date(reduxTerm.endDate) : null
            };
        }

        if (!initialRentState.dateStart) {
            initialRentState = { ...initialRentState, dateStart: term.startDate ? new Date(term.startDate) : null };
        }

        setRent(initialRentState);
        setTerm(initialTermState);
    }, []);

    const getSaDefaultFirstPaymentDue = () => {
        if (location === SA_STATE && reduxTerm.startDate) {
            return new Date(reduxTerm.startDate);
        } else {
            return null;
        }
    };

    const getSaDefaultSecondPaymentDue = () => {
        if (location === SA_STATE && reduxTerm.startDate) {
            return addDays(new Date(reduxTerm.startDate), 7);
        } else {
            return null;
        }
    };

    const getSecondPaymentDue = (firstPaymentDueOn, payablePeriod) => {
        switch (payablePeriod) {
            case PERIOD_MONTHLY:
                return addMonths(firstPaymentDueOn, 1);
            case PERIOD_FORTNIGHTLY:
                return addDays(firstPaymentDueOn, 14);
            case PERIOD_WEEKLY:
                return addDays(firstPaymentDueOn, 7);
            default:
                return null;
        }
    };

    function getDayOfPeriod(firstPaymentDueOn, payablePeriod) {
        switch (payablePeriod) {
            case PERIOD_MONTHLY:
                return format(firstPaymentDueOn, 'do');
            case PERIOD_FORTNIGHTLY:
                return format(firstPaymentDueOn, 'EEEE');
            case PERIOD_WEEKLY:
                return format(firstPaymentDueOn, 'EEEE');
            default:
                return null;
        }
    }

    const updateRent = data => {
        return axios.post(`/api/agency/lease/${props.leaseId}/rent`, data);
    };

    const validate = values => {
        const clientSideErrors = {};
        if (location === QLD_STATE) {
            // Perform required validations here.

            if (!values?.nominateDateLastIncreased) {
                clientSideErrors.nominateDateLastIncreased =
                    'Do you need to nominate when the rent was last increased is required';
            }
            if (values?.nominateDateLastIncreased === YES_VALUE && !values?.nominatedDateLastIncreased) {
                clientSideErrors.nominatedDateLastIncreased = 'Nominated date last increased is required';
            }
        }

        return !isEmpty(clientSideErrors) ? clientSideErrors : null;
    };

    const submitForm = values => {
        const validationErrors = validate(values);
        if (validationErrors) {
            return validationErrors;
        }
        /**
         * Store the ref of the component so it can be used in this function
         * We need this otherwise the promise below doesn't have access to the ref
         */
        const currentRef = ref.current;
        /**
         * Grab the bypassFormValidation that was set from the parent component: LeaseAgreementForm.js
         */
        values.bypassFormValidation = currentRef.bypassFormValidation;

        return updateRent(values)
            .then(result => {
                if (agreementUtils.isLongTermRenewalSubLease(subLeaseType)) {
                    result.isLeaseEditingFinished = !currentRef.bypassFormValidation;
                } else {
                    result.isLeaseEditingFinished = false;
                }
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_RENT_SUCCESS)).then(() => {
                    /**
                     * Callback after submit this form so that the parent component can take an action
                     */
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                let errors = {};
                if (has(error, 'response.data.errors.rent')) {
                    errors = error.response.data.errors.rent;
                    if (errors.amount) {
                        errors.showAmount = errors.amount;
                    }
                    if (errors.rentIncreaseList) {
                        errors.rentIncreaseList.forEach(rentIncreaseListItem => {
                            if (rentIncreaseListItem.rentIncrease && rentIncreaseListItem.rentIncrease.newRentAmount) {
                                rentIncreaseListItem.rentIncrease.showNewRentAmount =
                                    rentIncreaseListItem.rentIncrease.newRentAmount;
                            }
                        });
                    }
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
                return errors;
            });
    };

    const calcRentMonthly = (amount, payablePeriod) => {
        switch (payablePeriod) {
            case PERIOD_MONTHLY: {
                return agreementUtils.calculateMonthlyRent(amount);
            }
            default: {
                return amount;
            }
        }
    };

    const roundUpMonthlyRent = values => {
        const currentMonthly = Math.floor(values.rentMonthly) + 1;
        const rentAmount = agreementUtils.calculateFromMonthlyRent(currentMonthly);
        const { amountInWords, dueOnDayOfMonth, nextPayment, bondAmount, bondAmountInWords } =
            getChangedDetailsDependOnAmount(values, rentAmount);
        const rent = {
            ...values,
            amount: rentAmount,
            showAmount: parseFloat(rentAmount).toFixed(2),
            rentMonthly: parseFloat(calcRentMonthly(rentAmount, values.payablePeriod)).toFixed(2),
            amountInWords,
            dueOnDayOfMonth,
            firstPayment: nextPayment,
            secondPayment: nextPayment,
            thereAfterPayment: nextPayment,
            bondAmount,
            bondAmountInWords
        };

        setRent(rent);
    };

    const roundDownMonthlyRent = values => {
        const currentMonthly = Math.ceil(values.rentMonthly) - 1;
        const rentAmount = agreementUtils.calculateFromMonthlyRent(currentMonthly);
        const { amountInWords, dueOnDayOfMonth, nextPayment, bondAmount, bondAmountInWords } =
            getChangedDetailsDependOnAmount(values, rentAmount);
        const rent = {
            ...values,
            amount: rentAmount,
            showAmount: parseFloat(rentAmount).toFixed(2),
            rentMonthly: parseFloat(calcRentMonthly(rentAmount, values.payablePeriod)).toFixed(2),
            amountInWords,
            dueOnDayOfMonth,
            firstPayment: nextPayment,
            secondPayment: nextPayment,
            thereAfterPayment: nextPayment,
            bondAmount,
            bondAmountInWords
        };
        setRent(rent);
    };

    const roundUpNewMonthlyRent = (values, index) => {
        const currentMonthly = Math.floor(values.rentIncreaseList[index].rentIncrease.newRentMonthly) + 1;
        const rentAmount = agreementUtils.calculateFromMonthlyRent(currentMonthly);
        let rentIncreaseList = cloneDeep(values.rentIncreaseList);
        if (rentIncreaseList[index] && rentIncreaseList[index].rentIncrease) {
            rentIncreaseList[index].rentIncrease.newRentAmount = rentAmount;
            rentIncreaseList[index].rentIncrease.showNewRentAmount = parseFloat(rentAmount).toFixed(2);
            rentIncreaseList[index].rentIncrease.newRentMonthly = parseFloat(
                calcRentMonthly(rentAmount, rentIncreaseList[index].rentIncrease.newRentPayablePeriod)
            ).toFixed(2);
        }
        const rent = {
            ...values,
            rentIncreaseList
        };
        setRent(rent);
    };

    const roundDownNewMonthlyRent = (values, index) => {
        const currentMonthly = Math.ceil(values.rentIncreaseList[index].rentIncrease.newRentMonthly) - 1;
        const rentAmount = agreementUtils.calculateFromMonthlyRent(currentMonthly);
        let rentIncreaseList = cloneDeep(values.rentIncreaseList);
        if (rentIncreaseList[index] && rentIncreaseList[index].rentIncrease) {
            rentIncreaseList[index].rentIncrease.newRentAmount = rentAmount;
            rentIncreaseList[index].rentIncrease.showNewRentAmount = parseFloat(rentAmount).toFixed(2);
            rentIncreaseList[index].rentIncrease.newRentMonthly = parseFloat(
                calcRentMonthly(rentAmount, rentIncreaseList[index].rentIncrease.newRentPayablePeriod)
            ).toFixed(2);
        }

        const rent = {
            ...values,
            rentIncreaseList
        };
        setRent(rent);
    };

    const roundUpBond = values => {
        const currentBond = Math.floor(values.bondAmount) + 1;
        const bondAmount = currentBond.toFixed(2);
        let bondAmountInWords = '';
        if (location === SA_STATE && bondAmount) {
            bondAmountInWords = toWords.convert(bondAmount);
        }
        const rent = {
            ...values,
            bondAmount,
            bondAmountInWords
        };
        setRent(rent);
    };

    const roundDownBond = values => {
        const currentBond = Math.ceil(values.bondAmount) - 1;
        const bondAmount = currentBond.toFixed(2);
        let bondAmountInWords = '';
        if (location === SA_STATE && bondAmount) {
            bondAmountInWords = toWords.convert(bondAmount);
        }
        const rent = {
            ...values,
            bondAmount,
            bondAmountInWords
        };
        setRent(rent);
    };
    const addRentIncrease = values => {
        const rentIncreaseList = cloneDeep(values.rentIncreaseList);
        rentIncreaseList.push({
            rentIncrease: {
                showNewRentAmount: '',
                newRentAmount: '',
                newRentPayablePeriod: 'weekly',
                newRentDateStart: null,
                newRentMonthly: ''
            }
        });
        const newRent = {
            ...rent,
            rentIncreaseList
        };
        setRent(newRent);
    };

    const checkAndAddRentIncrease = values => {
        const rentIncreaseList = cloneDeep(rent.rentIncreaseList);
        if (rentIncreaseList.length === 1) {
            confirmAlert({
                title: '',
                message:
                    'You are entering multiple rent increases, please ensure they comply with the current legislation',
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => {
                            addRentIncrease(values);
                        }
                    },
                    {
                        label: 'No',
                        onClick: () => {}
                    }
                ]
            });
        } else {
            addRentIncrease(values);
        }
    };

    const removeRentIncrease = (values, index) => {
        let rentIncreaseList = cloneDeep(values.rentIncreaseList);

        rentIncreaseList = [...rentIncreaseList.slice(0, index), ...rentIncreaseList.slice(index + 1)];
        const rent = {
            ...values,
            rentIncreaseList
        };
        setRent(rent);
    };

    const setRentIncrease = (value, values) => {
        let rentIncreaseList = cloneDeep(values.rentIncreaseList);
        if (!value || !rentIncreaseList) {
            rentIncreaseList = [];
        }
        if (value && rentIncreaseList.length === 0) {
            rentIncreaseList.push({
                rentIncrease: {
                    showNewRentAmount: '',
                    newRentAmount: '',
                    newRentPayablePeriod: 'weekly',
                    newRentDateStart: null,
                    newRentMonthly: ''
                }
            });
        }
        const rent = {
            ...values,
            isRentIncrease: value,
            rentIncreaseList
        };
        setRent(rent);
    };

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    const getChangedDetailsDependOnAmount = (values, amount) => {
        let amountInWords = '';
        let dueOnDayOfMonth = '';
        let nextPayment;
        if (location === SA_STATE && amount) {
            amountInWords = `${toWords.convert(
                agreementUtils.getRentForPeriod(parseFloat(amount), values.payablePeriod)
            )}`;
            nextPayment = agreementUtils.getRentForPeriod(parseFloat(amount), values.payablePeriod);
            dueOnDayOfMonth = getDayOfPeriod(values.firstPaymentDueOn, values.payablePeriod);
        }
        const bondAmount = agreementUtils.calcBond(amount, location === SA_STATE).toFixed(2);
        let bondAmountInWords = '';
        if (location === SA_STATE && bondAmount) {
            bondAmountInWords = toWords.convert(bondAmount);
        }
        return {
            amountInWords,
            dueOnDayOfMonth,
            nextPayment,
            bondAmount,
            bondAmountInWords
        };
    };

    const handleRentAmountChange = (value, values) => {
        let amount = null;
        if (value.target.value) {
            amount = parseFloat(value.target.value);
        }
        const { amountInWords, dueOnDayOfMonth, nextPayment, bondAmount, bondAmountInWords } =
            getChangedDetailsDependOnAmount(values, amount);
        setRent({
            ...values,
            amount: amount,
            showAmount: amount,
            amountInWords,
            bondAmount,
            bondAmountInWords,
            rentMonthly: parseFloat(calcRentMonthly(amount, values.payablePeriod)).toFixed(2),
            firstPayment: nextPayment,
            secondPayment: nextPayment,
            thereAfterPayment: nextPayment,
            dueOnDayOfMonth
        });
    };

    const handleNewRentAmountChange = (value, index, values, formatAmount = false) => {
        let rentIncreaseList = cloneDeep(values.rentIncreaseList);
        if (rentIncreaseList[index]) {
            const newAmount = value.target.value;
            rentIncreaseList[index].rentIncrease.newRentAmount = newAmount;
            rentIncreaseList[index].rentIncrease.showNewRentAmount = formatAmount
                ? parseFloat(newAmount).toFixed(2)
                : newAmount;
            rentIncreaseList[index].rentIncrease.newRentMonthly = parseFloat(
                calcRentMonthly(newAmount, rentIncreaseList[index].rentIncrease.newRentPayablePeriod)
            ).toFixed(2);
        }
        setRent({
            ...values,
            rentIncreaseList
        });
    };

    const handleNewRentAmountSuggestionChange = (value, index, values) => {
        handleNewRentAmountChange(value, index, values, true);
    };

    const handleChangeBondAmount = values => {
        let bondAmountInWords = '';
        if (location === SA_STATE && values.bondAmount) {
            bondAmountInWords = toWords.convert(values.bondAmount);
        }
        setRent({
            ...values,
            bondAmountInWords
        });
    };

    const handlePayPeriodChange = (value, values) => {
        const payablePeriod = value.value;
        const amount = parseFloat(values.amount);

        let amountInWords = '';
        let nextPayment = 0;
        let secondPaymentDueOn = null;
        let dueOnDayOfMonth = null;

        if (location === SA_STATE && amount) {
            amountInWords = `${toWords.convert(agreementUtils.getRentForPeriod(amount, payablePeriod))}`;
            nextPayment = agreementUtils.getRentForPeriod(amount, payablePeriod);
            secondPaymentDueOn = getSecondPaymentDue(values.firstPaymentDueOn, payablePeriod);
            dueOnDayOfMonth = getDayOfPeriod(values.firstPaymentDueOn, payablePeriod);
        }

        setRent({
            ...values,
            payablePeriod: payablePeriod,
            payableInAdvanced: payablePeriod,
            dueOnDayOfMonthPeriod: payablePeriod,
            rentMonthly: parseFloat(calcRentMonthly(amount, payablePeriod)).toFixed(2),
            amountInWords,
            firstPayment: nextPayment,
            secondPayment: nextPayment,
            thereAfterPayment: nextPayment,
            secondPaymentDueOn,
            dueOnDayOfMonth
        });
    };

    return (
        <div className="rent">
            <Form onSubmit={submitForm} initialValues={rent}>
                {({ handleSubmit, values, form }) => {
                    return (
                        <form onSubmit={handleSubmit} id={RENT_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div className="agreement-select">
                                <div>
                                    <FormNumber
                                        name="showAmount"
                                        label={getResTenLabel('rentPerWeek', location)}
                                        onChange={value => {
                                            handleRentAmountChange(value, values);
                                        }}
                                        required
                                    />
                                    <FormTextHidden name="amount" />
                                </div>
                                <div>
                                    <SelectField
                                        label={getResTenLabel('payable', location)}
                                        name="payablePeriod"
                                        options={period}
                                        selectClassName="sel-5"
                                        onChange={value => {
                                            handlePayPeriodChange(value, values);
                                        }}
                                    />
                                </div>
                            </div>
                            {values.amount && agreementUtils.isLongTermRenewalSubLease(subLeaseType) && (
                                <RentAmount
                                    label="The current rent is"
                                    value={`$${agreementUtils.getRentForPeriod(
                                        values.amount,
                                        values.payablePeriod
                                    )} per ${formatPeriod(values.payablePeriod)}`}
                                />
                            )}
                            {agreementUtils.isDefaultSubLease(subLeaseType) && (
                                <>
                                    {location === SA_STATE && (
                                        <React.Fragment>
                                            <FormTextRegular
                                                name="amountInWords"
                                                label={`The ${values.payablePeriod} rent (in words)`}
                                                required
                                                disabled={true}
                                            />
                                            <div className="agreement-select">
                                                {values.payablePeriod === PERIOD_MONTHLY && (
                                                    <p className="help-text big column monthly-rent">
                                                        The monthly rent is
                                                        <br />
                                                        <span
                                                            className="button-minus"
                                                            onClick={() => roundDownMonthlyRent(values)}
                                                        />
                                                        <span>${rent.rentMonthly}</span>
                                                        <span
                                                            className="button-plus"
                                                            onClick={() => roundUpMonthlyRent(values)}
                                                        />
                                                    </p>
                                                )}
                                                <div>
                                                    <SelectField
                                                        label="The rent is payable in advance*"
                                                        name="payableInAdvanced"
                                                        options={period}
                                                        selectClassName="sel-5 payableInAdvanced"
                                                    />
                                                </div>
                                            </div>
                                            <div className="agreement-select">
                                                <div>
                                                    <FormNumber name="firstPayment" label="First payment of" required />
                                                </div>
                                                <div className="calendar">
                                                    <DatePickerInAgencyTimeZone
                                                        label="Due on"
                                                        name="firstPaymentDueOn"
                                                        selected={values.firstPaymentDueOn}
                                                        required
                                                        onChange={value => {
                                                            setRent({
                                                                ...values,
                                                                firstPaymentDueOn: value,
                                                                secondPaymentDueOn: getSecondPaymentDue(
                                                                    value,
                                                                    values.payablePeriod
                                                                )
                                                            });
                                                        }}
                                                        validateOnTouch={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="agreement-select">
                                                <div>
                                                    <FormNumber
                                                        name="secondPayment"
                                                        label="Second payment of"
                                                        required
                                                    />
                                                </div>
                                                <div className="calendar">
                                                    <DatePickerInAgencyTimeZone
                                                        label="Due on"
                                                        name="secondPaymentDueOn"
                                                        selected={values.secondPaymentDueOn}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <FormNumber
                                                name="thereAfterPayment"
                                                label="Thereafter payment of"
                                                required
                                            />
                                            <div className="agreement-select">
                                                <div>
                                                    <FormTextRegular
                                                        name="dueOnDayOfMonth"
                                                        label={`Due on the (day of the ${formatPeriod(
                                                            values.payablePeriod
                                                        )})`}
                                                        required
                                                    />
                                                </div>
                                                <div>
                                                    <SelectField
                                                        label="Payable*"
                                                        name="dueOnDayOfMonthPeriod"
                                                        options={period}
                                                        selectClassName="sel-5 dueOnDayOfMonthPeriod"
                                                    />
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )}
                                    {location !== SA_STATE && rent.payablePeriod === PERIOD_MONTHLY && (
                                        <p className="help-text big monthly-rent">
                                            The monthly rent is
                                            <span
                                                className="button-minus"
                                                onClick={() => roundDownMonthlyRent(values)}
                                            />
                                            <span>{rent.rentMonthly}</span>
                                            <span className="button-plus" onClick={() => roundUpMonthlyRent(values)} />
                                        </p>
                                    )}
                                    {values.amount && values.payablePeriod && (
                                        <RentAmount
                                            label="The tenant will pay"
                                            value={`$${agreementUtils.getRentForPeriod(
                                                values.payablePeriod === PERIOD_MONTHLY
                                                    ? agreementUtils.calculateFromMonthlyRent(values.rentMonthly)
                                                    : values.amount,
                                                values.payablePeriod
                                            )} per ${formatPeriod(values.payablePeriod)}`}
                                        />
                                    )}
                                    {location === QLD_STATE && (
                                        <>
                                            <FormRadioGroup
                                                label="Do you need to nominate when the rent was last increased?"
                                                name={'nominateDateLastIncreased'}
                                                data={[
                                                    {
                                                        label: 'Yes',
                                                        value: YES_VALUE
                                                    },
                                                    {
                                                        label: 'No',
                                                        value: NO_VALUE
                                                    }
                                                ]}
                                                value={values.nominateDateLastIncreased}
                                                validateOnTouch
                                                required
                                            />
                                            {values.nominateDateLastIncreased === YES_VALUE && (
                                                <div className="calendar">
                                                    <SuggestionDatePicker
                                                        label="When was the rent for the premises last increased?"
                                                        name="nominatedDateLastIncreased"
                                                        selected={rent.nominatedDateLastIncreased}
                                                        onChange={value => {
                                                            setRent({
                                                                ...values,
                                                                nominatedDateLastIncreased: value
                                                            });
                                                        }}
                                                        required
                                                        validateOnTouch
                                                        suggestionsFieldName={`nominatedDateLastIncreased`}
                                                    />
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {location !== SA_STATE && (
                                        <div className="calendar">
                                            <DatePickerInAgencyTimeZone
                                                label={getResTenLabel('dateStart', location)}
                                                name="dateStart"
                                                selected={rent.dateStart}
                                                onChange={value => {
                                                    setRent({
                                                        ...values,
                                                        dateStart: value
                                                    });
                                                }}
                                            />
                                        </div>
                                    )}

                                    <CheckboxCheck
                                        className={'isBond'}
                                        dataTest={'isBond'}
                                        name="isBond"
                                        value={false}
                                        label="There is bond payable"
                                        onClick={value => {
                                            // value is the previous value of the checkbox not the new value
                                            if (value) {
                                                form.change('bondAlreadyHeld', false);
                                            }
                                        }}
                                    />

                                    {values.isBond && (
                                        <div>
                                            <FormTextRegular
                                                name="bondAmount"
                                                label="The Bond is"
                                                disabled={!values.isBond}
                                                required
                                                onChange={e => {
                                                    values.bondAmount = e.target.value;
                                                    handleChangeBondAmount(values);
                                                }}
                                            />
                                            {location === SA_STATE && (
                                                <React.Fragment>
                                                    <FormTextRegular
                                                        name="bondAmountInWords"
                                                        label="Amount in words"
                                                        disabled={true}
                                                        required
                                                    />
                                                    <p className="note">
                                                        NB: In accordance with section 61 of the Residential Tenancies
                                                        Act 1995 (SA) and Regulation 8 of the Residential Tenancies
                                                        Regulation 2010 (SA), the Bond will be 4 weeks' rent if the rent
                                                        is less than $800 per week or 6 weeks' rent if the rent is more
                                                        than $800 per week.
                                                    </p>
                                                    <br />
                                                </React.Fragment>
                                            )}
                                            {location === NSW_STATE && (
                                                <div className="radioGroupList">
                                                    <FormRadioGroup
                                                        label="Bond payable to:"
                                                        name={'bondPayableTo'}
                                                        radioGroupClass="wrapped-radio-group"
                                                        data={[
                                                            {
                                                                label: 'The landlord or other person',
                                                                value: 'bondPayableToLandlordOther'
                                                            },
                                                            {
                                                                label: "The landlord's agent",
                                                                value: 'bondPayableToLandlordsAgent'
                                                            },
                                                            {
                                                                label: 'NSW Fair Trading through Rental Bonds Online',
                                                                value: 'bondPayableToFairTrading'
                                                            }
                                                        ]}
                                                    />
                                                </div>
                                            )}
                                            {location === VIC_STATE && (
                                                <div className="calendar">
                                                    <DatePickerInAgencyTimeZone
                                                        label="Bond Payment Due"
                                                        required
                                                        name="bondDate"
                                                        selected={rent.bondDate}
                                                        onChange={value => {
                                                            setRent({
                                                                ...values,
                                                                bondDate: value
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            )}
                                            {values.bondAmount && (
                                                <div>
                                                    <p className="help-text big bond-amount">
                                                        Bond amount
                                                        <span
                                                            className="button-minus"
                                                            onClick={() => roundDownBond(values)}
                                                        />
                                                        <span>{rent.bondAmount}</span>
                                                        <span
                                                            className="button-plus"
                                                            onClick={() => roundUpBond(values)}
                                                        />
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    )}

                                    <CheckboxCheck
                                        className={'bond-already-held'}
                                        name="bondAlreadyHeld"
                                        value={false}
                                        label="Bond already held?"
                                        onClick={value => {
                                            // value is the previous value of the checkbox not the new value
                                            if (value) {
                                                form.change('isBond', false);
                                            }
                                        }}
                                    />
                                    <CheckboxCheck
                                        className={'is-rent-increase'}
                                        name="isRentIncrease"
                                        value={false}
                                        onClick={value => {
                                            setRentIncrease(value, values);
                                        }}
                                        label="There is a rent increase during the term of this lease"
                                    />
                                </>
                            )}
                            {agreementUtils.isLongTermRenewalSubLease(subLeaseType) && (
                                <div className="no-margin-container">
                                    <FormYesNoCheckbox
                                        className="is-rent-increase"
                                        name="isRentIncrease"
                                        text="Is there change in the current rent?"
                                        onClick={value => {
                                            setRentIncrease(value, values);
                                        }}
                                    />
                                </div>
                            )}
                            {values.isRentIncrease && (
                                <div>
                                    {rent.rentIncreaseList && (
                                        <div className="rent-increase-group">
                                            {rent.rentIncreaseList.map((rentIncrease, index) => (
                                                <RentIncreaseGroup
                                                    index={index}
                                                    location={location}
                                                    key={index}
                                                    rent={rent}
                                                    values={values}
                                                    setRent={setRent}
                                                    calcRentMonthly={calcRentMonthly}
                                                    multipleRentIncrease={
                                                        [NSW_STATE, ACT_STATE, SA_STATE].includes(location) &&
                                                        !agreementUtils.isLongTermRenewalSubLease(subLeaseType)
                                                    }
                                                    removeRentIncrease={index => removeRentIncrease(values, index)}
                                                    roundUpNewMonthlyRent={index =>
                                                        roundUpNewMonthlyRent(values, index)
                                                    }
                                                    roundDownNewMonthlyRent={index =>
                                                        roundDownNewMonthlyRent(values, index)
                                                    }
                                                    handleNewRentAmountChange={handleNewRentAmountChange}
                                                    handleNewRentAmountSuggestionChange={
                                                        handleNewRentAmountSuggestionChange
                                                    }
                                                    subLeaseType={subLeaseType}
                                                />
                                            ))}
                                        </div>
                                    )}
                                    {(location === NSW_STATE ||
                                        location === ACT_STATE ||
                                        (location === SA_STATE &&
                                            rent.rentIncreaseList.length < 2 &&
                                            agreementUtils.isDefaultSubLease(subLeaseType))) && (
                                        <div className="button">
                                            <button type="button" onClick={() => checkAndAddRentIncrease(values)}>
                                                Add a new rent increase
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                        </form>
                    );
                }}
            </Form>
        </div>
    );
});

export default memo(Rent);
