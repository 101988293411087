import React from 'react';
import cx from 'classnames';
import { Tag, TagSize, TagColor, TagBorder } from '@app/common/components/tag/Tag';
import Icon, { Icons } from '@app/common/components/Icon';
import { VerificationStatus } from '@app/types/UploadADoc';
import useComponentId from '@app/hooks/useComponentId';
import Tooltip from '@app/common/components/tooltips/Tooltip';

import styles from './VerificationStatusDisplay.module.scss';

type VerificationStatusProps = {
    status?: VerificationStatus;
    clientFirstName?: string;
};

export const VerificationStatusDisplay: React.FC<VerificationStatusProps> = ({
    status = VerificationStatus.PENDING,
    clientFirstName
}) => {
    const componentId = useComponentId().toString();

    return (
        <>
            {status === VerificationStatus.VERIFIED && (
                <Tag
                    className={cx(styles.requireProofOfIdentity, styles.verified)}
                    text="Verified"
                    startIcon={
                        <Icon icon={Icons.VERIFICATION_REQUIRED} className={styles.requireProofOfIdentityIcon} />
                    }
                    size={TagSize.X_Small}
                    color={TagColor.Success}
                    border={TagBorder.None}
                />
            )}
            {status === VerificationStatus.PENDING && (
                <Tag
                    className={styles.requireProofOfIdentity}
                    text="Verification Required"
                    startIcon={
                        <Icon icon={Icons.VERIFICATION_REQUIRED} className={styles.requireProofOfIdentityIcon} />
                    }
                    size={TagSize.X_Small}
                />
            )}
            {status === VerificationStatus.FAILED && (
                <>
                    <Tag
                        className={cx(styles.requireProofOfIdentity, styles.failed)}
                        text="Verification Failed"
                        startIcon={
                            <Icon icon={Icons.VERIFICATION_FAILED} className={styles.requireProofOfIdentityIcon} />
                        }
                        size={TagSize.X_Small}
                        color={TagColor.Error}
                        data-for={componentId}
                        data-tip
                    />
                    <Tooltip effect="solid" place="bottom" id={componentId} className={styles.tooltip}>
                        <p>
                            {clientFirstName || 'The recipient'} is unable to complete the verification process as part
                            of signing.
                        </p>
                        <p>Contact them for more information.</p>
                    </Tooltip>
                </>
            )}
        </>
    );
};
