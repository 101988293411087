import React, { useEffect, memo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import LeftMenu from '../components/dashboard/LeftMenu';
import GeneralLeftMenu from '../general/components/dashboard/GeneralLeftMenu';
import NotificationsPanel from '../components/dashboard/NotificationsPanel';
import Header from './Header';
import LeaseAgreementForm from './dashboard/LeaseAgreementForm';
import AgreementTemplateForm from '../containers/dashboard/template/AgreementTemplateForm';
import TerminationNoticeWithoutLeaseForm from './dashboard/documents/termination/TerminationNoticeWithoutLeaseForm';
import RentReductionForm from './dashboard/documents/rentReduction/RentReductionForm';
import RentIncreaseForm from './dashboard/documents/rentIncrease/RentIncreaseForm';
import IntentionToSellForm from './dashboard/documents/intentionToSell/IntentionToSellForm';
import EstimatedSellingPriceForm from './dashboard/documents/estimatedSellingPriceNotice/EstimatedSellingPriceForm';
import CloneResTen from './dashboard/lease/CloneResTen';
import InfoAgreement from './dashboard/InfoAgreement.tsx';
import Renewal from './dashboard/Renewal';
import LeaseAgreementWithRequestsForm from '../containers/dashboard/LeaseAgreementWithRequestsForm';
import ConfirmPlanUpgradeModal from '../components/modals/ConfirmPlanUpgradeModal';
import ModalWarningMessage from '../components/modals/ModalWarningMessage';
import ResendAgreementForSigningModal from '../components/modals/ResendForSigningModal/ResendAgreementForSigningModal';
import SendLandlordInformationStatementModal from '../components/modals/sendLandlordInformationStatementModal/SendLandlordInformationStatementModal';
import TerminateLeaseModal from '../components/modals/TerminateLeaseModal';
import EditAddressModal from '../components/modals/EditAddressModal';
import AuditTrailModal from '../components/modals/AuditTrailModal';
import NotesModal from '../components/modals/NotesModal';
import Toast from '../common/components/Toast.tsx';

import { useIntercom } from 'react-use-intercom';

import '../sass/layout.scss';
import { getUserInfo } from '../actions/user';
import { closeRentIncreaseForm } from '../actions/document';
import { getAgencyInfo as getAgencyInfoAction } from '../actions/account';
import { getUserInfo as userInfoSelector } from '../selectors/user';
import { getAgencyInfo } from '../selectors/agency';
import { getAgency } from '../selectors/settings/account';
import {
    isLeftMenuOpen as isLeftMenuOpenSelector,
    isNotificationPanelOpen as isNotificationPanelOpenSelector,
    getMobileViewEnabled,
    isNotesOpen,
    isIntentionRenewalModalOpen
} from '../selectors/dashboard';
import { getIsOpenLeaseAgreementForm, getLocation } from '../selectors/lease';
import { extractUserAndAgencyInfo } from '../utils/vendorUtils';
import ErrorBoundary from '../errorBoundary';
import AddressDetailsModal from '../components/modals/AddressDetailsModal';
import { isMobileWidth } from '../config';
import { getIsOpenRentIncreaseForm } from '../selectors/document/rentIncrease';
import { useRollbarProvider } from '../components/providers/RollbarProvider';
import ModalReasonCancelDocument from '../components/modals/ModalReasonCancelDocument';
import CreateFlkWrapper from './dashboard/documents/createFlk/CreateFlkWrapper';
import BuildADocV2 from './dashboard/documents/buildADoc/BuildADocV2';
import DisclosureDocumentWrapper from './dashboard/documents/disclosure/DisclosureDocumentWrapper';
import FlkAPdfForm from './dashboard/documents/FlkAPdf/FlkAPdfForm';
import { ExchangeOfContracts } from './dashboard/documents/exchangeOfContracts/ExchangeOfContracts';
import { getIsOpenTerminationNoticeForm } from '../selectors/document/termination';
import { getIsOpenBreachAgreementModal } from '../selectors/document/breachAgreement';
import { getIsOpenIntentionToSellDocumentModal } from '../selectors/document/intentionToSellDocument.ts';
import { getIsOpenEstimatedSellingPriceNoticeDocumentModal } from '../selectors/document/estimatedSellingPriceDocument.ts';
import { getIsOpenEntryNoticeModal } from '../selectors/document/entryNotice.ts';
import IntentionRenewalModal from '../components/modals/IntentionRenewalModal';
import { getIsCustomDocumentModalOpen } from '../selectors/document/customDocument';
import { getIsExchangeOfContractsModalOpen } from '../selectors/document/exchangeOfContracts';
import { getIsOpenFlkAKeyForm, getIsOpenFlkAKeyCheckInModal } from '../selectors/flkAKey';
import { useAccountProvider } from '../components/providers/AccountProvider';
import { isAgencyUserAccount, isBusinessUserAccount } from '../utils/userUtils';
import BreachNoticeWithoutLeaseForm from './dashboard/documents/breach/BreachNoticeWithoutLeaseForm';
import EntryNoticeWithoutLeaseForm from './dashboard/documents/entryNotice/EntryNoticeWithoutLeaseForm.tsx';
import FlkAKeyForm from './dashboard/flkAKey/FlkAKeyForm';
import FlkAKeyCheckInModal from './dashboard/flkAKey/FlkAKeyCheckInModal';
import { getIsOpenRentReductionForm } from '../selectors/document/rentReduction';
import { getIsOpenCreateFlkModal } from '../selectors/document/createFlk';
import { getIsResendLeaseModalOpen } from '../selectors/resendLease';
import { getIsResendLandlordInformationStatementModalOpen } from '../selectors/resendInformationStatement';
import { getAddressDetailsOpen } from '../selectors/lease/addresses';
import { getIsCancelReasonModalOpen } from '../selectors/document';
import { getIsOpenDisclosureDocumentModal } from '../selectors/document/discloserDocument';
import { getIsNoSignatureModalOpen } from '../selectors/signatures';
import { initLiveSession } from '../utils/generalUtils';
import RentIncreaseListModal from './dashboard/RentIncreaseListModal';
import { getIsRentIncreaseListOpen } from '../selectors/rentIncrease';
import { getIsTerminateLeaseListModalOpen } from '../selectors/termination';
import TerminationListModal from './dashboard/TerminationListModal';
import {
    getIsOpenRetractLeaseConfirmModal,
    getIsOpenCancelFutureAlertsConfirmModal,
    getIsOpenNoLicenseeNumberModal
} from '../selectors/confirmModals';
import RetractOfferConfirmModal from '../components/modals/RetractOfferConfirmModal';
import CancelFutureAlertsConfirmModal from '../components/modals/CancelFutureAlertsConfirmModal';
import LicenseeNumberMissingAlertModal from '../components/modals/LicenseeNumberMissingAlertModal';
import { omit } from 'lodash';
import cx from 'classnames';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { useClarity } from '../hooks/useClarity';
import { BUILD_A_DOC_V2, FLK_B_MOBILE_RESPONSIVE } from '../constants/featureFlags';
import pushDetailsToHubspot from '../utils/hubspotUtils';
import { useFlkBusinessMobileRedirect } from '../hooks/useFlkBusinessMobileRedirect';
import { getIsUserLoggedIn } from '../selectors/login/index.js';
import { clearLoginUser } from '../actions/login.js';

const Layout = props => {
    const dispatch = useDispatch();
    const { boot, update } = useIntercom();

    const Rollbar = useRollbarProvider();

    const userInfo = useSelector(userInfoSelector);
    const agency = useSelector(getAgencyInfo);
    const agencyPopulated = useSelector(getAgency);
    const isLeftMenuOpen = useSelector(isLeftMenuOpenSelector);
    const isNotificationPanelOpen = useSelector(isNotificationPanelOpenSelector);
    const isOpenRequestsToChangeForm = useSelector(state => state.leaseWithRequest.isOpenForm);
    const isOpenTerminationNoticeForm = useSelector(getIsOpenTerminationNoticeForm);
    const isOpenBreachAgreementModal = useSelector(getIsOpenBreachAgreementModal);
    const isOpenEntryNoticeModal = useSelector(getIsOpenEntryNoticeModal);
    const demoModule = useSelector(state => state.user.demoModule);
    const location = useSelector(getLocation);
    const mobileViewEnabled = useSelector(getMobileViewEnabled);
    const isCustomDocumentModalOpen = useSelector(getIsCustomDocumentModalOpen);
    const isExchangeOfContractsModalOpen = useSelector(getIsExchangeOfContractsModalOpen);
    const isOpenRentIncreaseForm = useSelector(getIsOpenRentIncreaseForm);
    const isOpenFlkAKeyForm = useSelector(getIsOpenFlkAKeyForm);
    const isOpenFlkAKeyCheckInModal = useSelector(getIsOpenFlkAKeyCheckInModal);
    const isOpenRentReductionForm = useSelector(getIsOpenRentReductionForm);
    const isOpenCreateFlkModal = useSelector(getIsOpenCreateFlkModal);
    const isResendLeaseModalOpen = useSelector(getIsResendLeaseModalOpen);
    const isResendLandlordInformationStatementModalOpen = useSelector(getIsResendLandlordInformationStatementModalOpen);
    const isTerminateLeaseListOpen = useSelector(getIsTerminateLeaseListModalOpen);
    const isRentIncreaseListOpen = useSelector(getIsRentIncreaseListOpen);
    const addressDetailsOpen = useSelector(getAddressDetailsOpen);
    const isNotesModalOpen = useSelector(isNotesOpen);
    const isIntentionRenewalOpen = useSelector(isIntentionRenewalModalOpen);
    const isCancelReasonModalOpen = useSelector(getIsCancelReasonModalOpen);
    const IsOpenDisclosureDocumentModal = useSelector(getIsOpenDisclosureDocumentModal);
    const isNoSignatureModalOpen = useSelector(getIsNoSignatureModalOpen);
    const isOpenLeaseAgreementForm = useSelector(getIsOpenLeaseAgreementForm);
    const isOpenRetractLeaseConfirmModal = useSelector(getIsOpenRetractLeaseConfirmModal);
    const isOpenCancelFutureAlertsConfirmModal = useSelector(getIsOpenCancelFutureAlertsConfirmModal);
    const isOpenNoLicenseeNumberModal = useSelector(getIsOpenNoLicenseeNumberModal);
    const isOpenIntentionToSellDocumentModal = useSelector(getIsOpenIntentionToSellDocumentModal);
    const isOpenEstimatedSellingPriceNoticeDocumentModal = useSelector(
        getIsOpenEstimatedSellingPriceNoticeDocumentModal
    );
    const isUserLoggedIn = useSelector(getIsUserLoggedIn);

    const isBuildADocV2Enabled = useFeatureFlag(BUILD_A_DOC_V2);
    const isFlkBMobileResponsivenessEnabled = useFeatureFlag(FLK_B_MOBILE_RESPONSIVE);

    const [mobileView, setMobileView] = useState(mobileViewEnabled);
    const accountType = useAccountProvider();

    const modalTitle = isAgencyUserAccount(accountType) ? 'NO AGENT SIGNATURE' : 'NO SIGNATURE';

    useEffect(() => {
        if (isUserLoggedIn === false) {
            dispatch(clearLoginUser());
        } else {
            dispatch(getUserInfo());
        }
    }, [dispatch, isUserLoggedIn]);

    useEffect(() => {
        if (userInfo && userInfo.agency && userInfo.agency.id) {
            dispatch(getAgencyInfoAction(userInfo.agency.id));
        }
    }, [dispatch, userInfo]);

    useEffect(() => {
        if (userInfo && userInfo.id && agencyPopulated && agencyPopulated.id) {
            // Create a User object and save it in window
            const user = extractUserAndAgencyInfo(userInfo, agencyPopulated);
            window.user = user;
            if (GTM_ID) {
                const tagManagerArgs = {
                    dataLayer: omit(user, ['email', 'phone']),
                    dataLayerName: 'user'
                };

                window.dataLayer.push(tagManagerArgs);
            }

            if (!isMobileWidth() && INTERCOM_APP_ID) {
                boot({
                    custom_launcher_selector: 'intercom_live_chat',
                    customAttributes: user
                });
                // if it was already booted, this will update so we have all relevant info
                update({ customAttributes: user });
            }

            initLiveSession(user);

            Rollbar.configure({
                payload: {
                    person: {
                        id: userInfo.id,
                        email: userInfo.email,
                        username: userInfo.fullName,
                        agency: agencyPopulated.id
                    }
                }
            });
            window.rollbar = Rollbar;
            pushDetailsToHubspot(user.email);
        }
    }, [userInfo, agencyPopulated, location, boot, update, Rollbar, accountType, isFlkBMobileResponsivenessEnabled]);

    useEffect(() => {
        if (mobileViewEnabled && (isFlkBMobileResponsivenessEnabled || isAgencyUserAccount(accountType))) {
            setMobileView(true);
        } else {
            setMobileView(false);
        }
    }, [isFlkBMobileResponsivenessEnabled, accountType, mobileViewEnabled]);

    useFlkBusinessMobileRedirect(accountType, { disable: isFlkBMobileResponsivenessEnabled });
    useClarity();

    return (
        <div
            className={cx('layout', `notifications-${isNotificationPanelOpen}`, {
                'mobile-view-enabled': mobileView
            })}
            id="main-layout"
        >
            <Header />
            <ErrorBoundary>
                <main className="main">
                    {isLeftMenuOpen && isAgencyUserAccount(accountType) && (
                        <LeftMenu leftMenu={isLeftMenuOpen} demoModule={demoModule} />
                    )}
                    {isLeftMenuOpen && isBusinessUserAccount(accountType) && (
                        <GeneralLeftMenu leftMenu={isLeftMenuOpen} demoModule={demoModule} />
                    )}
                    <div className="main-content">{props.children}</div>
                    {userInfo && agency && (
                        <NotificationsPanel isOpen={isNotificationPanelOpen} agency={agency} userInfo={userInfo} />
                    )}
                </main>
                <Renewal />
                <InfoAgreement />
                {isOpenLeaseAgreementForm && <LeaseAgreementForm />}
                <AgreementTemplateForm />
                {isOpenTerminationNoticeForm && <TerminationNoticeWithoutLeaseForm />}
                {isOpenRentReductionForm && <RentReductionForm />}
                {isOpenRentIncreaseForm && (
                    <RentIncreaseForm
                        rentIncreaseWithoutLease={true}
                        isOpen={isOpenRentIncreaseForm}
                        closeRentIncreaseModal={() => dispatch(closeRentIncreaseForm())}
                    />
                )}
                {isOpenCreateFlkModal && <>{isBuildADocV2Enabled ? <BuildADocV2 /> : <CreateFlkWrapper />}</>}
                {isCustomDocumentModalOpen && <FlkAPdfForm />}
                {isExchangeOfContractsModalOpen && <ExchangeOfContracts />}
                <CloneResTen />
                {isOpenRequestsToChangeForm && <LeaseAgreementWithRequestsForm />}
                <ConfirmPlanUpgradeModal />
                {isResendLeaseModalOpen && <ResendAgreementForSigningModal />}
                {isResendLandlordInformationStatementModalOpen && <SendLandlordInformationStatementModal />}
                <TerminateLeaseModal />
                {isTerminateLeaseListOpen && <TerminationListModal />}
                {isRentIncreaseListOpen && <RentIncreaseListModal />}
                {addressDetailsOpen && <AddressDetailsModal />}
                <EditAddressModal />
                <AuditTrailModal />
                {isNotesModalOpen && <NotesModal />}
                {isIntentionRenewalOpen && <IntentionRenewalModal />}
                {isCancelReasonModalOpen && <ModalReasonCancelDocument />}
                {IsOpenDisclosureDocumentModal && <DisclosureDocumentWrapper />}
                {isNoSignatureModalOpen && (
                    <ModalWarningMessage
                        titleTxt={modalTitle}
                        message={`You do not have a signature saved in the system. Please go here to add your signature so you can complete the document:`}
                        link={'/user/signatures'}
                        accountType={accountType}
                    />
                )}
                {isOpenBreachAgreementModal && <BreachNoticeWithoutLeaseForm />}
                {isOpenEntryNoticeModal && <EntryNoticeWithoutLeaseForm />}
                {isOpenFlkAKeyForm && <FlkAKeyForm />}
                {isOpenFlkAKeyCheckInModal && <FlkAKeyCheckInModal />}
                {isOpenRetractLeaseConfirmModal && <RetractOfferConfirmModal />}
                {isOpenCancelFutureAlertsConfirmModal && <CancelFutureAlertsConfirmModal />}
                {isOpenNoLicenseeNumberModal && <LicenseeNumberMissingAlertModal />}
                {isOpenIntentionToSellDocumentModal && <IntentionToSellForm />}
                {isOpenEstimatedSellingPriceNoticeDocumentModal && <EstimatedSellingPriceForm />}
            </ErrorBoundary>
        </div>
    );
};

export default memo(Layout);
