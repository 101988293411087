import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { getError, handleInputValueChange } from '../../utils/formUtils';
import { useMutation } from '@tanstack/react-query';
import cx from 'classnames';
import Suggestion from '../suggestions/Suggestion';
import axios from 'axios';
import { CHAT_GPT_INTEGRATION } from '../../constants/featureFlags.ts';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { isEmpty } from 'lodash';

const TextInput = ({
    input,
    label,
    dataTest,
    disabledLabel,
    labelHint,
    labelClassName,
    required,
    onChange,
    onKeyDown,
    meta,
    validateOnTouch,
    showVirtualDollar,
    onBlur,
    alwaysShowOn,
    suggestionsList,
    removeSuggestion,
    suggestionIcon,
    allowSelectMultipleSuggestions,
    containerClassName,
    getSuggestionIndex,
    ...rest
}) => {
    return (
        <div className={cx('FormInput', getError(meta, validateOnTouch) ? 'FormInput--error' : '', containerClassName)}>
            <div
                className={`${input.value ? 'form-group regular on' : 'form-group regular'} ${
                    showVirtualDollar && !isNaN(input.value[0]) ? 'virtual-dollar' : ''
                }`}
            >
                <label
                    className={cx(labelClassName, disabledLabel, {
                        FormError: getError(meta, validateOnTouch)
                    })}
                >
                    {label}
                    {required ? '*' : null}
                </label>
                {!isEmpty(suggestionsList) && (
                    <div className="with-suggestions">
                        {(allowSelectMultipleSuggestions || !input.value) &&
                            suggestionsList?.map((suggestion, index) => {
                                return (
                                    <Suggestion
                                        className={'form-suggestions'}
                                        key={index}
                                        text={suggestion}
                                        icon={suggestionIcon}
                                        onClick={() => {
                                            // If allowSelectMultipleSuggestions is true and input value is not empty, then append the suggestion to the input value
                                            const inputValue =
                                                allowSelectMultipleSuggestions && !isEmpty(input.value)
                                                    ? `${input.value} ${suggestion}`
                                                    : suggestion;

                                            handleInputValueChange(input, onChange, { target: { value: inputValue } });
                                            getSuggestionIndex?.(index);
                                            removeSuggestion(index);

                                        }}
                                        onClickEndIcon={e => {
                                            e.stopPropagation();
                                            removeSuggestion(index);
                                        }}
                                    />
                                );
                            })}
                    </div>
                )}
                {showVirtualDollar && !isNaN(input.value[0]) && <p className="dollar-mark">$</p>}
                {labelHint && <label className={'label-hint'}>{labelHint}</label>}
                <input
                    data-test={dataTest}
                    data-has-error={!!getError(meta, validateOnTouch)}
                    type="text"
                    {...input}
                    onChange={item => handleInputValueChange(input, onChange, item)}
                    onBlur={e => {
                        if (onBlur) {
                            onBlur(e);
                        }
                        input.onBlur(e);
                    }}
                    onKeyDown={onKeyDown}
                    {...rest}
                />
                {getError(meta, validateOnTouch) && (
                    <span className={'FormError'}>{getError(meta, validateOnTouch)}</span>
                )}
            </div>
        </div>
    );
};

export const HiddenInput = ({ input, meta, showError }) => (
    <>
        <input type="hidden" {...input} />{' '}
        {showError && getError(meta) && <span className={'text-danger'}>{getError(meta)}</span>}
    </>
);

const numberInput = ({
    input,
    disabledLabel,
    label,
    onChange,
    required,
    meta,
    alwaysShowOn,
    validateOnTouch,
    containerClassName,
    onBlur,
    ...rest
}) => (
    <div className={cx('FormInput', getError(meta, validateOnTouch) ? 'FormInput--error' : '', containerClassName)}>
        <div
            className={
                input.value === 0 || input.value || alwaysShowOn ? 'form-group regular on' : 'form-group regular'
            }
        >
            <label className={getError(meta) ? `FormError ${disabledLabel}` : disabledLabel}>
                {label}
                {required ? '*' : null}
            </label>
            <input
                type="number"
                {...input}
                onChange={item => {
                    input.onChange(item);
                    if (onChange) {
                        onChange(item);
                    }
                }}
                onBlur={e => {
                    if (onBlur) {
                        onBlur();
                    }
                    input.onBlur(e);
                }}
                {...rest}
            />
            {getError(meta) && <span className={'FormError'}>{getError(meta)}</span>}
        </div>
    </div>
);
const ChatGptTextAreaInput = ({
    input,
    disabledLabel,
    label,
    labelClassName,
    required,
    meta,
    staticText,
    validateOnTouch,
    containerClassName,
    onBlur,
    small,
    inputClassName,
    gpt_fieldName,
    gpt_isLease,
    gpt_isDocument,
    gpt_documentId,
    gpt_leaseId,
    disabled,
    ...rest
}) => {
    const [inputText, setInputText] = useState('');

    const getCompletionText = useMutation(
        data => {
            return axios.post(`/api/open-ai/completion`, {
                ...data,
                fieldName: gpt_fieldName || input.name,
                isLease: gpt_isLease,
                isDocument: gpt_isDocument,
                documentId: gpt_documentId,
                leaseId: gpt_leaseId
            });
        },
        {
            onSuccess: response => {
                input.onChange(response.data.choices[0].message.content);
            }
        }
    );

    const handleInputChange = event => {
        input.onChange(event);
        setInputText(event.target.value);
    };
    const onKeyDown = event => {
        const key = event.keyCode || event.which;
        if (inputText.startsWith('//') && key === 13) {
            event.preventDefault();
            getCompletionText.mutate({ inputText: inputText.split('//')[1].trim() });
        }
    };
    return (
        <div className={cx('FormInput', getError(meta, validateOnTouch) ? 'FormInput--error' : '', containerClassName)}>
            <div
                className={
                    input.value || staticText
                        ? 'text-area-container form-group regular on'
                        : 'text-area-container form-group regular'
                }
            >
                {label && (
                    <label
                        className={cx(labelClassName, disabledLabel, { FormError: !!getError(meta, validateOnTouch) })}
                    >
                        {label}
                        {required ? '*' : null}
                    </label>
                )}
                {staticText && <span className="static-text">{staticText}</span>}
                <textarea
                    data-has-error={!!getError(meta, validateOnTouch)}
                    {...input}
                    {...rest}
                    className={cx({ 'with-static-text': staticText, small, loading: getCompletionText.isLoading })}
                    onBlur={e => {
                        if (onBlur) {
                            onBlur();
                        }
                        input.onBlur(e);
                    }}
                    onChange={handleInputChange}
                    onKeyDown={onKeyDown}
                    disabled={getCompletionText.isLoading || disabled}
                />
                {getError(meta, validateOnTouch) && (
                    <span className={'FormError'}>{getError(meta, validateOnTouch)}</span>
                )}
            </div>
        </div>
    );
};

const TextAreaInput = ({
    input,
    disabledLabel,
    label,
    labelClassName,
    required,
    meta,
    staticText,
    validateOnTouch,
    containerClassName,
    onBlur,
    small,
    inputClassName,
    ...rest
}) => (
    <div className={cx('FormInput', getError(meta, validateOnTouch) ? 'FormInput--error' : '', containerClassName)}>
        <div
            className={
                input.value || staticText
                    ? 'text-area-container form-group regular on'
                    : 'text-area-container form-group regular'
            }
        >
            {label && (
                <label className={cx(labelClassName, disabledLabel, { FormError: !!getError(meta, validateOnTouch) })}>
                    {label}
                    {required ? '*' : null}
                </label>
            )}
            {staticText && <span className="static-text">{staticText}</span>}
            <textarea
                data-has-error={!!getError(meta, validateOnTouch)}
                {...input}
                {...rest}
                className={cx(inputClassName, { 'with-static-text': staticText, small })}
                onBlur={e => {
                    if (onBlur) {
                        onBlur();
                    }
                    input.onBlur(e);
                }}
            />
            {getError(meta, validateOnTouch) && <span className={'FormError'}>{getError(meta, validateOnTouch)}</span>}
        </div>
    </div>
);

const currencyInput = ({
    input,
    precision,
    name,
    disabledLabel,
    label,
    disabled,
    required,
    meta,
    onValueChange,
    onBlur,
    validateOnTouch,
    containerClassName,
    toolTipIcon,
    ...rest
}) => (
    <div
        className={cx(
            'FormInput',
            'with-placeholder',
            getError(meta, validateOnTouch) ? 'FormInput--error' : '',
            containerClassName
        )}
    >
        <div className={input.value || input.value === 0 ? 'form-group regular on' : 'form-group regular'}>
            <label className={getError(meta) ? `FormError ${disabledLabel}` : disabledLabel}>
                {label}
                {required ? '*' : null}
            </label>{' '}
            {toolTipIcon && <span>{toolTipIcon}</span>}
            <NumericFormat
                {...input}
                {...rest}
                inputMode="decimal"
                disabled={disabled}
                thousandSeparator={true}
                decimalScale={parseInt(precision)}
                placeholder="$0.00"
                fixedDecimalScale={true}
                allowLeadingZeros={true}
                prefix={'$'}
                value={input.value}
                onValueChange={values => {
                    // update form values using floatValue
                    const value = typeof values.floatValue !== 'undefined' ? values.floatValue : '';
                    input.onChange(value);
                    if (onValueChange) {
                        onValueChange(value);
                    }
                }}
                onChange={e => {
                    // this is need to prevent passing formatted string to form
                    // onValueChange function passes the float value to the form
                    e.preventDefault();
                }}
                onBlur={e => {
                    if (onBlur) {
                        onBlur();
                    }
                    input.onBlur(e);
                }}
            />
            {getError(meta) && <span className={'FormError'}>{getError(meta)}</span>}
        </div>
    </div>
);

export const FormText = ({
    name,
    value,
    required,
    label,
    disabled,
    onBlur,
    validateOnTouch,
    showVirtualDollar,
    containerClassName
}) => {
    let disabledLabel = '';
    if (true === disabled) {
        disabledLabel = 'disabled';
    }
    return (
        <Field
            name={name}
            component={TextInput}
            onBlur={onBlur}
            containerClassName={containerClassName}
            disabled={disabled}
            disabledLabel={disabledLabel}
            value={value}
            required={required}
            label={label}
            parse={value => (value === '' ? null : value)} // this is needed so it will submit empty fields as a string '' see https://github.com/final-form/react-final-form/issues/130
            validateOnTouch={validateOnTouch}
            showVirtualDollar={showVirtualDollar}
        />
    );
};

export const FormTextRegular = ({
    name,
    label,
    required,
    disabled,
    onChange,
    onBlur,
    onKeyDown,
    type,
    labelClassName,
    labelHint,
    maxLength,
    dataTest,
    showVirtualDollar,
    alwaysShowOn,
    suggestionsList,
    removeSuggestion,
    suggestionIcon,
    containerClassName,
    onSuggestionClickIndex,
    ...additionalAttributes
}) => {
    let disabledLabel = '';
    if (true === disabled) {
        disabledLabel = 'disabled';
    }

    return (
        <Field
            dataTest={dataTest}
            type={type || 'text'}
            component={TextInput}
            containerClassName={containerClassName}
            disabledLabel={disabledLabel}
            labelHint={labelHint}
            label={label}
            maxLength={maxLength}
            labelClassName={labelClassName}
            required={required}
            name={name}
            disabled={disabled}
            onChange={onChange}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
            parse={value => (value === '' ? null : value)} // this is needed so it will submit empty fields as a string '' see https://github.com/final-form/react-final-form/issues/130
            {...additionalAttributes}
            showVirtualDollar={showVirtualDollar}
            suggestionsList={suggestionsList}
            removeSuggestion={removeSuggestion}
            suggestionIcon={suggestionIcon}
            getSuggestionIndex={onSuggestionClickIndex}
        />
    );
};

export const FormTextHidden = ({ name, showError, ...additionalAttributes }) => {
    return (
        <Field
            type="hidden"
            component={HiddenInput}
            name={name}
            showError={showError}
            parse={value => (value === '' ? null : value)} // this is needed so it will submit empty fields as a string '' see https://github.com/final-form/react-final-form/issues/130
            {...additionalAttributes}
        />
    );
};

export const FormTextCurrency = ({
    name,
    label,
    required,
    disabled,
    precision = 0,
    value,
    onChange,
    validateOnTouch,
    containerClassName,
    toolTipIcon
}) => {
    let disabledLabel = '';
    if (true === disabled) {
        disabledLabel = 'disabled';
    }
    return (
        <Field
            type="text"
            component={currencyInput}
            name={name}
            containerClassName={containerClassName}
            precision={precision}
            label={label}
            disabled={disabled}
            disabledLabel={disabledLabel}
            required={required}
            value={value}
            onValueChange={onChange}
            toolTipIcon={toolTipIcon}
            parse={value => (value === '' ? null : value)} // this is needed, so it will submit empty fields as a string '' see https://github.com/final-form/react-final-form/issues/130
        />
    );
};

export const FormNumber = ({
    name,
    value,
    label,
    required,
    disabled,
    onChange,
    alwaysShowOn,
    validateOnTouch,
    containerClassName,
    addLabelSpacing,
    ...additionalAttributes
}) => {
    let disabledLabel = '';
    if (typeof value === 'undefined') {
        value = '';
    }
    if (true === disabled) {
        disabledLabel = 'disabled';
    }
    return (
        <>
            {addLabelSpacing && (
                <label
                    aria-hidden={addLabelSpacing}
                    className={cx({
                        'visually-hidden': addLabelSpacing,
                        disabled: disabled
                    })}
                >
                    &nbsp;
                </label>
            )}
            <Field
                type="number"
                name={name}
                containerClassName={containerClassName}
                component={numberInput}
                disabledLabel={disabledLabel}
                onChange={onChange}
                label={label}
                required={required}
                value={value}
                disabled={disabled}
                parse={value => (value === '' ? null : value)} // this is needed so it will submit empty fields as a string '' see https://github.com/final-form/react-final-form/issues/130
                {...additionalAttributes}
            />
        </>
    );
};

export const FormTextMultiline = ({
    name,
    value,
    required,
    label,
    labelClassName,
    disabled,
    staticText,
    validateOnTouch,
    containerClassName,
    small,
    // For now we are using chat gpt enabled in all text area places. We can change it later if needed.
    isChatGpt = true,
    inputClassName,
    ...additionalAttributes
}) => {
    const isChatGptEnabled = useFeatureFlag(CHAT_GPT_INTEGRATION);
    let disabledLabel = '';
    if (true === disabled) {
        disabledLabel = 'disabled';
    }
    return (
        <Field
            type="text"
            containerClassName={containerClassName}
            component={isChatGpt && isChatGptEnabled ? ChatGptTextAreaInput : TextAreaInput}
            disabledLabel={disabledLabel}
            label={label}
            labelClassName={labelClassName}
            required={required}
            name={name}
            value={value}
            disabled={disabled}
            staticText={staticText}
            parse={value => (value === '' ? null : value)} // this is needed so it will submit empty fields as a string '' see https://github.com/final-form/react-final-form/issues/130
            small={small}
            validateOnTouch={validateOnTouch}
            inputClassName={inputClassName}
            {...additionalAttributes}
        />
    );
};
