import React from 'react';
import Card, { CardStyles, CardShadow } from '@app/common/components/cards/Card';
import Icon, { Icons } from '@app/common/components/Icon';
import Button from '@app/common/components/Button';
import { VisibilitySharp } from '@app/common/components/mui-icons/icons';
import { ExchangeOfContracts } from '@app/types/ExchangeOfContracts';
import { LEASE_STATUS_COMPLETE } from '@app/actions/dashboard';
import { PDF_GEN_COMPLETE } from '@app/constants/constants';

import styles from './DocumentsSummary.module.scss';

type DocumentsSummaryProps = {
    eocDocument: ExchangeOfContracts;
};

const DocumentsSummary: React.FC<DocumentsSummaryProps> = ({ eocDocument }) => {
    return (
        <>
            {eocDocument.uploadedDocuments.map((uploadedDocument, index) => {
                return (
                    <Card
                        key={index}
                        style={CardStyles.SQUARE}
                        shadow={CardShadow.SMALL}
                        animate={false}
                        className={styles.document}
                    >
                        <Icon icon={Icons.PDF} className={styles.icon} />
                        <div className={styles.details}>
                            <div className={styles.name}>{uploadedDocument.document.documentName}</div>
                            <div className={styles.size}>{uploadedDocument.document.prettyBytes}</div>
                        </div>
                        <Button
                            className={styles.viewDocumentButton}
                            type="button"
                            startIcon={<VisibilitySharp />}
                            onClick={() => {
                                window.open(
                                    `/reader/document/custom-document/${eocDocument.id}/${uploadedDocument.id}`,
                                    '_blank'
                                );
                            }}
                        >
                            View PDF
                        </Button>
                        {eocDocument.status === LEASE_STATUS_COMPLETE && (
                            <Button
                                className={styles.viewDocumentButton}
                                type="button"
                                startIcon={<VisibilitySharp />}
                                onClick={() => {
                                    window.open(
                                        `/reader/document/custom-document-execution/${eocDocument.id}/${uploadedDocument.id}`,
                                        '_blank'
                                    );
                                }}
                                disabled={eocDocument.pdfGeneratedState !== PDF_GEN_COMPLETE}
                            >
                                View Acknowledgement
                            </Button>
                        )}
                    </Card>
                );
            })}
        </>
    );
};

export default DocumentsSummary;
