import { TagColor } from '@app/common/components/tag/Tag';
import axios from 'axios';
import { CustomPlaceholderRespondentType } from '../../../../types/UploadADoc';

type BaseIntegrationDetails = {
    propertyDetails: {
        address: string;
        id: string;
    };
};

type PropertyMeIntegrationDetails = BaseIntegrationDetails & {
    Id: string;
} & ({ type: 'propertyMe' } | { integration: 'propertyMe' });

type PropertyTreeIntegrationDetails = BaseIntegrationDetails & {
    id: string;
} & ({ type: 'propertyTree' } | { integration: 'propertyTree' });

type ManagedIntegrationDetails = BaseIntegrationDetails & {
    id: string;
    type: 'managed';
} & ({ type: 'managed' } | { integration: 'managed' });

export type IntegrationDetails =
    | PropertyMeIntegrationDetails
    | PropertyTreeIntegrationDetails
    | ManagedIntegrationDetails;

export const getIntegrationPropertyDetails = async (selectedIntegration?: IntegrationDetails) => {
    const integrationId =
        (selectedIntegration as PropertyMeIntegrationDetails)?.Id ||
        (selectedIntegration as PropertyTreeIntegrationDetails | ManagedIntegrationDetails)?.id;

    if (!selectedIntegration || !integrationId) {
        return;
    }

    let integrationApiUrl;
    let integrationType;

    if ('type' in selectedIntegration) {
        integrationType = selectedIntegration.type;
    } else if ('integration' in selectedIntegration) {
        integrationType = selectedIntegration.integration;
    }

    switch (integrationType) {
        case 'propertyMe':
            integrationApiUrl = `/api/integrations/property-me/document/${integrationId}`;
            break;
        case 'propertyTree':
            integrationApiUrl = `/api/integrations/property-tree/document/${integrationId}`;
            break;
        case 'managed':
            integrationApiUrl = `/api/integrations/managed/document/${integrationId}`;
            break;
    }

    if (integrationApiUrl) {
        const result = await axios.get(integrationApiUrl);

        return result?.data.propertyDetails;
    }
};

export const getCustomPlaceholderEmptyText = (respondentType: CustomPlaceholderRespondentType) => {
    switch (respondentType) {
        case CustomPlaceholderRespondentType.CLIENT:
            // TODO: Get the first name of the client
            return "Recipient's response";
        case CustomPlaceholderRespondentType.SENDER:
        default:
            return 'Details pending';
    }
};

export const getCustomPlaceholderValuePlaceholderText = (respondentType: CustomPlaceholderRespondentType) => {
    switch (respondentType) {
        case CustomPlaceholderRespondentType.CLIENT:
            return "Recipient's response";
        case CustomPlaceholderRespondentType.SENDER:
        default:
            return 'Enter response or leave blank';
    }
};

export const getTagColour = (clientIndex: number) => {
    switch (clientIndex) {
        case 0:
            return TagColor.Orange;
        case 1:
            return TagColor.Yellow;
        case 2:
            return TagColor.Blue;
        case 3:
            return TagColor.Red;
        case 4:
            return TagColor.Pink;
        case 5:
            return TagColor.Mint;
        case 6:
            return TagColor.Aqua;
        case 7:
            return TagColor.Purple;
    }
};
