import { createSelector } from 'reselect';
import { isAdmin } from '../utils/userUtils';

export const getUser = state => state.user;

export const getUserInfo = createSelector([getUser], user => user.userInfo);

/**
 * @deprecated Use getAgencyDefaultLocation instead. This selector uses NSW as the default value until data is loaded, which can cause incorrect behaviour.
 */
export const getAgencyLocation = createSelector([getUser], user => user.agencyLocation);

export const getAgency = createSelector([getUser], user => (user.agency ? user.agency : null));

export const getAgencyName = createSelector([getAgency], agency => agency?.details?.agencyName);

export const getIsListNowEnabled = createSelector([getAgency], agency => agency?.externalServices?.listNow?.enabled);

export const getAgencyId = createSelector([getUser], user => user.agencyId);

export const getAgencyContactNumber = createSelector([getAgency], agency =>
    agency.details ? agency.details.phone : null
);
export const getHasPermissionTo = createSelector([getUserInfo], userInfo => userInfo.hasPermissionTo);

export const getSavedTeamMemberSelection = createSelector([getUserInfo], userInfo => userInfo.savedTeamMemberSelection);

export const getUserRole = createSelector([getUserInfo], info => info.role);

export const getIsSuperAdmin = createSelector([getUserInfo], user => user.isSuperAdmin);

export const isUserRoleAdmin = createSelector([getUserRole], role => isAdmin(role));

export const isFetchingUserInfo = createSelector([getUserInfo], userInfo => userInfo === true);

export const getAgent = createSelector([getUser], user => user.agent);

export const getIsTeamMemberSelectionPending = createSelector([getUser], user => user.UPDATE_TEAM_MEMBER_INFO_REQUEST);
export const getIsTeamMemberSelectionSuccess = createSelector([getUser], user => user.UPDATE_TEAM_MEMBER_INFO_SUCCESS);

const findModule = (user, key) => {
    if (user.modules) {
        const moduleFound = user.modules.find(productModule => {
            return productModule.productKey === key;
        });
        return moduleFound ? moduleFound.active : false;
    }
    return false;
};

/**
 * FEATURE FLAGS
 */
// export const getActModuleActive = createSelector([getUser], user => findModule(user, 'ACT'));
export const getBulkModuleActive = createSelector([getUser], user => findModule(user, 'BULK_FLK_A_PDF'));

export const getValidationErrors = createSelector([getUser], user => user.validationErrors);
export const getMfaError = createSelector([getUser], user => user.mfaError);

export const getAgencyDefaultLocation = createSelector([getAgency], agency => agency?.details?.location);
