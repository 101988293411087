import React from 'react';
import ModalDialog from '@app/common/components/ModalDialog';
import cx from 'classnames';

import styles from './EocModal.module.scss';

interface EocModalProps {
    isOpen: boolean;
    closeModal: () => void;
    children: React.ReactNode;
    header?: React.ReactNode;
    title?: string;
    allowOverflow?: boolean;
    className?: string;
    footer?: React.ReactNode;
}

const EocModal: React.FC<EocModalProps> = ({
    isOpen,
    closeModal,
    children,
    header,
    title,
    allowOverflow,
    className,
    footer
}) => {
    return (
        <ModalDialog
            isOpen={isOpen}
            closeModal={closeModal}
            className={cx(styles.modal, className)}
            bodyClassName={styles.modalBody}
            containerClassName={styles.modalContainer}
            shouldBlockBodyScroll
            title={title}
            customHeader={!!header}
            customHeaderContent={() => header}
            allowOverflow={allowOverflow}
        >
            <div className={styles.content}>{children}</div>
            {footer && footer}
        </ModalDialog>
    );
};

export default EocModal;
