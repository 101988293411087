import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentDocument, getDocumentEditMode } from '@app/selectors/document';
import { isDocumentEditMode, isDocumentReadOnlyMode } from '@app/utils/generalUtils';
import { EocCreate } from './eoc-create/EocCreate';
import { EocForm } from './eoc-form/EocForm';
import { EocSummary } from './eoc-summary/EocSummary';
import { ExchangeOfContracts as ExchangeOfContractsType } from '@app/types/ExchangeOfContracts';
import { closeExchangeOfContractsForm } from '@app/actions/document';
import { useHistory } from 'react-router';
import { EXCHANGE_OF_CONTRACTS } from '@app/config';

const ExchangeOfContracts = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const documentEditMode = useSelector(getDocumentEditMode);
    const eocDocument: ExchangeOfContractsType | undefined = useSelector(getCurrentDocument);

    const hasCreatedDocument = !!eocDocument;

    const close = () => {
        dispatch(closeExchangeOfContractsForm());
        if (hasCreatedDocument) {
            history.push(`/dashboard/documents/${EXCHANGE_OF_CONTRACTS}/${eocDocument.status}`);
        }
    };

    return (
        <>
            {!hasCreatedDocument && <EocCreate close={close} />}
            {hasCreatedDocument && isDocumentEditMode(documentEditMode) && (
                <EocForm close={close} key={eocDocument.id} />
            )}
            {hasCreatedDocument && isDocumentReadOnlyMode(documentEditMode) && (
                <EocSummary close={close} key={eocDocument.id} />
            )}
        </>
    );
};

export { ExchangeOfContracts };
