import React, { memo } from 'react';
import { NO_LABEL, NO_VALUE, YES_LABEL, YES_VALUE } from '../../../../../config';
import { FormRadioGroup } from '../../../../form/FormRadioGroup.js';

type UnitOrTownhouseProps = {
    values: {
        unitOrTownhouse?: string;
    };
};

const UnitOrTownhouse: React.FC<UnitOrTownhouseProps> = props => {
    const { values } = props;

    return (
        <>
            <FormRadioGroup
                label="Is the property a unit or townhouse?"
                name={'unitOrTownhouse'}
                className="additional-term-item"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'unit-or-townhouse-yes',
                        id: 'unit-or-townhouse-yes',
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'unit-or-townhouse-no',
                        id: 'unit-or-townhouse-no',
                        value: NO_VALUE
                    }
                ]}
            />
            {values?.unitOrTownhouse === YES_VALUE && (
                <p className="text-warning">
                    <span className="icon icon-warning" /> You will be required to upload the 'Unit title rental
                    certificate' in the documents section.
                </p>
            )}
        </>
    );
};

export default memo(UnitOrTownhouse);
