import { CompletionStatus, DeliveryType, DOCUMENT_CUSTOM, FLK_A_PDF_TEMPLATE } from '@app/config';
import { Agency } from './Agency';
import { AddressDetails } from './Common';
import { DEFINE_ME, DateString } from './utilityTypes';
import { ConfirmationType, integration, UploadStatus } from '@app/constants/constants';
import { BaseDocument } from './BaseDocument';

export type UploadedFile = {
    documentName: string;
    fileName: string;
    file: string;
    size: number;
    category: string;
    leaseType: string;
    prettyBytes: string;
};

export type UploadedDocument = {
    pdfGeneratedState?: string;
    annotations?: string;
    annotationsCount?: string;
    witnessAnnotationsCount?: string;
    id: string;
    document: UploadedFile & {
        agencyDefault?: boolean;
        id?: string;
        created?: DateString;
        updated?: DateString;
        documentName: string;
    };
    optimisedPdfFile?: boolean;
    confirmedPdf?: string;
    insertedSignaturesPdf?: string;
};

export const isUploadedDocument = (
    document: UploadedDocument | { document: UploadedFile }
): document is UploadedDocument => {
    return (document as UploadedDocument).id !== undefined;
};

export enum VerificationStatus {
    VERIFIED = 'verified',
    PENDING = 'pending',
    FAILED = 'failed'
}

export type Client = {
    id: string;
    name: string;
    email: string;
    phone: string;
    source?: string;
    requireProofOfIdentity?: boolean;
    signingOrder?: number;
    uploadedDocumentsMeta?: {
        read: boolean;
        id: string;
        uploadedDocumentId: string;
        parentDocumentId: string;
        documentName: string;
    }[];
    acknowledgedDate?: DateString;
    signature?: {
        signedDate: string;
        ip: string;
        signedDateFormatted: string;
        signedDateFormattedForPdf: string;
        id: string;
    };
    isSigned?: boolean;
    verifiedProofOfIdentity?: VerificationStatus;
};

export enum CustomPlaceholderRespondentType {
    SENDER = 'sender',
    CLIENT = 'client'
}

export type CustomPlaceholder = {
    id: string;
    label: string;
    value?: string;
    respondentType: CustomPlaceholderRespondentType;
    count: number;
};

type Completion = {
    progress?: {
        completed: boolean;
        stepNumber: number;
        step: string;
        error: string;
    }[];
    status?: CompletionStatus;
    sendSeparateEmails: boolean;
    toNotificationList: string[];
    ccNotificationList: string[];
    bccNotificationList: string[];
    emails: {
        clientEmail: string;
        toNotificationList: string[];
        ccNotificationList: string[];
        bccNotificationList: string[];
    }[];
    signedByAgent: boolean;
};

type ListNow = {
    enabled?: boolean;
    address?: string;
    budget?: number;
    addressDetails?: AddressDetails;
};
// This is an in-progress type definition
export type UploadADoc = BaseDocument & {
    id: string;
    additionalCharges: {
        requiresApproval: boolean;
        bulkSendApprovalStatus: string;
    };
    isSentViaContactList: boolean;
    createdViaEmail: boolean;
    docType: string;
    pdfGeneratedState: string;
    signingClients: DEFINE_ME[]; // Could be an array of specific types if known
    documentTitle: string;
    status: string;
    sentForSigning?: DateString;
    firstSentForSigning: null;
    agency: string | Agency;
    documentLink?: DEFINE_ME;
    client?: {
        id: string;
        clients: Client[];
        currentSigningClientOrder?: number;
    };
    documents: {
        category: string;
        leaseType: string;
        agencyDefault: boolean;
        id: string;
        documentName: string;
        size: number;
        file: string;
        fileName: string;
    }[];
    uploadedDocuments: UploadedDocument[];
    created: DateString;
    updated: DateString;
    acknowledgementText: string;
    confirmationType: ConfirmationType;
    deadline: number;
    deliveryType?: DeliveryType;
    descriptionText: string;
    executionType: string;
    hasAgentSignatureAnnotations?: boolean;
    acknowledgedDate?: DateString;
    customPlaceholders?: CustomPlaceholder[];
    integrationPropertyId?: string;
    address?: string;
    completion?: Completion;
    listNow?: ListNow;
    enforceSequentialSigning?: boolean;
    integrationUploadStatus?: UploadStatus;
    lastSuccessfulUploadToIntegration?: Date;
    integration?: integration;
};

/**
 * This will return true exclusively for UaD documents. It will return false for templates.
 */
export const isUploadADoc = (doc: unknown): doc is UploadADoc => {
    return !!doc && (doc as UploadADoc).docType === DOCUMENT_CUSTOM;
};

export const isUploadADocOrTemplate = (doc: unknown): doc is UploadADoc => {
    return (
        (!!doc && (doc as UploadADoc).docType === DOCUMENT_CUSTOM) || (doc as UploadADoc).docType === FLK_A_PDF_TEMPLATE
    );
};
