import * as React from 'react';
import Button from '../../../../common/components/Button';
import Icon, { Icons } from '../../../../common/components/Icon';
import { ReactComponent as EditPen } from '../../../../../assets/images/icons/editPen.svg';
import cx from 'classnames';
import UploadADocDropdown from '../../../../components/dropdowns/UploadADocDropdown';
import { ReactComponent as ExpandIcon } from '../../../../../assets/images/icons/expand.svg';
import { ReactComponent as DocumentIcon } from '../../../../../assets/images/icons/icon-document.svg';
import { ACKNOWLEDGE_CONFIRMATION_TYPE, SIGN_CONFIRMATION_TYPE } from '../../../../constants/constants';
import { zipWith } from 'lodash';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Tooltip from '../../../../common/components/tooltips/Tooltip';
import useComponentId from '../../../../hooks/useComponentId';

import styles from './UploadADocUploadedDoc.module.scss';
import { Tag, TagColor, TagSize } from '@app/common/components/tag/Tag';
import { getTagColour } from './utils';

export function UploadADocUploadedDoc({
    uploadedDocument,
    clients,
    confirmationType,
    viewDocument,
    uploadedDocumentIndex,
    addAnnotations,
    removeFileToUpload,
    handleClearSignaturePlaceholders,
    handleClearSignaturePlaceholdersOnAllDocs,
    uploadedDocumentsCount,
    isDragging,
    isDraggable,
    isDragDisabled,
    loading,
    hidePdfViewerButton,
    isTemplate
}) {
    const radixDropdownPortalContainerRef = React.useRef(null);
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: uploadedDocument.id,
        disabled: isDragDisabled
    });
    const tooltipId = useComponentId().toString();

    const getParsedAnnotationCounts = () => {
        try {
            // Transform from object with shape {"clientIndex": annotationCount} to array with index corresponding to client position
            // and value corresponding to annotation count for that client. Uses Object.assign() so that keys are converted to indices to leave space
            // for clients without annotations, as these are not present in the raw object.
            return Object.assign([], JSON.parse(uploadedDocument.annotationsCount));
        } catch (e) {
            return [];
        }
    };

    const annotationCounts = getParsedAnnotationCounts();

    // Zip the arrays together to handle clients without annotations (i.e. clients.length > annotationCounts.length)
    // clientAnnotationsCounts.length = max(clients.length, annotationCounts.length)
    let clientAnnotationCounts = zipWith(clients, annotationCounts, (_client, annotationCount) => annotationCount ?? 0);

    if (!isTemplate) {
        clientAnnotationCounts = clientAnnotationCounts.slice(0, clients.length);
    }

    function getIndexClass(index) {
        switch (index) {
            case 0:
                return 'one';
            case 1:
                return 'two';
            case 2:
                return 'three';
            case 3:
                return 'four';
            default:
                return 'zero';
        }
    }

    function handleAddAnnotations(file) {
        addAnnotations(file);
    }

    return (
        <div
            className={cx(styles.uploadItem, { [styles.dragging]: isDragging })}
            ref={setNodeRef}
            data-test={`custom-document-${uploadedDocumentIndex}`}
            style={{ '--dragTransform': CSS.Transform.toString(transform), '--dragTransition': transition }}
        >
            {isDraggable && (
                <Button
                    type="button"
                    {...attributes}
                    {...listeners}
                    className={cx(styles.dragHandle, {
                        [styles.dragging]: isDragging,
                        [styles.dragDisabled]: isDragDisabled
                    })}
                    data-test={`drag-handle-${uploadedDocumentIndex}`}
                >
                    <Icon icon={Icons.DRAG} />
                </Button>
            )}
            <Icon icon={Icons.PDF} className={styles.itemIcon} />
            <div
                className={styles.fileNameContainer}
                data-tip={uploadedDocument.document.documentName}
                data-for={tooltipId}
            >
                {uploadedDocument.document && uploadedDocument.document.id && (
                    <a
                        className={cx(styles.fileName, styles.specialLink)}
                        onClick={() => viewDocument(uploadedDocument.document.id)}
                    >
                        {uploadedDocument.document.documentName}
                    </a>
                )}
                {uploadedDocument.document && !uploadedDocument.document.id && (
                    <div className={styles.fileName}>{uploadedDocument.document.documentName}</div>
                )}
                {uploadedDocument.document && uploadedDocument.document.id && (
                    <div className={cx(styles.fileName, styles.bytes)}>{uploadedDocument.document.prettyBytes}</div>
                )}
            </div>
            <Tooltip place="bottom" effect="solid" id={tooltipId} className={styles.toolTip} />
            {confirmationType === SIGN_CONFIRMATION_TYPE && (
                <div className={cx(styles.placeHolders)}>
                    {clientAnnotationCounts.length ? (
                        <div className={styles.signatureCounts}>
                            {clientAnnotationCounts.map((signatureCount, clientIndex) => (
                                <Tag
                                    className={cx(styles.signatureCount, {
                                        [styles.singleDigit]: signatureCount < 10
                                    })}
                                    text={signatureCount > 0 ? signatureCount : ''}
                                    size={TagSize.Small}
                                    color={signatureCount > 0 ? getTagColour(clientIndex) : TagColor.Grey}
                                    key={clientIndex}
                                />
                            ))}
                        </div>
                    ) : (
                        <div>No Signature Placeholders</div>
                    )}
                </div>
            )}
            {confirmationType === ACKNOWLEDGE_CONFIRMATION_TYPE && (
                <div className={cx(styles.ackStateContainer)}>
                    {uploadedDocument.optimisedPdfFile ? (
                        <div key={uploadedDocumentIndex} className={styles.ackState}>
                            <div className={styles.ackStateCount}>+1</div>
                            <DocumentIcon className={styles.documentIcon} />
                            <div>Acknowledgement page</div>
                        </div>
                    ) : (
                        <div key={uploadedDocumentIndex}>
                            <p>Acknowledgement page</p>
                        </div>
                    )}
                </div>
            )}
            <div ref={radixDropdownPortalContainerRef} className={styles.actions}>
                {confirmationType === SIGN_CONFIRMATION_TYPE && !hidePdfViewerButton && handleAddAnnotations && (
                    <Button
                        className={cx(styles.pdfViewerButton, { [styles.hidden]: !uploadedDocument.annotations })}
                        onClick={() => handleAddAnnotations(uploadedDocument)}
                        startIcon={<EditPen className={styles.annotationButtonIcon} />}
                        type="button"
                        loading={loading}
                    >
                        Edit
                    </Button>
                )}
                <UploadADocDropdown
                    uploadedDocument={uploadedDocument}
                    removeFileToUpload={() => removeFileToUpload(uploadedDocument.id)}
                    handleClearSignaturePlaceholders={handleClearSignaturePlaceholders}
                    handleClearSignaturePlaceholdersOnAllDocs={handleClearSignaturePlaceholdersOnAllDocs}
                    uploadedDocumentsCount={uploadedDocumentsCount}
                    radixDropdownPortalContainer={radixDropdownPortalContainerRef.current}
                >
                    <ExpandIcon
                        className={styles.expandIcon}
                        data-test={`document-${uploadedDocumentIndex}-dropdown`}
                    />
                </UploadADocDropdown>
            </div>
        </div>
    );
}

export default UploadADocUploadedDoc;
