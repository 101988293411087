import React from 'react';
import { useDispatch } from 'react-redux';
import ModalDialog from '../../../../common/components/ModalDialog';
import { Form } from 'react-final-form';
import { isUploadADocOrTemplate, UploadADoc } from '@app/types/UploadADoc';
import TextInputField from '../buildADoc/components/TextInput';
import { FormPhone } from '@app/components/form/FormPhone';
import Card, { CardShadow, CardStyles } from '@app/common/components/cards/Card';
import { DOCUMENT_DELIVERY_TYPE_SMS } from '@app/config';
import Button from '../../../../common/components/Button';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { addOrReplaceDocumentInList, setCurrentDocument } from '../../../../actions/document';
import { UAD_CONNECT_ID } from '@app/constants/featureFlags';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { CheckboxCheck } from '@app/components/form/FormCheckboxCheck';
import { ConnectIdTooltip } from './ConnectIdTooltip';
import { ClientType, ExchangeOfContracts, isExchangeOfContracts } from '@app/types/ExchangeOfContracts';

import styles from './EditRecipientsModal.module.scss';

type EditRecipientsModalProps = {
    isOpen: boolean;
    closeModal: () => void;
    doc: UploadADoc | ExchangeOfContracts;
    openResendModal: () => void;
};

type Client = {
    name: string;
    email: string;
    phone?: string;
};

const getClientNameLabel = (doc: UploadADoc | ExchangeOfContracts) => {
    if (isExchangeOfContracts(doc)) {
        return 'Legal Full Name or Corporation Name + ABN/ACN';
    } else {
        return 'Recipient Name';
    }
};

const EditRecipientsModal: React.FC<EditRecipientsModalProps> = ({ isOpen, closeModal, doc, openResendModal }) => {
    const isConnectIdEnabled = useFeatureFlag(UAD_CONNECT_ID);
    const dispatch = useDispatch();

    const editRecipientsMutation = useMutation(
        (data: Client[]) => {
            if (isExchangeOfContracts(doc)) {
                return axios.post(`api/document/exchange-of-contracts/${doc.id}/edit-recipients`, data);
            } else {
                return axios.post(`api/document/custom-document/${doc.id}/edit-recipients`, data);
            }
        },
        {
            onSuccess: response => {
                dispatch(addOrReplaceDocumentInList(response.data.doc));
                dispatch(setCurrentDocument(response.data.doc));
                closeModal();
                openResendModal();
            }
        }
    );

    const submitValues = async (values: Client[]) => {
        try {
            await editRecipientsMutation.mutateAsync(values);
        } catch (err) {
            if (err.response?.data?.errors?.client?.clients) {
                return err.response.data.errors.client;
            }
        }
    };

    const firstPurchaserIndex = isExchangeOfContracts(doc)
        ? doc.client?.clients.findIndex(client => client.clientType === ClientType.PURCHASER)
        : undefined;

    const firstVendorIndex = isExchangeOfContracts(doc)
        ? doc.client?.clients.findIndex(client => client.clientType === ClientType.VENDOR)
        : undefined;

    return (
        // TODO: Add type to client
        <ModalDialog title="Edit Recipients" isOpen={isOpen} closeModal={closeModal} shouldCloseOnOverlayClick={false}>
            <Form onSubmit={submitValues} initialValues={{ clients: doc?.client?.clients || [] }}>
                {({ handleSubmit }) => {
                    return (
                        <form id="update-tenant-detail-form" onSubmit={handleSubmit} noValidate className={styles.form}>
                            <div className={styles.recipients}>
                                {doc?.client?.clients.map((client, index) => (
                                    <>
                                        {index === firstPurchaserIndex && <h3>Purchasers</h3>}
                                        {index === firstVendorIndex && <h3>Vendors</h3>}
                                        <Card
                                            key={index}
                                            style={CardStyles.SQUARE}
                                            shadow={CardShadow.SMALL}
                                            className={styles.fields}
                                        >
                                            {!!client.signature && (
                                                <p className={styles.error}>
                                                    The recipient has already signed the document.
                                                </p>
                                            )}
                                            <TextInputField
                                                name={`clients[${index}].name`}
                                                label={getClientNameLabel(doc, client)}
                                                isRequired
                                                placeholder="Enter full name"
                                                className={styles.nameField}
                                                validateOnTouch={false}
                                                disabled={true}
                                            />
                                            <TextInputField
                                                name={`clients[${index}].email`}
                                                label="Email"
                                                isRequired
                                                placeholder="Enter email address"
                                                className={styles.emailField}
                                                validateOnTouch={false}
                                                disabled={!!client.signature}
                                            />
                                            {(isExchangeOfContracts(doc) ||
                                                doc.deliveryType === DOCUMENT_DELIVERY_TYPE_SMS) && (
                                                <FormPhone
                                                    name={`clients[${index}].phone`}
                                                    label="Phone"
                                                    required
                                                    className={styles.phoneField}
                                                    validateOnTouch={false}
                                                    dataTest={`clients[${index}].phone`}
                                                    disabled={!!client.signature}
                                                />
                                            )}
                                            {isConnectIdEnabled && isUploadADocOrTemplate(doc) && (
                                                <div className={styles.requireIdContainer}>
                                                    <CheckboxCheck
                                                        containerClassName={styles.requireIdCheckbox}
                                                        name={`clients[${index}].requireProofOfIdentity`}
                                                        label="Require Proof of Identity via ConnectID"
                                                        disabled={!!client.signature}
                                                    />
                                                    {!client.signature && <ConnectIdTooltip place="top" />}
                                                </div>
                                            )}
                                        </Card>
                                    </>
                                ))}
                            </div>
                            <div className={styles.footer}>
                                <Button
                                    type="submit"
                                    disabled={editRecipientsMutation.isLoading}
                                    loading={editRecipientsMutation.isLoading}
                                    primary
                                    className={styles.submitButton}
                                >
                                    Save changes
                                </Button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </ModalDialog>
    );
};

export default EditRecipientsModal;
