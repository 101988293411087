import { EXCHANGE_OF_CONTRACTS } from '@app/config';
import { BaseDocument } from './BaseDocument';
import { AddressDetails } from './Common';
import { CustomPlaceholder, Client as UadClient, UploadedDocument } from './UploadADoc';
import { DateString } from './utilityTypes';
import { CompletionProgress } from './CompletionProgress';

export enum ClientType {
    VENDOR = 'vendor',
    PURCHASER = 'purchaser'
}
export type EocClient = UadClient & {
    clientType: ClientType;
};

export type ExchangeOfContracts = BaseDocument & {
    address: string;
    addressDetails: AddressDetails;
    id: string;
    status: string;
    client?: {
        clients: EocClient[];
        currentSigningClientOrder?: number;
    };
    uploadedDocuments: UploadedDocument[];
    customPlaceholders: CustomPlaceholder[];
    deadline?: number;
    docType: string;
    sentForSigning?: DateString;
    completion?: {
        progress?: CompletionProgress;
        status: string;
    };
    hasAgentSignatureAnnotations?: boolean;
    descriptionText: string;
    pdfGeneratedState?: string;
};

export const isExchangeOfContracts = (doc: unknown): doc is ExchangeOfContracts => {
    return !!doc && (doc as ExchangeOfContracts).docType === EXCHANGE_OF_CONTRACTS;
};
