import React, { memo, useState, forwardRef, useImperativeHandle, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, FormSpy } from 'react-final-form';
import axios from 'axios';
import { cloneDeep, has } from 'lodash';

import { formSubmitFail, setDirtyStep, updateSubmitTypeSuccess } from '../../../../actions/lease';
import { getLocation, getStep, getDirtyStep, getLeaseType } from '../../../../selectors/lease';
import { getPmFees } from '../../../../selectors/lease/pmLease';
import { PERIOD_MONTHLY, PERIOD_YEARLY } from '../../../../config';
import { FormTextCurrency, FormTextRegular, FormNumber } from '../../../../components/form/FormText';
import { FeeTypeGroup } from '../../../../components/lease/mainScreen/common/pm/FeeTypeGroup';
import { calculateFromMonthlyRent, getRentForPeriod } from '../../../../utils/agreementUtils';
import * as Lease from '../../../../reducers/lease';
import '../../../../sass/pmFeesVic.scss';
import plusIcon from '../../../../../assets/images/icons/plus.svg?url';
import { getAgency } from '../../../../selectors/user';

const PM_FEES_FORM = 'pmFeesForm';
function getDefaultState(agency) {
    if (agency && agency.pmFees) {
        return Object.assign(initState, agency.pmFees);
    } else {
        return initState;
    }
}

const initState = {
    leaseRenewalFee: '$',
    managementFee: '',
    leasePreparationFee: '$',
    estimatedWeeklyRent: '',
    estimatedMonthlyRent: '',
    estimatedAnnualRent: '',
    leasingFee: '',
    estimatedLeaseRenewalFee: '',
    additionalFeesList: []
};

function FeesAndChargesLayout(props, ref) {
    const dispatch = useDispatch();
    const location = useSelector(getLocation);
    const leaseType = useSelector(getLeaseType);
    const reduxPmFees = useSelector(getPmFees);
    const step = useSelector(getStep);
    const dirtyStep = useSelector(getDirtyStep);
    const agency = useSelector(getAgency);
    const formRef = useRef(null);
    const [isTyping, setIsTyping] = useState(true);
    const onFirstMount = useRef(true);

    const [pmFees, setPmFees] = useState(reduxPmFees || getDefaultState(agency));
    const [estimatedWeeklyRent, setEstimatedWeeklyRent] = useState(pmFees.estimatedWeeklyRent || 0);
    const [estimatedMonthlyRent, setEstimatedMonthlyRent] = useState(pmFees.estimatedMonthlyRent || 0);
    const [estimatedAnnualRent, setEstimatedAnnualRent] = useState(pmFees.estimatedAnnualRent || 0);
    const [managementFee, setManagementFee] = useState(pmFees.managementFee || 0);
    const [estimatedManagementFee, setEstimatedManagementFee] = useState(pmFees.estimatedManagementFee || '0.00');

    useEffect(() => {
        if (reduxPmFees) {
            const data = cloneDeep(reduxPmFees);
            const rent = parseFloat(data.rent) || 0;

            const estimatedAnnualRent = getRentForPeriod(rent, PERIOD_YEARLY);
            const estimatedMonthlyRent = getRentForPeriod(rent, PERIOD_MONTHLY);

            setPmFees({
                ...data,
                estimatedWeeklyRent: rent,
                estimatedMonthlyRent,
                estimatedAnnualRent
            });
        }
    }, [reduxPmFees]);

    // Called from parent LeaseAgreementForm nextStep
    useImperativeHandle(ref, () => ({
        submitStep() {
            document.getElementById(PM_FEES_FORM).dispatchEvent(
                new Event('submit', {
                    cancelable: true,
                    bubbles: true
                })
            );
        }
    }));

    useEffect(() => {}, [estimatedWeeklyRent, managementFee]);

    const handleFormDirtyChange = (values, form) => {
        if (form.getState().dirty) {
            if (dirtyStep !== step) {
                dispatch(setDirtyStep(step));
            }
        }
    };

    function submitForm(values) {
        const currentRef = ref.current;
        values.bypassFormValidation = currentRef.bypassFormValidation;
        values.estimatedMonthlyRent = estimatedMonthlyRent;
        values.estimatedAnnualRent = estimatedAnnualRent;
        values.estimatedManagementFee = estimatedManagementFee;
        return axios
            .post(`/api/agency/lease/${props.leaseId}/pm-fees`, values)
            .then(result => {
                dispatch(updateSubmitTypeSuccess(result, Lease.LEASE_SECTION_UPDATE_SUCCESS)).then(() => {
                    if (currentRef) {
                        currentRef.callbackAfterSubmit();
                    }
                });
            })
            .catch(error => {
                if (has(error, 'response.data.errors.pmFees')) {
                    return error.response.data.errors.pmFees;
                } else if (has(error, 'response.data.errors')) {
                    dispatch(formSubmitFail(error.response.data.errors, leaseType, location));
                }
            });
    }

    function calculateEstimatedManagementFee(_managementFee, _estimatedWeeklyRent) {
        const managementFeeParsed = parseFloat(_managementFee) || 0;
        const estimatedWeeklyRentParsed = parseFloat(_estimatedWeeklyRent) || 0;
        if (estimatedWeeklyRentParsed && managementFeeParsed) {
            const valueToBeSet = (estimatedWeeklyRentParsed * managementFeeParsed) / 100;
            setEstimatedManagementFee(valueToBeSet.toFixed(2));
        } else {
            setEstimatedManagementFee('0.00');
        }
    }

    function calculateEstimatedRent(value) {
        setEstimatedWeeklyRent(value);
        calculateEstimatedManagementFee(managementFee, value);
    }

    const updateFields = (updatedRent, periodType) => {
        if (periodType === PERIOD_YEARLY || periodType === PERIOD_MONTHLY) {
            const form = formRef.current;

            if (periodType === PERIOD_MONTHLY) {
                const updatedWeeklyRentAmount = calculateFromMonthlyRent(updatedRent);
                form.change('estimatedWeeklyRent', updatedWeeklyRentAmount);
                setEstimatedAnnualRent(getRentForPeriod(+updatedWeeklyRentAmount.toFixed(2), PERIOD_YEARLY));
            }
            if (periodType === PERIOD_YEARLY) {
                const updatedWeeklyRentAmount = calculateFromMonthlyRent(updatedRent / 12);
                form.change('estimatedWeeklyRent', updatedWeeklyRentAmount);
                setEstimatedMonthlyRent(getRentForPeriod(+updatedWeeklyRentAmount.toFixed(2), PERIOD_MONTHLY));
            }
        }
    };

    const roundUpEstimatedMonthlyRent = () => {
        setIsTyping(false);
        setEstimatedMonthlyRent(prevValue => {
            const updatedMonthlyRent = (Math.floor(prevValue) + 1).toFixed(2);
            updateFields(updatedMonthlyRent, PERIOD_MONTHLY);
            return updatedMonthlyRent;
        });
    };

    const roundDownEstimatedMonthlyRent = () => {
        setIsTyping(false);

        if (estimatedMonthlyRent >= 0) {
            setEstimatedMonthlyRent(prevValue => {
                const updatedMonthlyRent = (Math.ceil(prevValue) - 1).toFixed(2);
                updateFields(updatedMonthlyRent, PERIOD_MONTHLY);
                return updatedMonthlyRent;
            });
        }
    };

    const roundUpEstimatedAnnualRent = () => {
        setIsTyping(false);
        setEstimatedAnnualRent(prevValue => {
            const updatedAnnualRent = (Math.floor(prevValue) + 1).toFixed(2);
            updateFields(updatedAnnualRent, PERIOD_YEARLY);
            return updatedAnnualRent;
        });
    };

    const roundDownEstimatedAnnualRent = () => {
        setIsTyping(false);
        if (estimatedAnnualRent >= 0) {
            setEstimatedAnnualRent(prevValue => {
                const updatedMonthlyRent = (Math.ceil(prevValue) - 1).toFixed(2);
                updateFields(updatedMonthlyRent, PERIOD_YEARLY);
                return updatedMonthlyRent;
            });
        }
    };

    function addFee(values) {
        const { additionalFeesList } = values;
        let updatedFees = {
            ...values,
            additionalFeesList: [
                ...additionalFeesList,
                {
                    title: '',
                    amount: '$'
                }
            ]
        };
        setPmFees(updatedFees);
    }

    function removeFee(values, index) {
        const { additionalFeesList } = values;
        const updatedFees = {
            ...values,
            additionalFeesList: [...additionalFeesList.slice(0, index), ...additionalFeesList.slice(index + 1)]
        };
        setPmFees(updatedFees);
    }

    useEffect(() => {
        if (onFirstMount.current && pmFees.estimatedWeeklyRent === 0) {
            const form = formRef.current;
            form.change('estimatedWeeklyRent', '');
            onFirstMount.current = false;
            return;
        }
    }, [pmFees]);

    return (
        <div className="pm-fees-vic rent">
            <Form onSubmit={submitForm} initialValues={pmFees}>
                {({ handleSubmit, values, form }) => {
                    formRef.current = form;
                    return (
                        <form onSubmit={handleSubmit} noValidate id={PM_FEES_FORM}>
                            <FormSpy
                                subscription={{ values: true }}
                                onChange={state => handleFormDirtyChange(state.values, form)}
                            />
                            <div className="fees">
                                <h3 className="title-1">Agent Fee Schedule</h3>
                                <p className="description">
                                    The following fees and charges are based on the following estimates
                                </p>
                                <FormTextCurrency
                                    name="estimatedWeeklyRent"
                                    label="Estimated Weekly Rent"
                                    onChange={value => {
                                        if (isTyping) {
                                            const annualValue = getRentForPeriod(value, PERIOD_YEARLY);
                                            const monthlyValue = getRentForPeriod(value, PERIOD_MONTHLY);
                                            setEstimatedMonthlyRent(monthlyValue);
                                            setEstimatedAnnualRent(annualValue);
                                        } else {
                                            setIsTyping(true);
                                        }

                                        calculateEstimatedRent(value);
                                    }}
                                    precision={2}
                                    required
                                />
                                <div className="total-rent">
                                    <span className="rent-label">Estimated Monthly Rent</span>
                                    <span
                                        className="button-minus"
                                        data-button="decrement-monthly"
                                        onClick={roundDownEstimatedMonthlyRent}
                                    />
                                    <span className="amount" data-monthly-amount={estimatedMonthlyRent}>
                                        ${estimatedMonthlyRent || '0'}
                                    </span>
                                    <span
                                        className="button-plus"
                                        data-button="increment-monthly"
                                        onClick={roundUpEstimatedMonthlyRent}
                                    />
                                </div>
                                <div className="total-rent">
                                    <span className="rent-label">Estimated Annual Rent</span>
                                    <span
                                        className="button-minus"
                                        data-button="decrement-yearly"
                                        onClick={roundDownEstimatedAnnualRent}
                                    />
                                    <span className="amount" data-annual-amount={estimatedAnnualRent}>
                                        ${estimatedAnnualRent || '0'}
                                    </span>
                                    <span
                                        className="button-plus"
                                        data-button="increment-yearly"
                                        onClick={roundUpEstimatedAnnualRent}
                                    />
                                </div>
                            </div>
                            <br />
                            <hr />
                            <div className="other-fees">
                                <p>* All fees listed below are inclusive of GST</p>
                                <br />
                                <FormTextRegular
                                    name="leasingFee"
                                    label="Leasing fee"
                                    labelHint="(applicable to initial and subsequent new renters)"
                                />
                                <FormTextCurrency
                                    name="estimatedLeasingFee"
                                    label="Estimated Leasing Fee"
                                    precision={2}
                                />
                                <FormTextRegular
                                    name="leaseRenewalFee"
                                    label="Lease Renewal Fee"
                                    labelHint="(applicable to existing renters only)"
                                />
                                <FormTextCurrency
                                    name="estimatedLeaseRenewalFee"
                                    label="Estimated Lease Renewal Fee"
                                    precision={2}
                                />
                                <div className="fee-item">
                                    <div className="inline">
                                        <FormNumber
                                            name="managementFee"
                                            label="Management Fee"
                                            onChange={e => {
                                                const value = e.target.value;
                                                setManagementFee(value);
                                                calculateEstimatedManagementFee(value, estimatedWeeklyRent);
                                            }}
                                        />
                                        %
                                    </div>
                                    <p className="total-rent">
                                        Estimated Management Fee
                                        <span className="amount">${estimatedManagementFee}</span>
                                        per week
                                    </p>
                                </div>
                            </div>
                            <div className="additional-fees">
                                {Array.isArray(pmFees.additionalFeesList) && (
                                    <div>
                                        {pmFees.additionalFeesList.map((item, index) => (
                                            <FeeTypeGroup
                                                index={index}
                                                key={index}
                                                removeFee={index => removeFee(values, index)}
                                                location={location}
                                                fee={item}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className="button">
                                <button className="mobile add-item" type="button" onClick={() => addFee(values)}>
                                    <img src={plusIcon} className="for-sm-modal" />
                                    Add fee type
                                </button>
                            </div>
                        </form>
                    );
                }}
            </Form>
        </div>
    );
}

export default memo(forwardRef(FeesAndChargesLayout));
