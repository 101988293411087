import cx from 'classnames';
import { isEmpty } from 'lodash';
import React from 'react';
import { useForm } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { confirmAlert } from 'react-confirm-alert';

import ContactSearch from '@app/components/search/ContactSearch';
import { EXCHANGE_OF_CONTRACTS } from '@app/config';
import { ContactSearchResult } from '@app/types/ContactSearch';
import { isDocumentStatusDraft } from '@app/utils/generalUtils';
import { formatPhoneNumber } from '@app/utils/phoneUtils';
import { FormPhone } from '@app/components/form/FormPhone';
import TextInputField from '../../../buildADoc/components/TextInput';
import { RecipientSummaryContent } from '../../../FlkAPdf/RecipientSummary';
import ConfirmAlert from '../../../FlkAPdf/ConfirmAlert';
import ReorderableAccordion from '../../../FlkAPdf/ReorderableAccordion';
import { ClientType, ExchangeOfContracts } from '@app/types/ExchangeOfContracts';
import { FormClient } from '../../ExchangeOfContracts.model';

import styles from './RecipientSection.module.scss';

type Client = FormClient;

type RecipientSectionProps = {
    document?: ExchangeOfContracts;
    isReadOnly?: boolean;
    shouldDisableAddingRecipients?: boolean;
    hasSignerPlaceholders?: boolean;
    showPhoneInput: boolean;
    isReorderEnabled: boolean;
    onReorderClients: (activeIndex: number, overIndex: number) => void;
    onAddClient?: (clientIndex: number) => void;
    clientType: ClientType;
    maxRecipients: number;
    indexOffset?: number;
    handleDeleteClient: (clientIndex: number) => void;
};

export const RecipientSection: React.FC<RecipientSectionProps> = ({
    document,
    isReadOnly = false,
    shouldDisableAddingRecipients,
    hasSignerPlaceholders,
    showPhoneInput,
    isReorderEnabled,
    onReorderClients,
    onAddClient,
    clientType,
    maxRecipients,
    indexOffset = 0,
    handleDeleteClient
}) => {
    const form = useForm();
    const { errors } = form.getState();

    // This is used to collapse the last client when a new client is added from contact search
    const [collapseLastClient, setCollapseLastClient] = React.useState(false);

    const clientsFieldName = React.useMemo(() => {
        if (clientType === ClientType.PURCHASER) {
            return 'purchasers';
        } else {
            return 'vendors';
        }
    }, [clientType]);

    const clientLabel = React.useMemo(() => {
        if (clientType === ClientType.PURCHASER) {
            return 'Purchaser';
        } else {
            return 'Vendor';
        }
    }, [clientType]);

    const clientLabelPlural = React.useMemo(() => {
        if (clientType === ClientType.PURCHASER) {
            return 'Purchasers';
        } else {
            return 'Vendors';
        }
    }, [clientType]);

    const clients = useFieldArray<Client>(clientsFieldName);

    const clientsLength = clients.fields.length ?? 0;

    const addSearchRecipient = (result: ContactSearchResult) => {
        const lastClient = clientsLength > 0 ? clients.fields.value[clientsLength - 1] : null;
        const isLastClientEmpty = lastClient && !lastClient.name && !lastClient.email && !lastClient.phone;

        const phone = formatPhoneNumber(result.mobile || result.phone || '');

        if (isLastClientEmpty) {
            clients.fields.update(clientsLength - 1, {
                name: result.fullName || '',
                email: result.email || '',
                phone,
                clientType: clientType
            });
        } else {
            clients.fields.push({
                name: result.fullName || '',
                email: result.email || '',
                phone,
                clientType: clientType
            });
            onAddClient?.(clientsLength);
        }
        setCollapseLastClient(true);
    };

    const deleteClient = async (index: number, afterDelete?: () => void) => {
        handleDeleteClient(index);
        clients.fields.remove(index);
        afterDelete?.();
    };

    return (
        <div className={styles.container}>
            <ReorderableAccordion<Client>
                header={<h2 className={styles.title}>{clientLabelPlural}</h2>}
                topContent={
                    document &&
                    isDocumentStatusDraft(document.status) && (
                        <ContactSearch
                            onSelect={(result: ContactSearchResult) => addSearchRecipient(result)}
                            allowStaffSearch={true}
                            disabled={shouldDisableAddingRecipients || clientsLength >= maxRecipients}
                            contactType={EXCHANGE_OF_CONTRACTS}
                            toolTipText={
                                shouldDisableAddingRecipients
                                    ? 'You have used placeholders for the recipient to enter information. This feature is currently only available with 1 recipient only.'
                                    : 'Type to search all contacts imported'
                            }
                        />
                    )
                }
                itemData={clients.fields.value}
                onReorderItems={(activeIndex, overIndex) => {
                    if (hasSignerPlaceholders && (activeIndex === 0 || overIndex === 0)) {
                        const newPrimaryRecipientIndex = activeIndex === 0 ? 1 : activeIndex;
                        confirmAlert({
                            customUI: ({ onClose }) => {
                                return (
                                    <ConfirmAlert
                                        title="Are you sure?"
                                        message={
                                            <>
                                                <p>
                                                    <strong>
                                                        Custom Info Placeholders are currently assigned to{' '}
                                                        {clients?.fields?.value[0]?.name || 'Recipient 1'}.
                                                    </strong>
                                                </p>
                                                <p>
                                                    If the signing order is modified, the Placeholders will be assigned
                                                    to{' '}
                                                    <strong>
                                                        {clients?.fields?.value[newPrimaryRecipientIndex]?.name ||
                                                            'the new Recipient 1'}
                                                    </strong>
                                                    .
                                                </p>
                                            </>
                                        }
                                        confirmText="Yes, Continue with reordering"
                                        cancelText="No, Keep existing"
                                        onConfirm={() => {
                                            onReorderClients(activeIndex, overIndex);
                                            clients.fields.move(activeIndex, overIndex);
                                            onClose();
                                        }}
                                        onCancel={onClose}
                                        onClose={onClose}
                                    />
                                );
                            }
                        });
                    } else {
                        onReorderClients(activeIndex, overIndex);
                        clients.fields.move(activeIndex, overIndex);
                    }
                }}
                onAddItem={callback => {
                    clients.fields.push({ name: '', email: '', phone: '', clientType });
                    callback?.();
                    onAddClient?.(clientsLength);
                }}
                canAddItem={!shouldDisableAddingRecipients && clientsLength < maxRecipients}
                disabledAddItemTooltip={
                    shouldDisableAddingRecipients
                        ? 'You have used placeholders for the recipient to enter information. This feature is currently only available with 1 recipient.'
                        : undefined
                }
                onDeleteItem={async (index, callback) => {
                    if (hasSignerPlaceholders && index === 0) {
                        confirmAlert({
                            customUI: ({ onClose }) => {
                                return (
                                    <ConfirmAlert
                                        title="Are you sure?"
                                        message={
                                            <>
                                                <p>
                                                    <b>
                                                        Custom Info Placeholders are currently assigned to{' '}
                                                        {clients?.fields?.value[0]?.name ||
                                                            `${clientLabel.toLowerCase()} 1`}
                                                        .
                                                    </b>
                                                </p>
                                                <p>
                                                    By removing this {clientLabel} from your document,{' '}
                                                    {clients?.fields?.value[1]?.name ||
                                                        `the second ${clientLabel.toLowerCase()}`}{' '}
                                                    will as a result inherit the assigned Custom Info Placeholders and
                                                    will be required to complete their responses as they will be the new
                                                    {clientLabel} 1.
                                                </p>
                                            </>
                                        }
                                        confirmText={`Yes, remove ${clientLabel}`}
                                        cancelText="No, do not remove"
                                        onConfirm={async () => {
                                            await deleteClient(index, callback);
                                            onClose();
                                        }}
                                        onCancel={onClose}
                                        onClose={onClose}
                                    />
                                );
                            }
                        });
                    } else {
                        await deleteClient(index, callback);
                    }
                }}
                itemDisplayNameLowercase={clientLabel.toLowerCase()}
                isReadOnly={isReadOnly}
                isReorderEnabled={isReorderEnabled}
                collapseLastClient={collapseLastClient}
                setCollapseLastClient={setCollapseLastClient}
                renderCollapsedView={(item, index) => {
                    return (
                        <RecipientSummaryContent
                            client={item}
                            index={index + indexOffset}
                            hasError={!isEmpty(errors?.clients?.[index])}
                        />
                    );
                }}
                renderExpandedHeader={(item, index) => {
                    return (
                        <h4 className={styles.recipientHeader}>
                            {clientLabel} {index + 1} Details
                        </h4>
                    );
                }}
                renderExpandedView={(_, index) => {
                    return (
                        <div
                            className={cx(styles.fields, {
                                [styles.withPhoneInput]: showPhoneInput
                            })}
                            data-testid={`${clientsFieldName}-${index}`}
                        >
                            <TextInputField
                                name={`${clientsFieldName}[${index}].name`}
                                label="Legal Full Name or Corporation Name + ABN/ACN"
                                isRequired
                                placeholder="Enter full name"
                                className={styles.nameField}
                                validateOnTouch={false}
                            />
                            <TextInputField
                                name={`${clientsFieldName}[${index}].email`}
                                label="Email Address"
                                isRequired
                                placeholder="Enter email address"
                                className={styles.emailField}
                                validateOnTouch={false}
                            />
                            {showPhoneInput && (
                                <FormPhone
                                    name={`${clientsFieldName}[${index}].phone`}
                                    label="Phone"
                                    required
                                    className={styles.phoneField}
                                    validateOnTouch={false}
                                    dataTest={`${clientsFieldName}[${index}].phone`}
                                />
                            )}
                        </div>
                    );
                }}
            />
        </div>
    );
};
