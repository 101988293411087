import { WebViewerInstance } from '@pdftron/webviewer';
import {
    Content as AccordionContent,
    Item as AccordionItem,
    Root as AccordionRoot,
    Trigger as AccordionTrigger
} from '@radix-ui/react-accordion';
import React from 'react';
import cx from 'classnames';

import Icon, { Icons, IconSize } from '../../../common/components/Icon';
import Card, { CardShadow, CardStyles } from '../../../common/components/cards/Card';
import { Tag, TagColor, TagSize } from '../../../common/components/tag/Tag';
import { Point } from '../types';
import {
    SIGNATURE_IMAGES,
    WITNESS_SIGNATURE_IMAGES,
    createFreeTextAnnotation,
    createStampAnnotation,
    getClientDateAnnotationText,
    getWitnessNameAnnotationText
} from '../utils';
import AnnotationButton, { AnnotationButtonTypes } from './AnnotationButton';
import styles from './Client.module.scss';
import { getTagColour } from '@app/containers/dashboard/documents/FlkAPdf/utils';

type ClientProps = {
    details: {
        name: string;
        contact?: string;
        id?: string;
    };
    clientIndex: number;
    webViewerInstance?: WebViewerInstance;
    dropPointRef: React.MutableRefObject<Point | undefined>;
    canAddWitness?: boolean;
    className?: string;
};

const Client: React.FC<ClientProps> = ({
    details,
    clientIndex,
    webViewerInstance,
    dropPointRef,
    canAddWitness,
    className
}) => {
    const getAnnotationButtonType = () => {
        switch (clientIndex) {
            case 0:
                return AnnotationButtonTypes.CLIENT_1;
            case 1:
                return AnnotationButtonTypes.CLIENT_2;
            case 2:
                return AnnotationButtonTypes.CLIENT_3;
            case 3:
                return AnnotationButtonTypes.CLIENT_4;
            case 4:
                return AnnotationButtonTypes.CLIENT_5;
            case 5:
                return AnnotationButtonTypes.CLIENT_6;
            case 6:
                return AnnotationButtonTypes.CLIENT_7;
            case 7:
                return AnnotationButtonTypes.CLIENT_8;
            default:
                return AnnotationButtonTypes.CLIENT_1;
        }
    };

    return (
        <Card
            className={cx(styles.client, className)}
            style={CardStyles.SQUARE}
            shadow={CardShadow.NONE}
            animate={false}
        >
            <AccordionRoot type="single" collapsible defaultValue="signer-1">
                <AccordionItem value={`signer-${clientIndex + 1}`}>
                    <AccordionTrigger className={styles.details}>
                        <Tag
                            className={styles.index}
                            text={`Signer ${clientIndex + 1}`}
                            color={getTagColour(clientIndex)}
                            size={TagSize.X_Small}
                        />
                        <div className={styles.name}>{details.name}</div>
                        <div className={styles.contact}>{details.contact}</div>
                        <Icon icon={Icons.CHEVRON_DOWN} className={styles.accordionIcon} />
                    </AccordionTrigger>
                    <AccordionContent>
                        <div className={styles.buttons}>
                            <AnnotationButton
                                webViewerInstance={webViewerInstance}
                                dropPointRef={dropPointRef}
                                createAnnotation={(dropPoint, options) => {
                                    if (webViewerInstance) {
                                        createStampAnnotation(
                                            SIGNATURE_IMAGES[clientIndex],
                                            `client-${details.name}-${clientIndex}`,
                                            webViewerInstance,
                                            dropPoint,
                                            { ...options, clientId: details.id }
                                        );
                                    }
                                }}
                                type={getAnnotationButtonType()}
                                dragImageSource={SIGNATURE_IMAGES[clientIndex]}
                                icon={<Icon icon={Icons.SIGNATURE} size={IconSize.SMALL} />}
                                dataTest={`client-${clientIndex}-signature`}
                            >
                                Signature
                            </AnnotationButton>
                            <AnnotationButton
                                webViewerInstance={webViewerInstance}
                                dropPointRef={dropPointRef}
                                createAnnotation={(dropPoint, options) => {
                                    if (webViewerInstance) {
                                        createFreeTextAnnotation(
                                            details.name,
                                            `name-${details.name}-${clientIndex}`,
                                            true,
                                            webViewerInstance,
                                            dropPoint,
                                            { ...options, clientId: details.id }
                                        );
                                    }
                                }}
                                icon={<Icon icon={Icons.LABEL} size={IconSize.SMALL} />}
                                type={getAnnotationButtonType()}
                                dragImageText={details.name}
                                dataTest={`client-${clientIndex}-name`}
                            >
                                Name
                            </AnnotationButton>
                            <AnnotationButton
                                webViewerInstance={webViewerInstance}
                                dropPointRef={dropPointRef}
                                createAnnotation={(dropPoint, options) => {
                                    if (webViewerInstance) {
                                        createFreeTextAnnotation(
                                            getClientDateAnnotationText(clientIndex),
                                            `date-${details.name}-${clientIndex}`,
                                            true,
                                            webViewerInstance,
                                            dropPoint,
                                            { ...options, clientId: details.id }
                                        );
                                    }
                                }}
                                icon={<Icon icon={Icons.DATE} size={IconSize.SMALL} />}
                                type={getAnnotationButtonType()}
                                dragImageText={getClientDateAnnotationText(clientIndex)}
                            >
                                Signing date
                            </AnnotationButton>
                            {canAddWitness && (
                                <AnnotationButton
                                    webViewerInstance={webViewerInstance}
                                    dropPointRef={dropPointRef}
                                    createAnnotation={(dropPoint, options) => {
                                        if (webViewerInstance) {
                                            createFreeTextAnnotation(
                                                getWitnessNameAnnotationText(clientIndex),
                                                `witnessName-${details.name}-${clientIndex}`,
                                                true,
                                                webViewerInstance,
                                                dropPoint,
                                                { ...options, clientId: details.id }
                                            );
                                        }
                                    }}
                                    icon={<Icon icon={Icons.LABEL} size={IconSize.SMALL} />}
                                    type={getAnnotationButtonType()}
                                    dragImageText={getWitnessNameAnnotationText(clientIndex)}
                                >
                                    Witness Name
                                </AnnotationButton>
                            )}
                            {canAddWitness && (
                                <AnnotationButton
                                    webViewerInstance={webViewerInstance}
                                    dropPointRef={dropPointRef}
                                    createAnnotation={(dropPoint, options) => {
                                        if (webViewerInstance) {
                                            createStampAnnotation(
                                                WITNESS_SIGNATURE_IMAGES[clientIndex],
                                                `witnessSign-${details.name}-${clientIndex}`,
                                                webViewerInstance,
                                                dropPoint,
                                                { ...options, clientId: details.id }
                                            );
                                        }
                                    }}
                                    icon={<Icon icon={Icons.WITNESS_SIGNATURE} size={IconSize.SMALL} />}
                                    type={getAnnotationButtonType()}
                                    dragImageSource={WITNESS_SIGNATURE_IMAGES[clientIndex]}
                                >
                                    Witness signature
                                </AnnotationButton>
                            )}
                        </div>
                    </AccordionContent>
                </AccordionItem>
            </AccordionRoot>
        </Card>
    );
};

export default Client;
