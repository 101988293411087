import React, { memo } from 'react';
import cx from 'classnames';
import * as PropTypes from 'prop-types';
import { find, findLastIndex } from 'lodash';
import CheckCircleSharpIcon from '@flk-mui-icons/CheckCircleSharp';

import '../../../../sass/completionProgress.scss';
import { capitalize } from '../../../../utils/generalUtils';
import { CancelSharp } from '@flk-mui-icons';

const CompletionProgress = props => {
    if (!props.progress || props.progress.length < 1) {
        return false;
    }
    const getCurrentStep = () => {
        const currentStep = find(props.progress, step => !step.completed);
        if (currentStep) {
            return currentStep;
        }
        return false;
    };

    const isCurrentStepTheLast = () => {
        return findLastIndex(props.progress, step => step.completed) + 1 === props.progress.length;
    };

    const getClassStep = () => {
        let result = 'progress ';
        if (isCurrentStepTheLast()) {
            return (result += 'progress--complete');
        }
        return (result += `progress-${props.progress.length}steps--${
            findLastIndex(props.progress, step => step.completed) + 2
        }`);
    };

    return (
        <div className={cx('progress-container', props.className)}>
            <div className={getClassStep()}>
                <div className="progress__bg" />
                <div className="progress_indicator_container">
                    {props.progress.map((item, index) => {
                        const showError = !item.completed && !!item.error;
                        return (
                            <div
                                key={index}
                                className={cx(
                                    `progress__step progress__step-${props.progress.length}steps--${index + 1}`,
                                    { 'progress__step--complete': item.completed },
                                    {
                                        'progress__step--active':
                                            item.step === getCurrentStep().step && !getCurrentStep().error
                                    },
                                    { 'progress__step--error': showError }
                                )}
                            >
                                <div className="progress__indicator">
                                    {item.completed && <CheckCircleSharpIcon />}
                                    {showError && <CancelSharp />}
                                </div>
                                <div className="progress__label">{capitalize(item.step.split('_').join(' '))}</div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

CompletionProgress.propTypes = {
    progress: PropTypes.array
};

export default memo(CompletionProgress);
