import axios from 'axios';
import * as Dashboard from './../reducers/dashboard';
import { isEmpty } from 'lodash';

import {
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    LEASE_TYPE_RESIDENTIAL,
    LEASE_TYPE_SALES,
    LEASE_TYPE_COMMERCIAL_LEASE,
    LEASE_TYPE_HOLIDAY_LETTING,
    DOCUMENT_TERMINATION_NOTICE,
    DOCUMENT_RENT_INCREASE,
    DOCUMENT_RENT_REDUCTION,
    DOCUMENT_CUSTOM,
    DISCLOSURE_DOCUMENT,
    DOCUMENT_CREATE_A_FLK,
    BREACH_NOTICE,
    ALL,
    ENTRY_NOTICE,
    DOCUMENT_INTENTION_TO_SELL,
    DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE,
    EXCHANGE_OF_CONTRACTS
} from '../config';
import AppHistory from '../AppHistory';

// Lease agreement available statuses.
export const LEASE_STATUS_DRAFT = 'draft';
export const LEASE_STATUS_SENT_SIGNING = 'sent_for_signing';
export const LEASE_STATUS_AWAITING_COMPLETION = 'awaiting_completion';
export const LEASE_STATUS_AWAITING_RENEWAL = 'awaiting_renewal';
export const LEASE_STATUS_ARCHIVED = 'archived';
export const LEASE_STATUS_COMPLETE = 'complete';
export const LEASE_STATUS_CANCELLED = 'cancelled';

export const DOC_STATUS_ACTIVE = 'active';
export const DOC_STATUS_CANCELLED = 'cancelled';

export const FLK_A_KEY_STATUS_DRAFT = 'draft';
export const FLK_A_KEY_STATUS_CHECKED_OUT = 'checked_out';
export const FLK_A_KEY_STATUS_OVERDUE = 'overdue';
export const FLK_A_KEY_STATUS_RETURNED = 'returned';

export const availableDocumentTypes = [
    DOCUMENT_TERMINATION_NOTICE,
    DOCUMENT_RENT_REDUCTION,
    DOCUMENT_RENT_INCREASE,
    DISCLOSURE_DOCUMENT
];
export const availableAgreementStatuses = [
    LEASE_STATUS_DRAFT,
    LEASE_STATUS_SENT_SIGNING,
    LEASE_STATUS_AWAITING_COMPLETION,
    LEASE_STATUS_AWAITING_RENEWAL,
    LEASE_STATUS_ARCHIVED,
    LEASE_STATUS_CANCELLED
];

export const availableAgreementTypes = [
    LEASE_TYPE_RESIDENTIAL,
    // LEASE_TYPE_PRIVATE_TREATY,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    // LEASE_TYPE_AUCTION_AGREEMENTS,
    LEASE_TYPE_SALES,
    LEASE_TYPE_COMMERCIAL_LEASE,
    LEASE_TYPE_HOLIDAY_LETTING
];

export const agreementStatuses = {
    [LEASE_TYPE_RESIDENTIAL]: [
        LEASE_STATUS_DRAFT,
        LEASE_STATUS_SENT_SIGNING,
        LEASE_STATUS_AWAITING_COMPLETION,
        LEASE_STATUS_AWAITING_RENEWAL,
        LEASE_STATUS_ARCHIVED,
        LEASE_STATUS_COMPLETE,
        LEASE_STATUS_CANCELLED
    ],
    [LEASE_TYPE_SALES]: [
        LEASE_STATUS_DRAFT,
        LEASE_STATUS_SENT_SIGNING,
        LEASE_STATUS_AWAITING_COMPLETION,
        LEASE_STATUS_ARCHIVED,
        LEASE_STATUS_COMPLETE
    ],
    [LEASE_TYPE_PROPERTY_MANAGEMENT]: [
        LEASE_STATUS_DRAFT,
        LEASE_STATUS_SENT_SIGNING,
        LEASE_STATUS_AWAITING_COMPLETION,
        LEASE_STATUS_ARCHIVED,
        LEASE_STATUS_COMPLETE
    ],
    [LEASE_TYPE_COMMERCIAL_LEASE]: [
        LEASE_STATUS_DRAFT,
        LEASE_STATUS_SENT_SIGNING,
        LEASE_STATUS_AWAITING_COMPLETION,
        LEASE_STATUS_ARCHIVED,
        LEASE_STATUS_COMPLETE
    ],
    [LEASE_TYPE_HOLIDAY_LETTING]: [
        LEASE_STATUS_DRAFT,
        LEASE_STATUS_SENT_SIGNING,
        LEASE_STATUS_AWAITING_COMPLETION,
        LEASE_STATUS_ARCHIVED,
        LEASE_STATUS_COMPLETE
    ],
    [DOCUMENT_TERMINATION_NOTICE]: [LEASE_STATUS_DRAFT, DOC_STATUS_ACTIVE, DOC_STATUS_CANCELLED],
    [DOCUMENT_RENT_REDUCTION]: [
        LEASE_STATUS_DRAFT,
        LEASE_STATUS_SENT_SIGNING,
        LEASE_STATUS_AWAITING_COMPLETION,
        LEASE_STATUS_ARCHIVED,
        LEASE_STATUS_COMPLETE
    ],
    [DOCUMENT_CUSTOM]: [
        LEASE_STATUS_DRAFT,
        LEASE_STATUS_SENT_SIGNING,
        LEASE_STATUS_AWAITING_COMPLETION,
        LEASE_STATUS_COMPLETE
    ],
    [ALL]: [LEASE_STATUS_DRAFT, LEASE_STATUS_SENT_SIGNING, LEASE_STATUS_AWAITING_COMPLETION, LEASE_STATUS_COMPLETE],
    [DOCUMENT_RENT_INCREASE]: [LEASE_STATUS_DRAFT, DOC_STATUS_ACTIVE, DOC_STATUS_CANCELLED],
    [DISCLOSURE_DOCUMENT]: [
        LEASE_STATUS_DRAFT,
        LEASE_STATUS_SENT_SIGNING,
        LEASE_STATUS_AWAITING_COMPLETION,
        LEASE_STATUS_COMPLETE
    ],
    [DOCUMENT_CREATE_A_FLK]: [
        LEASE_STATUS_DRAFT,
        LEASE_STATUS_SENT_SIGNING,
        LEASE_STATUS_AWAITING_COMPLETION,
        LEASE_STATUS_COMPLETE
    ],
    [EXCHANGE_OF_CONTRACTS]: [
        LEASE_STATUS_DRAFT,
        LEASE_STATUS_SENT_SIGNING,
        LEASE_STATUS_AWAITING_COMPLETION,
        LEASE_STATUS_COMPLETE
    ],
    [BREACH_NOTICE]: [LEASE_STATUS_DRAFT, DOC_STATUS_ACTIVE, DOC_STATUS_CANCELLED],
    [ENTRY_NOTICE]: [LEASE_STATUS_DRAFT, DOC_STATUS_ACTIVE, DOC_STATUS_CANCELLED],
    [DOCUMENT_INTENTION_TO_SELL]: [LEASE_STATUS_DRAFT, DOC_STATUS_ACTIVE, DOC_STATUS_CANCELLED],
    [DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE]: [LEASE_STATUS_DRAFT, DOC_STATUS_ACTIVE, DOC_STATUS_CANCELLED]
};

export function toggleMobileView(data) {
    return {
        type: Dashboard.TOGGLE_MOBILE_VIEW,
        payload: { data }
    };
}

export function changeAgreementAddress(leaseId, data) {
    return {
        types: [Dashboard.CHANGE_ADDRESS_REQUEST, Dashboard.CHANGE_ADDRESS_SUCCESS, Dashboard.CHANGE_ADDRESS_FAIL],
        promise: axios.put(`/api/agency/lease/${leaseId}/address`, data)
    };
}
export function changeAgreementAgent(leaseId, data) {
    return {
        types: [Dashboard.CHANGE_AGENT_REQUEST, Dashboard.CHANGE_AGENT_SUCCESS, Dashboard.CHANGE_AGENT_FAIL],
        promise: axios.put(`/api/agency/lease/${leaseId}/agent`, { agent: data })
    };
}
export function changeDocumentAgent() {
    return {
        type: Dashboard.CHANGE_DOCUMENT_AGENT_SUCCESS
    };
}
export function changeDocumentTitle(newTitle) {
    return {
        type: Dashboard.CHANGE_DOCUMENT_TITLE_SUCCESS,
        payload: { newTitle }
    };
}

export function changeTemplateName(leaseId, data) {
    return {
        types: [
            Dashboard.CHANGE_TEMPLATE_NAME_REQUEST,
            Dashboard.CHANGE_TEMPLATE_NAME_SUCCESS,
            Dashboard.CHANGE_TEMPLATE_NAME_FAIL
        ],
        promise: axios.put(`/api/template/${leaseId}/templateName`, { templateName: data })
    };
}

export function cloneAgreement(leaseId) {
    return dispatch => {
        dispatch({
            type: Dashboard.CLONE_AGREEMENTS_REQUEST
        });

        try {
            axios.post(`/api/agency/lease/${leaseId}/clone`).then(result => {
                // This is a hack to close the modal and redirect to the new lease agreement
                dispatch(closeAgreementInfoModal());
                AppHistory.push(`/dashboard/agreement/${result.data.lease.id}`);
                dispatch({
                    type: Dashboard.CLONE_AGREEMENTS_SUCCESS,
                    result
                });
            });
        } catch (error) {
            dispatch({
                type: Dashboard.CLONE_AGREEMENTS_FAILURE,
                error
            });
        }
    };
}

/**
 * Cancel signing of lease agreement request.
 * After canceling lease agreement will be moved to the DRAFT status.
 * Also will be removed all signs
 *
 * @param {string} leaseId - Lease Agreement ID
 * @returns {{types: [*,*,*], promise: AxiosPromise}}
 */
export function closeSigning(leaseId) {
    return {
        types: [Dashboard.CLOSE_SIGNING_REQUEST, Dashboard.CLOSE_SIGNING_SUCCESS, Dashboard.CLOSE_SIGNING_FAILURE],
        promise: axios.post(`/api/agency/lease/${leaseId}/cancel-signing`)
    };
}

export function deleteAgreement(leaseId) {
    return {
        types: [
            Dashboard.DELETE_AGREEMENTS_REQUEST,
            Dashboard.DELETE_AGREEMENTS_SUCCESS,
            Dashboard.DELETE_AGREEMENTS_FAILURE
        ],
        promise: axios.delete(`/api/agency/lease/${leaseId}`)
    };
}

export function toggleMenu() {
    return {
        type: Dashboard.TOGGLE_LEFT_MENU
    };
}

export function closeMenu() {
    return {
        type: Dashboard.CLOSE_LEFT_MENU
    };
}

export function toggleNotificationsPanel() {
    return {
        type: Dashboard.TOGGLE_NOTIFICATIONS_PANEL
    };
}

export function closeNotificationsPanel() {
    return {
        type: Dashboard.CLOSE_NOTIFICATIONS_PANEL
    };
}

/**
 * Open agreement details modal window.
 *
 * @param {object} agreementInfo - lease agreement model to be shown.
 *
 * @return {{type}}
 */
export function openAgreementInfoModal(agreementInfo = null) {
    return {
        type: Dashboard.OPEN_AGREEMENT_INFO_MODAL,
        payload: { agreementInfo: agreementInfo }
    };
}
/**
 * Open edit tenants modal window.
 *
 * @param {object} agreementInfo - lease agreement model to be shown.
 *
 * @return {{type}}
 */
export function openEditTenantsModal(agreementInfo = null) {
    return {
        type: Dashboard.OPEN_EDIT_TENANTS_MODAL,
        payload: { agreementInfo: agreementInfo }
    };
}

export function openEditLandlordsModal(agreementInfo = null) {
    return {
        type: Dashboard.OPEN_EDIT_LANDLORDS_MODAL,
        payload: { agreementInfo: agreementInfo }
    };
}

export function closeAgreementInfoModal() {
    return {
        type: Dashboard.CLOSE_AGREEMENT_INFO_MODAL
    };
}

export function closeEditTenantsModal() {
    return {
        type: Dashboard.CLOSE_EDIT_TENANTS_MODAL
    };
}

export function closeEditLandlordsModal() {
    return {
        type: Dashboard.CLOSE_EDIT_LANDLORDS_MODAL
    };
}

export function openEditVendorsModal(agreementInfo = null) {
    return {
        type: Dashboard.OPEN_EDIT_VENDORS_MODAL,
        payload: { agreementInfo: agreementInfo }
    };
}

export function closeEditVendorsModal() {
    return {
        type: Dashboard.CLOSE_EDIT_VENDORS_MODAL
    };
}

export function openExtendExpiryModal(agreementInfo = null) {
    return {
        type: Dashboard.OPEN_EXTEND_EXPIRY_MODAL,
        payload: { agreementInfo: agreementInfo }
    };
}

export function closeExtendExpiryModal() {
    return {
        type: Dashboard.CLOSE_EXTEND_EXPIRY_MODAL
    };
}

export function closeAgreementInfoModalWithoutAnyOtherAction() {
    return {
        type: Dashboard.CLOSE_AGREEMENT_INFO_MODAL
    };
}

/**
 * Get list of agreements for the dashboard filtered by lease agreement type status.
 *
 * @param {string} leaseType - agreement type. See LEASE_TYPE... constants of this class.
 * @param {string} leaseStatus - agreement status to be filtered. See LEASE_STATUS... constants of this class.
 * @param {number} page - number of page
 * @param recordsPerPage - count of records per page.
 * @param teamMembersViewing - an array of selected team members the user has permission to view
 * @param clearList
 * @return {{types: [*,*,*], promise: AxiosPromise}}
 */
export function getAgreementList(
    leaseType,
    page,
    recordsPerPage,
    leaseStatus,
    teamMembersViewing,
    clearList = false,
    customSort
) {
    let request = Dashboard.GET_AGREEMENT_LIST_REQUEST;
    let success = Dashboard.GET_AGREEMENT_LIST_SUCCESS;
    let failure = Dashboard.GET_AGREEMENT_LIST_FAILURE;

    let endpoint = `/api/dashboard/${leaseType}`;

    if (leaseStatus) {
        endpoint += `/${leaseStatus}`;
    }

    endpoint += `?page=${page}&recordsPerPage=${recordsPerPage}`;
    if (!isEmpty(teamMembersViewing)) {
        endpoint += `&teamMembersViewing=${teamMembersViewing.join('|')}`;
    }
    if (!isEmpty(customSort)) {
        endpoint += `&customSort=${JSON.stringify(customSort)}`;
    }
    return {
        types: [request, success, failure],
        clearList: clearList,
        payload: {
            status: leaseStatus
        },
        promise: axios.get(endpoint)
    };
}

/**
 * Get list of documents for the dashboard.
 *
 * @param {string} docType - document type.
 * @param {number} page - number of page
 * @param recordsPerPage - count of records per page.
 * @param docStatus
 * @param teamMembersViewing - an array of selected team members the user has permission to view
 * @param clearList
 * @return {{types: [*,*,*], promise: AxiosPromise}}
 */
export function loadDocumentList(docType, page, recordsPerPage, docStatus, teamMembersViewing, clearList = false) {
    let request = Dashboard.GET_DOCUMENT_LIST_REQUEST;
    let success = Dashboard.GET_DOCUMENT_LIST_SUCCESS;
    let failure = Dashboard.GET_DOCUMENT_LIST_FAILURE;

    let endpoint = `/api/dashboard/document`;

    if (docType) {
        endpoint += `/${docType}`;
    }

    if (docStatus) {
        endpoint += `/${docStatus}`;
    }

    endpoint += `?page=${page}&recordsPerPage=${recordsPerPage}`;
    if (!isEmpty(teamMembersViewing)) {
        endpoint += `&teamMembersViewing=${teamMembersViewing.join('|')}`;
    }
    return {
        types: [request, success, failure],
        clearList: clearList,
        docType: docType,
        docStatus: docStatus,
        promise: axios.get(endpoint)
    };
}

/**
 *
 * @param leaseId
 * @param overrideLandlordAcknowledgement
 * @return {{types: [*,*,*], promise: AxiosPromise}}
 */
export function startCompletionProcess(leaseId, overrideLandlordAcknowledgement) {
    return {
        types: [
            Dashboard.START_COMPLETION_PROCESS_REQUEST,
            Dashboard.START_COMPLETION_PROCESS_SUCCESS,
            Dashboard.START_COMPLETION_PROCESS_FAIL
        ],
        promise: axios.post(`/api/agency/lease/${leaseId}/start-completion`, { overrideLandlordAcknowledgement })
    };
}

/**
 *
 * @param {string} leaseId - id of lease agreement
 * @param {object} completion - data of completion form
 * @return {{types: [*,*,*], promise: AxiosPromise}}
 */
export function finishCompletionProcess(leaseId, completion) {
    return dispatch => {
        dispatch({
            type: Dashboard.FINISH_COMPLETION_PROCESS_REQUEST
        });

        return axios
            .post(`/api/agency/lease/${leaseId}/finish-completion`, completion)
            .then(result => {
                dispatch({
                    type: Dashboard.FINISH_COMPLETION_PROCESS_SUCCESS,
                    result
                });
            })
            .catch(error => {
                dispatch({
                    type: Dashboard.FINISH_COMPLETION_PROCESS_FAIL,
                    error
                });
            });
    };
}

export function loadAgencySignatureList() {
    return {
        types: [
            Dashboard.GET_AGENCY_SIGNATURES_LIST_REQUEST,
            Dashboard.GET_AGENCY_SIGNATURES_LIST_SUCCESS,
            Dashboard.GET_AGENCY_SIGNATURES_LIST_FAIL
        ],
        promise: axios.get('/api/agency/account/signatures')
    };
}

export function handleCompletionFormChange(data) {
    return {
        type: Dashboard.HANDLE_COMPLETION_FORM_CHANGE,
        data
    };
}

export function updatePageNav(data) {
    return {
        type: Dashboard.UPDATE_PAGE_NAV,
        data
    };
}

export function updateDocPageNav(data) {
    return {
        type: Dashboard.UPDATE_DOC_PAGE_NAV,
        data
    };
}

export function updateKeyPageNav(data) {
    return {
        type: Dashboard.UPDATE_KEY_PAGE_NAV,
        data
    };
}

export function openEditAddressModal(leaseId, addressToEdit) {
    return {
        type: Dashboard.OPEN_EDIT_ADDRESS_MODAL,
        addressToEdit,
        leaseId
    };
}

export function openAuditTrailModal(id, isDocument = false) {
    return {
        type: Dashboard.OPEN_AUDIT_TRAIL_MODAL,
        id,
        isDocument
    };
}

export function closeAuditTrailModal() {
    return {
        type: Dashboard.CLOSE_AUDIT_TRAIL_MODAL
    };
}

export function openNotesModal(id, isDocument = false) {
    return {
        type: Dashboard.OPEN_NOTES_MODAL,
        id,
        isDocument
    };
}

export function closeNotesModal() {
    return {
        type: Dashboard.CLOSE_NOTES_MODAL
    };
}

export function openIntentionRenewalModal(id, lease) {
    return {
        type: Dashboard.OPEN_INTENTION_RENEWAL_MODAL,
        id,
        lease
    };
}

export function closeIntentionRenewalModal() {
    return {
        type: Dashboard.CLOSE_INTENTION_RENEWAL_MODAL
    };
}

export function openConnectIntegrationModal() {
    return {
        type: Dashboard.OPEN_CONNECT_INTEGRATION_MODAL
    };
}

export function closeConnectIntegrationModal() {
    return {
        type: Dashboard.CLOSE_CONNECT_INTEGRATION_MODAL
    };
}

export function closeEditAddressModal() {
    return {
        type: Dashboard.CLOSE_EDIT_ADDRESS_MODAL
    };
}

export function updateAddress(leaseId, data) {
    return {
        types: [
            Dashboard.UPDATE_ADDRESS_REQUEST,
            Dashboard.UPDATE_ADDRESS_REQUEST_SUCCESS,
            Dashboard.UPDATE_ADDRESS_REQUEST_FAIL
        ],
        promise: axios.put(`/api/agency/lease/${leaseId}/addressDetails`, data)
    };
}

export function socketUpdateFullAgreement(data) {
    return dispatch => {
        return axios.get(`/api/agency/lease/${data.id}`).then(response => {
            // get lease
            dispatch({
                type: Dashboard.SOCKET_UPDATE_AGREEMENT,
                data: response.data.lease
            });
        });
    };
}
export function socketUpdatePartialAgreement(data) {
    return {
        type: Dashboard.SOCKET_UPDATE_AGREEMENT,
        data
    };
}

export function socketUpdatePageRefresh(data, refreshPage) {
    return {
        type: Dashboard.SOCKET_UPDATE_PAGE,
        data,
        refreshPage
    };
}

export function updateAgreementLoadingState(leaseId, isLoading = true) {
    return {
        type: Dashboard.UPDATE_AGREEMENT_TO_LOADING,
        leaseId,
        isLoading
    };
}

export function toggleAgreementsMenu(data) {
    return {
        type: Dashboard.TOGGLE_AGREEMENTS_MENU,
        data
    };
}

export function toggleDocumentsMenu(data) {
    return {
        type: Dashboard.TOGGLE_DOCUMENTS_MENU,
        data
    };
}

export function resetRefreshPage() {
    return {
        type: Dashboard.RESET_REFRESH_PAGE
    };
}

export function updateAgreementToRemoveLater(leaseId) {
    return dispatch => {
        setTimeout(() => {
            dispatch({
                type: Dashboard.UPDATE_AGREEMENT_TO_REMOVE_LATER,
                leaseId
            });
        }, 5000);
    };
}

export function updateSortOptions(status, data) {
    return {
        type: Dashboard.UPDATE_SORT_OPTIONS,
        status,
        data
    };
}

export function updateLeaseInList(result) {
    return {
        type: Dashboard.UPDATE_LEASE_IN_LIST,
        result
    };
}

export function updateLeaseInTerminations(leaseTerminations) {
    return {
        type: Dashboard.UPDATE_LEASE_TERMINATIONS,
        payload: { leaseTerminations }
    };
}

export function refreshPageOnUpdate() {
    return {
        type: Dashboard.REFRESH_PAGE_ON_AGREEMENTS_UPDATE
    };
}

export function changeIsPendingStatus(triggerState) {
    return {
        type: Dashboard.CHANGE_IS_PENDING_STATUS,
        payload: { triggerState }
    };
}

export function updateManualSignorLinkGenerateSuccess(isManuallyShareLinks) {
    return {
        type: Dashboard.LEASE_MANUAL_LINK_GENERATE_SUCCESS,
        payload: {
            isManuallyShareLinks
        }
    };
}

/**
 * Get list of flk a keys for the dashboard.
 *
 * @param keyStatus
 * @param {number} page - number of page
 * @param recordsPerPage - count of records per page.
 * @param teamMembersViewing - an array of selected team members the user has permission to view
 * @param clearList
 * @param addressFilter
 * @return {{types: [*,*,*], promise: AxiosPromise}}
 */
export function loadFlkAKeyList(
    keyStatus,
    page,
    recordsPerPage,
    teamMembersViewing,
    clearList = false,
    addressFilter = ''
) {
    let request = Dashboard.GET_FLK_A_KEY_LIST_REQUEST;
    let success = Dashboard.GET_FLK_A_KEY_LIST_SUCCESS;
    let failure = Dashboard.GET_FLK_A_KEY_LIST_FAILURE;

    let endpoint = `/api/dashboard/flk-a-key`;

    if (keyStatus) {
        endpoint += `/${keyStatus}`;
    }

    endpoint += `?page=${page}&recordsPerPage=${recordsPerPage}&addressFilter=${addressFilter || ''}`;
    if (!isEmpty(teamMembersViewing)) {
        endpoint += `&teamMembersViewing=${teamMembersViewing.join('|')}`;
    }
    return {
        types: [request, success, failure],
        clearList: clearList,
        keyStatus: keyStatus,
        promise: axios.get(endpoint)
    };
}

export function uploadDocumentIntegration(leaseId) {
    return {
        type: Dashboard.UPLOAD_INTEGRATION_DOCUMENT_FOR_COMPLETED_AGREEMENT,
        payload: { leaseId }
    };
}

export function updateDashboardIntegrationForTerminationNotice(docId) {
    return {
        type: Dashboard.UPLOAD_INTEGRATION_DOCUMENT_FOR_TERMINATION_NOTICE,
        payload: { docId }
    };
}

export function updateDashboardIntegrationForIntentionToSellNotice(docId) {
    return {
        type: Dashboard.UPLOAD_INTEGRATION_DOCUMENT_FOR_INTENTION_TO_SELL_NOTICE,
        payload: { docId }
    };
}

export function updateDashboardIntegrationForEntryNotice(docId) {
    return {
        type: Dashboard.UPLOAD_INTEGRATION_DOCUMENT_FOR_ENTRY_NOTICE,
        payload: { docId }
    };
}

export function updateDashboardIntegrationForRentIncrease(docId) {
    return {
        type: Dashboard.UPLOAD_INTEGRATION_DOCUMENT_FOR_RENT_INCREASE,
        payload: { docId }
    };
}

export function updateDashboardIntegrationForBreachNotice(docId) {
    return {
        type: Dashboard.UPLOAD_INTEGRATION_DOCUMENT_FOR_BREACH_NOTICE,
        payload: { docId }
    };
}
