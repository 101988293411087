import axios from 'axios';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { DOCUMENT_CUSTOM } from '../../../../config';
import { SIGN_CONFIRMATION_TYPE } from '../../../../constants/constants';
import { formatDocumentsForApi } from '../../../../utils/formUtils';
import DocumentDrop from '../../../shared/DocumentDrop';
import styles from './UploadADoc.module.scss';
import UploadADocUploadedDoc from './UploadADocUploadedDoc';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToVerticalAxis, restrictToParentElement } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import Button from '../../../../common/components/Button';
import Icon, { Icons } from '../../../../common/components/Icon';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { UAD_DOCUMENT_REORDERING } from '../../../../constants/featureFlags';

export function UploadADocUpload({
    values,
    uploadEndPoint,
    clients,
    addDocuments,
    reorderDocumentsList,
    deleteDocument,
    addAnnotationsForFile,
    handleClearSignaturePlaceholders,
    handleClearSignaturePlaceholdersOnAllDocs,
    uploadedDocuments = [],
    backendError,
    viewDocument,
    disabled,
    disabledTooltipText,
    hideUploader,
    isBusy,
    hidePdfViewerButton,
    singleFile,
    uploaderLabel,
    isTemplate
}) {
    const isUadReorderingEnabled = useFeatureFlag(UAD_DOCUMENT_REORDERING, false);
    const [uploading, setUploading] = useState(false);
    const [draggingDocumentId, setDraggingDocumentId] = useState(null);
    const [documentUploadProgress, setDocumentUploadProgress] = useState(0);

    const removeFileToUpload = documentToRemoveId => {
        const updatedDocumentList = uploadedDocuments.filter(document => document.id !== documentToRemoveId);
        deleteDocument(updatedDocumentList, documentToRemoveId);
    };

    const addAnnotations = file => {
        let tempFiles = [...uploadedDocuments];
        addAnnotationsForFile(file, tempFiles);
    };

    // We only want to post newly added documents to the API
    const handleUpdateDocuments = async (_allDocuments, newDocuments) => {
        setUploading(true);

        const documentsForUpload = formatDocumentsForApi([...newDocuments]);

        const postOptions = {
            onUploadProgress: progressEvent => {
                const { loaded, total } = progressEvent;
                setDocumentUploadProgress(Math.floor((loaded * 100) / total));
            }
        };

        try {
            const response = await axios.post(uploadEndPoint, documentsForUpload, postOptions);
            setUploading(false);
            addDocuments(response.data.documents);
        } catch {
            setUploading(false);
            confirmAlert({
                title: '',
                message: `Failed to save documents, please try again, if this does not resolve the issue please contact FLK`,
                buttons: [
                    {
                        label: 'OK'
                    }
                ]
            });
        }
    };

    const handleDragStart = dragEvent => {
        setDraggingDocumentId(dragEvent.active.id);
    };

    const handleDragEnd = dragEvent => {
        const { active, over } = dragEvent;
        if (active.id !== over.id) {
            reorderDocumentsList(active.id, over.id);
        }
        setDraggingDocumentId(null);
    };

    return (
        <React.Fragment>
            {!hideUploader && (
                <DocumentDrop
                    // happens on the server, so is not needed here
                    flattenPdfs={false}
                    allowMultiple={
                        !singleFile && values.confirmationType && values?.confirmationType === SIGN_CONFIRMATION_TYPE
                    }
                    documents={uploadedDocuments}
                    updateDocuments={handleUpdateDocuments}
                    maxFileSize={100}
                    maxTotalSize={100}
                    rejectEncryptedPdfs
                    documentLeaseType={DOCUMENT_CUSTOM}
                    disabled={disabled || (singleFile && uploadedDocuments.length > 0)}
                    disabledTooltipText={disabledTooltipText}
                    label={uploaderLabel}
                />
            )}
            <div className="has-error"> {backendError}</div>
            <div className="files-to-upload-container agreement-section-documents">
                {uploading && (
                    <div className="dropzone-spinner">
                        <span className="progress-value for-lg-modal">{documentUploadProgress}%</span>
                        <label className="progress-line for-lg-modal" style={{ width: `${documentUploadProgress}%` }} />
                    </div>
                )}
                {!isEmpty(uploadedDocuments) && values.confirmationType === SIGN_CONFIRMATION_TYPE && (
                    <div className={styles.documentsHeader}>
                        <h2 className={styles.documentsTitle}>Documents</h2>
                    </div>
                )}
                <DndContext
                    collisionDetection={closestCenter}
                    modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                >
                    <SortableContext items={uploadedDocuments} strategy={verticalListSortingStrategy}>
                        <div className={styles.fileToUploadList}>
                            {!isEmpty(uploadedDocuments) &&
                                uploadedDocuments.map((uploadedDocument, index) => {
                                    return (
                                        <UploadADocUploadedDoc
                                            isDragDisabled={isBusy}
                                            confirmationType={values.confirmationType}
                                            key={uploadedDocument.id}
                                            id={uploadedDocument.id}
                                            clients={clients}
                                            uploadedDocument={uploadedDocument}
                                            documentUploadProgress={documentUploadProgress}
                                            uploading={uploading}
                                            uploadedDocumentIndex={index}
                                            viewDocument={viewDocument}
                                            disabled={disabled}
                                            addAnnotations={addAnnotationsForFile ? addAnnotations : undefined}
                                            removeFileToUpload={removeFileToUpload}
                                            handleClearSignaturePlaceholders={handleClearSignaturePlaceholders}
                                            handleClearSignaturePlaceholdersOnAllDocs={
                                                handleClearSignaturePlaceholdersOnAllDocs
                                            }
                                            uploadedDocumentsCount={uploadedDocuments?.length}
                                            isDragging={uploadedDocument.id === draggingDocumentId}
                                            isDraggable={isUadReorderingEnabled && uploadedDocuments?.length > 1}
                                            loading={isBusy}
                                            hidePdfViewerButton={hidePdfViewerButton}
                                            isTemplate={isTemplate}
                                        />
                                    );
                                })}
                        </div>
                    </SortableContext>
                </DndContext>
                {values.confirmationType === SIGN_CONFIRMATION_TYPE && uploadedDocuments.length > 0 && (
                    <div className={styles.positionSignatureButtonContainer}>
                        <div className={styles.overrideDefaultButtonStyles}>
                            <Button
                                className={(styles.pdfViewerButton, styles.positionSignatureButton)}
                                primary
                                endIcon={<Icon icon={Icons.CHEVRON_RIGHT} className={styles.icon} />}
                                onClick={() => addAnnotations(uploadedDocuments[0])}
                                type="button"
                                loading={isBusy}
                            >
                                Position signatures
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default UploadADocUpload;
