import React, { useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import { CheckboxCheck } from '../../form/FormCheckboxCheck.js';
import Button from '../../../common/components/Button.js';
import styles from './Integration.module.scss';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { updateAgencyInfo, syncAccountIntegration } from '../../../actions/account.js';
import useQueryParams from '../../../hooks/useQueryParams';
import { getAgencyVaultReIntegration } from '@app/selectors/agency.js';
import useToast from '@app/hooks/useToast';
import { ToastTypes } from '@app/common/components/Toast';
import appHistory from '@app/AppHistory.js';
import { VAULT_RE_INTEGRATION } from '@app/constants/featureFlags';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import SaveButtonIcon from '../../../containers/settings/SaveButtonIcon';
import { debounce } from 'lodash';

type FormValues = {
    shouldPushFinishedDocuments?: boolean;
    code?: string;
};

const VaultReIntegration = () => {
    const dispatch = useDispatch();
    const formRef = React.useRef<HTMLFormElement>(null);
    const query = useQueryParams();
    const { addNewToast } = useToast();
    const isVaultReEnabled = useFeatureFlag(VAULT_RE_INTEGRATION);

    const integrationType = query.get('integration');
    const integrationCode = query.get('code');
    const integrationStatusReason = query.get('reason');

    const vaultReIntegration: {
        enabled: boolean;
        shouldPushFinishedDocuments: boolean;
    } | null = useSelector(getAgencyVaultReIntegration);

    const [isConnectionInstructionOpen, toggleConnectionInstruction] = useState(false);
    const [isGettingLogInLink, setGettingLogInLink] = useState(false);

    useEffect(() => {
        enableVaultRe();
        return () => {
            enableVaultRe.cancel(); // Cancel debounce on cleanup
        };
    }, [integrationType, integrationCode, integrationStatusReason]);

    // use debounce to avoid multiple calls to updateVaultReIntegration.mutate
    // This can happen when parent component mount and unmount multiple times
    const enableVaultRe = debounce(() => {
        if (
            !vaultReIntegration?.enabled &&
            integrationType === 'vaultRe' &&
            integrationStatusReason === 'success' &&
            integrationCode
        ) {
            updateVaultReIntegration.mutate({ code: integrationCode, shouldPushFinishedDocuments: false });
        } else if (integrationType === 'vaultRe' && integrationStatusReason !== 'success') {
            addNewToast("Integration didn't complete", ToastTypes.ERROR, true);
        }
    }, 10);

    const updateVaultReIntegration = useMutation(
        (data: FormValues) => {
            return axios.post(`/api/agency/integration/vault-re`, data);
        },
        {
            onSuccess: response => {
                addNewToast('Integration details updated successfully', ToastTypes.SUCCESS, true);
                //If we don't change the route, route still have old code in query params
                appHistory.push('/user/integrations');
                dispatch(updateAgencyInfo(response.data));
                dispatch(syncAccountIntegration());
            }
        }
    );

    const disconnectVaultReIntegration = useMutation(
        () => {
            return axios.delete(`/api/agency/integration/vault-re`);
        },
        {
            onSuccess: response => {
                dispatch(updateAgencyInfo(response.data));
            }
        }
    );

    async function handleSubmit(values: FormValues) {
        try {
            await updateVaultReIntegration.mutateAsync(values);
        } catch (error) {
            return error?.response?.data?.errors?.integrations?.vaultRe ?? {};
        }
    }

    const integrate = () => {
        setGettingLogInLink(true);
        axios
            .get(`/api/integrations/vault-re/link`)
            .then(response => {
                window.location.href = response.data.link;
            })
            .catch(() => {
                setGettingLogInLink(false);
                addNewToast('Failed to get log in link', ToastTypes.ERROR, true);
            });
    };

    return isVaultReEnabled ? (
        <Form
            initialValues={{ shouldPushFinishedDocuments: vaultReIntegration?.shouldPushFinishedDocuments }}
            onSubmit={handleSubmit}
        >
            {({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit} ref={formRef}>
                        <div className={`panel vaultRe ${styles.container}`}>
                            <h2 className="panel-header">VaultRE</h2>
                            <div className="panel-body">
                                <CheckboxCheck
                                    name={`shouldPushFinishedDocuments`}
                                    label="Automatically store completed agreements and documents in VaultRE"
                                />
                                {vaultReIntegration?.enabled ? (
                                    <div>
                                        <span className="savestatus-saved">
                                            Connected <span className="icon savestatus-icon" />
                                        </span>
                                    </div>
                                ) : (
                                    <div>
                                        <Button
                                            primary
                                            type="button"
                                            onClick={integrate}
                                            isLoading={isGettingLogInLink || updateVaultReIntegration.isLoading}
                                            disabled={isGettingLogInLink || updateVaultReIntegration.isLoading}
                                            className={styles.integrationButton}
                                        >
                                            Integrate
                                        </Button>
                                        <div className="question">
                                            <span />
                                            <a
                                                onClick={() => toggleConnectionInstruction(s => !s)}
                                                className="special-link"
                                            >
                                                Connection instructions
                                            </a>
                                        </div>
                                        {isConnectionInstructionOpen && (
                                            <div>
                                                <ol>
                                                    <li>
                                                        Click on the button above to{' '}
                                                        <strong>connect to VaultRE.</strong>
                                                    </li>
                                                    <li>You will be redirected to the VaultRE login page.</li>
                                                    <li>Enter your VaultRE username and password to log in.</li>
                                                    <li>
                                                        Once logged in, you will see an authorisation screen,{' '}
                                                        <strong>where you can grant FLK it over access.</strong>
                                                    </li>
                                                    <li>
                                                        If you do not wish to proceed, click “Cancel” to exit the
                                                        process.
                                                    </li>
                                                    <li>
                                                        After authorising, you will be redirected back to our website.
                                                    </li>
                                                    <li>You will be notified once the process is complete.</li>
                                                </ol>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="panel-footer">
                                {vaultReIntegration?.enabled && (
                                    <>
                                        <Button
                                            loading={disconnectVaultReIntegration.isLoading}
                                            type="button"
                                            onClick={disconnectVaultReIntegration.mutate}
                                            disabled={disconnectVaultReIntegration.isLoading}
                                        >
                                            {disconnectVaultReIntegration.isLoading ? 'Disconnecting' : 'Disconnect'}
                                        </Button>
                                        <SaveButtonIcon
                                            isSaving={updateVaultReIntegration.isLoading}
                                            success={updateVaultReIntegration.isSuccess}
                                            failure={updateVaultReIntegration.isError}
                                            withToast={false}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    </form>
                );
            }}
        </Form>
    ) : null;
};

export default VaultReIntegration;
