import { changeDocumentAgent, LEASE_STATUS_DRAFT, LEASE_STATUS_SENT_SIGNING } from '@app/actions/dashboard';
import { setCurrentDocument, refreshDocumentList, removeDocumentInList } from '@app/actions/document';
import { ToastTypes } from '@app/common/components/Toast';
import { EXCHANGE_OF_CONTRACTS } from '@app/config';
import { EXCHANGE_OF_CONTRACTS_API_PATH } from '@app/constants/constants';
import { ExchangeOfContracts } from '@app/types/ExchangeOfContracts';
import { getErrorMessageForToast } from '@app/utils/errorUtils';
import { getErrorObjectFromYupError } from '@app/utils/formUtils';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import {
    ApiModel,
    FormModel as DraftFormModel,
    projectApiModel,
    formValuesSchema,
    projectFormErrors
} from '../ExchangeOfContracts.model';
import { useDispatch } from 'react-redux';
import useDoubleSendGuard from '@app/hooks/useDoubleSendGuard';
import useToast from '@app/hooks/useToast';
import useResendCounter from '@app/hooks/useResendCounter';
import { useHistory } from 'react-router';
import { FormModel as AwaitingCompletionFormModel } from '../eoc-summary/awaiting-completion-email-form/AwaitingCompletionEmailForm.model';
import { scrollToError } from '@app/utils/finalFormUtils';
import { doAllClientsHaveSignatures } from '@app/containers/PdfViewer/utils';
import { UploadedDocumentState } from '@app/containers/PdfViewer/components/useUploadedDocumentState';

export const useApi = (eocDocument: ExchangeOfContracts) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { addNewToast } = useToast();
    const doubleSendGuard = useDoubleSendGuard();
    const { startResendCounter } = useResendCounter();

    const documentId = eocDocument.id;

    const errorToast = (text: string) => addNewToast(getErrorMessageForToast(text), ToastTypes.ERROR, true);
    const successToast = (text: string) => addNewToast(text, ToastTypes.SUCCESS, true);

    const saveMutation = useMutation<ExchangeOfContracts, AxiosError, ApiModel>(
        async values => {
            const response = await axios.post(`${EXCHANGE_OF_CONTRACTS_API_PATH}/${documentId}`, values);
            return response.data.doc;
        },
        {
            onError: error => {
                window.rollbar.error('Failed to save exchange of contracts', error);
                errorToast('Failed to save the document.');
            }
        }
    );

    const sendMutation = useMutation<ExchangeOfContracts, AxiosError, ApiModel>(
        async values => {
            const response = await axios.post(`${EXCHANGE_OF_CONTRACTS_API_PATH}/${documentId}/send`, values);
            return response.data.doc;
        },
        {
            onError: error => {
                if (error.response?.status !== 400) {
                    window.rollbar.error('Failed to send exchange of contracts', error);
                    errorToast('Failed to send the document.');
                }
            }
        }
    );

    const updateAgentMutation = useMutation<void, AxiosError, string>(
        async agentId => {
            await axios.put(`/api/document/${eocDocument.id}/agent`, { agent: agentId });
        },
        {
            onError: error => {
                window.rollbar.error('Failed to update the agent', error);
                errorToast('Failed to update the agent.');
            }
        }
    );

    const save = async (
        values: DraftFormModel,
        uploadedDocuments: ApiModel['uploadedDocuments'],
        options?: { silent?: boolean }
    ) => {
        const document = await saveMutation.mutateAsync(projectApiModel(values, uploadedDocuments));
        dispatch(setCurrentDocument(document));
        dispatch(refreshDocumentList());
        if (!options?.silent) {
            successToast('Document saved successfully');
        }
    };

    const validate = (
        values: DraftFormModel,
        uploadedDocuments: ApiModel['uploadedDocuments'],
        uploadedDocumentStateItems: UploadedDocumentState[],
        hasReviewedPdf: boolean
    ) => {
        try {
            const recipients = [...values.purchasers, ...values.vendors];
            const doAllRecipientsHaveSignatures = doAllClientsHaveSignatures(
                recipients.length,
                uploadedDocumentStateItems
            );
            formValuesSchema.validateSync(
                { ...values, uploadedDocuments, hasReviewedPdf, doAllRecipientsHaveSignatures },
                { abortEarly: false }
            );
        } catch (error) {
            const errors = getErrorObjectFromYupError(error);
            return errors;
        }
    };

    const send = async (values: DraftFormModel, uploadedDocuments: ApiModel['uploadedDocuments']) => {
        return doubleSendGuard(async () => {
            try {
                const document = await sendMutation.mutateAsync(projectApiModel(values, uploadedDocuments));
                dispatch(removeDocumentInList(document, LEASE_STATUS_DRAFT));
                history.push(`/dashboard/documents/${EXCHANGE_OF_CONTRACTS}/${LEASE_STATUS_SENT_SIGNING}`);
                successToast('Document sent successfully');
                startResendCounter();
            } catch (error) {
                const apiErrors = error.response?.data?.errors;
                if (apiErrors) {
                    scrollToError();
                    return projectFormErrors(apiErrors, values);
                }
            }
        });
    };

    const updateAgent = async (agentId: string) => {
        await updateAgentMutation.mutateAsync(agentId);
        dispatch(changeDocumentAgent());
    };

    const resendMutation = useMutation(
        (data: { selectedClients: string[] }) => {
            return axios.post(`api/document/exchange-of-contracts/${eocDocument.id}/resend`, {
                selectedClients: data.selectedClients
            });
        },
        {
            onError: error => {
                window.rollbar.error('Failed to resend exchange of contracts', error);
                errorToast('Failed to send the document.');
            }
        }
    );

    const resend = async (selectedClients: string[], onSuccess: () => void) => {
        await resendMutation.mutateAsync({ selectedClients });
        successToast('Document sent successfully');
        dispatch(refreshDocumentList());
        onSuccess();
    };

    const completeMutation = useMutation<void, AxiosError, AwaitingCompletionFormModel>(
        data => {
            return axios.post(`api/document/exchange-of-contracts/${eocDocument.id}/complete`, data);
        },
        {
            onError: error => {
                window.rollbar.error('Failed to complete exchange of contracts', error);
                errorToast('Failed to complete the document.');
            }
        }
    );

    const complete = async (values: AwaitingCompletionFormModel) => {
        await completeMutation.mutateAsync(values);
        successToast('Document completed successfully');
        dispatch(refreshDocumentList());
    };

    return {
        validate,
        scrollToError,
        save,
        send,
        updateAgent,
        resend,
        complete,
        isApiBusy:
            saveMutation.isLoading ||
            sendMutation.isLoading ||
            updateAgentMutation.isLoading ||
            resendMutation.isLoading ||
            completeMutation.isLoading,
        isSaving: saveMutation.isLoading,
        isSending: sendMutation.isLoading,
        isResending: resendMutation.isLoading,
        isCompleting: completeMutation.isLoading,
        isUpdatingAgent: updateAgentMutation.isLoading
    };
};
