import React, { memo, useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Pagination from '../../common/components/Pagination';
import { useParams } from 'react-router-dom';
import { cloneDeep, filter, includes, isEmpty, debounce } from 'lodash';
import * as agencyActions from '../../actions/agency';
import * as dashboard from '../../actions/dashboard';
import { loadFlkAKeyList } from '../../actions/dashboard';
import * as connections from '../../actions/connections';
import { updateTeamMemberViewing, updateTeamMemberViewingState } from '../../actions/user';
import FlkAKeyStatusToolbar from '../../components/dashboard/flkAKeyList/FlkAKeyStatusToolbar';
import FlkAKeyTable from '../../components/dashboard/flkAKeyList/FlkAKeyTable';
import MultiSelect from '../../common/components/MultiSelect';
import { getAgentSignature } from '../../actions/completion';
import { isAdmin } from '../../utils/userUtils';
import { ReactComponent as KeyIcon } from '../../../assets/images/icons/key.svg';

import { getTeam, getUserAgency } from '../../selectors/agency';
import { getSavedTeamMemberSelection, getUserInfo } from '../../selectors/user';
import {
    getKeyPageNav,
    getFlkAKeyList,
    getIsPending,
    getHasInitialKeyListRequested
} from '../../selectors/dashboard/flkAKeyList';
import { DEBOUNCE_TIME, DEFAULT_PAGE, DEFAULT_RECORDS_PER_PAGE } from '../../config';
import { usePrevious } from '../../utils/reactUtils';
import {
    getAddressFilter,
    getCurrentKeyImageUrls,
    getCurrentKey,
    getIsReminderModalOpen,
    getReloadKeyList
} from '../../selectors/flkAKey';
import {
    changeCurrentKeyImage,
    changeReloadKeyListStatus,
    removeKeyInList,
    updateKeyInList,
    resetSelectKey
} from '../../actions/flkAKey';

import { useKeyUpdate } from '../../hooks/useKeyUpdate';
import { useKeyRemoved } from '../../hooks/useKeyRemoved';
import Dashboard from '../../common/components/dashboard/Dashboard';
import DashboardHeader from '../../common/components/dashboard/DashboardHeader';
import FlkAKeyReminderModal from '../../components/modals/FlkAKeyReminderModal';
import { getIsUserLoggedIn } from '../../selectors/login';

const FlkAKeyList = () => {
    const dispatch = useDispatch();
    const savedDataRef = useRef(null);

    const [selected, setSelected] = useState([]);
    const isPending = useSelector(getIsPending);
    const addressFilter = useSelector(getAddressFilter);
    const { keyStatus } = useParams();
    const keyPageNav = useSelector(getKeyPageNav);
    const userInfo = useSelector(getUserInfo);
    const flkAKeyList = useSelector(getFlkAKeyList);
    const agency = useSelector(getUserAgency);
    const initialKeyListRequested = useSelector(getHasInitialKeyListRequested);
    const team = useSelector(getTeam);
    const savedTeamMemberSelection = useSelector(getSavedTeamMemberSelection);
    const prevKeyStatus = usePrevious(keyStatus);
    const currentKey = useSelector(getCurrentKey);
    const imageUrls = useSelector(getCurrentKeyImageUrls);
    const isOpenKeyReminderModal = useSelector(getIsReminderModalOpen);
    const reloadKeyList = useSelector(getReloadKeyList);
    const isUserLoggedIn = useSelector(getIsUserLoggedIn);

    // we need to save this inside a ref. otherwise Twilio events get old status
    savedDataRef.current = { keyStatus, imageUrls, currentKey };

    useKeyUpdate(agency, item => {
        if (
            savedDataRef.current.currentKey &&
            savedDataRef.current.currentKey.id === item.data.id &&
            savedDataRef.current.imageUrls !== item.data.compressedImageUrls
        ) {
            dispatch(changeCurrentKeyImage(item.data.compressedImageUrls, item.data.images));
        }
        dispatch(updateKeyInList(item.data, savedDataRef.current.keyStatus));
    });

    useKeyRemoved(agency, item => {
        dispatch(removeKeyInList(item.data));
    });

    useEffect(() => {
        if (isUserLoggedIn) {
            dispatch(connections.getConnectionCompanies());
            dispatch(agencyActions.getAllTeamMembers());
            if (userInfo.id) {
                dispatch(getAgentSignature(userInfo.id));
                setSelected(userInfo.savedTeamMemberSelection);
            }
        }
    }, [dispatch, userInfo.id, isUserLoggedIn]);

    useEffect(() => {
        if (reloadKeyList) {
            const newPageNav = getPaginationByStatus(keyStatus);
            loadKeysByStatus(
                keyStatus,
                savedTeamMemberSelection,
                newPageNav.page,
                newPageNav.recordsPerPage,
                addressFilter
            );
            dispatch(changeReloadKeyListStatus(false));
        }
    }, [reloadKeyList]);

    useEffect(() => {
        if (typeof savedTeamMemberSelection !== 'undefined') {
            const newPageNav = getPaginationByStatus(keyStatus);
            loadAllKeys(keyStatus, newPageNav, savedTeamMemberSelection);
        }
    }, [savedTeamMemberSelection]);

    /**
     * Load specific key when status is changed
     */
    useEffect(() => {
        // if we do not have a prevKeyStatus, that means this is loading the page first time. If so we do not need to get keys by status
        if (prevKeyStatus && keyStatus) {
            let newPageNav = getPaginationByStatus(keyStatus, keyPageNav);
            debouncedLoadKeysByStatus(
                keyStatus,
                savedTeamMemberSelection,
                1,
                newPageNav.recordsPerPage,
                addressFilter
            );
        }
    }, [keyStatus, addressFilter]);

    const loadAllKeys = (keyStatus, allPageNav, teamMembersViewing = []) => {
        let pageNav = getPaginationByStatus(status, allPageNav);
        const clearList = true;
        if (isEmpty(teamMembersViewing)) {
            dispatch(loadFlkAKeyList(keyStatus, pageNav.page, pageNav.recordsPerPage, clearList, addressFilter));
        } else {
            dispatch(
                loadFlkAKeyList(
                    keyStatus,
                    pageNav.page,
                    pageNav.recordsPerPage,
                    teamMembersViewing,
                    clearList,
                    addressFilter
                )
            );
        }
    };

    const loadKeysByStatus = (
        keyStatus,
        teamMembersViewing = [],
        page = DEFAULT_PAGE,
        recordsPerPage = DEFAULT_RECORDS_PER_PAGE,
        address
    ) => {
        const clearList = false;
        if (isEmpty(teamMembersViewing)) {
            dispatch(loadFlkAKeyList(keyStatus, page, recordsPerPage, undefined, clearList, address));
        } else {
            dispatch(loadFlkAKeyList(keyStatus, page, recordsPerPage, teamMembersViewing, clearList, address));
        }
    };

    const debouncedLoadKeysByStatus = useCallback(debounce(loadKeysByStatus, DEBOUNCE_TIME), []);

    const getPaginationByStatus = (status, allPagination = keyPageNav) => {
        return status in allPagination && allPagination[status]
            ? allPagination[status]
            : { page: DEFAULT_PAGE, recordsPerPage: DEFAULT_RECORDS_PER_PAGE };
    };

    const changePage = page => {
        const newPageNav = getPaginationByStatus(keyStatus);
        loadKeysByStatus(keyStatus, savedTeamMemberSelection, page, newPageNav.recordsPerPage, addressFilter);
        dispatch(resetSelectKey());
    };

    const handleMultiSelection = teamMembers => {
        setSelected(teamMembers);

        let newPageNav = cloneDeep(keyPageNav);
        newPageNav[keyStatus].page = 1;

        dispatch(dashboard.updateKeyPageNav(newPageNav));
        dispatch(updateTeamMemberViewingState(teamMembers)).then(() => {
            dispatch(updateTeamMemberViewing(teamMembers));
        });
    };

    const customSelectionBoxHeader = selected => {
        let teamMemberNumber = selected.length;
        if (teamMemberNumber > 1) {
            return teamMemberNumber + ' team members selected';
        } else if (teamMemberNumber > 0) {
            return teamMemberNumber + ' team member selected';
        }
        return 'Select team members';
    };

    /**
     * Check whether the user has permission to view others' keys
     *
     * @return {boolean}
     */
    const canViewOthersKeys = () => {
        const hasPermissionTo = userInfo.hasPermissionTo;
        if (isAdmin(userInfo.role)) {
            return true;
        } else {
            return !isEmpty(hasPermissionTo);
        }
    };

    /**
     * get the other agents' names that the user has permission to
     *
     * @return {array}
     */
    const getAgentNamesByIds = () => {
        const hasPermissionTo = userInfo.hasPermissionTo;

        let permittedAgentsList;

        if (isAdmin(userInfo.role)) {
            permittedAgentsList = team;
        } else {
            permittedAgentsList = filter(team, teamMember => {
                return includes(hasPermissionTo, teamMember.id);
            });
            if (team && team.length > 0) {
                const member = team.find(currentMember => currentMember.id === userInfo.id);
                if (member) {
                    permittedAgentsList.unshift(member);
                }
            }
        }

        return permittedAgentsList.map(value => {
            return { value: value.id, label: value.firstName + ' ' + value.secondName };
        });
    };

    const options = getAgentNamesByIds();
    const enableTeamViewing = canViewOthersKeys();
    let newPageNav = getPaginationByStatus(keyStatus);
    let pagination = (
        <div className="wrapper-pagination">
            <Pagination pageNav={newPageNav} onChange={e => changePage(e)} />
            {newPageNav.recordsPerPage < newPageNav.totalItemsCount ? (
                <p className="pagination-text desktop-only">
                    Page {newPageNav.page} of {Math.ceil(newPageNav.totalItemsCount / newPageNav.recordsPerPage)}
                </p>
            ) : (
                <p />
            )}
        </div>
    );

    return (
        <Dashboard
            header={
                <DashboardHeader
                    icon={<KeyIcon />}
                    title="FLK a Key"
                    selectAgent={
                        enableTeamViewing ? (
                            <MultiSelect
                                options={options}
                                selected={selected || []}
                                onSelectedChanged={handleMultiSelection}
                                selectAllLabel="All members"
                                valueRenderer={customSelectionBoxHeader}
                            />
                        ) : undefined
                    }
                />
            }
            tabBarProps={{
                tabBar: (
                    <FlkAKeyStatusToolbar
                        status={keyStatus}
                        isPending={isPending}
                    />
                )
            }}
            body={
                initialKeyListRequested && flkAKeyList ? (
                    <FlkAKeyTable
                        flkAKeyList={flkAKeyList}
                        keyStatus={keyStatus}
                    />
                ) : (
                    undefined
                )
            }
            pagination={pagination}
            modalProps={{
                modal: <FlkAKeyReminderModal />,
                isOpen: isOpenKeyReminderModal
            }}
        />
    );
};

export default memo(FlkAKeyList);
