import React from 'react';
import '../../../sass/dashboardContentTable.scss';
import Draft from './tables/exchangeOfContracts/Draft';
import * as dashboard from '../../../actions/dashboard';
import SentForSigning from './tables/exchangeOfContracts/SentForSigning';
import AwaitingCompletion from './tables/exchangeOfContracts/AwaitingCompletion';
import Completed from './tables/exchangeOfContracts/Completed';

const ExchangeOfContractsListings = ({ documentList, docType, docStatus }) => {
    let contentTable;

    switch (docStatus) {
        case dashboard.LEASE_STATUS_DRAFT: {
            contentTable = <Draft documentList={documentList} docType={docType} />;
            break;
        }
        case dashboard.LEASE_STATUS_SENT_SIGNING: {
            contentTable = <SentForSigning documentList={documentList} docType={docType} />;
            break;
        }
        case dashboard.LEASE_STATUS_AWAITING_COMPLETION: {
            contentTable = <AwaitingCompletion documentList={documentList} docType={docType} />;
            break;
        }
        case dashboard.LEASE_STATUS_COMPLETE: {
            contentTable = <Completed documentList={documentList} docType={docType} />;
            break;
        }
    }

    return <div className="table-sort">{contentTable}</div>;
};

export default ExchangeOfContractsListings;
