import React from 'react';
import { map, startCase, isEmpty } from 'lodash';
import Email from '@flk-mui-icons/EmailSharp';
import PhoneIphoneSharp from '@flk-mui-icons/PhoneIphoneSharp';
import AgreementSection from '../../../../../containers/dashboard/infoAgreement/AgreementSection';
import { viewDefaultDocument, viewUploadedDocument, formatUrl } from '../../../../../config';
import {
    isLeaseTypeSales,
    isLeaseTypeResidentialTenancy,
    getResTenStepNo,
    getPmStepNo,
    getSalesStepNo,
    isLeaseTypePm,
    isLeaseTypeCommercialLease,
    getClStepNo,
    getDefaultAttachedDocuments,
    isLeaseLocationACT
} from '../../../../../utils/agreementUtils';

import { useSelector } from 'react-redux';
import { formatDateDayWithTh, getAgencyTimezoneFromUser } from '@app/utils/dateUtils';
import { getUserInfo } from '@app/selectors/user';

export default function AgreementMainDocuments({ documents, leaseAllInfo }) {
    const part = 'documents';

    const loggedInUser = useSelector(getUserInfo);
    const agencyTimezone = getAgencyTimezoneFromUser(loggedInUser);

    if (!documents || !leaseAllInfo) {
        return <></>;
    }

    const { leaseType, location, subLeaseType, additionalTerms } = leaseAllInfo;
    let stepIndex;
    if (isLeaseTypeResidentialTenancy(leaseType)) {
        stepIndex = getResTenStepNo(location, part, subLeaseType);
    } else if (isLeaseTypeSales(leaseType)) {
        stepIndex = getSalesStepNo(location, part);
    } else if (isLeaseTypePm(leaseType)) {
        stepIndex = getPmStepNo(location, part);
    } else if (isLeaseTypeCommercialLease(leaseType)) {
        stepIndex = getClStepNo(location, part);
    }

    const withDocumentLinks =
        (leaseAllInfo.tenantLinks && !isEmpty(leaseAllInfo.tenantLinks.links)) || leaseAllInfo.conditionReport;
    const conditionReport = leaseAllInfo.conditionReport;

    return (
        <React.Fragment>
            <AgreementSection title="Attached Documents" step={stepIndex} leaseAllInfo={leaseAllInfo}>
                <div className="agreement-section-documents">
                    {map(getDefaultAttachedDocuments(leaseType, location), (defaultAttachedDocument, key) => {
                        // This is added so that we will only apply the filtering for this specific PDF
                        const isGuidanceMaterial = defaultAttachedDocument?.id === 'SWIMMING_POOL_COMPLIANCE_PDF';
                        const isACTAndResidentialDisplay =
                            isLeaseLocationACT(location) && isLeaseTypeResidentialTenancy(leaseType);
                        const hasSwimmingPool = additionalTerms?.swimmingPool === true;

                        if (isGuidanceMaterial) {
                            if (isACTAndResidentialDisplay && !hasSwimmingPool) {
                                return null;
                            }
                        }

                        return (
                            <p key={key}>
                                <span className="bold">{startCase(defaultAttachedDocument.category)}:</span>{' '}
                                <a
                                    className="special-link uploaded-doc-file"
                                    onClick={() => viewDefaultDocument(location, defaultAttachedDocument.fileName)}
                                >
                                    {' '}
                                    {defaultAttachedDocument.documentName}
                                </a>
                            </p>
                        );
                    })}
                    {map(documents, (uploadedDocument, key) => {
                        return (
                            <p key={key}>
                                <span className="uploaded-doc-category">{startCase(uploadedDocument.category)}:</span>
                                <a
                                    onClick={() => viewUploadedDocument(leaseAllInfo.id, uploadedDocument.id)}
                                    className="special-link uploaded-doc-file"
                                >
                                    {uploadedDocument.documentName}
                                </a>
                                <span>({uploadedDocument.prettyBytes})</span>
                            </p>
                        );
                    })}
                </div>
            </AgreementSection>
            {withDocumentLinks && (
                <AgreementSection title="Documents Links" step={stepIndex} leaseAllInfo={leaseAllInfo}>
                    <div className="agreement-section-documents">
                        {conditionReport && (
                            <div className="link-wrapper" key="condition-report">
                                <PhoneIphoneSharp />
                                <Email />
                                <p>
                                    <span className="uploaded-doc-category">Condition Report:</span>
                                    <a
                                        className="special-link uploaded-doc-file"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={formatUrl(conditionReport.reportUrl)}
                                    >
                                        {formatDateDayWithTh(conditionReport.dateTimeCompleted, agencyTimezone)}
                                    </a>
                                </p>
                            </div>
                        )}
                        {map(leaseAllInfo?.tenantLinks?.links, (tenantLink, key) => {
                            return (
                                <div className="link-wrapper" key={key}>
                                    {tenantLink.includeInMobile ? <PhoneIphoneSharp /> : null}
                                    {tenantLink.includeInEmail ? <Email /> : null}
                                    <p>
                                        <span className="uploaded-doc-category">{startCase(tenantLink.title)}:</span>
                                        <a
                                            className="special-link uploaded-doc-file"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={formatUrl(tenantLink.url)}
                                        >
                                            {tenantLink.url}
                                        </a>
                                    </p>
                                </div>
                            );
                        })}
                    </div>
                </AgreementSection>
            )}
        </React.Fragment>
    );
}
