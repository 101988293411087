import React from 'react';
import { EXCHANGE_OF_CONTRACTS } from '@app/config';
import { getCurrentDocument } from '@app/selectors/document';
import { getIsExchangeOfContractsModalOpen } from '@app/selectors/document/exchangeOfContracts';
import { ExchangeOfContracts } from '@app/types/ExchangeOfContracts';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Header } from '../eoc-form/header/Header';
import { RecipientSummary } from '../../FlkAPdf/RecipientSummary';
import Button from '@app/common/components/Button';
import { ListSharp } from '@app/common/components/mui-icons/icons';
import DocumentSignatures from '../../DocumentSignatures';
import CustomPlaceholdersSummary from './custom-placeholders-summary/CustomPlaceholdersSummary';
import Note, { NoteType } from '@app/common/components/Note';
import { openAuditTrailModal } from '@app/actions/dashboard';
import EditRecipientsModal from '../../FlkAPdf/EditRecipientsModal';
import ResendDocForSigningModal from '@app/components/modals/ResendForSigningModal/ResendDocForSigningModal';
import { useApi } from '../hooks/useApi';
import { COMPLETE_FORM_ID, projectUiModel, shouldShowClientLink } from './EocSummary.model';
import SentForSigningFooter from './sent-for-signing-footer/SentForSigningFooter';
import AwaitingCompletionFooter from './awaiting-completion-footer/AwaitingCompletionFooter';
import AwaitingCompletionEmailForm from './awaiting-completion-email-form/AwaitingCompletionEmailForm';
import DocumentsSummary from './documents-summary/DocumentsSummary';
import Section from './section/Section';
import EocModal from '../eoc-modal/EocModal';

import styles from './EocSummary.module.scss';

type EocSummaryProps = {
    close: () => void;
};

export const EocSummary: React.FC<EocSummaryProps> = ({ close }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const eocDocument: ExchangeOfContracts = useSelector(getCurrentDocument);
    const isExchangeOfContractsModalOpen = useSelector(getIsExchangeOfContractsModalOpen);

    const [isResendModalOpen, setIsResendModalOpen] = React.useState(false);
    const [isEditRecipientsModalOpen, setIsEditRecipientsModalOpen] = React.useState(false);

    React.useEffect(() => {
        window.history.pushState({}, '', `/dashboard/document/${eocDocument.id}`);
    }, [eocDocument.id, history]);

    const openAuditTrail = () => {
        dispatch(openAuditTrailModal(eocDocument.id, true));
    };

    const {
        showEmailForm,
        showCustomPlaceholders,
        showAuditTrail,
        showSentForSigningFooter,
        showAwaitingCompletionFooter,
        purchasers,
        vendors,
        isCompletionInProgress,
        showSignatures
    } = React.useMemo(() => projectUiModel(eocDocument), [eocDocument]);

    const { updateAgent, resend, isApiBusy, isResending, complete } = useApi(eocDocument);

    return (
        <>
            <EocModal
                isOpen={isExchangeOfContractsModalOpen}
                closeModal={close}
                header={
                    <Header
                        eocDocument={eocDocument}
                        closeModal={close}
                        updateAgent={updateAgent}
                        openAuditTrail={openAuditTrail}
                        editRecipients={() => setIsEditRecipientsModalOpen(true)}
                    />
                }
                footer={
                    <>
                        {showSentForSigningFooter && (
                            <SentForSigningFooter
                                documentId={eocDocument.id}
                                setIsResendModalOpen={setIsResendModalOpen}
                                isBusy={isApiBusy}
                            />
                        )}
                        {showAwaitingCompletionFooter && (
                            <AwaitingCompletionFooter
                                completeFormId={COMPLETE_FORM_ID}
                                isCompletionInProgress={isCompletionInProgress}
                                completionProgress={eocDocument.completion?.progress}
                                isBusy={isApiBusy}
                            />
                        )}
                    </>
                }
            >
                {showSignatures && (
                    <DocumentSignatures
                        className={styles.signatures}
                        label="Recipient"
                        note={
                            <Note
                                title="Signing Order has been enforced."
                                type={NoteType.INFO}
                                className={styles.signatureNote}
                            >
                                Your recipients can only start signing their documents once the previous signer has
                                completed their part.
                            </Note>
                        }
                    />
                )}
                <Section title="Purchasers">
                    {purchasers.map((purchaser, index) => (
                        <RecipientSummary
                            key={purchaser.id}
                            client={purchaser}
                            index={index}
                            document={{ id: eocDocument.id, sentForSigning: eocDocument.sentForSigning }}
                            showCopyLink={shouldShowClientLink(eocDocument, purchaser)}
                        />
                    ))}
                </Section>
                <Section title="Vendors">
                    {vendors.map((vendor, index) => (
                        <RecipientSummary
                            key={vendor.id}
                            client={vendor}
                            index={index + purchasers.length}
                            document={{ id: eocDocument.id, sentForSigning: eocDocument.sentForSigning }}
                            showCopyLink={shouldShowClientLink(eocDocument, vendor)}
                        />
                    ))}
                </Section>
                <Section title="Message for the Purchaser/Vendor" divider>
                    <p className={styles.descriptionText}>{eocDocument.descriptionText}</p>
                </Section>
                <Section title="Documents" divider>
                    <DocumentsSummary eocDocument={eocDocument} />
                </Section>
                {showCustomPlaceholders && (
                    <Section title="Contract/Sale details" divider>
                        <CustomPlaceholdersSummary customPlaceholders={eocDocument.customPlaceholders} />
                    </Section>
                )}
                {showEmailForm && (
                    <Section title="Email Finalised Documents to..." divider>
                        <AwaitingCompletionEmailForm
                            onSubmit={async values => {
                                return await complete(values);
                            }}
                            formId={COMPLETE_FORM_ID}
                            purchasers={purchasers}
                            vendors={vendors}
                            isCompletionInProgress={isCompletionInProgress}
                            eocDocument={eocDocument}
                            isApiBusy={isApiBusy}
                        />
                    </Section>
                )}
                {showAuditTrail && (
                    <Section title="Audit Trail" divider>
                        <Button
                            className={styles.auditTrailButton}
                            type="button"
                            startIcon={<ListSharp />}
                            onClick={openAuditTrail}
                        >
                            Open audit trail
                        </Button>
                    </Section>
                )}
            </EocModal>
            <ResendDocForSigningModal
                isOpen={isResendModalOpen}
                enforceSequentialSigning
                clients={eocDocument.client?.clients.filter(
                    client => client.signingOrder === eocDocument.client?.currentSigningClientOrder
                )}
                closeModal={() => setIsResendModalOpen(false)}
                shouldResendForAll
                resendForSigning={(selectedClients: string[]) => {
                    resend(selectedClients, () => {
                        setIsResendModalOpen(false);
                        close();
                    });
                }}
                isResendingForSigning={isResending}
                documentType={EXCHANGE_OF_CONTRACTS}
            />
            <EditRecipientsModal
                isOpen={isEditRecipientsModalOpen}
                closeModal={() => setIsEditRecipientsModalOpen(false)}
                doc={eocDocument}
                openResendModal={() => setIsResendModalOpen(true)}
            />
        </>
    );
};
