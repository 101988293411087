import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import arrayMutators from 'final-form-arrays';
import { Form } from 'react-final-form';

import { ClientType, ExchangeOfContracts } from '@app/types/ExchangeOfContracts';
import { getCurrentDocument } from '@app/selectors/document';
import { getIsExchangeOfContractsModalOpen } from '@app/selectors/document/exchangeOfContracts';
import { addOrReplaceDocumentInList, setCurrentDocument } from '@app/actions/document';
import { CLOSE_MODAL_MESSAGE, FormSubmitType, viewUploadedDocumentFromDocument } from '@app/config';
import { RecipientSection } from './recipient-section/RecipientSection';
import {
    ApiModel,
    doAllRecipientsHaveNames,
    FormModel,
    getMaxRecipients,
    isRecipientSectionValid,
    projectApiModelWithoutUploadedDocuments,
    projectFormModel
} from '../ExchangeOfContracts.model';
import { SectionHeader } from './SectionHeader';
import Button from '@app/common/components/Button';
import UploadADocUpload from '../../FlkAPdf/UploadADocUpload';
import { ConfirmationType } from '@app/constants/constants';
import PdfViewer from '@app/containers/PdfViewer/PdfViewer';
import { useUploadedDocumentStateItems } from '@app/containers/PdfViewer/components/useUploadedDocumentState';
import CustomPlaceholderFormSection from '../../FlkAPdf/CustomPlaceholderFormSection';
import {
    addClientAtIndex,
    deleteAnnotationsForCustomPlaceholder,
    deleteClientAtIndex,
    doAllClientsHaveSignatures,
    getCustomPlaceholderCountsAfterDeletingDocument,
    getDocumentsWithUpdatedAnnotations,
    reorderClientAnnotations
} from '@app/containers/PdfViewer/utils';
import { Spinner, SpinnerSizes, SpinnerTypes } from '@app/common/design-system/spinner';
import useResendCounter from '@app/hooks/useResendCounter';
import { useApi } from '../hooks/useApi';
import { usePdfViewer } from './hooks/usePdfViewer';
import { DocumentCheckSection } from './document-check-section/DocumentCheckSection';
import { Header } from './header/Header';
import { confirmAlert } from 'react-confirm-alert';
import ConfirmAlert from '../../FlkAPdf/ConfirmAlert';
import { openAuditTrailModal } from '@app/actions/dashboard';
import EocModal from '../eoc-modal/EocModal';
import { SidebarAccordionItems } from '@app/containers/PdfViewer/components/Sidebar';
import { Textarea } from '../../buildADoc/components/Textarea';

import styles from './EocForm.module.scss';

type EocFormProps = {
    close: () => void;
};

const EocForm: React.FC<EocFormProps> = ({ close }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const eocDocument: ExchangeOfContracts = useSelector(getCurrentDocument);
    const isExchangeOfContractsModalOpen = useSelector(getIsExchangeOfContractsModalOpen);

    const [localDocumentList, setLocalDocumentList] = React.useState<ApiModel['uploadedDocuments']>([]);

    const { resendWaitSeconds } = useResendCounter();

    const {
        uploadedDocumentStateItems,
        selectedUploadedDocId,
        setSelectedUploadedDocId,
        addUploadedDocumentStateItem,
        removeUploadedDocumentStateItem,
        resetVisitedState,
        handleUpdateAnnotationCount,
        defaultOpenSidebarItems,
        setIsWebViewerInitialised,
        areAllDocumentsInitialised
    } = useUploadedDocumentStateItems({
        defaultOpenSidebarItems: [SidebarAccordionItems.CLIENTS, SidebarAccordionItems.CUSTOM_PLACEHOLDERS]
    });

    const { save, send, updateAgent, validate, scrollToError, isApiBusy, isSaving, isSending, isUpdatingAgent } =
        useApi(eocDocument);

    const {
        isPdfViewerOpen,
        openPdfViewer,
        closePdfViewer,
        isSavingForPdfViewer,
        memoizedAgencyDetails,
        memoizedAgent,
        hasOpenedPdfViewer
    } = usePdfViewer({
        eocDocument,
        localDocumentList,
        save,
        setSelectedUploadedDocId
    });

    const initialFormValues = React.useMemo(() => projectFormModel(eocDocument), [eocDocument]);

    React.useEffect(() => {
        window.history.pushState({}, '', `/dashboard/document/${eocDocument.id}`);
    }, [eocDocument.id, history]);

    React.useEffect(() => {
        if (eocDocument?.uploadedDocuments) {
            setLocalDocumentList(eocDocument.uploadedDocuments);
        }
    }, [eocDocument.uploadedDocuments]);

    const handleSubmit = async (values: FormModel) => {
        const uploadedDocumentsWithUpdatedAnnotations = await getDocumentsWithUpdatedAnnotations(
            localDocumentList,
            uploadedDocumentStateItems
        );

        if (values.submitType === FormSubmitType.Save) {
            return await save(values, uploadedDocumentsWithUpdatedAnnotations);
        }
        if (values.submitType === FormSubmitType.Send) {
            const errors = validate(
                values,
                uploadedDocumentsWithUpdatedAnnotations,
                uploadedDocumentStateItems,
                hasOpenedPdfViewer
            );

            if (errors) {
                scrollToError();
                return errors;
            }
            return await send(values, uploadedDocumentsWithUpdatedAnnotations);
        }
    };

    const openAuditTrail = () => {
        dispatch(openAuditTrailModal(eocDocument.id, true));
    };

    const isBusy = isApiBusy || !areAllDocumentsInitialised;

    return (
        <>
            <Form<FormModel>
                onSubmit={handleSubmit}
                mutators={{
                    ...arrayMutators,
                    removeCustomPlaceholderError: (args: [number], state) => {
                        const [fieldIndex] = args;
                        const submitErrors = state.formState.submitErrors as Record<string, unknown>;
                        if (submitErrors?.customPlaceholders && Array.isArray(submitErrors.customPlaceholders)) {
                            submitErrors.customPlaceholders.splice(fieldIndex, 1);
                        }
                    }
                }}
                initialValues={initialFormValues}
            >
                {({ form, values, submitErrors, dirty }) => {
                    const clientsArray = [...values.purchasers, ...values.vendors];
                    const vendorIndexOffset = values.purchasers.length;

                    return (
                        <>
                            <EocModal
                                isOpen={isExchangeOfContractsModalOpen}
                                closeModal={close}
                                header={
                                    <Header
                                        eocDocument={eocDocument}
                                        closeModal={() => {
                                            if (dirty) {
                                                confirmAlert({
                                                    customUI: ({ onClose }) => (
                                                        <ConfirmAlert
                                                            message={CLOSE_MODAL_MESSAGE}
                                                            confirmText="Yes"
                                                            cancelText="No"
                                                            onConfirm={async () => {
                                                                form.change('submitType', FormSubmitType.Save);
                                                                await form.submit();
                                                                onClose();
                                                                close();
                                                            }}
                                                            onCancel={() => {
                                                                onClose();
                                                                close();
                                                            }}
                                                            onClose={onClose}
                                                        />
                                                    )
                                                });
                                            } else {
                                                close();
                                            }
                                        }}
                                        updateAgent={updateAgent}
                                        isUpdatingAgent={isUpdatingAgent}
                                        openPdfViewer={() => openPdfViewer(values)}
                                        saveDoc={async () => {
                                            form.change('submitType', FormSubmitType.Save);
                                            await form.submit();
                                        }}
                                        openAuditTrail={openAuditTrail}
                                    />
                                }
                                footer={
                                    <div className={styles.footer}>
                                        <p className={styles.footerText}>
                                            A <em>fee of $30 AUD (incl. GST)</em> will be charged on your monthly
                                            invoice when the last Recipient has completed signing.
                                        </p>
                                        <div className={styles.footerButtons}>
                                            <Button
                                                className={styles.saveButton}
                                                type="button"
                                                onClick={() => {
                                                    form.change('submitType', FormSubmitType.Save);
                                                    form.submit();
                                                }}
                                                secondary
                                                disabled={isBusy}
                                                loading={isSaving}
                                            >
                                                Save
                                            </Button>
                                            <Button
                                                className={styles.sendButton}
                                                type="button"
                                                onClick={() => {
                                                    form.change('submitType', FormSubmitType.Send);
                                                    form.submit();
                                                }}
                                                primary
                                                loading={isSending}
                                                disabled={isBusy || resendWaitSeconds > 0}
                                            >
                                                Send for signing{resendWaitSeconds > 0 && ` (${resendWaitSeconds}s)`}
                                            </Button>
                                        </div>
                                    </div>
                                }
                            >
                                <SectionHeader
                                    title="Enter the Purchaser and Vendor details below"
                                    subtitle="The contract will be sent to each party (via email and SMS) in the order specified by the signing sequence (e.g. 1, 2, 3, ...)."
                                />
                                <RecipientSection
                                    document={eocDocument}
                                    showPhoneInput={true}
                                    isReorderEnabled
                                    onReorderClients={(fromIndex, toIndex) => {
                                        reorderClientAnnotations(
                                            fromIndex,
                                            toIndex,
                                            uploadedDocumentStateItems,
                                            setLocalDocumentList
                                        );
                                    }}
                                    clientType={ClientType.PURCHASER}
                                    maxRecipients={getMaxRecipients(ClientType.PURCHASER, values)}
                                    handleDeleteClient={clientIndex => {
                                        deleteClientAtIndex(
                                            clientIndex,
                                            uploadedDocumentStateItems,
                                            setLocalDocumentList
                                        );
                                    }}
                                    onAddClient={clientIndex => {
                                        addClientAtIndex(clientIndex, uploadedDocumentStateItems, setLocalDocumentList);
                                    }}
                                />
                                <RecipientSection
                                    document={eocDocument}
                                    showPhoneInput={true}
                                    isReorderEnabled
                                    onReorderClients={(fromIndex, toIndex) => {
                                        reorderClientAnnotations(
                                            fromIndex + vendorIndexOffset,
                                            toIndex + vendorIndexOffset,
                                            uploadedDocumentStateItems,
                                            setLocalDocumentList
                                        );
                                    }}
                                    clientType={ClientType.VENDOR}
                                    maxRecipients={getMaxRecipients(ClientType.VENDOR, values)}
                                    indexOffset={vendorIndexOffset}
                                    handleDeleteClient={clientIndex => {
                                        deleteClientAtIndex(
                                            clientIndex,
                                            uploadedDocumentStateItems,
                                            setLocalDocumentList
                                        );
                                    }}
                                />
                                <SectionHeader
                                    title="Message for the Purchaser/Vendor"
                                    subtitle="Introduce your document; both the Purchasers and Vendors will see this before they sign."
                                />
                                <Textarea label="Introduction statement" name="descriptionText" />
                                <SectionHeader title="Upload Contract" />
                                <div className={styles.uploadSection}>
                                    <UploadADocUpload
                                        uploadedDocuments={localDocumentList}
                                        singleFile
                                        isBusy={isBusy}
                                        values={values}
                                        clients={clientsArray}
                                        uploadEndPoint={`api/document/documents-custom/${eocDocument.id}`}
                                        viewDocument={uploadedDocumentId => {
                                            viewUploadedDocumentFromDocument(eocDocument.id, uploadedDocumentId);
                                        }}
                                        deleteDocument={async (updatedDocumentList, documentToDeleteId) => {
                                            // TODO: Fix TS error
                                            getCustomPlaceholderCountsAfterDeletingDocument(
                                                form,
                                                uploadedDocumentStateItems.find(
                                                    item => item.uploadedDocumentId === documentToDeleteId
                                                )
                                            );
                                            setLocalDocumentList(updatedDocumentList);
                                            removeUploadedDocumentStateItem(documentToDeleteId);
                                            // TODO Update hasAnnotations and annotationsCount - for document validation
                                            // TODO: Does this lose updated annotations
                                            await save(values, updatedDocumentList, { silent: true });
                                        }}
                                        addDocuments={async files => {
                                            const uploadObjects = files?.map(file => ({ document: file }));
                                            const newDocumentsList = [...localDocumentList, ...uploadObjects];
                                            setLocalDocumentList(newDocumentsList);
                                            // TODO: Does this lose updated annotations
                                            await save(values, newDocumentsList, { silent: true });
                                        }}
                                        // TODO: Implement
                                        reorderDocumentsList={() => {
                                            console.log('todo');
                                        }}
                                        uploaderLabel="Drag and drop your Contract here or click to browse"
                                    />
                                </div>
                                <CustomPlaceholderFormSection
                                    deleteAnnotationsForCustomPlaceholder={customPlaceholderId => {
                                        deleteAnnotationsForCustomPlaceholder(
                                            customPlaceholderId,
                                            uploadedDocumentStateItems
                                        );
                                    }}
                                    hideOpenPdfViewerButton
                                    recipientLabelLowercase="purchaser"
                                    removeCustomPlaceholderValidation={form.mutators.removeCustomPlaceholderError}
                                    title="Contract/Sale Details"
                                    subtitle="Fill out the fields below to place the information onto the contract for review."
                                />
                                <DocumentCheckSection
                                    steps={[
                                        {
                                            text: 'Add purchasers/vendors',
                                            isDone: isRecipientSectionValid(values)
                                        },
                                        { text: 'Upload contract', isDone: localDocumentList.length > 0 },
                                        {
                                            text: 'Fill out contract/sale details',
                                            isDone: !!values.customPlaceholders?.every(
                                                customPlaceholder => customPlaceholder.value && customPlaceholder.label
                                            )
                                        },
                                        {
                                            text: 'Complete the "final document review"',
                                            subItems: [
                                                {
                                                    text: 'Add signatures',
                                                    isDone: doAllClientsHaveSignatures(
                                                        clientsArray.length,
                                                        uploadedDocumentStateItems
                                                    )
                                                },
                                                {
                                                    text: 'Add contract details',
                                                    isDone: !!values.customPlaceholders?.every(
                                                        customPlaceholder => customPlaceholder.count > 0
                                                    )
                                                },
                                                {
                                                    text: 'Check that contract details are legible',
                                                    isDone: hasOpenedPdfViewer
                                                }
                                            ]
                                        }
                                    ]}
                                    hasDocuments={localDocumentList.length > 0}
                                    doAllRecipientsHaveNames={
                                        values.purchasers.length > 0 &&
                                        values.vendors.length > 0 &&
                                        doAllRecipientsHaveNames(values)
                                    }
                                    isBusy={isBusy}
                                    sectionError={
                                        submitErrors?.hasReviewedPdf || submitErrors?.doAllRecipientsHaveSignatures
                                    }
                                    openPdfViewer={() => openPdfViewer(values)}
                                />

                                <PdfViewer<ExchangeOfContracts, FormModel>
                                    isOpen={isPdfViewerOpen}
                                    close={closePdfViewer}
                                    document={eocDocument}
                                    selectedUploadedDocId={selectedUploadedDocId}
                                    setSelectedUploadedDocId={setSelectedUploadedDocId}
                                    agencyDetails={memoizedAgencyDetails}
                                    uploadedDocumentStateItems={uploadedDocumentStateItems}
                                    onCreateWebviewerInstance={addUploadedDocumentStateItem}
                                    onUpdateAnnotationCount={handleUpdateAnnotationCount}
                                    defaultOpenSidebarItems={defaultOpenSidebarItems}
                                    numberOfFormClients={values.purchasers.length + values.vendors.length}
                                    onWebViewerInitialised={uploadedDocumentId =>
                                        setIsWebViewerInitialised(uploadedDocumentId, true)
                                    }
                                    clients={eocDocument.client?.clients}
                                    confirmationType={ConfirmationType.SIGN}
                                    agent={memoizedAgent}
                                    onSaveSuccess={(updatedDocument: ExchangeOfContracts) => {
                                        resetVisitedState();
                                        dispatch(setCurrentDocument(updatedDocument));
                                        dispatch(addOrReplaceDocumentInList(updatedDocument));
                                    }}
                                    projectApiModel={projectApiModelWithoutUploadedDocuments}
                                />
                            </EocModal>
                        </>
                    );
                }}
            </Form>
            {isSavingForPdfViewer && <Spinner size={SpinnerSizes.LARGE} fullScreen type={SpinnerTypes.SECONDARY} />}
        </>
    );
};

export { EocForm };
