import { NavLink } from "react-router-dom";
import React, { memo } from "react";
import Button from "./Button";
import { AddBox } from "@flk-mui-icons";
import styles from "./LeftMenuLink.module.scss";
import cx from "classnames";
import Tooltip from "./tooltips/Tooltip";

type LeftMenuLinkProps = {
    label: string;
    onClick: () => void;
    isActive: () => boolean;
    toPath: string;
    icon: React.ReactNode;
    onCreateNew?: () => void;
    className?: string;
    tooltipText?: string;
};

const LeftMenuLink: React.FunctionComponent<LeftMenuLinkProps> = ({
    label,
    onClick,
    isActive,
    toPath,
    icon,
    onCreateNew,
    className,
    tooltipText
}) => (
    <NavLink
        onClick={onClick}
        isActive={isActive}
        activeClassName={styles.active}
        to={toPath}
        className={cx(styles.link, className)}
    >
        <div className={styles.icon}>{icon}</div>
        <div className={styles.label}>{label}</div>
        {onCreateNew && (
            <Button onClick={onCreateNew} className={styles.button} tooltip={tooltipText}>
                <AddBox />
            </Button>
        )}
        <Tooltip />
    </NavLink>
);

export default memo(LeftMenuLink);
