import React from 'react';
import TagInput from '@app/common/design-system/tag-input/TagInput';
import { isCorrectEmail } from '@app/components/Validate';
import useComponentId from '@app/hooks/useComponentId';
import Tooltip, { TooltipSize, TooltipVariant } from '@app/common/components/tooltips/Tooltip';

import styles from './EmailFieldSet.module.scss';
import Icon, { Icons, IconSize } from '@app/common/components/Icon';

type EmailFieldSetProps = {
    namePrefix?: string;
    title: string;
    descriptionText?: string;
    disabled?: boolean;
    tooltip?: {
        triggerText: string;
        content: React.ReactNode;
    };
    isToOptional?: boolean;
};

export const EmailFieldSet: React.FC<EmailFieldSetProps> = ({
    namePrefix,
    title,
    disabled,
    descriptionText,
    tooltip,
    isToOptional
}) => {
    const getNameWithPrefix = (name: string) => (namePrefix ? `${namePrefix}.${name}` : name);
    const componentId = useComponentId().toString();

    return (
        <div className={styles.emailFieldsContainer}>
            <div className={styles.header}>
                <h3 className={styles.emailFieldsTitle}>{title}</h3>
                <div className={styles.subTitle}>
                    {descriptionText || "Enter email address and press 'Enter/Return' to confirm"}
                </div>
            </div>
            <TagInput
                name={getNameWithPrefix('to')}
                label="TO:"
                placeholder="Enter email"
                required={!isToOptional}
                validateTag={isCorrectEmail}
                addTagOnBlur
                disabled={disabled}
            />
            <TagInput
                name={getNameWithPrefix('cc')}
                label="CC:"
                placeholder="Enter email"
                validateTag={isCorrectEmail}
                addTagOnBlur
                disabled={disabled}
            />
            <TagInput
                name={getNameWithPrefix('bcc')}
                label="BCC:"
                placeholder="Enter email"
                validateTag={isCorrectEmail}
                addTagOnBlur
                disabled={disabled}
            />
            {tooltip && (
                <>
                    <div className={styles.tooltip} data-for={componentId} data-tip>
                        {tooltip.triggerText} <Icon icon={Icons.HELP} size={IconSize.SMALL} />
                    </div>
                    <Tooltip
                        variant={TooltipVariant.LIGHT}
                        size={TooltipSize.LARGE}
                        id={componentId}
                        effect="solid"
                        place="bottom"
                    >
                        {tooltip.content}
                    </Tooltip>
                </>
            )}
        </div>
    );
};

export default EmailFieldSet;
