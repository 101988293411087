import React from 'react';

import cx from 'classnames';

import { Tag, TagSize, TagColor } from '../../../common/components/tag/Tag';
import Icon, { Icons } from '../../../common/components/Icon';
import Card, { CardShadow, CardStyles } from '../../../common/components/cards/Card';
import { ClientAnnotationCounts } from '../types';
import { getTagColour } from '@app/containers/dashboard/documents/FlkAPdf/utils';

import styles from './UploadedDocumentInfoCard.module.scss';

type UploadedDocumentInfoCardProps = {
    displayName: string;
    isSelected: boolean;
    clientSignatureCounts?: ClientAnnotationCounts;
    agentSignatureCount?: number;
};

const UploadedDocumentInfoCard: React.FC<UploadedDocumentInfoCardProps> = ({
    displayName,
    isSelected,
    clientSignatureCounts,
    agentSignatureCount
}) => {
    return (
        <Card
            className={cx(styles.card, { [styles.selected]: isSelected })}
            shadow={CardShadow.NONE}
            style={CardStyles.SQUARE}
            animate={false}
        >
            <div className={styles.displayName}>{displayName}</div>
            {isSelected && clientSignatureCounts && (
                <>
                    <div className={styles.signatureCounts}>
                        {/* explicitly check for undefined because we still want to display count when value is 0 */}
                        {agentSignatureCount !== undefined && (
                            <Tag
                                className={cx(styles.signatureCount, {
                                    [styles.singleDigit]: agentSignatureCount < 10
                                })}
                                text={agentSignatureCount.toString()}
                                size={TagSize.Small}
                                color={TagColor.Green}
                            />
                        )}
                        {Object.keys(clientSignatureCounts).map(clientIndex => {
                            const signatureCount = clientSignatureCounts[clientIndex];

                            return (
                                <Tag
                                    className={cx(styles.signatureCount, {
                                        [styles.singleDigit]: signatureCount < 10
                                    })}
                                    text={signatureCount.toString()}
                                    size={TagSize.Small}
                                    color={getTagColour(Number.parseInt(clientIndex))}
                                    key={clientIndex}
                                />
                            );
                        })}
                    </div>
                    <div className={styles.label}>Signature placeholders</div>
                </>
            )}
            <Icon className={styles.pdfIcon} icon={Icons.PDF} />
        </Card>
    );
};

export default UploadedDocumentInfoCard;
