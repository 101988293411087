import { createDetailsSuccess, setPending, setTariffPlan } from '@app/actions/authorization.js';
import { Subscription } from '@app/common/components/cards/SubscriptionCard';
import { SignUpFormContainer } from '@app/components/signup';
import {
    CreateAgencyDetailsForm,
    CreateAgencyDetailsFormValues,
    CreateGeneralDetailsForm,
    CreateGeneralDetailsFormValues
} from '@app/components/signup/SignUpForms';
import { SignUpStep } from '@app/config';
import { AccountType, AvailableCountries } from '@app/constants/constants';
import { getAgencyId, getCouponInfo, getSelectedPlan } from '@app/selectors/authorization.js';
import { getAccountTypePrefix } from '@app/utils/marketingUtils';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styles from './DetailsStep.module.scss';

type DetailsStepProps = {
    isPending: boolean;
    accountType: AccountType;
    country: AvailableCountries;
    selectedSubscription: Subscription;
    initialValues: Partial<CreateAgencyDetailsFormValues | CreateGeneralDetailsFormValues>;
};

const DetailsStep: React.FC<DetailsStepProps> = ({
    isPending,
    initialValues,
    accountType,
    selectedSubscription,
    country
}) => {
    const dispatch = useDispatch();

    const agencyId = useSelector(getAgencyId);
    const selectedPlan: object | undefined = useSelector(getSelectedPlan);
    const couponInfo: { promotionId: string } | undefined = useSelector(getCouponInfo);

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const attachMarketingData = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'successfulRegistration',
            planValue: selectedSubscription?.price,
            accountType
        });
    };

    const handleSetTariffPlan = () => {
        const promotionId = couponInfo?.promotionId;

        const data = { ...selectedPlan, promotionId };
        dispatch(setTariffPlan(data, agencyId));
    };

    const createDetailsMutation = useMutation({
        mutationFn: async (values: CreateAgencyDetailsFormValues | CreateGeneralDetailsFormValues) => {
            const additionalDataForGeneralAccount = {
                userTimezone: timezone,
                officeTimezone: timezone,
                agencyName: values.companyName
            };

            const valuesWithAdditionalData = {
                ...values,
                accountType: accountType,
                ...(accountType === AccountType.GENERAL_ACCOUNT_TYPE ? additionalDataForGeneralAccount : {})
            };

            const response = await axios.post(`/api/sign-up/${agencyId}/details`, valuesWithAdditionalData);
            return response.data;
        },
        onMutate: () => {
            dispatch(setPending(true));
        },
        onSuccess: data => {
            dispatch(createDetailsSuccess(data));
            attachMarketingData();
            handleSetTariffPlan();
        },
        onSettled: () => {
            dispatch(setPending(false));
        }
    });

    return (
        <SignUpFormContainer
            hideBackButton
            currentStep={SignUpStep.Details - 1} //Offset because we index from 1
            className={styles.form}
            contentClassName={styles.content}
        >
            {accountType === AccountType.AGENCY_ACCOUNT_TYPE ? (
                <CreateAgencyDetailsForm
                    onSubmit={async values => {
                        try {
                            await createDetailsMutation.mutateAsync(values);
                        } catch (error) {
                            if (error.response) {
                                return error.response.data.errors;
                            }
                        }
                    }}
                    isPending={isPending}
                    initialValues={initialValues}
                    submitButtonId={`CreateCompany${getAccountTypePrefix(accountType)}`}
                />
            ) : (
                <CreateGeneralDetailsForm
                    onSubmit={async values => {
                        try {
                            await createDetailsMutation.mutateAsync(values);
                        } catch (error) {
                            if (error.response) {
                                return error.response.data.errors;
                            }
                        }
                    }}
                    isPending={isPending}
                    initialValues={{ ...initialValues, country }}
                    submitButtonId={`CreateCompany${getAccountTypePrefix(accountType)}`}
                />
            )}
        </SignUpFormContainer>
    );
};

export default DetailsStep;
