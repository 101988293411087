import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import DocumentEditAddressModal from './DocumentEditAddressModal';
import EditButton from '../../common/components/EditButton';

import '../../sass/documentHeader.scss';
import { getAgencyIntegrations } from '../../selectors/agency';
import { IntegrationConnection } from '../../components/integration/IntegrationConnection';
import BasicDocumentsMoreOptionBlock from '../../components/dashboard/documentList/cells/BasicDocumentsMoreOptionBlock';
import FlowDocumentsMoreOptionBlock from '../../components/dashboard/documentList/cells/FlowDocumentsMoreOptionBlock';
import AuditTrailOpenButton from '../../common/components/AuditTrailOpenButton';
import EditableAgentContent from '../../components/form/EditableAgentContent';
import { getIsRequestingTeam, getTeam } from '../../selectors/agency';
import { getAllTeamMembers } from '../../actions/agency';
import { changeDocumentAgent } from '../../actions/dashboard';

const DocumentHeader = ({
    disabled,
    address,
    addressError,
    handleAddressChange,
    setAddressDetails,
    agentName,
    parentSelector,
    integration,
    linkIntegrationProperty,
    unLinkIntegrationProperty,
    doc,
    viewOnlyIntegration,
    isFlowDocument,
    hideData,
    hideMoreOptions,
    blockEditAgent,
    setAssignedAgent,
    showIntegration = true,
    className
}) => {
    const dispatch = useDispatch();
    const team = useSelector(getTeam);
    const isRequestingTeam = useSelector(getIsRequestingTeam);

    const [agent, setAgent] = useState({
        value: doc && doc.agent ? doc.agent.id : '',
        label: doc && doc.agent ? doc.agent.fullName : ''
    });
    const [isOpenEditAddress, setIsOpenEditAddress] = useState(false);
    const agencyIntegrations = useSelector(getAgencyIntegrations);
    const integrationObject = {
        ...integration,
        type:
            integration && integration.type
                ? integration.type
                : agencyIntegrations
                  ? agencyIntegrations.activeIntegration
                  : ''
    };

    /**
     * Update agent when lease all info is grabbed
     */
    useEffect(() => {
        if (doc && doc.agent && doc.agent.fullName) {
            setAgent({
                value: doc.agent.id,
                label: doc.agent.fullName
            });
        }
        /**
         * If we dont have team yet, fetch team as it is used for the agent switch of an agreement
         */
        if (!isRequestingTeam && team.length === 0) {
            dispatch(getAllTeamMembers());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doc]);

    const handleChangedAgent = data => {
        setAgent(data.agent);
        if (setAssignedAgent) {
            setAssignedAgent(data.agent);
        }
        return axios.put(`/api/document/${doc.id}/agent`, { agent: data.agent.value }).then(() => {
            dispatch(changeDocumentAgent());
        });
    };

    return (
        <div className={cx('document-header', className)}>
            {doc && doc.auditTrail && doc.auditTrail.length > 0 && (
                <div className="audit-trail">
                    <AuditTrailOpenButton leaseId={doc.id} showText={false} />
                </div>
            )}
            {doc && isFlowDocument && !hideMoreOptions && (
                <div className="action">
                    <FlowDocumentsMoreOptionBlock agreementStatus={doc.status} leaseType={doc.docType} doc={doc} />
                    <span className="arrow-right" />
                </div>
            )}
            {doc && !isFlowDocument && !hideMoreOptions && (
                <div className="action">
                    <BasicDocumentsMoreOptionBlock agreementStatus={doc.status} leaseType={doc.docType} doc={doc} />
                    <span className="arrow-right" />
                </div>
            )}
            {!hideData && (
                <React.Fragment>
                    <DocumentEditAddressModal
                        parentSelector={parentSelector}
                        isOpen={isOpenEditAddress}
                        closeModal={() => setIsOpenEditAddress(false)}
                        address={address}
                        handleAddressChange={handleAddressChange}
                        setAddressDetails={setAddressDetails}
                    />
                    <div>
                        <div
                            onClick={() => setIsOpenEditAddress(true)}
                            className={`address-info header-container ${disabled ? 'disabled' : ''}`}
                        >
                            <label className="select-label" htmlFor="select-address">
                                <p>
                                    <b>Address:&nbsp;</b>
                                </p>
                            </label>
                            <div className="edit-group">
                                <p className="header-address" data-test="address-header">
                                    {address}
                                </p>
                                {!disabled && <EditButton id="edit-address" />}
                            </div>
                        </div>
                        {addressError && <p className="error-message">{addressError}</p>}
                        {(blockEditAgent || !doc || doc.status !== 'draft') && (
                            <div className="address-info header-container disabled">
                                <label className="select-label" htmlFor="select-address">
                                    <p>
                                        <b>Agent:&nbsp;</b>
                                    </p>
                                </label>
                                <div className="edit-group">
                                    <p className="header-address" data-test="agent-name-header">
                                        {agentName}
                                    </p>
                                </div>
                            </div>
                        )}
                        {!blockEditAgent && doc && doc.status === 'draft' && (
                            <div className="editable">
                                {agent.value && (
                                    <EditableAgentContent
                                        type="input"
                                        agent={agent}
                                        paramName="agent"
                                        editContent={handleChangedAgent}
                                    />
                                )}
                            </div>
                        )}
                        {showIntegration && (
                            <IntegrationConnection
                                integration={integrationObject}
                                agencyIntegrations={agencyIntegrations}
                                isDocument={true}
                                viewOnly={viewOnlyIntegration}
                                linkIntegrationProperty={linkIntegrationProperty}
                                unLinkIntegrationProperty={unLinkIntegrationProperty}
                                doc={doc}
                            />
                        )}
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default DocumentHeader;
