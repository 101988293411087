export const ADDITIONAL_SWIMMINGPOOL_MAINTAIN_TENANT = 'tenant';
export const ADDITIONAL_SWIMMINGPOOL_MAINTAIN_OWNER = 'owner';

export const serviceDefault = {
    name: '',
    mustPay: null, // Boolean - If the tenant must pay for the service
    individuallyMetred: null, // Boolean - If the tenant must pay for the service then is the service individually metred
    costApportionment: '', // If the service is not individually metred then what must the tenant pay for
    directToSupplier: null, // Boolean - If the payment method is direct to the supplier
    howToPay: '', // If the payment method is not direct to the supplier then how must they pay for the service
    locked: false // Locked services cannot be removed
};

export const initState = {
    separatedWaterUsage: null,
    communityScheme: null,
    tenantReceivedCommunityScheme: null,
    allowAnimals: null,
    animals: null,
    swimmingPool: null,
    isFeeIncluded: null,
    isIndemnityProvisionIncluded: null,
    gardeningClause: null,
    isDefenceClauseEnabled: null,
    isLeaseRenewalEnabled: null,
    leaseRenewalDate: null,
    premiseServices: null
};

export const commercialLeaseInitState = {
    optionOfRenewal: '',
    outgoings: '',
    rentReview: ''
};

export const defaultPets = {
    information: '',
    amount: ''
};

export const nswInitState = {
    ...initState,
    communityScheme: 'no',
    isOwnerResponsibleForRepairSmokeAlarms: 'no',
    allowAnimals: 'no',
    animals: {
        pets: []
    },
    swimmingPool: 'no',
    swimingPoolMaintenance: ADDITIONAL_SWIMMINGPOOL_MAINTAIN_TENANT,
    isIndemnityProvisionIncluded: false,
    gardeningClause: 'no',
    isDefenceClauseEnabled: 'no',
    isLeaseRenewalEnabled: 'no',
    leaseRenewalDate: null,
    isGasSupplied: 'no',
    isElectricitySupplied: 'no',
    smokeAlarm: {
        isHardwired: false,
        isBatteryOperated: false,
        hardwired: {
            canReplaceBatteries: 'no',
            batteryType: '9v',
            customBatteryType: ''
        },
        batteryOperated: {
            canReplaceBatteries: 'no',
            batteryType: '9v',
            customBatteryType: ''
        }
    }
};

export const qldInitState = {
    ...initState,
    communityScheme: 'no',
    tenantReceivedCommunityScheme: 'no',
    allowAnimals: 'no',
    animals: {
        pets: []
    },
    premiseServices: {
        premiseServices: [
            {
                ...serviceDefault,
                name: 'Electricity',
                locked: true
            },
            {
                ...serviceDefault,
                name: 'Gas',
                locked: true
            },
            {
                ...serviceDefault,
                name: 'Phone',
                locked: true
            }
        ]
    },
    swimmingPool: 'no',
    isDefenceClauseEnabled: 'no'
};

export const vicInitState = {
    ...initState,
    allowAnimals: 'no',
    animals: {
        pets: []
    },
    swimmingPool: 'no',
    isDefenceClauseEnabled: 'no',
    isPropertyOnTheMarket: 'no',
    isPropertyRepossessedUnderMortgage: 'no',
    isNotTheOwner: 'no'
};
export const actInitState = {
    ...initState,
    allowAnimals: 'no',
    animals: {
        pets: []
    },
    isDefenceClauseEnabled: 'no',
    breakLeaseClause: 'no',
    fairClause: 'no',
    maintenanceClause: 'no',
    swimmingPool: 'no',
    unitOrTownhouse: null
};
