import classnames from 'classnames/bind';
import React, { useCallback, useState } from 'react';
import { NoteProps } from '../../common/components/Note';
import { ACT_STATE, NSW_STATE, NT_STATE, QLD_STATE, SA_STATE, TAS_STATE, VIC_STATE, WA_STATE } from '../../config';
import '../../sass/agreement-signatures-new.scss';
import { DEFAULT } from '../../utils/labelUtils';
import { Signature } from './Signature';

const cx = classnames.bind({
    container: 'agreement-signatures__container',
    list: 'agreement-signatures__list'
});

const States = [QLD_STATE, NSW_STATE, VIC_STATE, ACT_STATE, NT_STATE, SA_STATE, TAS_STATE, WA_STATE, DEFAULT] as const;

type Locations = { [K in (typeof States)[number]]: string };
type Signature = { id: string; base64SignatureImage: string };
type Initial = { tooltip: string } & Signature;

interface SignatureProps {
    id: string;
    initials?: Initial[];
    name: string;
    primary?: boolean;
    signature?: Signature;
    signed?: boolean;
    witness?: boolean;
}

export interface AgreementSignaturesProps extends React.HTMLAttributes<HTMLDivElement> {
    className?: string;
    label?: string;
    leaseType?: string;
    location?: Locations;
    note?: React.ReactElement<NoteProps>;
    signatures: SignatureProps[];
    showWitnessSignature?: boolean;
}

export const AgreementSignatures: React.FC<AgreementSignaturesProps> = ({
    className,
    label,
    leaseType,
    location,
    note,
    signatures,
    showWitnessSignature = false,
    ...divProps
}) => {
    // Always expand the first set of signatures
    const [expand, setExpand] = useState<string[]>(signatures.map(item => item.id));

    const handleExpand = useCallback(
        (id: string) => {
            const index = expand.indexOf(id);
            const updates = expand.concat();

            if (index === -1) {
                updates.push(id);
            } else {
                updates.splice(index, 1);
            }

            setExpand(updates);
        },
        [expand]
    );

    return (
        <div {...divProps} className={cx('container', className)}>
            <h4>Signatures</h4>

            {!!note && note}

            <ul className={cx('list')}>
                {signatures.map((signature, index) => {
                    const shouldShowSignature = !signature.witness || showWitnessSignature;

                    return (
                        shouldShowSignature && (
                            <li key={index}>
                                <Signature
                                    {...signature}
                                    label={label}
                                    location={location}
                                    leaseType={leaseType}
                                    expanded={expand.includes(signature.id)}
                                    onClick={() => handleExpand(signature.id)}
                                />
                            </li>
                        )
                    );
                })}
            </ul>
        </div>
    );
};

AgreementSignatures.defaultProps = {
    className: ''
};
