import React from 'react';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';

import { DOCUMENT_EDIT_MODE, EXCHANGE_OF_CONTRACTS, NSW_STATE } from '@app/config';
import LocationAndAddressSelection from '@app/containers/dashboard/LocationAndAddressSelection';
import { getIsExchangeOfContractsModalOpen } from '@app/selectors/document/exchangeOfContracts';
import { addOrReplaceDocumentInList, setCurrentDocument, setEditMode } from '@app/actions/document';
import { ExchangeOfContracts } from '@app/types/ExchangeOfContracts';
import { GoogleApiAddressDetails } from '@app/types/Common';
import useToast from '@app/hooks/useToast';
import { getErrorMessageForToast } from '@app/utils/errorUtils';
import { ToastTypes } from '@app/common/components/Toast';
import { useHistory } from 'react-router';
import EocModal from '../eoc-modal/EocModal';

import styles from './EocCreate.module.scss';

type FormValues = {
    address: string;
    addressDetails: GoogleApiAddressDetails;
    location: string;
};

type EocCreateProps = {
    close: () => void;
};

const EocCreate: React.FC<EocCreateProps> = ({ close }) => {
    const dispatch = useDispatch();
    const { addNewToast } = useToast();
    const history = useHistory();

    const isExchangeOfContractsModalOpen = useSelector(getIsExchangeOfContractsModalOpen);

    const createEocMutation = useMutation<ExchangeOfContracts, Error, FormValues>(
        async values => {
            const response = await axios.post('/api/document/exchange-of-contracts', values);
            return response.data.doc;
        },
        {
            onError: error => {
                window.rollbar.error('Failed to create exchange of contracts', error);
                addNewToast(getErrorMessageForToast('Could not create the document.'), ToastTypes.ERROR);
            }
        }
    );

    const handleSubmit = async (values: FormValues) => {
        const document = await createEocMutation.mutateAsync(values);

        dispatch(addOrReplaceDocumentInList(document));
        dispatch(setCurrentDocument(document));
        dispatch(setEditMode(DOCUMENT_EDIT_MODE));

        history.push(`/dashboard/document/${document.id}`);
    };

    return (
        <EocModal
            isOpen={isExchangeOfContractsModalOpen}
            title="Create Exchange of Contracts"
            closeModal={close}
            allowOverflow
            className={styles.modal}
        >
            <Form<FormValues> onSubmit={handleSubmit} initialValues={{ location: NSW_STATE }}>
                {({ handleSubmit, values, form }) => (
                    <LocationAndAddressSelection
                        docType={EXCHANGE_OF_CONTRACTS}
                        location={values.location}
                        address={values.address}
                        handleAddressChange={(address?: string) => form.change('address', address)}
                        setAddressDetails={(address: string, details: GoogleApiAddressDetails) =>
                            form.batch(() => {
                                form.change('address', address);
                                form.change('addressDetails', details);
                            })
                        }
                        closeModal={close}
                        confirmDetails={handleSubmit}
                        isCreating={createEocMutation.isLoading}
                        hideIntegration
                    />
                )}
            </Form>
        </EocModal>
    );
};

export { EocCreate };
