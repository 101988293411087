import React from 'react';
import cx from 'classnames';
import Button from '@app/common/components/Button';
import { CompletionProgress as CompletionProgressType } from '@app/types/CompletionProgress';
import CompletionProgress from '@app/components/dashboard/InfoAgreementComponents/footerComponents/CompletionProgress';

import styles from './AwaitingCompletionFooter.module.scss';

interface AwaitingCompletionFooterProps {
    completeFormId: string;
    completionProgress?: CompletionProgressType;
    isCompletionInProgress?: boolean;
    isBusy?: boolean;
}

export const AwaitingCompletionFooter: React.FC<AwaitingCompletionFooterProps> = ({
    completeFormId,
    completionProgress,
    isCompletionInProgress,
    isBusy
}) => {
    return (
        <div className={styles.footer}>
            {completionProgress && <CompletionProgress progress={completionProgress} className={styles.completion} />}
            {!isCompletionInProgress && (
                <Button
                    className={cx(styles.button, styles.completeButton)}
                    type="submit"
                    primary
                    formId={completeFormId}
                    disabled={isBusy}
                >
                    Complete exchange
                </Button>
            )}
        </div>
    );
};

export default AwaitingCompletionFooter;
