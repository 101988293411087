import React from 'react';
import ReactTooltip from 'react-tooltip';
import cx from 'classnames';
import styles from './Tooltip.module.scss';

export enum TooltipVariant {
    DARK = 'dark',
    LIGHT = 'light'
}

export enum TooltipSize {
    SMALL = 'small',
    LARGE = 'large'
}

type TooltipProps = {
    place?: 'top' | 'right' | 'bottom' | 'left';
    className?: string;
    html?: boolean;
    effect?: 'solid' | 'float';
    id?: string;
    backgroundColor?: string;
    hideArrow?: boolean;
    clickable?: boolean;
    children?: React.ReactNode;
    variant?: TooltipVariant;
    size?: TooltipSize;
};

// We can't style the tooltip arrow with CSS, so instead we pass in this background colour to the <ReactTooltip />
// component, which colours the tooltip arrow for us
const DARK_BACKGROUND_COLOR = '#3E3E3E';
const LIGHT_BACKGROUND_COLOR = '#FFFFFF';

const getBackgroundColor = (variant: TooltipVariant, backgroundColor?: string) => {
    if (backgroundColor) {
        return backgroundColor;
    }

    if (variant === TooltipVariant.DARK) {
        return DARK_BACKGROUND_COLOR;
    }

    return LIGHT_BACKGROUND_COLOR;
};

const Tooltip: React.FunctionComponent<TooltipProps> = ({
    place = 'right',
    className,
    html,
    effect = 'float',
    id,
    backgroundColor,
    hideArrow,
    clickable,
    children,
    variant = TooltipVariant.DARK,
    size = TooltipSize.SMALL
}) => {
    return (
        <ReactTooltip
            effect={effect}
            place={place}
            className={cx(styles.tooltip, className, {
                [styles.hideArrow]: hideArrow,
                [styles.dark]: variant === TooltipVariant.DARK,
                [styles.light]: variant === TooltipVariant.LIGHT,
                [styles.small]: size === TooltipSize.SMALL,
                [styles.large]: size === TooltipSize.LARGE
            })}
            backgroundColor={getBackgroundColor(variant, backgroundColor)}
            html={html}
            id={id}
            clickable={clickable}
            delayHide={clickable ? 1000 : 50}
            globalEventOff="wheel"
        >
            {children}
        </ReactTooltip>
    );
};

export default Tooltip;
