import React, { memo } from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import {
    LEASE_TYPE_RESIDENTIAL,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT,
    LEASE_TYPE_SALES,
    LEASE_TYPE_COMMERCIAL_LEASE,
    LEASE_TYPE_HOLIDAY_LETTING,
    DOCUMENT_RENT_REDUCTION,
    DOCUMENT_TERMINATION_NOTICE,
    DOCUMENT_RENT_INCREASE,
    DOCUMENT_CUSTOM,
    DOCUMENT_INTENTION_TO_SELL,
    DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE,
    DISCLOSURE_DOCUMENT,
    DOCUMENT_CREATE_A_FLK,
    BREACH_NOTICE,
    ENTRY_NOTICE,
    SUB_LEASE_TYPE_SA_RENEWALS,
    FLK_A_PDF_DISPLAY,
    CREATE_A_FLK_DISPLAY,
    NSW_STATE,
    EXCHANGE_OF_CONTRACTS
} from '../../config';

import '../../sass/modalCreateNewAgreement.scss';
import { PictureAsPdfSharp } from '@flk-mui-icons';
import TrendingUpIcon from '@flk-mui-icons/TrendingUpSharp';
import TrendingDownIcon from '@flk-mui-icons/TrendingDownSharp';
import DescriptionSharpIcon from '@flk-mui-icons/DescriptionSharp';
import AnnouncementSharpIcon from '@flk-mui-icons/AnnouncementSharp';
import RefreshSharpIcon from '@flk-mui-icons/RefreshSharp';
import CommercialLeaseIcon from '@flk-mui-icons/BusinessSharp';
import { useAccountProvider } from '../providers/AccountProvider';
import { isAgencyUserAccount } from '../../utils/userUtils';
import { getAgencyDefaultLocation } from '../../selectors/settings/account';
import useOpenCreationModal from '../../hooks/useOpenCreationModal';
import Icon, { Icons } from '../../common/components/Icon';
import { openSubapp } from '../../utils/openSubapp';
import {
    HOLIDAY_LETTING_SUBAPP_PATH,
    PM_SUBAPP_PATH,
    COMMERCIAL_PM_SUBAPP_PATH,
    SALES_SUBAPP_PATH
} from '../../constants/constants';

const ModalCreateNewAgreement = ({ isOpen, close }) => {
    const accountType = useAccountProvider();
    const location = useSelector(getAgencyDefaultLocation);

    const afterOpenCreationModal = () => close();

    const {
        openLeaseAgreementCreationModal,
        openDocumentCreationModal,
        openFlkAKeyCreationModal,
        isLeaseTypeEnabled,
        isDagobahAgreement,
        isActiveUser,
        handleOpenSubLeaseAgreementForm
    } = useOpenCreationModal(afterOpenCreationModal);

    const getListItemClassName = desktopOnly => {
        return `${desktopOnly ? 'desktop-only' : ''} ${isActiveUser() ? '' : 'disabled'}`;
    };

    return (
        <Modal
            parentSelector={() => document.getElementById('main-layout')}
            ariaHideApp={false}
            isOpen={isOpen}
            style={customStyle}
            className={'modal-create-new-agreement'}
            contentLabel="Modal"
            onRequestClose={close}
            bodyOpenClassName="scroll-locked"
        >
            <div className="create-new-agreement">
                {isAgencyUserAccount(accountType) && (
                    <React.Fragment>
                        <h2>Create new agreement</h2>
                        <ul>
                            {isLeaseTypeEnabled(LEASE_TYPE_RESIDENTIAL) && (
                                <li
                                    onClick={() => openLeaseAgreementCreationModal(LEASE_TYPE_RESIDENTIAL)}
                                    className={getListItemClassName(true)}
                                >
                                    <i className="icon icon-tenancy" />
                                    Residential Tenancy
                                </li>
                            )}
                            {isLeaseTypeEnabled(LEASE_TYPE_SALES) && (
                                <li
                                    onClick={() =>
                                        location === NSW_STATE
                                            ? openSubapp(SALES_SUBAPP_PATH)
                                            : openLeaseAgreementCreationModal(LEASE_TYPE_SALES)
                                    }
                                    className={getListItemClassName(true)}
                                >
                                    <i className="icon icon-sales" />
                                    Sales
                                </li>
                            )}
                            {isLeaseTypeEnabled(EXCHANGE_OF_CONTRACTS) && (
                                <li
                                    onClick={() => openDocumentCreationModal(EXCHANGE_OF_CONTRACTS)}
                                    className={getListItemClassName(false)}
                                >
                                    <Icon icon={Icons.EXCHANGE_OF_CONTRACTS} className="icon" />
                                    Exchange of Contracts
                                </li>
                            )}
                            {isLeaseTypeEnabled(LEASE_TYPE_PROPERTY_MANAGEMENT) && (
                                <li
                                    onClick={() =>
                                        isDagobahAgreement(LEASE_TYPE_PROPERTY_MANAGEMENT, location)
                                            ? openSubapp(PM_SUBAPP_PATH)
                                            : openLeaseAgreementCreationModal(LEASE_TYPE_PROPERTY_MANAGEMENT)
                                    }
                                    className={getListItemClassName(false)}
                                >
                                    <i className="icon icon-property" />
                                    Property Management
                                </li>
                            )}
                            {isLeaseTypeEnabled(LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT) && (
                                <li
                                    onClick={() => openSubapp(COMMERCIAL_PM_SUBAPP_PATH)}
                                    className={getListItemClassName(true)}
                                >
                                    <i className="icon icon-property" />
                                    Commercial Property Management
                                </li>
                            )}
                            {isLeaseTypeEnabled(LEASE_TYPE_COMMERCIAL_LEASE) && (
                                <li
                                    onClick={() => openLeaseAgreementCreationModal(LEASE_TYPE_COMMERCIAL_LEASE)}
                                    className={getListItemClassName(true)}
                                >
                                    <CommercialLeaseIcon className="icon" />
                                    Commercial lease
                                </li>
                            )}
                            {isLeaseTypeEnabled(LEASE_TYPE_HOLIDAY_LETTING) && (
                                <li
                                    onClick={() => openSubapp(HOLIDAY_LETTING_SUBAPP_PATH)}
                                    className={getListItemClassName(true)}
                                >
                                    <Icon icon={Icons.HOLIDAY_HOUSE} className="icon" />
                                    Holiday letting
                                </li>
                            )}
                        </ul>
                    </React.Fragment>
                )}
                <h2>Create new document</h2>
                {isAgencyUserAccount(accountType) && (
                    <ul>
                        <li
                            onClick={() => openDocumentCreationModal(DOCUMENT_RENT_REDUCTION)}
                            className={getListItemClassName(true)}
                        >
                            <TrendingDownIcon className="icon" />
                            Rent relief agreement
                        </li>
                        {isLeaseTypeEnabled(DOCUMENT_TERMINATION_NOTICE) && (
                            <li
                                onClick={() => openDocumentCreationModal(DOCUMENT_TERMINATION_NOTICE)}
                                className={getListItemClassName(true)}
                            >
                                <i className="icon icon-termination-notice" />
                                Termination notice
                            </li>
                        )}
                        {isLeaseTypeEnabled(DOCUMENT_RENT_INCREASE) && (
                            <li
                                onClick={() => openDocumentCreationModal(DOCUMENT_RENT_INCREASE)}
                                className={getListItemClassName(true)}
                            >
                                <TrendingUpIcon className="icon" />
                                Rent increase notice
                            </li>
                        )}
                        {isLeaseTypeEnabled(BREACH_NOTICE) && (
                            <li
                                onClick={() => openDocumentCreationModal(BREACH_NOTICE)}
                                className={getListItemClassName(true)}
                            >
                                <AnnouncementSharpIcon className="icon" />
                                Breach Notice
                            </li>
                        )}

                        {isLeaseTypeEnabled(ENTRY_NOTICE) && (
                            <li
                                onClick={() => openDocumentCreationModal(ENTRY_NOTICE)}
                                className={getListItemClassName(true)}
                            >
                                <Icon icon={Icons.ENTRY_DOOR} className="icon" />
                                Entry Notice
                            </li>
                        )}
                        {isLeaseTypeEnabled(DOCUMENT_INTENTION_TO_SELL) && (
                            <li
                                onClick={() => openDocumentCreationModal(DOCUMENT_INTENTION_TO_SELL)}
                                className={getListItemClassName(true)}
                            >
                                <Icon icon={Icons.MEGAPHONE} className="icon" />
                                Intention to sell notice
                            </li>
                        )}
                        {isLeaseTypeEnabled(DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE) && (
                            <li
                                onClick={() => openDocumentCreationModal(DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE)}
                                className={getListItemClassName(true)}
                            >
                                <Icon icon={Icons.ESP} className="icon" />
                                Estimated selling price notice
                            </li>
                        )}
                        {isLeaseTypeEnabled(DISCLOSURE_DOCUMENT) && (
                            <li
                                onClick={() => openDocumentCreationModal(DISCLOSURE_DOCUMENT)}
                                className={getListItemClassName(true)}
                            >
                                <i className="icon-disclosure_document" />
                                Disclosure document - VIC
                            </li>
                        )}
                        {isLeaseTypeEnabled(SUB_LEASE_TYPE_SA_RENEWALS) && (
                            <li
                                onClick={() => handleOpenSubLeaseAgreementForm(SUB_LEASE_TYPE_SA_RENEWALS)}
                                className={getListItemClassName(true)}
                            >
                                <RefreshSharpIcon className="icon" />
                                Lease extension - SA
                            </li>
                        )}
                    </ul>
                )}
                <ul>
                    {isLeaseTypeEnabled(DOCUMENT_CUSTOM) && (
                        <li
                            onClick={() => openDocumentCreationModal(DOCUMENT_CUSTOM)}
                            className={getListItemClassName(false)}
                        >
                            <PictureAsPdfSharp className="icon" />
                            {FLK_A_PDF_DISPLAY}
                        </li>
                    )}
                    {isLeaseTypeEnabled(DOCUMENT_CREATE_A_FLK) && (
                        <li
                            onClick={() => openDocumentCreationModal(DOCUMENT_CREATE_A_FLK)}
                            className={getListItemClassName(true)}
                        >
                            <DescriptionSharpIcon className="icon" />
                            {CREATE_A_FLK_DISPLAY}
                        </li>
                    )}
                </ul>
                {isAgencyUserAccount(accountType) && (
                    <div className="desktop-only">
                        <h2>FLK a Key (Free trial)</h2>
                        <ul>
                            <li className="" onClick={() => openFlkAKeyCreationModal()}>
                                <div className="icon">
                                    <span className="key-icon key-icon-blue"></span>
                                </div>
                                Check out a Key
                            </li>
                        </ul>
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default memo(ModalCreateNewAgreement);

const customStyle = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.67)',
        zIndex: 5
    }
};
