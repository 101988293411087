import React from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import Button from '@app/common/components/Button';
import { confirmAlert } from 'react-confirm-alert';
import { cancelSigningDocumentAction } from '@app/actions/document';
import ConfirmAlert from '../../../FlkAPdf/ConfirmAlert';

import styles from './SentForSigningFooter.module.scss';

interface SentForSigningFooterProps {
    documentId: string;
    setIsResendModalOpen: (isOpen: boolean) => void;
    isBusy?: boolean;
}

export const SentForSigningFooter: React.FC<SentForSigningFooterProps> = ({
    documentId,
    setIsResendModalOpen,
    isBusy = false
}) => {
    const dispatch = useDispatch();

    const moveToDrafts = () => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <ConfirmAlert
                    message="Are you sure want to cancel? All progress and signatures will be lost."
                    confirmText="Yes"
                    cancelText="No"
                    onConfirm={async () => {
                        dispatch(cancelSigningDocumentAction(documentId));
                        onClose();
                    }}
                    onCancel={onClose}
                    onClose={onClose}
                />
            )
        });
    };

    return (
        <div className={styles.footer}>
            <Button
                className={cx(styles.button, styles.draftsButton)}
                type="button"
                onClick={moveToDrafts}
                secondary
                disabled={isBusy}
            >
                Move to draft
            </Button>
            <Button
                className={cx(styles.button, styles.resendButton)}
                type="button"
                onClick={() => {
                    setIsResendModalOpen(true);
                }}
                primary
                disabled={isBusy}
            >
                Resend signing link
            </Button>
        </div>
    );
};

export default SentForSigningFooter;
