import React from 'react';
import { useSelector } from 'react-redux';
import { AgreementSignatures, AgreementSignaturesProps } from '../../../components/agreementSignatures';
import { getSignatures } from '../../../selectors/document/documentSignatures';

type DocumentSignaturesProps = Omit<AgreementSignaturesProps, 'signatures' | 'showWitnessSignature'>;

const DocumentSignatures: React.FC<DocumentSignaturesProps> = props => {
    const signatures = useSelector(getSignatures);

    return <AgreementSignatures signatures={signatures} {...props} />;
};

export default DocumentSignatures;
