import React from 'react';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import cx from 'classnames';

import Card, { CardShadow, CardStyles } from '@app/common/components/cards/Card';
import CustomPlaceholderInputs from '../../../FlkAPdf/CustomPlaceholderInputs';
import { ExchangeOfContracts } from '@app/types/ExchangeOfContracts';

import styles from './CustomPlaceholdersSummary.module.scss';
import { Form } from 'react-final-form';

type CustomPlaceholdersSummaryProps = {
    className?: string;
    recipientLabelLowercase?: string;
    customPlaceholders: ExchangeOfContracts['customPlaceholders'];
};

const CustomPlaceholdersSummary: React.FC<CustomPlaceholdersSummaryProps> = ({
    className,
    recipientLabelLowercase,
    customPlaceholders
}) => {
    const inputToFocusRef = React.useRef<HTMLInputElement>(null);

    return (
        <Form
            initialValues={{ customPlaceholders: customPlaceholders }}
            onSubmit={() => undefined}
            mutators={{ ...arrayMutators }}
        >
            {() => (
                <Card
                    style={CardStyles.SQUARE}
                    shadow={CardShadow.SMALL}
                    className={cx(styles.container, className)}
                    animate={false}
                >
                    <FieldArray name={'customPlaceholders'}>
                        {({ fields }) =>
                            !!fields.length && (
                                <CustomPlaceholderInputs
                                    disabled
                                    inputToFocusRef={inputToFocusRef}
                                    recipientLabelLowercase={recipientLabelLowercase}
                                />
                            )
                        }
                    </FieldArray>
                </Card>
            )}
        </Form>
    );
};

export default CustomPlaceholdersSummary;
