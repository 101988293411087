export const STATUS_CODE_TOO_MANY_REQUEST = 429;
export const STATUS_CODE_UNAUTHORIZED = 401;

export const EXECUTION_TYPE_EMBEDDED_SIGNATURE = 'Embedded signatures';
export const EXECUTION_TYPE_ACKNOWLEDGEMENT_PAGE = 'Acknowledgement page';

export const ACKNOWLEDGE_CONFIRMATION_TYPE = 'acknowledge';
export const SIGN_CONFIRMATION_TYPE = 'sign';

export enum ConfirmationType {
    ACKNOWLEDGE = ACKNOWLEDGE_CONFIRMATION_TYPE,
    SIGN = SIGN_CONFIRMATION_TYPE
}

export enum AccountType {
    GENERAL_ACCOUNT_TYPE = 'general_account_type',
    AGENCY_ACCOUNT_TYPE = 'agency_account_type'
}

export const GENERAL_ACCOUNT_TYPE = AccountType.GENERAL_ACCOUNT_TYPE;
export const AGENCY_ACCOUNT_TYPE = AccountType.AGENCY_ACCOUNT_TYPE;

export const AUSTRALIA = 'Australia';

export const REFRESH_TOKEN_EXPIRED = 'Refresh user token expired';
export const AUTHENTICATION_FAILED = 'Authentication failed';
export const MFA_AUTHENTICATION_FAILED = 'The verification code is incorrect';
export const MFA_CODE_EXPIRE_MESSAGE = 'The verification code is expired';
export const VERIFICATION_CODE_REQUIRED_MESSAGE = 'The verification code is required';

export const BULK_SEND_APPROVAL_STATUS_PENDING = 'pending';
export const BULK_SEND_APPROVAL_STATUS_DECLINED = 'declined';
export const BULK_SEND_APPROVAL_STATUS_APPROVED = 'approved';

export const SEARCH_RESULT_DOCUMENT = 'DOCUMENT';
export const SEARCH_RESULT_TEMPLATE = 'TEMPLATE';
export const CLONE_TEMPLATE = 'CLONE_TEMPLATE';

export const PDF_GEN_COMPLETE = 'PDF_GEN_COMPLETE';
export const PDF_GEN_PENDING = 'PDF_GEN_PENDING';
export const PDF_GEN_PROCESSING = 'PDF_GEN_PROCESSING';
export const PDF_GEN_FAILED = 'PDF_GEN_FAILED';

export const HARNESS_IO_ANON_USER = 'anon-user';

export const ALL = 'all';
export const DOCUMENTS = 'documents';
export const FLK_A_KEY = 'flk_a_key';
export const DISCLAIMER_TEXT = {
    AU: {
        REAL_ESTATE: [
            '*You will be asked to add your payment details close to the end of your subscription. All prices shown are in AUD including GST.',
            '†FLK offers a suite of real estate documents for NSW, QLD, VIC, SA, and ACT. Contact our sales team for a more detailed discussion on what documents are available for your region.'
        ],
        BUSINESS:
            '*You will be asked to add your payment details close to the end of your subscription. All prices shown are in AUD including GST. Cancel anytime and keep your documents for life. Paid plans can send documents over their monthly limit for an additional $2.20 AUD each.'
    },
    NZ: {
        BUSINESS:
            '*You will be asked to add your payment details close to the end of your trial. All prices shown are in NZD (no GST applicable). Cancel anytime and keep your documents for life. †Paid plans can send documents over their monthly limit for an additional $2.60 NZD each.'
    },
    DEFAULT:
        '*You will be asked to add your payment details close to the end of your trial. Cancel anytime and keep your documents for life.'
};

export const ONBOARDING_TRACKING_PREFIX = 'uad-onboarding';

export const LANDING = 'landing';
export const BUSINESS = 'business';
export const PUBLIC_URL = 'https://flkitover.com/';
export const PUBLIC_URL_REAL_ESTATE = 'https://flkitover.com/real-estate/';
export const PUBLIC_URL_BUSINESS = 'https://flkitover.com/business/';

export const EMAIL_LIST_TYPES: Record<string, string> = {
    toEmail: 'toNotificationList',
    ccEmail: 'ccNotificationList',
    bccEmail: 'bccNotificationList'
};

export const ANNOTATION_SUBJECT_FREE_TEXT = 'Free Text';
export const ANNOTATION_FREE_TEXT_DEFAULT_VALUE = 'Add text here';
export const ANNOTATION_SUBJECT_CLIENT = 'client';
export const ANNOTATION_SUBJECT_CLIENT_NAME = 'name';
export const ANNOTATION_SUBJECT_AGENT = 'agent';
export const ANNOTATION_SUBJECT_DATE = 'date';
export const ANNOTATION_SUBJECT_AGENT_NAME = 'Agent name';
export const ANNOTATION_SUBJECT_SENDER_NAME = 'Sender name';
export const ANNOTATION_SUBJECT_COMPLETION_DATE = 'Completion date';
export const ANNOTATION_SUBJECT_CUSTOM_PLACEHOLDER = 'Custom Placeholder';

export const ANNOTATION_CONTENT_AGENT_NAME = 'Agent name';
export const ANNOTATION_CONTENT_SENDER_NAME = 'Sender name';
export const ANNOTATION_CONTENT_COMPLETION_DATE = 'Completion date';
export const ANNOTATION_CONTENT_COMPLETION_DATE_EOC = 'Contract date';

export const HOLIDAY_LETTING_SUBAPP_PATH = '/apps/holiday-letting';
export const HOLIDAY_LETTING_TEMPLATE_SUBAPP_PATH = '/apps/holiday-letting/template';
export const HOLIDAY_LETTING_EDIT_NSW_TEMPLATE_SUBAPP_PATH = '/apps/holiday-letting/template/nsw';

export const SALES_SUBAPP_PATH = '/apps/sales';
export const SALES_TEMPLATE_SUBAPP_PATH = '/apps/sales/template';
export const SALES_EDIT_NSW_TEMPLATE_SUBAPP_PATH = '/apps/sales/template/nsw';

export const PM_SUBAPP_PATH = '/apps/property-management';
export const PM_TEMPLATE_SUBAPP_PATH = '/apps/property-management/template';
export const PM_EDIT_NSW_TEMPLATE_SUBAPP_PATH = '/apps/property-management/template/nsw';
export const PM_EDIT_QLD_TEMPLATE_SUBAPP_PATH = '/apps/property-management/template/qld';

export const COMMERCIAL_PM_SUBAPP_PATH = '/apps/commercial-property-management';
export const COMMERCIAL_PM_TEMPLATE_SUBAPP_PATH = '/apps/commercial-property-management/template';
export const COMMERCIAL_PM_EDIT_NSW_TEMPLATE_SUBAPP_PATH = '/apps/commercial-property-management/template/nsw';

export const DOCUMENT_API_PATH = '/api/document';
export const UPLOAD_A_DOC_API_PATH = '/api/document/custom-document';
export const UPLOAD_A_DOC_ONBOARDING_API_PATH = '/api/onboarding/custom-document';

export const EXCHANGE_OF_CONTRACTS_API_PATH = '/api/document/exchange-of-contracts';

export const BUSINESS_MOBILE_LANDING_PATH = '/business-mobile-landing';

export const UAD_MOBILE_WIDTH = 600;
export enum IntegrationType {
    PROPERTY_TREE = 'propertyTree'
}

export enum EmailOwnerCopySendDestination {
    AGENT = 'agent',
    AGENT_AND_LANDLORD = 'agent_and_landlord'
}

export enum AvailableCountries {
    AU = 'AU',
    NZ = 'NZ'
}

export const HARNESS_TARGET_DATA = 'harnessTargetData';
export const DEFAULT_ROOM_COUNT = 4;
export const BATHROOM_COUNT = 5;

export const REAPIT_CONSOLE = 'reapitConsole';
export const VAULT_RE = 'vaultRe';

export const enum integrationType {
    PROPERTY = 'property',
    CONTACT = 'contact'
}

export const enum integration {
    INTEGRATION_PROPERTY_ME = 'propertyMe',
    INTEGRATION_PROPERTY_TREE = 'propertyTree',
    INTEGRATION_MANAGED = 'managed',
    INTEGRATION_VAULT_RE = 'vaultRe',
    INTEGRATION_REAPIT_CONSOLE = 'reapitConsole'
}

export const INTEGRATION_LABEL = {
    [integration.INTEGRATION_REAPIT_CONSOLE]: 'Console Integration',
    [integration.INTEGRATION_VAULT_RE]: 'VaultRE Integration',
    [integration.INTEGRATION_PROPERTY_ME]: 'PropertyMe Integration',
    [integration.INTEGRATION_PROPERTY_TREE]: 'PropertyTree Integration',
    [integration.INTEGRATION_MANAGED]: 'Managed Integration'
} as const;

export enum UploadStatus {
    DEFAULT = 'default',
    FAILED = 'failed',
    SUCCESS = 'success',
    UPLOADING = 'uploading'
}
