import { QLD_STATE, NSW_STATE, BREACH_NOTICE, DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE } from '../config';
import {
    QLD_ENTRY_NOTICE,
    NSW_HOLIDAY_LETTING,
    NSW_INTENTION_TO_SELL,
    NSW_ENTRY_NOTICE,
    QLD_BREACH_NOTICE,
    NSW_ESTIMATED_SELLING_PRICE_NOTICE,
    NSW_COMMERCIAL_PROPERTY_MANAGEMENT,
    EXCHANGE_OF_CONTRACTS_V1
} from '../constants/featureFlags';
import {
    ENTRY_NOTICE,
    LEASE_TYPE_HOLIDAY_LETTING,
    DOCUMENT_INTENTION_TO_SELL,
    LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT,
    EXCHANGE_OF_CONTRACTS
} from '../config';

export const ALL_AGREEMENT_FEATURE_FLAGS = new Map<string, string>([
    [`${ENTRY_NOTICE}${QLD_STATE}`, QLD_ENTRY_NOTICE],
    [`${ENTRY_NOTICE}${NSW_STATE}`, NSW_ENTRY_NOTICE],
    [`${LEASE_TYPE_HOLIDAY_LETTING}${NSW_STATE}`, NSW_HOLIDAY_LETTING],
    [`${DOCUMENT_INTENTION_TO_SELL}${NSW_STATE}`, NSW_INTENTION_TO_SELL],
    [`${BREACH_NOTICE}${QLD_STATE}`, QLD_BREACH_NOTICE],
    [`${DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE}${NSW_STATE}`, NSW_ESTIMATED_SELLING_PRICE_NOTICE],
    [`${LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT}${NSW_STATE}`, NSW_COMMERCIAL_PROPERTY_MANAGEMENT],
    [`${EXCHANGE_OF_CONTRACTS}${NSW_STATE}`, EXCHANGE_OF_CONTRACTS_V1]
]);
export const ALL_AGREEMENT_FEATURE_FLAGS_LIST = Array.from(ALL_AGREEMENT_FEATURE_FLAGS.values());
