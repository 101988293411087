import React from 'react';
import useComponentId from '@app/hooks/useComponentId';

import styles from './SectionHeader.module.scss';
import Icon, { Icons } from '@app/common/components/Icon';
import Tooltip from '@app/common/components/tooltips/Tooltip';

type SectionHeaderProps = {
    title: string;
    subtitle?: string;
    action?: React.ReactNode;
    tooltip?: string;
};

const SectionHeader: React.FC<SectionHeaderProps> = ({ title, subtitle, action, tooltip }) => {
    const componentId = useComponentId().toString();

    return (
        <div className={styles.container}>
            <div className={styles.text}>
                <div className={styles.titleContainer}>
                    <h2 className={styles.title}>{title}</h2>
                    {tooltip && (
                        <>
                            <Icon className={styles.icon} icon={Icons.HELP} data-tip data-for={componentId} />
                            <Tooltip id={componentId} effect="solid" place="top">
                                {tooltip}
                            </Tooltip>
                        </>
                    )}
                </div>
                {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
            </div>
            {action && <div className={styles.action}>{action}</div>}
        </div>
    );
};

export { SectionHeader };
