import React, { useEffect, useState, memo } from 'react';
import { getId } from '@app/utils/getId';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash';
import ModalDialog from './../../common/components/ModalDialog';
import '../../sass/modals/auditTrailModal.scss';
import '../../sass/dashboardContentTable.scss';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { closeAuditTrailModal } from '../../actions/dashboard';
import SubjectIcon from '@flk-mui-icons/SubjectSharp';
import PersonIcon from '@flk-mui-icons/PersonSharp';
import { Table, Tr, Td, Thead, Th } from 'reactable';
import {
    isLeaseTypeSales,
    isLeaseTypePm,
    isLeaseTypeResidentialTenancy,
    isLeaseTypeCommercialLease
} from '../../utils/agreementUtils';
import { formatDateTime, getAgencyTimezoneFromAgency, getAgencyTimezoneFromUser } from '../../utils/dateUtils';
import { getUserInfo } from '../../selectors/user';
import { isAuditTrailModalOpen, isAuditTrailDocument } from '../../selectors/dashboard';
import { getCurrentDocument } from '../../selectors/document';
import { isDocumentCustom, isDocumentDisclosure } from '../../utils/documentUtils';
import { getAgreementInfo, getAuditTrail } from '../../selectors/dashboard/agreementInfo';
import { isBusinessUserAccount } from '../../utils/userUtils';
import { getTimezoneOffset } from 'date-fns-tz';

const AuditTrailModal = () => {
    const dispatch = useDispatch();
    const loggedInUser = useSelector(getUserInfo);
    const isOpen = useSelector(isAuditTrailModalOpen);
    const isDocument = useSelector(isAuditTrailDocument);
    const currentDocument = useSelector(getCurrentDocument);
    const reduxAuditTrail = useSelector(getAuditTrail);
    const agreementInfo = useSelector(getAgreementInfo);

    const [usersAuditTrail, setUsersAuditTrail] = useState(new Map());
    const [signors, setSignors] = useState([]);
    const [isUserDifferentTimezone, setIsUserDifferentTimezone] = useState(false);
    let landlords = [];

    const closeModal = () => {
        dispatch(closeAuditTrailModal());
    };

    /**
     * This effect is only fired for Document type agreements
     */
    useEffect(() => {
        if (currentDocument) {
            usersAuditTrail.clear();
            //Set if the timezones are different for user and office
            if (
                getTimezoneOffset(loggedInUser.timezone) !==
                getTimezoneOffset(loggedInUser.agency?.details?.officeTimezone)
            ) {
                setIsUserDifferentTimezone(true);
            }

            const details = currentDocument.details;
            const client = currentDocument.client;
            let signorsForDocument = [];

            if (details && details.tenants) {
                signorsForDocument = details.tenants.map(item => {
                    return getId(item);
                });
            }

            if (client && client.clients) {
                signorsForDocument = client.clients.map(item => {
                    return getId(item);
                });
            }

            if (isDocumentDisclosure(currentDocument.docType)) {
                if (currentDocument.landlord && currentDocument.landlord.persons) {
                    signorsForDocument = currentDocument.landlord.persons.map(item => {
                        return getId(item);
                    });
                }
            }

            if (isDocumentCustom(currentDocument.docType)) {
                if (currentDocument.client && currentDocument.client.clients) {
                    signorsForDocument = currentDocument.client.clients.map(item => {
                        return getId(item);
                    });
                }
            }

            if (currentDocument.auditTrail) {
                cloneDeep(currentDocument.auditTrail).forEach(auditTrailItem => {
                    if (usersAuditTrail.get('ALL')) {
                        const auditItemAll = usersAuditTrail.get('ALL');
                        auditItemAll.push(auditTrailItem);
                    } else {
                        usersAuditTrail.set('ALL', [auditTrailItem]);
                    }

                    const userName = auditTrailItem.userName;
                    //Check that the audit item is from one of the signors
                    if (signorsForDocument.includes(auditTrailItem.createdBy)) {
                        if (userName) {
                            if (usersAuditTrail.get(userName)) {
                                const auditItem = usersAuditTrail.get(userName);
                                if (auditTrailItem.message.includes('has sent the agreement back')) {
                                    auditTrailItem.className = 'audit-trail-sent-back';
                                }
                                auditItem.push(auditTrailItem);
                            } else {
                                usersAuditTrail.set(userName, [auditTrailItem]);
                            }
                        }
                    }
                });
            }
            setUsersAuditTrail(usersAuditTrail);
        }
    }, [currentDocument]);

    //Onload
    useEffect(() => {
        if (!isDocument) {
            const leaseType = agreementInfo.leaseType;
            const { tenant, signatory, pmLandlord, landlord, lessee, guarantor } = agreementInfo;

            if (leaseType) {
                if (isLeaseTypeResidentialTenancy(leaseType)) {
                    if (tenant && tenant.tenants) {
                        setSignors(
                            agreementInfo.tenant.tenants.map(item => {
                                return item.id;
                            })
                        );
                    }
                    if (landlord && landlord.persons) {
                        landlords = agreementInfo.landlord.persons.map(item => {
                            return item.id;
                        });
                    }
                } else if (isLeaseTypeSales(leaseType)) {
                    if (signatory && signatory.signatories) {
                        setSignors(
                            agreementInfo.signatory.signatories.map(item => {
                                return item.id;
                            })
                        );
                    }
                } else if (isLeaseTypePm(leaseType)) {
                    if (pmLandlord && pmLandlord.persons) {
                        setSignors(
                            agreementInfo.pmLandlord.persons.map(item => {
                                return item.id;
                            })
                        );
                    }
                } else if (isLeaseTypeCommercialLease(leaseType)) {
                    let signorsList = [];
                    if (lessee && lessee.persons) {
                        signorsList = agreementInfo.lessee.persons.map(item => {
                            return item.id;
                        });
                    }
                    if (guarantor && !guarantor.noGuarantor && guarantor.persons.length > 0) {
                        signorsList = signorsList.concat(
                            agreementInfo.guarantor.persons.map(item => {
                                return item.id;
                            })
                        );
                    }
                    setSignors(signorsList);
                }
            }
        }
    }, [agreementInfo]);

    useEffect(() => {
        if (!isDocument) {
            usersAuditTrail.clear();
        }
        if (reduxAuditTrail && !isDocument) {
            cloneDeep(reduxAuditTrail).forEach(auditTrailItem => {
                if (usersAuditTrail.get('ALL')) {
                    const auditItemAll = usersAuditTrail.get('ALL');
                    auditItemAll.push(auditTrailItem);
                } else {
                    usersAuditTrail.set('ALL', [auditTrailItem]);
                }
                const userName = auditTrailItem.userName;

                //Check that the audit item is from one of the signors
                if (
                    signors.includes(auditTrailItem.createdBy) ||
                    signors.includes(auditTrailItem.userId) ||
                    (isLeaseTypeResidentialTenancy(agreementInfo.leaseType) &&
                        landlords.includes(auditTrailItem.createdBy))
                ) {
                    if (userName) {
                        if (usersAuditTrail.get(userName)) {
                            const auditItem = usersAuditTrail.get(userName);
                            if (auditTrailItem.message.includes('has sent the agreement back')) {
                                auditTrailItem.className = 'audit-trail-sent-back';
                            }
                            auditItem.push(auditTrailItem);
                        } else {
                            usersAuditTrail.set(userName, [auditTrailItem]);
                        }
                    }
                }
            });
            setUsersAuditTrail(usersAuditTrail);
        }
    }, [reduxAuditTrail]);

    const getTabTitles = () => {
        let tabTitles = [];
        usersAuditTrail.forEach(function (value, key) {
            tabTitles.push(
                <Tab key={key}>
                    {key === 'ALL' && <SubjectIcon>{key}</SubjectIcon>}
                    {key !== 'ALL' && <PersonIcon>{key}</PersonIcon>}
                    {key}
                </Tab>
            );
        });
        return tabTitles;
    };

    const getAuditItems = auditValue => {
        return auditValue.map((value, key) => {
            return (
                <Tr key={key}>
                    <Td column="createdDate" data-test="audit-date-time">
                        {formatDateTime(value.created, getAgencyTimezoneFromUser(loggedInUser))}
                    </Td>
                    <Td column="message" data-test="audit-message" className={value.className || ''}>
                        {value.message}
                    </Td>
                </Tr>
            );
        });
    };

    const getGeneralAuditItems = auditValue => {
        return auditValue.map((value, key) => {
            return (
                <Tr key={key}>
                    <Td column="createdDateOffice" data-test="audit-date-time">
                        {formatDateTime(value.created, getAgencyTimezoneFromAgency(loggedInUser.agency))}
                    </Td>
                    {isUserDifferentTimezone && (
                        <Td column="createdDateUser" data-test="audit-date-time">
                            {formatDateTime(value.created, getAgencyTimezoneFromUser(loggedInUser))}
                        </Td>
                    )}
                    <Td column="message" data-test="audit-message" className={value.className || ''}>
                        {value.message}
                    </Td>
                </Tr>
            );
        });
    };

    const getTabPanels = () => {
        const tabPanels = [];
        usersAuditTrail.forEach((auditItem, key) => {
            tabPanels.push(
                <TabPanel key={key}>
                    <div className="table-sort">
                        <div className="content-panel">
                            <Table>
                                <Thead>
                                    <Th className="created-column" column="createdDate">
                                        Created Date{' '}
                                    </Th>
                                    <Th className="message-column" column="message">
                                        Message{' '}
                                    </Th>
                                </Thead>
                                {getAuditItems(auditItem)}
                            </Table>
                        </div>
                    </div>
                </TabPanel>
            );
        });
        return tabPanels;
    };

    const getGeneralTabPanels = () => {
        const tabPanels = [];
        usersAuditTrail.forEach((auditItem, key) => {
            tabPanels.push(
                <TabPanel key={key}>
                    <div className="table-sort">
                        <div className="content-panel">
                            <Table>
                                <Thead>
                                    <Th className="created-column" column="createdDateOffice">
                                        <div className="header-title">Office date/time</div>
                                        <div className="header-sub-title">
                                            {loggedInUser.agency.details.officeTimezone}
                                        </div>
                                    </Th>
                                    {isUserDifferentTimezone && (
                                        <Th className="created-column" column="createdDateUser">
                                            <div className="header-title">User date/time</div>
                                            <div className="header-sub-title">{loggedInUser.timezone}</div>
                                        </Th>
                                    )}
                                    <Th className="message-column" column="message">
                                        Activity{' '}
                                    </Th>
                                </Thead>
                                {getGeneralAuditItems(auditItem)}
                            </Table>
                        </div>
                    </div>
                </TabPanel>
            );
        });
        return tabPanels;
    };

    return (
        <ModalDialog
            title="Audit trail"
            isOpen={isOpen}
            closeModal={closeModal}
            className="audit-trail-modal"
            zIndex={7}
        >
            <Tabs>
                <TabList>{getTabTitles()}</TabList>
                {isBusinessUserAccount(loggedInUser.accountType) ? getGeneralTabPanels() : getTabPanels()}
            </Tabs>
        </ModalDialog>
    );
};

export default memo(AuditTrailModal);
