import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Select } from './Select';
import { isAdmin, isBusinessUserAccount } from '../../utils/userUtils';
import { filter, includes, map } from 'lodash';
import SaveSharpIcon from '@flk-mui-icons/SaveSharp';
import EditButton from '../../common/components/EditButton';
import { getTeam } from '../../selectors/agency';
import { getUser, getUserInfo, getHasPermissionTo } from '../../selectors/user';
import useOnClickOutside from 'use-onclickoutside';

import '../../sass/editableContent.scss';
import { useAccountProvider } from '../providers/AccountProvider';

const EditableAgentContent = ({ agent, paramName, editContent, doc, disabled, label, className }) => {
    let [isEditable, setIsEditable] = useState(false);
    let [selectedAgent, setSelectedAgent] = useState(agent);

    const team = useSelector(getTeam);
    const user = useSelector(getUser);
    const userInfo = useSelector(getUserInfo);
    const hasPermissionTo = useSelector(getHasPermissionTo);
    const accountType = useAccountProvider();

    function handleClickOutside() {
        if (isEditable) {
            setIsEditable(false);
        }
    }

    useEffect(() => {
        setSelectedAgent(agent);
    }, [agent]);
    const ref = React.useRef(null);
    useOnClickOutside(ref, handleClickOutside);

    const changeHandler = value => {
        setSelectedAgent(value);
        setIsEditable(false);
        return editContent({
            [paramName]: value
        });
    };

    function getAgentNamesByIds() {
        // If document is locked, then agent can select only locked agents to assign for this document
        const isLockedDocument = doc && doc.locked;
        let permittedAgentsList;

        if (userInfo === true) {
            return [];
        }

        // If agent is admin and current documrnt is not locked, then agent can assign any agent in the team for this doc
        if (isAdmin(userInfo.role) && !isLockedDocument) {
            permittedAgentsList = team;
        } else {
            permittedAgentsList = filter(team, teamMember => {
                // If document is locked we can select only that locked agents to assign for this document
                if (isLockedDocument) {
                    return includes(doc.visibilityList, teamMember.id);
                } else {
                    // If document is not locked and agent is not an admin, then all agents in permission list can be assigned for this document
                    return includes(hasPermissionTo, teamMember.id);
                }
            });

            //Add the loggedIn agent to the list
            if (team && team.length > 0 && !isLockedDocument) {
                permittedAgentsList.unshift(team.find(currentMember => currentMember.id === user.userInfo.id));
            }
        }

        return map(permittedAgentsList, value => {
            if (value && value.id) {
                return { value: value.id, label: value.fullName };
            }
        });
    }

    const onEdit = () => {
        setIsEditable(oldIsEditable => !oldIsEditable);
    };

    const agentList = getAgentNamesByIds();

    if (isEditable) {
        return (
            <div className="header-select-dropdown" ref={ref}>
                <div className="onClick" onClick={onEdit} />
                <Select
                    options={agentList}
                    onChange={value => changeHandler(value)}
                    value={selectedAgent}
                    isSearchable={true}
                    defaultMenuIsOpen={true}
                    name="select-agent"
                />
                <button className="btn" onClick={() => setIsEditable(false)}>
                    <SaveSharpIcon />
                </button>
            </div>
        );
    } else {
        return (
            <div className={className}>
                <div
                    onClick={onEdit}
                    className={`agent-info header-container for-lg-modal ${disabled ? 'disabled' : ''}`}
                >
                    <label className="select-label" htmlFor="select-agent">
                        <p>
                            <b>{label || isBusinessUserAccount(accountType) ? 'Assigned sender' : 'Agent'}:&nbsp;</b>
                        </p>
                    </label>
                    <div className="edit-group">
                        <p className="header-agent" data-test="agent-name-header">
                            {selectedAgent.label}
                        </p>
                        <EditButton id="edit-agent" disabled={disabled} />
                    </div>
                </div>
            </div>
        );
    }
};

export default EditableAgentContent;
