import {
    DOCUMENT_TERMINATION_NOTICE,
    DOCUMENT_RENT_REDUCTION,
    DOCUMENT_RENT_INCREASE,
    DOCUMENT_CUSTOM,
    DISCLOSURE_DOCUMENT,
    DOCUMENT_CREATE_A_FLK,
    DOCUMENT_CREATE_MODE,
    BREACH_NOTICE,
    DOCUMENT_READ_ONLY_MODE,
    ENTRY_NOTICE,
    DOCUMENT_INTENTION_TO_SELL,
    DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE,
    EXCHANGE_OF_CONTRACTS
} from '../config';
import * as documentActions from '../actions/document';
import produce from 'immer';
import {
    CHANGE_DOCUMENT_TITLE_SUCCESS,
    CHANGE_DOCUMENT_AGENT_SUCCESS,
    UPDATE_DOCUMENT_PROGRESS,
    CHANGE_DOCUMENT_TITLE_REQUEST,
    CHANGE_DOCUMENT_AGENT_REQUEST,
    UPLOAD_INTEGRATION_DOCUMENT_FOR_TERMINATION_NOTICE,
    UPLOAD_INTEGRATION_DOCUMENT_FOR_INTENTION_TO_SELL_NOTICE,
    UPLOAD_INTEGRATION_DOCUMENT_FOR_ENTRY_NOTICE,
    UPLOAD_INTEGRATION_DOCUMENT_FOR_RENT_INCREASE,
    UPLOAD_INTEGRATION_DOCUMENT_FOR_BREACH_NOTICE
} from './dashboard';
import { BULK_SEND_APPROVAL_STATUS_APPROVED, BULK_SEND_APPROVAL_STATUS_DECLINED } from '../constants/constants';
import { LEASE_STATUS_DRAFT } from '../actions/dashboard';
import { UploadStatus } from '../constants/constants';

const initState = {
    docType: null,
    currentDocument: null,
    editMode: null,
    emailingTerminationId: null,
    isOpenTerminationNoticeForm: false,
    isSendingTerminateLease: false,
    isSendingTerminateLeaseSuccess: false,
    isSendingTerminateLeaseFail: false,
    isEmailingTerminateLease: false,
    isEmailingTerminateLeaseSuccess: false,
    isEmailingTerminateLeaseFail: false,
    rentReductionId: null,
    isCompletingRentReduction: false,
    isCompletingRentReductionSuccess: false,
    isCompletingRentReductionFail: false,
    isCancelingRentReduction: false,
    isCancelingRentReductionSuccess: false,
    isCancelingRentReductionFail: false,
    isResendingRentReduction: false,
    isResendingRentReductionSuccess: false,
    isResendingRentReductionFail: false,
    docsListChange: false,

    //Rent reduction
    isOpenRentReductionForm: false,
    isSendingRentReduction: false,
    isSendingRentReductionSuccess: false,
    isSendingRentReductionFail: false,
    rentReductionData: {
        tenants: [
            {
                fullName: '',
                email: ''
            }
        ]
    },
    //Rent increase
    isOpenRentIncreaseForm: false,
    validationErrors: null,
    availableClientFieldDetails: {},
    availableQuestions: {},
    pdfGeneratedState: '',
    files: []
};

export default function document(state = initState, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case documentActions.OPEN_CREATE_DOC_FORM: {
            if (action.docType === DOCUMENT_TERMINATION_NOTICE) {
                return produce(state, draftState => {
                    draftState.isOpenTerminationNoticeForm = true;
                    draftState.currentDocument = action.doc ? action.doc : null;
                    draftState.editMode = action.editMode;
                    draftState.isGlobalTemplate = action.isGlobalTemplate;
                });
            } else if (action.docType === DOCUMENT_RENT_REDUCTION) {
                return produce(state, draftState => {
                    draftState.isOpenRentReductionForm = true;
                    draftState.currentDocument = action.doc ? action.doc : null;
                    draftState.editMode = action.editMode;
                });
            } else if (action.docType === DOCUMENT_RENT_INCREASE) {
                return produce(state, draftState => {
                    draftState.isOpenRentIncreaseForm = true;
                    draftState.currentDocument = action.doc ? action.doc : null;
                    draftState.editMode = action.editMode;
                });
            } else if (action.docType === DOCUMENT_CUSTOM) {
                return produce(state, draftState => {
                    draftState.isOpenCustomDocumentModal = true;
                    draftState.currentDocument = action.doc ? action.doc : null;
                    draftState.files = action.files;
                    if (
                        action.doc &&
                        action.doc.bulkSendApprovalStatus &&
                        (action.doc.bulkSendApprovalStatus === BULK_SEND_APPROVAL_STATUS_APPROVED ||
                            action.doc.bulkSendApprovalStatus === BULK_SEND_APPROVAL_STATUS_DECLINED)
                    ) {
                        draftState.editMode = DOCUMENT_READ_ONLY_MODE;
                    } else {
                        draftState.editMode = action.editMode;
                    }
                    draftState.pdfGeneratedState = action.doc ? action.doc.pdfGeneratedState : '';
                    draftState.isGlobalTemplate = action.isGlobalTemplate;
                });
            } else if (action.docType === DISCLOSURE_DOCUMENT) {
                return produce(state, draftState => {
                    draftState.isOpenDisclosureDocumentModal = true;
                    draftState.currentDocument = action.doc ? action.doc : null;
                    draftState.editMode = action.editMode;
                });
            } else if (action.docType === DOCUMENT_CREATE_A_FLK) {
                return produce(state, draftState => {
                    draftState.isOpenCreateFlkModal = true;
                    draftState.currentDocument = action.doc ? action.doc : null;
                    draftState.editMode = action.editMode;
                    draftState.isGlobalTemplate = action.isGlobalTemplate;
                });
            } else if (action.docType === BREACH_NOTICE) {
                return produce(state, draftState => {
                    draftState.isOpenBreachAgreementModal = true;
                    draftState.currentDocument = action.doc ? action.doc : null;
                    draftState.editMode = action.editMode;
                });
            } else if (action.docType === ENTRY_NOTICE) {
                return produce(state, draftState => {
                    draftState.isOpenEntryNoticeModal = true;
                    draftState.currentDocument = action.doc ? action.doc : null;
                    draftState.editMode = action.editMode;
                });
            } else if (action.docType === DOCUMENT_INTENTION_TO_SELL) {
                return produce(state, draftState => {
                    draftState.isOpenIntentionToSellDocumentModal = true;
                    draftState.currentDocument = action.doc ? action.doc : null;
                    draftState.editMode = action.editMode;
                });
            } else if (action.docType === DOCUMENT_ESTIMATED_SELLING_PRICE_NOTICE) {
                return produce(state, draftState => {
                    draftState.isOpenEstimatedSellingPriceNoticeDocumentModal = true;
                    draftState.currentDocument = action.doc ? action.doc : null;
                    draftState.editMode = action.editMode;
                });
            } else if (action.docType === EXCHANGE_OF_CONTRACTS) {
                return produce(state, draftState => {
                    draftState.isExchangeOfContractsModalOpen = true;
                    draftState.currentDocument = action.doc ? action.doc : null;
                    draftState.editMode = action.editMode;
                });
            }
            break;
        }

        case documentActions.SET_CURRENT_DOCUMENT: {
            return produce(state, draftState => {
                draftState.currentDocument = action.currentDocument;
                draftState.pdfGeneratedState = action.pdfGeneratedState || '';
            });
        }

        case documentActions.SET_PDF_GEN_STATE: {
            return produce(state, draftState => {
                draftState.pdfGeneratedState = action.pdfGeneratedState;
            });
        }

        case documentActions.CLOSE_CREATE_DOC_FORM: {
            return produce(state, draftState => {
                draftState.isOpenTerminationNoticeForm = false;
                draftState.currentDocument = null;
            });
        }

        case documentActions.CLOSE_RENT_REDUCTION_MODAL: {
            return produce(state, draftState => {
                draftState.isOpenRentReductionForm = false;
                draftState.currentDocument = null;
            });
        }
        case documentActions.CLOSE_RENT_INCREASE_MODAL: {
            return produce(state, draftState => {
                draftState.isOpenRentIncreaseForm = false;
                draftState.currentDocument = null;
            });
        }
        case documentActions.CLOSE_BREACH_MODAL: {
            return produce(state, draftState => {
                draftState.isOpenBreachAgreementModal = false;
                draftState.currentDocument = null;
            });
        }
        case documentActions.CLOSE_INTENTION_TO_SELL_MODAL: {
            return produce(state, draftState => {
                draftState.isOpenIntentionToSellDocumentModal = false;
                draftState.currentDocument = null;
            });
        }
        case documentActions.CLOSE_ESTIMATED_SELLING_PRICE_MODAL: {
            return produce(state, draftState => {
                draftState.isOpenEstimatedSellingPriceNoticeDocumentModal = false;
                draftState.currentDocument = null;
            });
        }
        case documentActions.CLOSE_ENTRY_NOTICE_MODAL: {
            return produce(state, draftState => {
                draftState.isOpenEntryNoticeModal = false;
                draftState.currentDocument = null;
            });
        }
        case documentActions.CLOSE_ESTIMATED_SELLING_NOTICE_MODAL: {
            return produce(state, draftState => {
                draftState.isOpenEstimatedSellingPriceNoticeDocumentModal = false;
                draftState.currentDocument = null;
            });
        }

        case documentActions.CLOSE_CUSTOM_DOCUMENT_MODAL: {
            return produce(state, draftState => {
                draftState.isOpenCustomDocumentModal = false;
                draftState.currentDocument = null;
                draftState.pdfGeneratedState = '';
            });
        }

        case documentActions.CLOSE_DISCLOSURE_DOCUMENT_MODAL: {
            return produce(state, draftState => {
                draftState.isOpenDisclosureDocumentModal = false;
                draftState.currentDocument = null;
            });
        }

        case documentActions.CLOSE_CREATE_FLK_MODAL: {
            return produce(state, draftState => {
                draftState.isOpenCreateFlkModal = false;
                draftState.currentDocument = null;
            });
        }

        case documentActions.CLOSE_EXCHANGE_OF_CONTRACTS_MODAL: {
            return produce(state, draftState => {
                draftState.isExchangeOfContractsModalOpen = false;
                draftState.currentDocument = null;
            });
        }

        case documentActions.TERMINATE_WITHOUT_LEASE_CLEAR_SUCCESS: {
            return produce(state, draftState => {
                draftState.isSendingTerminateLease = false;
                draftState.isSendingTerminateLeaseSuccess = false;
                draftState.isSendingTerminateLeaseFail = false;
            });
        }

        case documentActions.EMAIL_TERMINATE_WITHOUT_LEASE_REQUEST: {
            return produce(state, draftState => {
                draftState.isEmailingTerminateLease = true;
                draftState.isEmailingTerminateLeaseSuccess = false;
                draftState.isEmailingTerminateLeaseFail = false;
                draftState.emailingTerminationId = action.payload.docId;
            });
        }

        case documentActions.EMAIL_TERMINATE_WITHOUT_LEASE_SUCCESS: {
            return produce(state, draftState => {
                draftState.isEmailingTerminateLease = false;
                draftState.isEmailingTerminateLeaseSuccess = true;
                draftState.isEmailingTerminateLeaseFail = false;
            });
        }

        case documentActions.EMAIL_TERMINATE_WITHOUT_LEASE_FAIL: {
            return produce(state, draftState => {
                draftState.isEmailingTerminateLease = false;
                draftState.isEmailingTerminateLeaseSuccess = false;
                draftState.isEmailingTerminateLeaseFail = true;
            });
        }
        case documentActions.DISCLOSURE_DOCUMENT_CREATE_SUCCESS: {
            return produce(state, draftState => {
                draftState.currentDocument = action.doc ? action.doc : null;
            });
        }

        case documentActions.SEND_COMPLETE_RENT_REDUCTION_REQUEST: {
            return produce(state, draftState => {
                draftState.isCompletingRentReduction = true;
                draftState.isCompletingRentReductionSuccess = false;
                draftState.isCompletingRentReductionFail = false;
                draftState.rentReductionId = action.payload.docId;
            });
        }

        case documentActions.SEND_COMPLETE_RENT_REDUCTION_SUCCESS: {
            return produce(state, draftState => {
                draftState.isCompletingRentReduction = false;
                draftState.isCompletingRentReductionSuccess = true;
                draftState.isCompletingRentReductionFail = false;
                draftState.isOpenRentReductionForm = false;
            });
        }

        case documentActions.SEND_COMPLETE_RENT_REDUCTION_FAIL: {
            return produce(state, draftState => {
                draftState.isCompletingRentReduction = false;
                draftState.isCompletingRentReductionSuccess = false;
                draftState.isCompletingRentReductionFail = true;
            });
        }
        case documentActions.CANCEL_SIGNING_DOCUMENT_REQUEST: {
            return produce(state, draftState => {
                draftState.isCancelingRentReduction = true;
                draftState.isCancelingRentReductionSuccess = false;
                draftState.isCancelingRentReductionFail = false;
                draftState.rentReductionId = action.payload.docId;
            });
        }

        case documentActions.CANCEL_SIGNING_DOCUMENT_SUCCESS: {
            return produce(state, draftState => {
                draftState.isCancelingRentReduction = false;
                draftState.isCancelingRentReductionSuccess = true;
                draftState.isCancelingRentReductionFail = false;
                draftState.editMode = DOCUMENT_CREATE_MODE;
                draftState.currentDocument = action.result.data.doc;
                draftState.pdfGeneratedState = action.result.data.doc ? action.result.data.doc.pdfGeneratedState : '';
            });
        }

        case documentActions.CANCEL_SIGNING_DOCUMENT_FAIL: {
            return produce(state, draftState => {
                draftState.isCancelingRentReduction = false;
                draftState.isCancelingRentReductionSuccess = false;
                draftState.isCancelingRentReductionFail = true;
            });
        }
        case documentActions.SET_DOCUMENT_TYPE: {
            return produce(state, draftState => {
                draftState.docType = action.payload.docType;
            });
        }
        case documentActions.RESEND_RENT_REDUCTION_REQUEST: {
            return produce(state, draftState => {
                draftState.isResendingRentReduction = true;
                draftState.isResendingRentReductionSuccess = false;
                draftState.isResendingRentReductionFail = false;
                draftState.rentReductionId = action.payload.docId;
            });
        }
        case documentActions.RESEND_RENT_REDUCTION_SUCCESS: {
            return produce(state, draftState => {
                draftState.isResendingRentReduction = false;
                draftState.isResendingRentReductionSuccess = true;
                draftState.isResendingRentReductionFail = false;
            });
        }
        case documentActions.RESEND_RENT_REDUCTION_FAIL: {
            return produce(state, draftState => {
                draftState.isResendingRentReduction = false;
                draftState.isResendingRentReductionSuccess = false;
                draftState.isResendingRentReductionFail = true;
            });
        }
        case CHANGE_DOCUMENT_TITLE_REQUEST:
        case CHANGE_DOCUMENT_AGENT_REQUEST: {
            return produce(state, draftState => {
                draftState.docsListChange = false;
            });
        }
        case CHANGE_DOCUMENT_TITLE_SUCCESS: {
            return produce(state, draftState => {
                draftState.docsListChange = true;
                draftState.currentDocument.documentTitle = action.payload.newTitle;
            });
        }
        case CHANGE_DOCUMENT_AGENT_SUCCESS:
        case documentActions.REFRESH_DOCUMENT_LIST: {
            return produce(state, draftState => {
                draftState.docsListChange = true;
            });
        }

        case documentActions.CLOSE_ALL_DOCUMENT_MODALS: {
            return produce(state, draftState => {
                draftState.isOpenTerminationNoticeForm = false;
                draftState.isOpenRentReductionForm = false;
                draftState.isOpenRentIncreaseForm = false;
                draftState.isOpenCustomDocumentModal = false;
                draftState.isOpenDisclosureDocumentModal = false;
                draftState.isOpenCreateFlkModal = false;
                draftState.isExchangeOfContractsModalOpen = false;
            });
        }

        case documentActions.OPEN_CANCEL_REASON_MODAL: {
            return produce(state, draftState => {
                draftState.isOpenCancelReasonModal = true;
                draftState.documentToCancel = action.payload.doc;
                draftState.cancelClone = action.payload.clone;
            });
        }

        case documentActions.CLOSE_CANCEL_REASON_MODAL: {
            return produce(state, draftState => {
                draftState.isOpenCancelReasonModal = false;
                draftState.documentToCancel = null;
            });
        }

        case documentActions.CANCEL_DOCUMENT_SUCCESS: {
            return produce(state, draftState => {
                draftState.isOpenCancelReasonModal = false;
                draftState.documentToCancel = null;
            });
        }

        case documentActions.CLONE_DOCUMENT_SUCCESS: {
            return produce(state, draftState => {
                draftState.docsListChange = true;
            });
        }

        case documentActions.DOCUMENT_LIST_RESET: {
            return produce(state, draftState => {
                draftState.docsListChange = false;
            });
        }

        case documentActions.GET_CLIENT_FIELD_DETAILS_SUCCESS: {
            return produce(state, draftState => {
                const details = action.result.data.clientFieldDetails
                    ? action.result.data.clientFieldDetails.clients
                    : [];
                draftState.availableClientFieldDetails[action.payload.documentType] = details;
            });
        }
        case documentActions.GET_CLIENT_FIELD_DETAILS_FAIL: {
            return produce(state, draftState => {
                draftState.availableClientFieldDetails[action.payload.documentType] = [];
            });
        }
        case documentActions.GET_QUESTIONS_SUCCESS: {
            return produce(state, draftState => {
                const questions = action.result.data.questions ? action.result.data.questions : [];
                draftState.availableQuestions[action.payload.document] = questions;
            });
        }
        case documentActions.GET_QUESTIONS_FAIL: {
            return produce(state, draftState => {
                draftState.availableQuestions[action.payload.document] = [];
            });
        }
        case documentActions.SET_DISABLED_FOR_APPROVAL: {
            return produce(state, draftState => {
                draftState.isSendButtonDisabledForApproval = action.payload.value;
            });
        }
        case UPDATE_DOCUMENT_PROGRESS: {
            const {
                completion,
                id,
                status,
                pdf,
                integrationUploadStatus,
                lastSuccessfulUploadToIntegration,
                integration
            } = action.payload;
            return produce(state, draftState => {
                if (draftState.currentDocument && draftState.currentDocument.id === id) {
                    if (status !== LEASE_STATUS_DRAFT) {
                        draftState.editMode = DOCUMENT_READ_ONLY_MODE;
                    }
                    if (completion) {
                        draftState.currentDocument.completion = completion;
                    }
                    if (pdf) {
                        draftState.currentDocument.pdf = pdf;
                    }
                    if (draftState.currentDocument.status !== status) {
                        draftState.currentDocument.status = status;
                    }
                    if (integrationUploadStatus) {
                        draftState.currentDocument.integrationUploadStatus = integrationUploadStatus;
                    }
                    if (lastSuccessfulUploadToIntegration) {
                        draftState.currentDocument.lastSuccessfulUploadToIntegration =
                            lastSuccessfulUploadToIntegration;
                    }
                    if (integration) {
                        if (!draftState.currentDocument.integration) {
                            draftState.currentDocument.integration = {};
                        }
                        draftState.currentDocument.integration.uploadStatus = integration.uploadStatus;
                    }
                }
            });
        }
        case documentActions.UPDATE_BULK_SEND_STATUS_REQUEST: {
            let sendingDecline;
            let sendingApproval;
            if (action.payload.data.approval) {
                sendingApproval = true;
                sendingDecline = false;
            } else {
                sendingApproval = false;
                sendingDecline = true;
            }
            return produce(state, draftState => {
                draftState.sendingApproval = sendingApproval;
                draftState.sendingDecline = sendingDecline;
            });
        }

        case documentActions.SHOW_APPROVAL_BUTTONS: {
            return produce(state, draftState => {
                draftState.bulkStatusEditMode = true;
                draftState.sendingDecline = false;
                draftState.sendingApproval = false;
            });
        }

        case documentActions.UPDATE_BULK_SEND_STATUS_SUCCESS: {
            return produce(state, draftState => {
                draftState.isSendButtonDisabledForApproval =
                    action.result.data.doc.bulkSendApprovalStatus !== BULK_SEND_APPROVAL_STATUS_APPROVED;
                draftState.currentDocument = action.result.data.doc;
                draftState.pdfGeneratedState = action.result.data.doc ? action.result.data.doc.pdfGeneratedState : '';
                draftState.bulkStatusEditMode = false;
                draftState.sendingDecline = false;
                draftState.sendingApproval = false;
            });
        }

        case documentActions.UPDATE_BULK_SEND_STATUS_FAIL: {
            return produce(state, draftState => {
                draftState.bulkStatusEditMode = false;
                draftState.sendingDecline = false;
                draftState.sendingApproval = false;
            });
        }

        case UPLOAD_INTEGRATION_DOCUMENT_FOR_TERMINATION_NOTICE: {
            return produce(state, draftState => {
                draftState.currentDocument.integration.uploadStatus = UploadStatus.UPLOADING;
            });
        }

        case UPLOAD_INTEGRATION_DOCUMENT_FOR_INTENTION_TO_SELL_NOTICE: {
            return produce(state, draftState => {
                draftState.currentDocument.integration.uploadStatus = UploadStatus.UPLOADING;
            });
        }

        case UPLOAD_INTEGRATION_DOCUMENT_FOR_ENTRY_NOTICE: {
            return produce(state, draftState => {
                draftState.currentDocument.integration.uploadStatus = UploadStatus.UPLOADING;
            });
        }

        case UPLOAD_INTEGRATION_DOCUMENT_FOR_RENT_INCREASE: {
            return produce(state, draftState => {
                draftState.currentDocument.integration.uploadStatus = UploadStatus.UPLOADING;
            });
        }

        case documentActions.SET_EDIT_MODE: {
            return produce(state, draftState => {
                draftState.editMode = action.payload.editMode;
            });
        }

        case UPLOAD_INTEGRATION_DOCUMENT_FOR_BREACH_NOTICE: {
            return produce(state, draftState => {
                draftState.currentDocument.integration.uploadStatus = UploadStatus.UPLOADING;
            });
        }
    }

    return newState;
}
