import React from 'react';

import { EXCHANGE_OF_CONTRACTS_DISPLAY } from '@app/config';
import { ExchangeOfContracts } from '@app/types/ExchangeOfContracts';
import VisibilityListModal from '@app/components/modals/VisibilityListModal';
import { ListSharp, LockOpenSharp, LockSharp } from '@app/common/components/mui-icons/icons';
import Button from '@app/common/components/Button';
import Icon, { Icons } from '@app/common/components/Icon';
import EditableAgentContent from '@app/components/form/EditableAgentContent';
import FlowDocumentsMoreOptionBlock from '@app/components/dashboard/documentList/cells/FlowDocumentsMoreOptionBlock';
import { Tag, TagColor, TagSize } from '@app/common/components/tag/Tag';
import {
    LEASE_STATUS_AWAITING_COMPLETION,
    LEASE_STATUS_COMPLETE,
    LEASE_STATUS_DRAFT,
    LEASE_STATUS_SENT_SIGNING
} from '@app/actions/dashboard';
import { EocDraftEditSenderConfirmation } from '@app/components/modals/document/EditSenderConfirmation';
import { getAllPdfSenderDetails } from '@app/utils/documentUtils';

import styles from './Header.module.scss';

type StatusTagProps = {
    status: string;
};

const StatusTag: React.FC<StatusTagProps> = ({ status }) => {
    switch (status) {
        case LEASE_STATUS_DRAFT:
            return <Tag text="Draft" size={TagSize.X_Small} />;
        case LEASE_STATUS_SENT_SIGNING:
            return <Tag text="Sent for signing" size={TagSize.X_Small} />;
        case LEASE_STATUS_AWAITING_COMPLETION:
            return <Tag text="Awaiting completion" size={TagSize.X_Small} color={TagColor.Orange} />;
        case LEASE_STATUS_COMPLETE:
            return <Tag text="Complete" size={TagSize.X_Small} color={TagColor.Green} />;
        default:
            return null;
    }
};

type HeaderProps = {
    eocDocument: ExchangeOfContracts;
    closeModal: () => void;
    updateAgent: (agentId: string) => Promise<void>;
    saveDoc?: () => Promise<void>; // Only required in drafts
    openAuditTrail: () => void;
    editRecipients?: () => void;
    isUpdatingAgent?: boolean;
    openPdfViewer?: () => void;
};

// TODO: Editable address (future ticket)
export const Header: React.FC<HeaderProps> = ({
    eocDocument,
    closeModal,
    updateAgent,
    saveDoc,
    openAuditTrail,
    editRecipients,
    isUpdatingAgent,
    openPdfViewer
}) => {
    const [isOpenLockedModal, setIsOpenLockedModal] = React.useState(false);

    const memoizedAgent = React.useMemo(() => {
        return { value: eocDocument.agent.id, label: eocDocument.agent.fullName };
    }, [eocDocument.agent.id, eocDocument.agent.fullName]);

    const [agentToDisplay, setAgentToDisplay] = React.useState(memoizedAgent);

    const [isEditSenderConfirmationModalOpen, setIsEditSenderConfirmationModalOpen] = React.useState(false);

    const pdfSenderDetails = React.useMemo(() => {
        return getAllPdfSenderDetails(eocDocument.uploadedDocuments);
    }, [eocDocument.uploadedDocuments]);

    return (
        <>
            <div>
                <div className={styles.container}>
                    <div className={styles.header}>
                        <h1 className={styles.heading}>{EXCHANGE_OF_CONTRACTS_DISPLAY}</h1>
                        <StatusTag status={eocDocument.status} />
                        <div className={styles.actions}>
                            {eocDocument.auditTrail.length > 0 && (
                                <Button className={styles.button} type="button" onClick={openAuditTrail}>
                                    <ListSharp />
                                </Button>
                            )}
                            <Button className={styles.button} type="button" onClick={() => setIsOpenLockedModal(true)}>
                                {eocDocument.locked ? <LockSharp /> : <LockOpenSharp />}
                            </Button>
                            <FlowDocumentsMoreOptionBlock
                                agreementStatus={eocDocument.status}
                                leaseType={eocDocument.docType}
                                doc={eocDocument}
                                className={styles.contextMenu}
                                editSigners={editRecipients}
                                signersLabel="Recipients"
                            />
                            <Button className={styles.button} type="button" onClick={closeModal}>
                                <Icon className={styles.closeIcon} icon={Icons.CLOSE} />
                            </Button>
                        </div>
                    </div>
                    <div className={styles.address}>
                        <span>
                            <strong>Address:</strong>
                        </span>
                        <span>{eocDocument.address}</span>
                    </div>
                    <EditableAgentContent
                        agent={agentToDisplay}
                        paramName="selectedAgent"
                        doc={eocDocument}
                        editContent={({ selectedAgent }) => {
                            if (eocDocument.agent.id !== selectedAgent.value) {
                                const shouldConfirmChange = pdfSenderDetails.totalAnnotationCount > 0;
                                if (shouldConfirmChange) {
                                    setAgentToDisplay(selectedAgent);
                                    setIsEditSenderConfirmationModalOpen(true);
                                } else {
                                    updateAgent(selectedAgent.value);
                                }
                            }
                        }}
                        className={styles.editAgent}
                    />
                    <VisibilityListModal
                        doc={eocDocument}
                        isOpenLockedModal={isOpenLockedModal}
                        closeModal={() => setIsOpenLockedModal(false)}
                        saveDoc={saveDoc}
                    />
                </div>
                <hr className={styles.divider} />
            </div>
            {isEditSenderConfirmationModalOpen && (
                <EocDraftEditSenderConfirmation
                    isOpen={isEditSenderConfirmationModalOpen}
                    close={() => setIsEditSenderConfirmationModalOpen(false)}
                    pdfAgentName={pdfSenderDetails.name}
                    updateAgent={async () => {
                        await updateAgent(agentToDisplay.value);
                        setIsEditSenderConfirmationModalOpen(false);
                    }}
                    openPdfViewer={() => {
                        openPdfViewer?.();
                    }}
                    cancelChange={() => {
                        setAgentToDisplay(memoizedAgent);
                    }}
                    isLoading={!!isUpdatingAgent}
                />
            )}
        </>
    );
};
