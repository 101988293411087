import React from 'react';
import Icon, { Icons } from '@app/common/components/Icon';

import styles from './ConfirmAlert.module.scss';
import Button from '@app/common/components/Button';

type ConfirmAlertProps = {
    title?: string;
    message: React.ReactNode;
    confirmText: string;
    cancelText: string;
    onConfirm: (() => void) | (() => Promise<void>);
    onCancel: () => void;
    onClose: () => void;
};

const ConfirmAlert: React.FC<ConfirmAlertProps> = ({
    title,
    message,
    confirmText,
    cancelText,
    onConfirm,
    onCancel,
    onClose
}) => {
    const [isBusy, setIsBusy] = React.useState(false);

    return (
        <div className={styles.confirmAlert}>
            <div className={styles.header}>
                {title && <h4 className={styles.title}>{title}</h4>}
                <Button type="button" onClick={onClose} className={styles.closeButton}>
                    <Icon icon={Icons.CLOSE} />
                </Button>
            </div>
            <div className={styles.body}>{message}</div>
            <div className={styles.footer}>
                <Button type="button" onClick={onCancel} tertiary className={styles.actionButton} disabled={isBusy}>
                    {cancelText}
                </Button>
                <Button
                    type="button"
                    onClick={async () => {
                        setIsBusy(true);
                        await onConfirm();
                        setIsBusy(false);
                    }}
                    primary
                    className={styles.actionButton}
                    loading={isBusy}
                >
                    {confirmText}
                </Button>
            </div>
        </div>
    );
};

export default ConfirmAlert;
