import React from 'react';
import { useForm } from 'react-final-form';
import { RadioButtonGroup, RadioButtonGroupStyle } from '@app/common/design-system/radio-button-group/RadioButtonGroup';
import Listen from '../buildADoc/components/Listen';
import { EmailSendType, Recipient, getHelpText } from './AwaitingCompletionForm.model';
import { EmailFieldSet } from './EmailFieldSet';

import styles from './EmailNotificationFields.module.scss';

type EmailNotificationsFieldProps = {
    emailSendType: EmailSendType;
    recipients: Recipient[];
    agentEmail: string;
    disabled?: boolean;
};

const EmailNotificationFields: React.FC<EmailNotificationsFieldProps> = ({
    emailSendType,
    recipients,
    agentEmail,
    disabled
}) => {
    const form = useForm();
    const showEmailSendType = recipients.length > 1;

    return (
        <div className={styles.container}>
            {showEmailSendType && (
                <div className={styles.sendType}>
                    <RadioButtonGroup
                        name="emailSendType"
                        radioGroupStyle={RadioButtonGroupStyle.SEPARATED}
                        options={[
                            { label: 'Send to Everyone', value: EmailSendType.EVERYONE },
                            { label: 'Send Separately', value: EmailSendType.SEPARATELY }
                        ]}
                        disabled={disabled}
                    />
                    <div className={styles.subTitle}>{getHelpText(emailSendType)}</div>
                </div>
            )}
            {emailSendType === EmailSendType.EVERYONE && (
                <EmailFieldSet title={recipients.map(recipient => recipient.name).join(' + ')} disabled={disabled} />
            )}
            {emailSendType === EmailSendType.SEPARATELY && (
                <div className={styles.separateEmailsContainer}>
                    {recipients.map((recipient, index) => (
                        <EmailFieldSet
                            key={index}
                            namePrefix={`recipients.${index}`}
                            title={recipient.name}
                            disabled={disabled}
                        />
                    ))}
                </div>
            )}
            <Listen
                to="emailSendType"
                onChange={value => {
                    form.batch(() => {
                        if (value === EmailSendType.EVERYONE) {
                            form.change(
                                'to',
                                recipients.map(recipient => recipient.email)
                            );
                            form.change('cc', [agentEmail]);
                            form.change('bcc', []);
                        } else if (value === EmailSendType.SEPARATELY) {
                            recipients.forEach((recipient, index) => {
                                form.change(`recipients.${index}.to`, [recipient.email]);
                                form.change(`recipients.${index}.cc`, [agentEmail]);
                                form.change(`recipients.${index}.bcc`, []);
                            });
                        }
                    });
                }}
            />
        </div>
    );
};

export default EmailNotificationFields;
