import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cookie from 'react-cookie';
import { useHistory, useParams } from 'react-router-dom';
import appHistory from '../../AppHistory';
import '../../sass/layout.scss';
import '../../sass/_welcome.scss';
import { PreLoader } from '../../common/components/PreLoader';
import * as dashboard from '../../actions/dashboard';
import * as loginActions from '../../actions/login';
import axios from 'axios';
import { getIsPending } from '../../selectors/dashboard/agreementList';
import { useRollbarProvider } from '../../components/providers/RollbarProvider';
import {
    mappingsToFrontEnd,
    mapLeaseStatusToCategory,
    isLeaseTypeSales,
    isLeaseTypePm,
    isLeaseTypeHolidayLetting,
    getV2RoutePath
} from '../../utils/agreementUtils';
import { getSavedTeamMemberSelection, getUserInfo } from '../../selectors/user';
import { DEFAULT_PAGE, DEFAULT_RECORDS_PER_PAGE, NSW_STATE } from '../../config';
import { confirmAlert } from 'react-confirm-alert';
import { PERMANENTLY_GONE_STATUS_CODE } from '../../utils/retryPolicies.ts';

const Agreement = () => {
    const dispatch = useDispatch();
    const { leaseId } = useParams();
    const history = useHistory();
    const Rollbar = useRollbarProvider();

    const isPending = useSelector(getIsPending);
    const savedTeamMemberSelection = useSelector(getSavedTeamMemberSelection);
    const loggedInUser = useSelector(getUserInfo);

    let [lease, setLease] = useState(false);

    useEffect(() => {
        document.title = 'Loading agreement';
        if (!cookie.load('userFromLogin')) {
            dispatch(loginActions.saveDirectLeaseId(leaseId));
            appHistory.push('/');
        } else {
            getLease();
        }
    }, []);

    useEffect(() => {
        if (!isPending && lease && loggedInUser && loggedInUser.agency) {
            //Check if user agency and lease agency are correct
            if (lease.agency.id === loggedInUser.agency.id) {
                history.push(
                    `/dashboard/agreements/${mappingsToFrontEnd[lease.leaseType]}/${mapLeaseStatusToCategory(
                        lease.status
                    )}`
                );
                dispatch(dashboard.openAgreementInfoModal(lease));
                window.history.pushState({}, '', `/dashboard/agreement/${lease.id}`);
            } else {
                history.push(
                    `/dashboard/agreements/${mappingsToFrontEnd[lease.leaseType]}/${mapLeaseStatusToCategory(
                        lease.status
                    )}`
                );
                confirmAlert({
                    title: '',
                    message: `This agreement is linked to ${lease.agency.details.agencyName}, you are currently logged into ${loggedInUser.agency.details.agencyName} please switch to the correct account.`,
                    buttons: [
                        {
                            label: 'OK',
                            onClick: () => {
                                window.history.pushState({}, '', `/dashboard/agreement/${lease.id}`);
                            }
                        }
                    ]
                });
            }
        }
    }, [isPending, loggedInUser]);

    const getLease = () => {
        return axios
            .get(`/api/agency/lease/${leaseId}`)
            .then(result => {
                const lease = result.data.lease;
                dispatch(loginActions.clearDirectLeaseId());

                const { id, leaseType, location, schemaVersion } = lease;
                // We can't use feature flags here because at this time all ff are undefined
                // Therefore all agreements with schemaVersion will be opened in new architecture
                const canOpenInV2 =
                    isLeaseTypeHolidayLetting(leaseType) ||
                    (isLeaseTypeSales(leaseType) && schemaVersion && location === NSW_STATE) ||
                    (isLeaseTypePm(leaseType) && schemaVersion && location === NSW_STATE);
                if (canOpenInV2) {
                    const agreementPath = getV2RoutePath(leaseType);
                    const agreementLocation = location.toLowerCase();
                    window.location.href = `/apps/${agreementPath}/${agreementLocation}/${id}`;
                } else {
                    setLease(lease);
                    dispatch(
                        dashboard.getAgreementList(
                            mappingsToFrontEnd[lease.leaseType],
                            DEFAULT_PAGE,
                            DEFAULT_RECORDS_PER_PAGE,
                            mapLeaseStatusToCategory(lease.status),
                            savedTeamMemberSelection,
                            undefined
                        )
                    );
                }
            })
            .catch(error => {
                Rollbar?.error(
                    'Failed to get lease from direct URL ',
                    {
                        error_message: error.message,
                        status: 'error',
                        env: ENVIRONMENT
                    },
                    error
                );
                if (error?.response?.status === PERMANENTLY_GONE_STATUS_CODE) {
                    dispatch(loginActions.clearDirectLeaseId());
                } else {
                    dispatch(loginActions.saveDirectLeaseId(leaseId));
                }
                appHistory.push('/');
            });
    };

    return (
        <div className="preloader-centred">
            <PreLoader />
        </div>
    );
};

export default memo(Agreement);
