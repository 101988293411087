import { emailValidationSchema } from '@app/components/Validate';
import { EocClient } from '@app/types/ExchangeOfContracts';
import * as Yup from 'yup';

export enum EmailRecipientType {
    PURCHASERS = 'purchasers',
    PURCHASER_SOLICITOR = 'purchaserSolicitor',
    VENDORS = 'vendors',
    VENDOR_SOLICITOR = 'vendorSolicitor'
}

type EmailFields = {
    to?: string[];
    cc?: string[];
    bcc?: string[];
};

export type FormModel = {
    emails: {
        [EmailRecipientType.PURCHASERS]: EmailFields;
        [EmailRecipientType.PURCHASER_SOLICITOR]: EmailFields;
        [EmailRecipientType.VENDORS]: EmailFields;
        [EmailRecipientType.VENDOR_SOLICITOR]: EmailFields;
    };
};

export const getInitialFormValues = (purchasers: EocClient[], vendors: EocClient[], agentEmail: string): FormModel => {
    const purchaserEmails = purchasers.map(purchaser => purchaser.email);
    const vendorEmails = vendors.map(vendor => vendor.email);

    return {
        emails: {
            [EmailRecipientType.PURCHASERS]: {
                to: purchaserEmails,
                cc: [agentEmail]
            },
            [EmailRecipientType.PURCHASER_SOLICITOR]: {
                to: [],
                cc: [agentEmail, ...purchaserEmails]
            },
            [EmailRecipientType.VENDORS]: {
                to: vendorEmails,
                cc: [agentEmail]
            },
            [EmailRecipientType.VENDOR_SOLICITOR]: {
                to: [],
                cc: [agentEmail, ...vendorEmails]
            }
        }
    };
};

const emailArraySchema = Yup.array().of(emailValidationSchema);
const requiredEmailArraySchema = emailArraySchema
    .required('Please enter an email address')
    .min(1, 'Please enter an email address');

export const validationSchema = Yup.object().shape({
    emails: Yup.object().shape({
        [EmailRecipientType.PURCHASERS]: Yup.object().shape({
            to: requiredEmailArraySchema,
            cc: emailArraySchema,
            bcc: emailArraySchema
        }),
        [EmailRecipientType.PURCHASER_SOLICITOR]: Yup.object().shape({
            to: emailArraySchema,
            cc: emailArraySchema,
            bcc: emailArraySchema
        }),
        [EmailRecipientType.VENDORS]: Yup.object().shape({
            to: requiredEmailArraySchema,
            cc: emailArraySchema,
            bcc: emailArraySchema
        }),
        [EmailRecipientType.VENDOR_SOLICITOR]: Yup.object().shape({
            to: emailArraySchema,
            cc: emailArraySchema,
            bcc: emailArraySchema
        })
    })
});
