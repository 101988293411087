import { isUploadedDocument } from '@app/types/UploadADoc';
import React from 'react';
import { ApiModel, FormModel } from '../../ExchangeOfContracts.model';
import { getUserInfo } from '@app/selectors/user';
import { useSelector } from 'react-redux';
import { ExchangeOfContracts } from '@app/types/ExchangeOfContracts';

type Params = {
    eocDocument: ExchangeOfContracts;
    localDocumentList: ApiModel['uploadedDocuments'];
    save: (
        values: FormModel,
        localDocumentList: ApiModel['uploadedDocuments'],
        options: { silent: boolean }
    ) => Promise<void>;
    setSelectedUploadedDocId: (id: string | undefined) => void;
};

export const usePdfViewer = ({ eocDocument, localDocumentList, save, setSelectedUploadedDocId }: Params) => {
    const loggedInUser = useSelector(getUserInfo);

    const [isPdfViewerOpen, setIsPdfViewerOpen] = React.useState(false);
    const [isSavingForPdfViewer, setIsSavingForPdfViewer] = React.useState(false);
    const [hasOpenedPdfViewer, setHasOpenedPdfViewer] = React.useState(false);

    const openPdfViewer = async (values: FormModel) => {
        const firstUploadedDocument = localDocumentList.find(document => isUploadedDocument(document));
        if (firstUploadedDocument) {
            setIsSavingForPdfViewer(true);

            await save(values, localDocumentList, { silent: true });

            setIsSavingForPdfViewer(false);
            setSelectedUploadedDocId(firstUploadedDocument.id);
            setIsPdfViewerOpen(true);
            setHasOpenedPdfViewer(true);
        }
    };

    const closePdfViewer = React.useCallback(() => {
        setIsPdfViewerOpen(false);
        setSelectedUploadedDocId(undefined);
    }, [setIsPdfViewerOpen, setSelectedUploadedDocId]);

    const memoizedAgencyDetails = React.useMemo(() => {
        return {
            name: loggedInUser?.agency?.details.agencyName,
            imageUrl: loggedInUser?.agency?.logo
        };
    }, [loggedInUser]);

    const memoizedAgent = React.useMemo(() => {
        return {
            fullName: eocDocument.agent.fullName,
            accountType: eocDocument.agent.accountType
        };
    }, [eocDocument]);

    return {
        memoizedAgencyDetails,
        memoizedAgent,
        closePdfViewer,
        openPdfViewer,
        isPdfViewerOpen,
        isSavingForPdfViewer,
        hasOpenedPdfViewer
    };
};
