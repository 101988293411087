import React, { memo } from 'react';
import { FormRadioGroup } from '../../../../components/form/FormRadioGroup';
import { YES_LABEL, YES_VALUE, NO_LABEL, NO_VALUE } from '../../../../config';

import AllowAnimals from '../../../../components/lease/mainScreen/common/additionalTerms/AllowAnimals';
import UnitOrTownhouse from '../../../../components/lease/mainScreen/common/additionalTerms/UnitOrTownhouse.tsx';
import DefenceClause from '../../../../components/lease/mainScreen/common/additionalTerms/DefenceClause';
import { FormTextRegular } from '../../../../components/form/FormText';
import SwimmingPool from '../../../../components/lease/mainScreen/common/additionalTerms/SwimmingPool';

const ActAdditional = ({ values, addPet, allowPets, clearPets, push, additional, location }) => {
    return (
        <React.Fragment>
            <AllowAnimals
                values={values}
                allowPets={allowPets}
                clearPets={clearPets}
                addPet={addPet}
                push={push}
                additional={additional}
                location={location}
            />
            <DefenceClause />
            <FormRadioGroup
                label="Break Lease clause enabled?"
                name={'breakLeaseClause'}
                addInfoIcon={true}
                infoIconToolTipData="breakLeaseToolTip"
                className="additional-term-item"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'break-lease-clause-yes',
                        id: 'break-lease-clause-yes',
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'break-lease-clause-no',
                        id: 'break-lease-clause-no',
                        value: NO_VALUE
                    }
                ]}
            />
            <FormRadioGroup
                label="Fair clause enabled?"
                name={'fairClause'}
                className="additional-term-item"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'fair-clause-yes',
                        id: 'fair-clause-yes',
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'fair-clause-no',
                        id: 'fair-clause-no',
                        value: NO_VALUE
                    }
                ]}
            />
            <FormRadioGroup
                label="90 Day Maintenance clause enabled? "
                name={'maintenanceClause'}
                className="additional-term-item"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'maintenance-clause-yes',
                        id: 'maintenance-clause-yes',
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'maintenance-clause-no',
                        id: 'maintenance-clause-no',
                        value: NO_VALUE
                    }
                ]}
            />
            <FormRadioGroup
                label="Is the property part of an embedded network?"
                name={'embeddedNetwork'}
                className="additional-term-item"
                data={[
                    {
                        label: YES_LABEL,
                        className: 'part-of-embedded-network-yes',
                        id: 'part-of-embedded-network-yes',
                        value: YES_VALUE
                    },
                    {
                        label: NO_LABEL,
                        className: 'part-of-embedded-network-no',
                        id: 'part-of-embedded-network-no',
                        value: NO_VALUE
                    }
                ]}
            />
            {values.embeddedNetwork === YES_VALUE && (
                <FormTextRegular
                    name="embeddedNetworkServices"
                    label="What services are included as part of the embedded network (eg. Electricity, Gas)?"
                />
            )}
            <SwimmingPool values={values} hasSwimingPoolMaintenance={false} location={location} />
            <UnitOrTownhouse values={values} />
        </React.Fragment>
    );
};

export default memo(ActAdditional);
