import React, { useMemo } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { EmailFieldSet } from '../../../FlkAPdf/EmailFieldSet';
import { EocClient, ExchangeOfContracts } from '@app/types/ExchangeOfContracts';
import {
    FormModel,
    getInitialFormValues,
    validationSchema,
    EmailRecipientType
} from './AwaitingCompletionEmailForm.model';
import { hydrateValidationErrors, scrollToError } from '@app/utils/finalFormUtils';
import { AccountType } from '@app/constants/constants';
import { EocAwaitingCompletionEditSenderConfirmation } from '@app/components/modals/document/EditSenderConfirmation';
import { useDispatch, useSelector } from 'react-redux';
import { getAgencyName, getUserInfo } from '@app/selectors/user';
import { getAllPdfSenderDetails } from '@app/utils/documentUtils';
import { getAgentSignature } from '@app/selectors/completion';
import { isEmpty } from 'lodash';
import { openNoSignatureModal } from '@app/actions/user';

import styles from './AwaitingCompletionEmailForm.module.scss';

interface SampleEmailProps {
    variant: EmailRecipientType;
    recipientNames?: string;
    address: string;
    agentName: string;
    agentEmail: string;
    agentPhone: string;
    agencyName: string;
}

const SampleEmail: React.FC<SampleEmailProps> = ({
    variant,
    recipientNames: recipientName,
    address,
    agentEmail,
    agentName,
    agentPhone,
    agencyName
}) => {
    switch (variant) {
        case EmailRecipientType.PURCHASERS:
        case EmailRecipientType.VENDORS:
            return (
                <div className={styles.sampleEmail}>
                    <h2 className={styles.title}>Email Preview</h2>
                    <div className={styles.emailSubject}>
                        <strong>Subject:</strong> Confirmation of Contract Exchange - {address}
                    </div>
                    <div className={styles.emailBody}>
                        <p>Hi {recipientName},</p>
                        <p>Congratulations! The contract for {address} has been signed and exchanged.</p>
                        <p>Attached is a copy of the contract for your records.</p>
                        <p>Please contact me if you have any questions.</p>
                        <p>
                            Regards,
                            <br />
                            {agentName}
                            <br />
                            {agencyName}
                            <br />
                            {agentEmail}
                            <br />
                            {agentPhone}
                        </p>
                    </div>
                </div>
            );
        case EmailRecipientType.PURCHASER_SOLICITOR:
        case EmailRecipientType.VENDOR_SOLICITOR:
            return (
                <div className={styles.sampleEmail}>
                    <h2 className={styles.title}>Email Preview</h2>
                    <div className={styles.emailSubject}>
                        <strong>Subject:</strong> Confirmation of Contract Exchange - {address}
                    </div>
                    <div className={styles.emailBody}>
                        <p>Dear Solicitor/Conveyancer,</p>
                        <p>I'm writing to confirm that contracts have now been exchanged for {address}.</p>
                        <p>
                            Please proceed with the necessary post-exchange steps, and let me know if you require any
                            further information to proceed.
                        </p>
                        <p>
                            Regards,
                            <br />
                            {agentName}
                            <br />
                            {agencyName}
                            <br />
                            {agentEmail}
                            <br />
                            {agentPhone}
                        </p>
                    </div>
                </div>
            );
    }
};

interface AwaitingCompletionEmailFormProps {
    onSubmit: (values: FormModel) => Promise<void>;
    formId: string;
    purchasers: EocClient[];
    vendors: EocClient[];
    isCompletionInProgress?: boolean;
    eocDocument: ExchangeOfContracts;
    isApiBusy: boolean;
}

export const AwaitingCompletionEmailForm: React.FC<AwaitingCompletionEmailFormProps> = ({
    onSubmit,
    formId,
    purchasers,
    vendors,
    isCompletionInProgress,
    eocDocument,
    isApiBusy
}) => {
    const dispatch = useDispatch();
    const agentSignature = useSelector(getAgentSignature);
    const loggedInUser = useSelector(getUserInfo);
    const agencyName = useSelector(getAgencyName);

    const {
        address,
        agent: { fullName: agentName, email: agentEmail, phone: agentPhone, id: agentId }
    } = eocDocument;
    const initialValues = useMemo(
        () => getInitialFormValues(purchasers, vendors, agentEmail),
        [purchasers, vendors, agentEmail]
    );

    const [isEditSenderConfirmationModalOpen, setIsEditSenderConfirmationModalOpen] = React.useState(false);

    const pdfSenderDetails = useMemo(() => {
        return getAllPdfSenderDetails(eocDocument.uploadedDocuments);
    }, [eocDocument.uploadedDocuments]);

    return (
        <Form<FormModel>
            onSubmit={values => {
                const errors = hydrateValidationErrors(() =>
                    validationSchema.validateSync(values, { abortEarly: false })
                );
                if (errors) {
                    scrollToError();
                    return errors;
                }

                if (eocDocument.hasAgentSignatureAnnotations && isEmpty(agentSignature)) {
                    dispatch(openNoSignatureModal());
                    return;
                }

                const shouldShowEditSenderConfirmation =
                    agentId !== loggedInUser.id && pdfSenderDetails.totalAnnotationCount > 0;

                if (shouldShowEditSenderConfirmation) {
                    setIsEditSenderConfirmationModalOpen(true);
                    return;
                }

                return onSubmit(values);
            }}
            initialValues={initialValues}
            mutators={{ ...arrayMutators }}
        >
            {({ handleSubmit, values }) => {
                const purchaserNamesString = purchasers.map(purchaser => purchaser.name).join(', ');
                const vendorNamesString = vendors.map(vendor => vendor.name).join(', ');

                return (
                    <form id={formId} onSubmit={handleSubmit}>
                        <div className={styles.container}>
                            <h3 className={styles.subheading}>Purchasers</h3>
                            <div className={styles.emailFieldSets}>
                                <EmailFieldSet
                                    title="Purchasers"
                                    descriptionText="Enter your Purchasers' contact emails in the relevant fields and press 'Enter/Return' to confirm."
                                    namePrefix={`emails.${EmailRecipientType.PURCHASERS}`}
                                    disabled={isCompletionInProgress}
                                    tooltip={{
                                        triggerText: 'View email preview',
                                        content: (
                                            <SampleEmail
                                                variant={EmailRecipientType.PURCHASERS}
                                                recipientNames={purchaserNamesString}
                                                address={address}
                                                agentName={agentName}
                                                agentEmail={agentEmail}
                                                agentPhone={agentPhone}
                                                agencyName={agencyName}
                                            />
                                        )
                                    }}
                                />
                                <EmailFieldSet
                                    title="Purchaser Solicitor or Conveyancer"
                                    descriptionText="Enter your Purchaser Solicitor or Conveyancer contact emails in the relevant fields and press 'Enter/Return' to confirm."
                                    namePrefix={`emails.${EmailRecipientType.PURCHASER_SOLICITOR}`}
                                    disabled={isCompletionInProgress}
                                    tooltip={{
                                        triggerText: 'View email preview',
                                        content: (
                                            <SampleEmail
                                                variant={EmailRecipientType.PURCHASER_SOLICITOR}
                                                address={address}
                                                agentName={agentName}
                                                agentEmail={agentEmail}
                                                agentPhone={agentPhone}
                                                agencyName={agencyName}
                                            />
                                        )
                                    }}
                                    isToOptional
                                />
                            </div>
                            <h3 className={styles.subheading}>Vendors</h3>
                            <div className={styles.emailFieldSets}>
                                <EmailFieldSet
                                    title="Vendors"
                                    descriptionText="Enter your Vendors' contact emails in the relevant fields and press 'Enter/Return' to confirm."
                                    namePrefix={`emails.${EmailRecipientType.VENDORS}`}
                                    disabled={isCompletionInProgress}
                                    tooltip={{
                                        triggerText: 'View email preview',
                                        content: (
                                            <SampleEmail
                                                variant={EmailRecipientType.VENDORS}
                                                recipientNames={vendorNamesString}
                                                address={address}
                                                agentName={agentName}
                                                agentEmail={agentEmail}
                                                agentPhone={agentPhone}
                                                agencyName={agencyName}
                                            />
                                        )
                                    }}
                                />
                                <EmailFieldSet
                                    title="Vendor Solicitor or Conveyancer"
                                    descriptionText="Enter your Vendor Solicitor or Conveyancer contact emails in the relevant fields and press 'Enter/Return' to confirm."
                                    namePrefix={`emails.${EmailRecipientType.VENDOR_SOLICITOR}`}
                                    disabled={isCompletionInProgress}
                                    tooltip={{
                                        triggerText: 'View email preview',
                                        content: (
                                            <SampleEmail
                                                variant={EmailRecipientType.VENDOR_SOLICITOR}
                                                address={address}
                                                agentName={agentName}
                                                agentEmail={agentEmail}
                                                agentPhone={agentPhone}
                                                agencyName={agencyName}
                                            />
                                        )
                                    }}
                                    isToOptional
                                />
                            </div>
                        </div>
                        <EocAwaitingCompletionEditSenderConfirmation
                            isOpen={isEditSenderConfirmationModalOpen}
                            close={() => setIsEditSenderConfirmationModalOpen(false)}
                            primaryHandler={async () => {
                                await onSubmit(values);
                                setIsEditSenderConfirmationModalOpen(false);
                            }}
                            pdfAgentName={pdfSenderDetails.name}
                            secondaryHandler={() => setIsEditSenderConfirmationModalOpen(false)}
                            assignedAgentName={agentName}
                            accountType={AccountType.AGENCY_ACCOUNT_TYPE}
                            isLoading={isApiBusy}
                            loggedInUserName={loggedInUser.fullName}
                        />
                    </form>
                );
            }}
        </Form>
    );
};

export default AwaitingCompletionEmailForm;
