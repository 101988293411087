import Icon, { Icons } from '@app/common/components/Icon';
import { STANDARD_DATE_TIME_FORMAT_WITH_AT } from '@app/config';
import { INTEGRATION_LABEL, integration, UploadStatus } from '@app/constants/constants';
import { format } from 'date-fns';
import React, { MouseEvent } from 'react';
import Button from '../../common/components/Button.js';

import cx from 'classnames';
import styles from './IntegrationUploadStatus.module.scss';

type IntegrationUploadStatusProps = {
    upload: () => void;
    uploadStatus?: UploadStatus;
    lastSuccessfulUpload?: Date;
    integration: integration;
    className?: string;
};

const IntegrationUploadStatus: React.FC<IntegrationUploadStatusProps> = ({
    upload,
    lastSuccessfulUpload,
    integration,
    className,
    uploadStatus = UploadStatus.DEFAULT
}) => {
    const lastSuccessfulUploadFormatted = lastSuccessfulUpload
        ? format(new Date(lastSuccessfulUpload), STANDARD_DATE_TIME_FORMAT_WITH_AT)
        : '';

    const isUploading = uploadStatus === UploadStatus.UPLOADING;
    const isError = uploadStatus === UploadStatus.FAILED;
    const isSuccess = uploadStatus === UploadStatus.SUCCESS;
    const isDefault = uploadStatus === UploadStatus.DEFAULT;

    const integrationLabel = INTEGRATION_LABEL[integration];

    const getUploadButtonText = () => {
        if (isUploading) {
            return 'Uploading...';
        }
        if (isError || isSuccess) {
            return 'Reupload';
        }
        if (isDefault) {
            return 'Upload';
        }
    };

    const getDescriptionText = () => {
        if (isUploading) {
            return 'In progress...';
        }
        if (isError) {
            return `Unable to upload this Agreement to ${integrationLabel}.`;
        }
        if (isSuccess) {
            return `Last updated ${lastSuccessfulUploadFormatted}`;
        }
        if (isDefault) {
            return `This Agreement hasn't been uploaded to '${integrationLabel}' yet...`;
        }
    };

    const handleButtonClick = (e: MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        if (!isUploading) {
            upload();
        }
    };

    return (
        <div className={cx(styles.integrationUploadStatusContainer, className)}>
            <h2>{INTEGRATION_LABEL[integration]}</h2>
            <div
                className={cx(
                    styles.uploadSection,
                    { [styles.isError]: isError },
                    { [styles.isUploading]: isUploading }
                )}
            >
                <div className={styles.body}>
                    <div className={styles.content}>
                        <div className={styles.title}>
                            <p>File Upload</p>
                            {isUploading && <Icon className={styles.alertSyncIcon} icon={Icons.ALERT_SYNC} />}
                            {isError && <Icon className={styles.noteErrorIcon} icon={Icons.NOTE_ERROR} />}
                            {isSuccess && <Icon className={styles.noteSuccessIcon} icon={Icons.NOTE_SUCCESS} />}
                        </div>
                        <p className={styles.description}>{getDescriptionText()}</p>
                    </div>
                    {!isSuccess && (
                        <Button
                            className={styles.uploadButton}
                            primary
                            {...(!isUploading && {
                                startIcon: (
                                    <Icon
                                        className={styles.uploadIcon}
                                        icon={isDefault ? Icons.UPLOAD : Icons.RE_UPLOAD}
                                    />
                                )
                            })}
                            loading={isUploading}
                            onClick={(e: MouseEvent<HTMLButtonElement, MouseEvent>) => handleButtonClick(e)}
                        >
                            {getUploadButtonText()}
                        </Button>
                    )}
                </div>

                {isError && (
                    <p className={styles.footer}>
                        Please try again. If the problem persists, you can contact our Customer Support team via
                        livechat or email.
                    </p>
                )}
            </div>
        </div>
    );
};

export default IntegrationUploadStatus;
