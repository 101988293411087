import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { includes, isEmpty } from 'lodash';
import { getUserInfo, getAgencyLocation } from '../selectors/user';
import {
    getAgencyInfo,
    getAgencyPropertyMeIntegration,
    getAgencyPropertyTreeIntegration,
    getAgencyVaultReIntegration
} from '../selectors/agency';
import { openLeaseAgreementForm } from '../actions/lease';
import { openDocumentForm } from '../actions/document';
import { openFlkAKeyForm } from '../actions/flkAKey';
import { syncAccountIntegration } from '../actions/account';
import * as dashboard from '../actions/dashboard';
import { isAgencyUserAccount } from '../utils/userUtils';
import { useAccountProvider } from '../components/providers/AccountProvider';
import {
    LEASE_TYPE_RESIDENTIAL,
    getAvailableStates,
    STATUS_ACTIVE,
    DOCUMENT_CUSTOM,
    DOCUMENT_CREATE_A_FLK,
    DOCUMENT_CREATE_MODE,
    LEASE_TYPE_COMMERCIAL_LEASE,
    LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT,
    LEASE_TYPE_HOLIDAY_LETTING,
    LEASE_TYPE_PROPERTY_MANAGEMENT,
    LEASE_TYPE_SALES,
    NSW_STATE,
    QLD_STATE
} from '../config';
import { useLeaseTypeEnabled } from './useLeaseTypeEnabled';
import { AgreementOrDocumentTypes, AgreementTypes, DocumentTypes, SubLeaseTypes } from '../types/Agreement';
import {
    PM_SUBAPP_PATH,
    COMMERCIAL_PM_SUBAPP_PATH,
    HOLIDAY_LETTING_SUBAPP_PATH,
    SALES_SUBAPP_PATH
} from '@app/constants/constants';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';

import { openSubapp } from '@app/utils/openSubapp';
import { DAGOBAH_QLD_RPMA } from '@app/constants/featureFlags';

const useOpenCreationModal = (afterOpenCreationModal?: () => void) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const accountType = useAccountProvider();
    const loggedInUser = useSelector(getUserInfo);
    const agency = useSelector(getAgencyInfo);
    const agencyLocation = useSelector(getAgencyLocation);
    const propertyMeIntegration = useSelector(getAgencyPropertyMeIntegration);
    const propertyTreeIntegration = useSelector(getAgencyPropertyTreeIntegration);
    const vaultReIntegration = useSelector(getAgencyVaultReIntegration);
    const { isFeatureFlagEnabled } = useLeaseTypeEnabled();

    const isDagobahQldRPMAEnabled = useFeatureFlag(DAGOBAH_QLD_RPMA);

    const isLeaseTypeEnabled = (documentType: AgreementOrDocumentTypes | SubLeaseTypes): boolean => {
        if (documentType && isAgencyUserAccount(accountType)) {
            if (includes(getAvailableStates(documentType), agencyLocation)) {
                return isFeatureFlagEnabled(documentType, agencyLocation);
            }
            return false;
        } else {
            return true;
        }
    };

    const isDagobahAgreement = (documentType: AgreementOrDocumentTypes | SubLeaseTypes, location: string) => {
        switch (documentType) {
            case LEASE_TYPE_PROPERTY_MANAGEMENT:
                return [NSW_STATE, isDagobahQldRPMAEnabled && QLD_STATE].filter(Boolean).includes(location);
            default:
                return false;
        }
    };

    const isActiveUser = (): boolean => {
        const isActiveUser = loggedInUser.status === STATUS_ACTIVE;
        const isActiveSubscription = agency && !isEmpty(agency.tariffPlan) && agency.tariffPlan.active;
        return isActiveUser && isActiveSubscription;
    };

    const syncIntegrations = () => {
        // We intentionally do not sync managed
        if (propertyMeIntegration || propertyTreeIntegration || vaultReIntegration) {
            dispatch(syncAccountIntegration());
        }
    };

    const openLeaseAgreementCreationModal = (leaseAgreementType: AgreementTypes, keepSameUrl?: boolean) => {
        if (!isLeaseTypeEnabled(leaseAgreementType) || !isActiveUser()) {
            return;
        }
        if (afterOpenCreationModal) {
            afterOpenCreationModal();
        }
        // Navigate to creation modal
        if (!keepSameUrl) {
            history.push(
                `/dashboard/agreements/${leaseAgreementType}/${dashboard.agreementStatuses[leaseAgreementType][0]}`
            );
        }
        dispatch(openLeaseAgreementForm(leaseAgreementType));
        if (leaseAgreementType === LEASE_TYPE_RESIDENTIAL || leaseAgreementType === LEASE_TYPE_COMMERCIAL_LEASE) {
            syncIntegrations();
        }
    };

    const openDocumentCreationModal = (documentType: DocumentTypes, files = []) => {
        if (!isLeaseTypeEnabled(documentType) || !isActiveUser()) {
            return;
        }
        if (afterOpenCreationModal) {
            afterOpenCreationModal();
        }
        if (documentType === DOCUMENT_CUSTOM || documentType === DOCUMENT_CREATE_A_FLK) {
            // We use a query for Create a FLK and FLK a PDF so we can open the modal by navigating to the route
            window.history.pushState(
                {},
                '',
                `/dashboard/documents/${documentType}/${dashboard.agreementStatuses[documentType][0]}?create=${documentType}`
            );
        } else {
            history.push(`/dashboard/documents/${documentType}/${dashboard.agreementStatuses[documentType][0]}`);
        }
        dispatch(openDocumentForm(documentType, null, DOCUMENT_CREATE_MODE, false, files));
        syncIntegrations();
    };

    const openFlkAKeyCreationModal = () => {
        // Same but for Flk a key
        if (afterOpenCreationModal) {
            afterOpenCreationModal();
        }
        history.push(`/dashboard/flk-a-key/${dashboard.FLK_A_KEY_STATUS_DRAFT}`);
        dispatch(openFlkAKeyForm());
        syncIntegrations();
    };

    const handleOpenSubLeaseAgreementForm = (subLeaseType: SubLeaseTypes) => {
        if (!isLeaseTypeEnabled(subLeaseType) || !isActiveUser()) {
            return;
        }
        if (afterOpenCreationModal) {
            afterOpenCreationModal();
        }
        // navigate to relevant section
        history.push(
            `/dashboard/agreements/${LEASE_TYPE_RESIDENTIAL}/${dashboard.agreementStatuses[LEASE_TYPE_RESIDENTIAL][0]}`
        );
        dispatch(openLeaseAgreementForm(subLeaseType));
    };

    const openLeaseCreationModalOrSubapp = (type: AgreementTypes, location: string, keepSameUrl?: boolean) => {
        if (type === LEASE_TYPE_PROPERTY_MANAGEMENT) {
            if (location === NSW_STATE || (isDagobahQldRPMAEnabled && location === QLD_STATE)) {
                openSubapp(PM_SUBAPP_PATH);
            } else {
                openLeaseAgreementCreationModal(LEASE_TYPE_PROPERTY_MANAGEMENT, keepSameUrl);
            }
        } else if (type === LEASE_TYPE_COMMERCIAL_PROPERTY_MANAGEMENT) {
            openSubapp(COMMERCIAL_PM_SUBAPP_PATH);
        } else if (type === LEASE_TYPE_HOLIDAY_LETTING) {
            openSubapp(HOLIDAY_LETTING_SUBAPP_PATH);
        } else if (type === LEASE_TYPE_SALES) {
            if (location === NSW_STATE) {
                openSubapp(SALES_SUBAPP_PATH);
            } else {
                openLeaseAgreementCreationModal(LEASE_TYPE_SALES, keepSameUrl);
            }
        }
    };

    return {
        openLeaseAgreementCreationModal,
        openDocumentCreationModal,
        openFlkAKeyCreationModal,
        isLeaseTypeEnabled,
        isDagobahAgreement,
        isActiveUser,
        handleOpenSubLeaseAgreementForm,
        openLeaseCreationModalOrSubapp
    };
};

export default useOpenCreationModal;
