import React from 'react';
import cx from 'classnames';
import { useFieldArray } from 'react-final-form-arrays';
import { useFeatureFlag } from '@harnessio/ff-react-client-sdk';
import { useField, useForm } from 'react-final-form';

import CustomLabelTextInput from '../../../dashboard/documents/buildADoc/components/CustomLabelTextInput';
import CustomPlaceholder from '../../../dashboard/documents/createFlk/CustomPlaceholder';
import {
    CustomPlaceholder as CustomPlaceholderType,
    CustomPlaceholderRespondentType
} from '../../../../types/UploadADoc';
import { SIGNER_PLACEHOLDERS_DEMO } from '../../../../constants/featureFlags';
import { getCustomPlaceholderValuePlaceholderText } from './utils';
import CustomPlaceholderTypeDropdown from './CustomPlaceholderTypeDropdown';

import styles from './CustomPlaceholderInputs.module.scss';
import { getFormError } from '@app/utils/finalFormUtils';

type CustomPlaceholderRowProps = {
    name: string;
    disabled?: boolean;
    onDelete?: (customPlaceholderData: CustomPlaceholderType, index: number) => void;
    inputToFocusRef: React.RefObject<HTMLInputElement>;
    customPlaceholderData: CustomPlaceholderType;
    isSignerPlaceholderDemoActive: boolean;
    index: number;
    recipientLabelLowercase?: string;
};

const CustomPlaceholderRow: React.FC<CustomPlaceholderRowProps> = ({
    index,
    name,
    disabled,
    onDelete,
    inputToFocusRef,
    customPlaceholderData,
    isSignerPlaceholderDemoActive,
    recipientLabelLowercase
}) => {
    const errorsRef = React.useRef<HTMLDivElement>(null);

    const form = useForm();
    const values = form.getState().values;
    const numberOfClients = values.clients?.length || 0;

    const { meta: countMeta } = useField(`${name}.count`, {
        subscription: { error: true, submitError: true, dirtySinceLastSubmit: true }
    });

    const countError = getFormError(countMeta);

    return (
        <React.Fragment>
            <CustomLabelTextInput
                className={cx(styles.customPlaceholderInput, {
                    [styles.signerPlaceholder]:
                        isSignerPlaceholderDemoActive &&
                        customPlaceholderData.respondentType === CustomPlaceholderRespondentType.CLIENT
                })}
                name={name}
                disabled={disabled}
                showDeleteButton
                onDelete={onDelete ? () => onDelete(customPlaceholderData, index) : undefined}
                valuePlaceholder={getCustomPlaceholderValuePlaceholderText(customPlaceholderData.respondentType)}
                hidePlaceholder={
                    customPlaceholderData.respondentType === CustomPlaceholderRespondentType.SENDER && index !== 0
                }
                labelInputRef={inputToFocusRef}
                valueDisabled={customPlaceholderData.respondentType === CustomPlaceholderRespondentType.CLIENT}
                labelClassName={styles.customPlaceholderInputLabel}
                additionalErrors={countError ? [countError] : []}
                errorsRef={errorsRef}
            />
            {isSignerPlaceholderDemoActive && (
                <div className={styles.dropdownContainer}>
                    <CustomPlaceholderTypeDropdown
                        customPlaceholderName={name}
                        customPlaceholderRespondentType={customPlaceholderData.respondentType}
                        disabled={disabled}
                        isSignerPlaceholderOptionDisabled={numberOfClients > 1}
                        index={index}
                        recipientLabelLowercase={recipientLabelLowercase}
                    />
                    {errorsRef.current && (
                        <div
                            className={styles.errorSpacing}
                            style={{ height: `${errorsRef.current?.clientHeight}px` }}
                        />
                    )}
                </div>
            )}
            {(customPlaceholderData.label || customPlaceholderData.value) && (
                <div className={styles.customPlaceholderContainer}>
                    <CustomPlaceholder
                        className={styles.customPlaceholderPreview}
                        number={index + 1}
                        label={customPlaceholderData.label}
                        value={customPlaceholderData.value}
                        respondentType={customPlaceholderData.respondentType}
                        disabled={disabled}
                    />
                    {errorsRef.current && (
                        <div
                            className={styles.errorSpacing}
                            style={{ height: `${errorsRef.current?.clientHeight}px` }}
                        />
                    )}
                </div>
            )}
        </React.Fragment>
    );
};

type CustomPlaceholderInputsProps = {
    disabled?: boolean;
    onDelete?: (customPlaceholderData: CustomPlaceholderType, index: number) => void;
    inputToFocusRef: React.RefObject<HTMLInputElement>;
    recipientLabelLowercase?: string;
};

const CustomPlaceholderInputs: React.FC<CustomPlaceholderInputsProps> = ({
    disabled,
    onDelete,
    inputToFocusRef,
    recipientLabelLowercase
}) => {
    const isSignerPlaceholderDemoActive = useFeatureFlag(SIGNER_PLACEHOLDERS_DEMO);

    const customPlaceholdersFieldArray = useFieldArray('customPlaceholders');
    const { fields } = customPlaceholdersFieldArray;

    return (
        <div
            className={cx(styles.customPlaceholders, {
                [styles.signerPlaceholders]: isSignerPlaceholderDemoActive
            })}
        >
            {fields.map((name, index) => {
                const customPlaceholderData = fields.value[index];

                return (
                    <CustomPlaceholderRow
                        key={index}
                        index={index}
                        name={name}
                        disabled={disabled}
                        onDelete={onDelete}
                        inputToFocusRef={inputToFocusRef}
                        customPlaceholderData={customPlaceholderData}
                        isSignerPlaceholderDemoActive={isSignerPlaceholderDemoActive}
                        recipientLabelLowercase={recipientLabelLowercase}
                    />
                );
            })}
        </div>
    );
};

export default CustomPlaceholderInputs;
